import { NgModule } from '@angular/core';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { LicensesComponent } from './licenses.component';
import { StateSelectBoxModule } from '../../../state-select-box/state-select-box.module';

@NgModule({
  imports: [SharedModule, ModalWindowModule, ButtonWithIndicatorModule, StateSelectBoxModule],
  declarations: [LicensesComponent],
  exports: [LicensesComponent],
})
export class LicensesModule {}
