import { AgentKeys, LookupBase } from '@ag-common-lib/public-api';

export enum SalesGoalsTab {
  PERSONAL = 0,
  CONFERENCE = 1,
  MANAGER = 2,
}

export const tabToKey = new Map<number, string>([
  [SalesGoalsTab.PERSONAL, AgentKeys.personal_goals],
  [SalesGoalsTab.CONFERENCE, AgentKeys.conference_goals],
  [SalesGoalsTab.MANAGER, AgentKeys.manager_goals],
]);

export const tabToTitle = new Map<number, string>([
  [SalesGoalsTab.PERSONAL, 'Personal'],
  [SalesGoalsTab.CONFERENCE, 'Alliance Group Conference'],
  [SalesGoalsTab.MANAGER, 'Agency Director'],
]);

export const SALES_GOALS_LOOKUP: LookupBase[] = [
  { value: SalesGoalsTab.PERSONAL, description: tabToTitle.get(SalesGoalsTab.PERSONAL) },
  { value: SalesGoalsTab.CONFERENCE, description: tabToTitle.get(SalesGoalsTab.CONFERENCE) },
  { value: SalesGoalsTab.MANAGER, description: tabToTitle.get(SalesGoalsTab.MANAGER) },
];
