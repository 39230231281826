import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { LookupBase } from 'ag-common-lib/lib/models/utils/lookup-base.model';
import { Agent } from 'ag-common-lib/lib/models/domain/agent.model';
import { LookupKeys } from 'ag-common-lib/lib/models/crm/lookup.model';
import { shareReplay } from 'rxjs/operators';
import { AgentsService } from '../../../../../agents/agents.service';
import { Lookups } from 'ag-common-lib/lib/lists/lookups.enum';
import { normalizeLookup } from './utils/normalize-lookup';
import { LookupsService } from 'ag-common-svc/lib/services';

@Injectable()
export class AttendeeChangeLogService {
  agentsLookupBase$ = this.agentsService.allAgentsList$.pipe(
    map(
      (agents): Partial<LookupBase[]> =>
        Array.isArray(agents)
          ? agents.map((agent: Agent) => {
              const { dbId, p_agent_first_name, p_agent_last_name } = agent;
              return {
                [LookupKeys.value]: dbId,
                [LookupKeys.description]: [p_agent_first_name, p_agent_last_name].filter(Boolean).join(' '),
              };
            })
          : [],
    ),
    shareReplay(1),
  );

  constructor(private agentsService: AgentsService, private lookupsService: LookupsService) {}

  getLookupByName = (key: Lookups) => {
    return this.lookupsService.getLookupByName(key).pipe(
      map(items => normalizeLookup(items)),
      shareReplay(1),
    );
  };
}
