import { Component, HostBinding, Input } from '@angular/core';
import {
  Association,
  AssociationKeys,
  Messages,
  RegistrantKeys,
  RegistrantData,
  Constants,
} from 'ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { InitializedEvent } from 'devextreme/ui/form';

@Component({
  selector: 'ag-crm-assotiations-form',
  templateUrl: './associations-form.component.html',
  styleUrls: ['./associations-form.component.scss'],
})
export class AssociationsFormComponent {
  @HostBinding('class') protected className = 'associations-form';

  @Input() association: Partial<Association>;
  @Input() registrantData: Partial<RegistrantData>;
  @Input() validationGroup: string;
  @Input() emergencyContactRelationshipTypeValidationGroup: string;
  @Input() isReadonly: boolean = false;
  @Input() hasSelectedAssociationKey: boolean = false;
  @Input() colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 12,
    lg: 12,
  };
  @Input() screenLimits;
  protected formSize$ = new BehaviorSubject(null);
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly AssociationKeys = AssociationKeys;
  protected readonly Messages = Messages;
  protected readonly RegistrantKeys = RegistrantKeys;

  protected onFormInitialized = (e: InitializedEvent) => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };
}
