import { Inject, Injectable } from '@angular/core';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';
import { ActivityBaseKeys, Email } from 'ag-common-lib/public-api';
import { ACTIVITY_TYPE, TARGET_TYPE } from 'ag-common-lib/public-api';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { SessionControllerService } from './util/session-controller.service';
import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { filter, shareReplay, switchMap } from 'rxjs/operators';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from 'ag-common-svc/public-api';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends DataService<Email> {
  public emailList$: Observable<Email[]>;

  private _refreshTrigger$ = new BehaviorSubject<void>(undefined);

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, sessionControllerService: SessionControllerService) {
    super(fireBaseApp);
    super.setCollection('email-records');

    this.emailList$ = combineLatest([sessionControllerService.selectedAgentId$, this._refreshTrigger$]).pipe(
      filter(([selectedAgentId]) => !!selectedAgentId),
      switchMap(([targetId]) => this._getEmailList(targetId)),
      shareReplay(1),
    );
  }

  public refreshList = () => {
    this._refreshTrigger$.next();
  };

  private _getEmailList = (targetId: string) =>
    defer(() => {
      const qp: QueryParam[] = [];
      // qp.push(new QueryParam(ActivityBaseKeys.targetId, WhereFilterOperandKeys.equal, targetId));
      // TODO clarify. next queries looks redundant because of table contain only calls
      // qp.push(new QueryParam('target_type', WhereFilterOperandKeys.equal, TARGET_TYPE.AGENT));
      // qp.push(new QueryParam('activity_type', WhereFilterOperandKeys.equal, ACTIVITY_TYPE.EMAIL));

      return this.getAllByValue(qp);
    });

  getEmailByEmailId(email_id: string): Promise<Email[]> {
    return this.getAllByValue([new QueryParam('email_id', WhereFilterOperandKeys.equal, email_id)]);
  }
}
