import { Component, HostBinding } from '@angular/core';
import config from 'devextreme/core/config';
import { AppInfoService } from './shared/services/util/app-info.service';
import { ScreenService } from './shared/services/util/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter(cl => this.screen.sizes[cl])
      .join(' ');
  }

  constructor(private screen: ScreenService, public appInfo: AppInfoService) {
    config({
      editorStylingMode: 'outlined',
    });
  }
}
