<dx-form [(formData)]="conference" labelMode="floating" [colCount]="12" [readOnly]="isReadonlyMode">
  <dxi-item [dataField]="ConferenceKeys.bccEmail" [label]="{ text: 'BCC' }" [colSpan]="12">
    <div *dxTemplate>
      <dx-tag-box
        #tagBoxRef
        [dataSource]="dataSource"
        [(value)]="conference && conference[ConferenceKeys.bccEmail]"
        [searchEnabled]="true"
        [showDropDownButton]="true"
        [showClearButton]="true"
        [multiline]="false"
        [acceptCustomValue]="false"
        [label]="'BCC'"
        labelMode="floating"
        class="attendees-box"
        tagTemplate="tagTemplate"
        [placeholder]="''"
        [maxDisplayedTags]="3"
        [readOnly]="isReadonlyMode"
      >
        <div *dxTemplate="let tagData of 'tagTemplate'" class="dx-tag-content attendees-box__tag">
          <ng-container *ngIf="tagData?.name; else numTagTemp">
            <b>{{ tagData?.name }}</b> <span><{{ tagData?.address }}></span>
          </ng-container>

          <ng-template #numTagTemp
            ><b>{{ tagData }}</b></ng-template
          >

          <div class="dx-tag-remove-button"></div>
        </div>

        <div *dxTemplate="let data of 'item'">
          <b>{{ data?.name }}</b> <span><{{ data?.address }}></span>
        </div>
      </dx-tag-box>
    </div>
  </dxi-item>

  <dxi-item
    [colSpan]="4"
    editorType="dxSelectBox"
    [dataField]="ConferenceKeys.summaryEmailTemplate"
    [label]="{ text: 'Registration Summary Email Template' }"
    [editorOptions]="{
      items: emailTemplatesLookup,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
      searchEnabled: true,
      placeholder: ''
    }"
  >
  </dxi-item>
</dx-form>
