import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFormFieldVisible',
})
export class IsFormFieldVisiblePipe implements PipeTransform {
  transform(excludedFieldsSet: Set<string>, keys: string | string[]) {
    if (!excludedFieldsSet || !excludedFieldsSet?.size) {
      return true;
    }
    const registrantKeys = (Array.isArray(keys) ? keys : [keys]).filter(Boolean);

    if (!registrantKeys?.length) {
      return true;
    }

    return registrantKeys.some(key => !excludedFieldsSet?.has(key));
  }
}
