<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Phone Numbers'"
    [isEditVisible]="canEdit"
    (clickEdit)="showPhoneNumbersEditorModal()"
  ></ag-shr-editor-toolbar>

  <div class="row my-2 px-3" *ngIf="phoneNumbers.length > 0">
    <div class="col-6 text-start"></div>

    <div class="col-2 text-center">
      <b>Type</b>
    </div>
    <div class="col-2 text-center">
      <b>WhatsApp</b>
    </div>
    <div class="col-2 text-center">
      <b>Primary</b>
    </div>
  </div>
  <div class="row my-2 px-3 text-center" *ngIf="phoneNumbers.length == 0">
    <p class="col editor-empty-text">No Phone Numbers Currently Exist</p>
  </div>
  <ng-container *ngFor="let phoneNumber of phoneNumbers">
    <div class="row mb-2 px-3 py-1">
      <div class="col-6 text-start">
        {{ phoneNumber | phoneNumberMask }}
      </div>

      <div class="col-2 text-center">
        <span *ngIf="PhoneNumberTypeDescriptionMap.has(phoneNumber?.phone_type)">
          {{ PhoneNumberTypeDescriptionMap.get(phoneNumber?.phone_type) }}
        </span>
      </div>
      <div class="col-2 text-center">
        <i
          *ngIf="phoneNumber?.is_whatsapp"
          class="phone-numbers__icon phone-numbers__icon--whatsapp dx-icon-agi-whatsapp"
        ></i>
      </div>
      <div class="col-2 text-center">
        <i *ngIf="phoneNumber?.is_primary" class="dx-icon-check"></i>
      </div>
    </div>
  </ng-container>
</div>

<ag-shr-modal-window
  #phoneNumbersEditorModalRef
  [title]="'Add / Edit Phone Numbers'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="false"
  (onPopupClose)="dataGridRef.instance.cancelEditData()"
>
  <dx-data-grid
    #dataGridRef
    [dataSource]="phoneNumbers"
    [showRowLines]="true"
    [showBorders]="true"
    [showColumnLines]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
  >
    <dxo-toolbar>
      <dxi-item *ngIf="canCreate" name="addRowButton"></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowUpdating]="canEdit"
      [allowDeleting]="canDelete"
      [allowAdding]="canCreate"
    >
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column dataField="number" [editorOptions]="{ mask: phoneMask }" cellTemplate="phoneNumberCellTemplate">
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_PHONE_TEXT"></dxi-validation-rule>
      <dxi-validation-rule
        type="stringLength"
        [min]="totalPhoneDigits"
        [max]="totalPhoneDigits"
        [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
      ></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="extension" caption="Extension"> </dxi-column>

    <dxi-column dataField="phone_type" caption="Type">
      <dxo-lookup [dataSource]="PHONE_NUMBER_TYPE_LOOKUP" valueExpr="value" displayExpr="description"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="is_whatsapp" caption="WhatsApp" dataType="boolean"></dxi-column>

    <dxi-column dataField="is_primary" caption="Primary" dataType="boolean"></dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'phoneNumberCellTemplate'">
      <div class="">
        {{ cell.data | phoneNumberMask : false }}
      </div>
    </div>
  </dx-data-grid>
</ag-shr-modal-window>
