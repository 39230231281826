import { Pipe, PipeTransform } from '@angular/core';
import {
  ConferenceRegistrationStepName,
  RegistrantWizardState,
  RegistrantWizardStateKeys,
} from 'ag-common-lib/public-api';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { Observable, map, of } from 'rxjs';

@Pipe({ name: 'registrantWizardState' })
export class RegistrantWizardStatePipe implements PipeTransform {
  constructor(private attendeeDetailsModalService: AttendeeDetailsModalService) {}

  transform(wizardState: RegistrantWizardState): Observable<string> {
    const { [RegistrantWizardStateKeys.isSubmitted]: isSubmitted, ...rest } = wizardState ?? {};
    const restState = Object.values(rest);

    if (!restState?.length) {
      return of('Initial');
    }

    return this.attendeeDetailsModalService.currentStepInProgress$.pipe(
      map(currentStep => {
        return currentStep?.title;
      }),
    );
  }
}
