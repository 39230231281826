import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcursionModalComponent } from './excursion-modal.component';
import { HtmlEditorModule, ModalWindowModule } from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ExcursionModalService } from './excursion-modal.service';
import { DxSwitchModule, DxTabPanelModule, DxValidationSummaryModule } from 'devextreme-angular';
import {
  ExcursionConfigurationGridComponent
} from './excursion-configuration-grid/excursion-configuration-grid.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    HtmlEditorModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxValidationSummaryModule,
  ],
  declarations: [ExcursionModalComponent, ExcursionConfigurationGridComponent],
  exports: [ExcursionModalComponent],
  providers: [ExcursionModalService],
})
export class ExcursionModalModule {}
