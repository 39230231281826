import { Injectable } from '@angular/core';
import { LookupKeys, AgentKeys, Agent, DietaryConsideration } from '@ag-common-lib/public-api';
import { AgentService, BaseFormService } from 'ag-common-svc/public-api';
import { updateDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class DietaryConsiderationService extends BaseFormService<DietaryConsideration> {
  public selectedDietaryConsiderationType$ = new BehaviorSubject(null);

  constructor(private readonly agentService: AgentService) {
    super();
  }

  public handleSave = agentId => {
    const updates = {};

    Object.assign(updates, { [AgentKeys.dietaryConsideration]: Object.assign({}, this.formData) });

    this.startProgress();
    return this.agentService
      .updateFields(agentId, updates)
      .then(() => {
        const selectedDietaryConsiderationType = this.selectedDietaryConsiderationType$?.value;
        if (selectedDietaryConsiderationType && !selectedDietaryConsiderationType?.isAssigned) {
          updateDoc(selectedDietaryConsiderationType?.reference, { [LookupKeys.isAssigned]: true }).then();
        }
        this.formChangesDetector.clear();

        return updates;
      })

      .finally(() => {
        this.stopProgress();
      });
  };

  public getFormData = (agent?: Partial<Agent>) => {
    const initialDietaryConsiderationInitialData = Object.assign({}, agent?.[AgentKeys.dietaryConsideration]);

    this.formData = new Proxy(initialDietaryConsiderationInitialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return this.formData;
  };
}
