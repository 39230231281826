import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';
import { PersonalInformationComponent } from './personal-information.component';
import { GenderSelectBoxModule } from '../../../gender-select-box/gender-select-box.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';

@NgModule({
    imports: [CommonModule, SharedModule, ModalWindowModule, ButtonWithIndicatorModule, GenderSelectBoxModule, AgEditorToolbarModule],
  declarations: [PersonalInformationComponent],
  exports: [PersonalInformationComponent],
})
export class PersonalInformationModule {}
