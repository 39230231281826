import { Pipe, PipeTransform } from '@angular/core';
import { AttendeeDetailsModalSection } from './attendee-details-modal.model';
import { map, Observable } from 'rxjs';
import { AttendeeDetailsModalService } from './attendee-details-modal.service';
import { AttendeeDetailsModalRequestsService } from './attendee-details-modal-requests.service';
import { RegistrantModelKeys } from 'ag-common-lib/public-api';

@Pipe({ name: 'attendeeDetailsTitleIcon', pure: false })
export class AttendeeDetailsTitleIconPipe implements PipeTransform {
  constructor(
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private attendeeDetailsModalRequestsService: AttendeeDetailsModalRequestsService,
  ) {}
  transform(section: AttendeeDetailsModalSection): Observable<string> {
    switch (section) {
      case AttendeeDetailsModalSection.guests:
        return this.attendeeDetailsModalRequestsService.hasNotResolvedGuestSectionRequests$.pipe(
          map(additionalGuestRequested => (additionalGuestRequested ? 'dx-icon-warning warning' : '')),
        );
      case AttendeeDetailsModalSection.generalInfo:
        return this.attendeeDetailsModalService.isFirstTimeAttendee$.pipe(
          map(isFirstTimeAttendee => (isFirstTimeAttendee ? 'dx-icon-agi-medal success' : '')),
        );
      case AttendeeDetailsModalSection.notes:
        return this.attendeeDetailsModalService.registrant$.pipe(
          map(registrant => registrant?.[RegistrantModelKeys.statistic]),
          map(statistics => !!statistics?.supportItemsNotArchived),
          map(hasSupportTickets => (hasSupportTickets ? 'dx-icon-warning danger' : '')),
        );
      case AttendeeDetailsModalSection.hotelReservation:
        return this.attendeeDetailsModalRequestsService.hasNotResolvedHotelReservationSectionRequests$.pipe(
          map(hasHotResolvedRequests => (hasHotResolvedRequests ? 'dx-icon-warning warning' : '')),
        );

      case AttendeeDetailsModalSection.flyInformation:
        return this.attendeeDetailsModalRequestsService.hasNotResolvedFlightInfoSectionRequests$.pipe(
          map(hasHotResolvedRequests => (hasHotResolvedRequests ? 'dx-icon-warning warning' : '')),
        );

      default:
        return null;
    }
  }
}
