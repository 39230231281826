import { Inject, Injectable } from '@angular/core';
import { ReportSummary } from 'ag-common-lib/public-api';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP, QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';

@Injectable({
  providedIn: 'root',
})
export class ReportSummaryService extends DataService<ReportSummary> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    super.setCollection('report_summaries');
  }

  getReportSummaryByYear(year: number, sortField: string): Promise<ReportSummary[]> {
    return this.getAllByValue([new QueryParam('year', WhereFilterOperandKeys.equal, year)], sortField);
  }

  getAgentSummaryByAgentId(agent_id: string, sortField: string): Promise<ReportSummary[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam('id', WhereFilterOperandKeys.equal, agent_id));
    qp.push(new QueryParam('type', WhereFilterOperandKeys.equal, 'Agent'));
    return this.getAllByValue(qp, sortField);
  }

  getAgentSummaryByAgentIdAndYear(agent_id: string, year: number[], sortField: string): Promise<ReportSummary[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam('id', WhereFilterOperandKeys.equal, agent_id));
    qp.push(new QueryParam('year', WhereFilterOperandKeys.in, year));
    qp.push(new QueryParam('type', WhereFilterOperandKeys.equal, 'Agent'));
    return this.getAllByValue(qp, sortField);
  }

  getManagerSummaryByAgentId(agent_id: string, sortField: string): Promise<ReportSummary[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam('id', WhereFilterOperandKeys.equal, agent_id));
    qp.push(new QueryParam('type', WhereFilterOperandKeys.equal, 'MGR'));
    return this.getAllByValue(qp, sortField);
  }
}
