import { Injectable } from '@angular/core';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { ConferenceStepsConfiguration } from 'ag-common-lib/public-api';

@Injectable()
export class ConferenceStepsInfoModalService extends BaseFormService<ConferenceStepsConfiguration> {
  constructor() {
    super();
  }

  getFormData = (stepConfiguration: ConferenceStepsConfiguration) => {
    const initialData = Object.assign({}, new ConferenceStepsConfiguration(), stepConfiguration);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });
    return this.formData;
  };
}
