import { Component, Input } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { MMDDYYYY_DATE_SHORT_TIME_FORMAT } from 'ag-common-lib/lib/constants/date.constants';

@Component({
  selector: 'app-email-accordian-header-template',
  templateUrl: './email-accordian-header-template.component.html',
  styleUrls: ['./email-accordian-header-template.component.scss'],
})
export class EmailAccordianHeaderTemplateComponent {
  @Input() template: any;

  readonly dateFormat = MMDDYYYY_DATE_SHORT_TIME_FORMAT;

  getDate(date: Timestamp): string {
    if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    } else {
      return '';
    }
  }
}
