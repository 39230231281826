import { Component } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  selectedMenuItem: string = 'General';

  menuItems = ['General', 'Security'];

  selectMenuItem(e) {
    this.selectedMenuItem = e.itemData;
  }
}
