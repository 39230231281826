import { Component, ViewChild } from '@angular/core';
import { Agent } from 'ag-common-lib/lib/models/domain/agent.model';
import { CampaignsManagementTaskBoardService } from './campaigns-management-task-board.service';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { CampaignsDetailsModalComponent } from './campaigns-details-modal/campaigns-details-modal.component';
import {
  AgentCampaignChange,
  BaseModelKeys,
  CampaignsManagementTasks,
  CampaignsManagementTasksKeys,
  CampaignsManagementTasksStatus,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from 'ag-common-lib/public-api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CampaignsAddIssuesModalComponent } from './campaigns-add-issues-modal/campaigns-add-issues-modal.component';
import { CampaignsAddIssuesModalService } from './campaigns-add-issues-modal/campaigns-add-issues-modal.service';
import { CampaignsTaskIssuesModalComponent } from './campaigns-task-issues-modal/campaigns-task-issues-modal.component';
import {
  AgAssignOwnerViewerComponent,
  AgentCampaignsManagementTasksService,
  AgentService,
} from 'ag-common-svc/public-api';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ag-crm-campaign-management-task-board',
  templateUrl: './campaigns-management-task-board.component.html',
  styleUrls: ['./campaigns-management-task-board.component.scss'],
  providers: [CampaignsManagementTaskBoardService, CampaignsAddIssuesModalService, HasPermissionPipe],
})
export class CampaignsManagementTaskBoardComponent {
  @ViewChild('campaignsDetailsModalRef', { static: true })
  campaignsDetailsModalComponent: CampaignsDetailsModalComponent;
  @ViewChild('campaignsIssueModalRef', { static: true }) campaignsIssueModalComponent: CampaignsAddIssuesModalComponent;
  @ViewChild('assignOwnerViewerRef', { static: false }) assignOwnerViewerComponent: AgAssignOwnerViewerComponent;
  @ViewChild('campaignsTaskIssuesModalRef', { static: true })
  campaignsTaskIssuesModalComponent: CampaignsTaskIssuesModalComponent;
  @ViewChild('campaignsInfoModalRef', { static: true }) campaignsInfoModalComponent: ModalWindowComponent;

  boardLists$ = this.campaignsManagementTaskBoardService.boardLists$;

  selectedAgent: Agent;
  selectedTask: CampaignsManagementTasks;
  inProgress$ = this.campaignsManagementTaskBoardService.inProgress$;
  viewAgentVisible = false;

  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly CampaignsManagementTasksStatus = CampaignsManagementTasksStatus;
  protected readonly BaseModelKeys = BaseModelKeys;
  private isWritePermissionDenied = false;

  constructor(
    private agentService: AgentService,
    private campaignsManagementTaskBoardService: CampaignsManagementTaskBoardService,
    private agentCampaignsManagementTasksService: AgentCampaignsManagementTasksService,
    private hasPermissionPipe: HasPermissionPipe,
  ) {
    this.hasPermissionPipe
      .transform(Entity.campaignBoardAdminister, EntityPermissionActivityKeys.write)
      .pipe(map(value => !value))
      .subscribe(denied => (this.isWritePermissionDenied = denied));
  }

  onTaskDrop(e, status: CampaignsManagementTasksStatus): void {
    const data = e.fromData[e?.fromIndex];
    const taskData: CampaignsManagementTasks = data?.task;
    const prevTaskStatus = taskData.taskStatus;
    const isOwnerAssigned = !!taskData?.assignedTo;
    taskData.taskStatus = status;
    this.selectedTask = taskData;

    switch (status) {
      case CampaignsManagementTasksStatus.done:
        const changes = taskData?.statistic?.issuesChanges;
        const allIssuesResolved = changes?.total === changes?.itemsDone;
        const allChangesApplied = Object.values(taskData.details)?.every(
          (stepDetails: AgentCampaignChange<any>) => !stepDetails?.incomingStepData || !!stepDetails?.isStepDone,
        );

        if (allIssuesResolved && allChangesApplied) {
          this.updateTasks(taskData);
        } else {
          taskData.taskStatus = prevTaskStatus;
          this.campaignsDetailsModalComponent.showModal(taskData);
        }
        break;

      case CampaignsManagementTasksStatus.contentIssues:
      case CampaignsManagementTasksStatus.technicalIssues:
        this.campaignsTaskIssuesModalComponent.showModal(taskData);
        break;

      default:
        if (!isOwnerAssigned) {
          const assignToData = {
            assignedTo: taskData[CampaignsManagementTasksKeys.assignedTo],
            assignedToNote: taskData[CampaignsManagementTasksKeys.note],
          };
          this.assignOwnerViewerComponent.showAssignOwnerModalClick(assignToData);
          return;
        }
        this.updateTasks(taskData);
        break;
    }
  }

  onDragStart(e) {
    e.cancel = this.isWritePermissionDenied;
  }

  viewCardInfo(task?: CampaignsManagementTasks): void {
    if (task?.[CampaignsManagementTasksKeys.taskStatus] !== CampaignsManagementTasksStatus.done) {
      this.campaignsDetailsModalComponent.showModal(task);
    }
  }

  async viewAgentDetails(selectedAgent: Agent): Promise<void> {
    this.selectedAgent = await this.agentService.getById(selectedAgent?.[BaseModelKeys.dbId]);
    this.viewAgentVisible = true;
  }

  changeOwner = owner => {
    const agentDbId = this.selectedTask?.[CampaignsManagementTasksKeys.agentDbId];
    const selectedTaskDbId = this.selectedTask?.[BaseModelKeys.dbId];
    const update = {
      [CampaignsManagementTasksKeys.assignedAt]: new Date(),
      [CampaignsManagementTasksKeys.assignedTo]: owner?.assignedTo ?? '',
      [CampaignsManagementTasksKeys.note]: owner?.assignedToNote ?? '',
      [CampaignsManagementTasksKeys.taskStatus]: this.selectedTask?.taskStatus,
    };
    return this.campaignsManagementTaskBoardService.assignOwner(agentDbId, selectedTaskDbId, update);
  };

  private updateTasks(taskData: CampaignsManagementTasks): void {
    const agentCampaignDbId = taskData[CampaignsManagementTasksKeys.agentCampaignDbId];
    const taskId = taskData[BaseModelKeys.dbId];

    this.agentCampaignsManagementTasksService.update(agentCampaignDbId, taskId, {
      [CampaignsManagementTasksKeys.taskStatus]: taskData[CampaignsManagementTasksKeys.taskStatus],
    });
  }

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
