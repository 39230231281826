import { Pipe, PipeTransform } from '@angular/core';
import {
  ConferenceStepsConfiguration,
  ConferenceStepsConfigurationKeys,
  conferenceRegistrationSectionNameMap,
  ConferenceRegistrationStepName,
} from '@ag-common-lib/public-api';

@Pipe({
  name: 'conferenceStepTitle',
})
export class ConferenceStepsTitlePipe implements PipeTransform {
  transform(configurations: ConferenceStepsConfiguration[], stepName: ConferenceRegistrationStepName): string {
    const defaultTitle = conferenceRegistrationSectionNameMap.get(stepName)?.title;
    if (!configurations?.length || !stepName) {
      return defaultTitle;
    }

    const conferenseStepTitle = configurations.find(
      config => config[ConferenceStepsConfigurationKeys.stepName] === stepName,
    )?.title;
    return conferenseStepTitle ?? defaultTitle;
  }
}
