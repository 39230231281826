<div class="container p-0 m-0">
  <div class="row p-2 m-0 text-center">
    <div class="col-2 p-0 m-0">Sent: {{ emailsSent }}</div>
    <div class="col-2 p-0 m-0">Accepted: {{ emailsAccepted }}</div>
    <div class="col-2 p-0 m-0">Rejected: {{ emailsRejected }}</div>
    <div class="col-2 p-0 m-0">Viewed: {{ emailsUniqueViews }}</div>
    <div class="col-2 p-0 m-0">Not Viewed: {{ emailsNotViewed }}</div>
    <div class="col-2 p-0 m-0">Total Views: {{ emailsViewed }}</div>
    <hr />
  </div>
  <div *ngFor="let response of emailResponses">
    <div class="row p-2 m-0" *ngIf="response.viewed > 0">
      <!--<div class="col-12 p-0 m-0">
                {{response.agent_name}} opened email {{response.viewed}} time{{response.viewed > 1? 's': ''}} on {{getDate(response.updated_date)}}
            </div>-->
    </div>
  </div>
</div>
