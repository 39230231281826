import { Component, Input, OnInit } from '@angular/core';
import { ActiveLookup, Agent, languages, Prospect, YES_NO_TYPE } from 'ag-common-lib/public-api';
import { LookupsService } from 'ag-common-svc/public-api';
import { Observable } from 'rxjs';

@Component({
  selector: 'ag-crm-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.scss'],
})
export class ProspectComponent {
  @Input() agent: Agent;
  // eslint-disable-next-line
  @Input('prospect') selectedProspect: Prospect;

  public statesLookup$: Observable<ActiveLookup[]>;
  public languages = languages;
  public yesNoType = YES_NO_TYPE;

  constructor(lookupsService: LookupsService) {
    this.statesLookup$ = lookupsService.statesLookup$;
  }
}
