import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignChanges, AgentCampaignStepName } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-campaign-changes-list',
  templateUrl: './agent-campaign-changes-list.component.html',
  styleUrls: ['./agent-campaign-changes-list.component.scss'],
})
export class AgentCampaignChangesListComponent {
  @HostBinding('class') className = 'agent-campaign-changes-list';

  @Input() agentCampaignChanges: AgentCampaignChanges;

  readonly AgentCampaignStepName = AgentCampaignStepName;

  constructor() {}
}
