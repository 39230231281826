import { Pipe, PipeTransform } from '@angular/core';
import { isValid, isWithinInterval, toDate } from 'date-fns';
import { ConferencePossibleStayPeriod, ConferencePossibleStayPeriodKey } from '@ag-common-lib/public-api';

@Pipe({
  name: 'isOutsideMainDates',
})
export class IsOutsideMainDatesPipe implements PipeTransform {
  transform(
    conferencePossibleStayPeriod: ConferencePossibleStayPeriod,
    requestedArrival: Date,
    requestedDeparture: Date,
  ): boolean {
    if (
      [
        requestedArrival,
        requestedDeparture,
        conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryArrivalDate],
        conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryDepartureDate],
      ].some(date => !isValid(toDate(date)))
    ) {
      return false;
    }

    const interval = {
      start: conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryArrivalDate],
      end: conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryDepartureDate],
    };

    return !isWithinInterval(requestedArrival, interval) || !isWithinInterval(requestedDeparture, interval);
  }
}
