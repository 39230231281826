import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ARMAuthService } from '../../shared/services/util/arm-auth.service';
import {
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  RoleEntityPermissionMap,
} from 'ag-common-lib/lib/models/utils/entity-permission.model';
import { Entity } from 'ag-common-lib/lib/lists/entity-permission.enum';
import { tap } from 'rxjs/operators';
import { hasPermissionGuard } from 'ag-common-svc/shared/utils/has-permission-guard';

@Injectable({ providedIn: 'root' })
export class SalesGoalsManagerGuard implements CanActivate {
  constructor(private authService: ARMAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.armPermissions$.pipe(
      map((roleEntityPermissionMap: RoleEntityPermissionMap) => {
        return hasPermissionGuard([Entity.agentSalesGoal], roleEntityPermissionMap);
      }),
      tap(hasPermission => {
        if (!hasPermission) {
          this.router.navigate(['/access-denied']);
        }
      }),
    );
  }
}
