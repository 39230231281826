import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceRegistrationStepName } from 'ag-common-lib/public-api';
import { map, Observable } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';

@Pipe({ name: 'conferenceGeneralInfoSectionVisible' })
export class GeneralInfoSectionVisiblePipe implements PipeTransform {
  constructor(private attendeeDetailsModalService: AttendeeDetailsModalService) {}

  transform(sectionName: ConferenceRegistrationStepName): Observable<boolean> {
    if (!sectionName) {
      return null;
    }
    return this.attendeeDetailsModalService.conferencePersonalInfoExcludedSections$.pipe(
      map(excludedSections => !excludedSections?.some(section => section === sectionName)),
    );
  }
}
