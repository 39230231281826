<dx-data-grid
  #logGrid
  class="logs-manager__component"
  [dataSource]="dataSource"
  [showBorders]="false"
  [remoteOperations]="true"
  [syncLookupFilterValues]="false"
  [columnAutoWidth]="true"
  [scrollLeft]="true"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [focusedRowEnabled]="false"
  [focusedColumnIndex]="0"
  [autoNavigateToFocusedRow]="false"
  height="100%"
>
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>Documents Write Logs</div>
    </dxi-item>
  </dxo-toolbar>

  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

  <dxo-filter-row [visible]="false"></dxo-filter-row>

  <dxo-header-filter [visible]="false"></dxo-header-filter>

  <dxo-search-panel [visible]="false" [width]="240" placeholder="Search..."></dxo-search-panel>

  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

  <dxi-column dataField="collection"></dxi-column>

  <dxi-column dataField="action"></dxi-column>

  <dxi-column dataField="date" dataType="datatime"></dxi-column>

  <dxi-column dataField="documentIds.conferenceDbId" caption="Conference DbId"></dxi-column>
  <dxi-column dataField="documentIds.registrantDbId" caption="Registrant DbId"></dxi-column>
  <dxi-column dataField="documentIds.registrantGuestDbId" caption="Guest DbId"></dxi-column>
  <dxi-column dataField="documentIds.registrantStripeTransactionDbId" caption="Transaction DbId"></dxi-column>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

  <div *dxTemplate="let masterDetails of 'detail'">
    <a [href]="masterDetails.data.wasabiPath | wasabiFile">Download</a>

    <div [innerHTML]="masterDetails.data.wasabiPath | wasabiFile | logsManagerDiff | async"></div>
  </div>
</dx-data-grid>
