import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebinarsComponent } from './webinars/webinars.component';
import { ContestsComponent } from './contests/contests.component';
import { FormsModule } from '@angular/forms';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
} from 'devextreme-angular';
import { StateSelectBoxModule } from 'ag-common-svc/lib/components/state-select-box/state-select-box.module';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { ConferencesModule } from './conferences/conferences.module';
import { ConferenceRegistrationGridModule } from './conference-registration-grid/conference-registration-grid.module';
import { WasabiImgPipe } from 'ag-common-svc/shared/pipes/wasabi-img.pipe';

@NgModule({
  declarations: [WebinarsComponent, ContestsComponent],
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    DxPopupModule,
    DxFormModule,
    DxButtonModule,
    DxDateBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxToolbarModule,
    DxListModule,
    DxScrollViewModule,
    DxFileUploaderModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxTabsModule,
    DxSwitchModule,
    DxRadioGroupModule,
    StateSelectBoxModule,
    PipesModule,
    ConferencesModule,
    ConferenceRegistrationGridModule,
  ],
  providers: [WasabiImgPipe],
})
export class RegistrationsModule {}
