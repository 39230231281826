import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'ag-common-svc/public-api';
import { DistributionListService } from 'src/app/shared/services/distribution-list.service';
import { SessionControllerService } from 'src/app/shared/services/util/session-controller.service';
import { AgentService } from 'ag-common-svc/public-api';

@Component({
  selector: 'app-distribution-list-detail-info',
  templateUrl: './distribution-list-detail-info.component.html',
  styleUrls: ['./distribution-list-detail-info.component.scss'],
})
export class DistributionListDetailInfoComponent implements OnInit {
  addButtonOptions;
  addSummaryButtonOptions;

  selectedAgents = [];

  selectAgentVisible: boolean = false;
  selectSummaryVisible: boolean = false;

  constructor(
    public session: SessionControllerService,
    public agentService: AgentService,
    public distributionListService: DistributionListService,
    public authService: AuthService,
    public toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.addButtonOptions = {
      icon: 'user',
      hint: 'Select Users based on Attributes',
      onClick: () => {
        this.selectAgentVisible = true;
      },
    };

    this.addSummaryButtonOptions = {
      icon: 'money',
      hint: 'Select Users based on Performance',
      onClick: () => {
        this.selectSummaryVisible = true;
      },
    };

    if (this.session.selectedDistributionList) {
      this.session.selectedDistributionList.to.forEach(agent => {
        this.selectedAgents.push(agent.dbId);
      });
    }
  }

  onClose() {
    this.selectAgentVisible = false;
  }

  onAgentsSelected(e) {
    this.session.selectedDistributionList.to = e;
    this.distributionListService.update(this.session.selectedDistributionList).then(() => {
      this.toastrService.success('Distribution List Saved Successfully');
    });
  }

  deleteRecipient(e) {
    this.session.selectedDistributionList.to.splice(1, 1);
    this.distributionListService.update(this.session.selectedDistributionList).then(() => {
      this.toastrService.success('Recipient Removed Successfully');
    });
  }
}
