import { Pipe, PipeTransform } from '@angular/core';
import {
  ConferenceGuestsStepsConfigurationSectionName,
  ConferenceGuestsStepsConfigurationSectionNameMap,
  ConferencePersonalStepsConfigurationSectionNameMap,
  ConferenceRegistrationStepName,
} from 'ag-common-lib/public-api';

@Pipe({
  name: 'conferenceExcludedSections',
})
export class ConferenceExcludedSectionsPipe implements PipeTransform {
  transform(
    sections: ConferenceGuestsStepsConfigurationSectionName[] | ConferenceRegistrationStepName[],
    stepName?: ConferenceRegistrationStepName,
  ): string {
    if (!Array.isArray(sections) || !sections?.length) {
      return '';
    }
    if (stepName === ConferenceRegistrationStepName.registrantPersonalInformationStep) {
      return sections
        .map(section => ConferencePersonalStepsConfigurationSectionNameMap.get(section))
        .filter(Boolean)
        .join(', ');
    }
    return sections
      .map(section => ConferenceGuestsStepsConfigurationSectionNameMap.get(section))
      .filter(Boolean)
      .join(', ');
  }
}
