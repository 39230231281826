import { Pipe, PipeTransform } from '@angular/core';
import { fieldsMap } from '../config/conference-step-info.config';

@Pipe({
  name: 'conferenceExcludedFields',
})
export class ConferenceExcludedFieldsPipe implements PipeTransform {
  transform(fields: string[]): string {
    if (!Array.isArray(fields) || !fields?.length) {
      return '';
    }

    return fields
      .map(field => fieldsMap.get(field))
      .filter(Boolean)
      .join(', ');
  }
}
