<div class="md-4">
  <ng-container *ngFor="let item of keysConfig; trackBy: trackByKeyValue">
    <ng-container *var="item.value | changeLogChildKey as key">
      <ng-container *ngIf="changeLogs[key] as log">
        <ng-container *ngIf="isLogArray(log); else objectLogData">
          <ng-container *ngIf="log?.length === 2; else removedLogData">
            <!-- modified data = [oldValue, newValue]-->
            <ng-container
              *ngTemplateOutlet="
                detailsContainer;
                context: {
                  item: item,
                  log: log,
                  type: ChangeLogsType.changed
                }
              "
            ></ng-container>
          </ng-container>

          <ng-template #removedLogData>
            <ng-container *ngIf="log?.length === 3; else addedLogData">
              <!-- deleted data = [oldValue, 0, 0]-->
              <ng-content select="[changeLogsRemovedDescription]"></ng-content>
              <ng-container *ngIf="showRemovedData">
                <ng-container
                  *ngTemplateOutlet="
                    detailsContainer;
                    context: {
                      item: item,
                      log: log[0],
                      type: ChangeLogsType.removed
                    }
                  "
                ></ng-container>
              </ng-container>
            </ng-container>

            <ng-template #addedLogData>
              <!-- added data = [newValue]-->
              <ng-content select="[changeLogsAddedDescription]"></ng-content>
              <ng-container
                *ngTemplateOutlet="
                  detailsContainer;
                  context: {
                    item: item,
                    log: log[0],
                    type: ChangeLogsType.added
                  }
                "
              ></ng-container>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-template #objectLogData>
          <!--  can have different combinations ({"property": value } or {"_t": "a", "0": [...]}) etc.-->
          <ng-container
            *ngTemplateOutlet="
              detailsContainer;
              context: {
                item: item,
                log: log,
                type: ChangeLogsType.object
              }
            "
          ></ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #detailsContainer let-item="item" let-log="log" let-type="type">
  <ng-container *ngIf="item?.dataType === ChangeLogsDataType.boolean ? !![log]?.length : !!log">
    <ag-crm-change-log-detail
      [item]="item?.description"
      [type]="type"
      [changeLog]="log"
      [dataType]="item?.dataType ?? null"
      [activeLookupName]="item?.activeLookupName ?? null"
      [valueLookup]="item?.valueLookup ?? null"
    ></ag-crm-change-log-detail>
  </ng-container>
</ng-template>
