import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConferencesComponent } from './conferences.component';
import { ModalWindowModule } from 'ag-common-svc/lib/components/modal-window/modal-window.module';
import { AgencyAddressesModule, GenderSelectBoxModule } from 'ag-common-svc/lib/components';
import { ConferenceService } from 'ag-common-svc/lib/services/conference.service';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeesListModalModule } from './modals/attendees-list-modal/attendees-list-modal.module';
import { DietaryConsiderationsFormModule } from 'ag-common-svc/lib/components/agent-editor/components/dietary-considerations-form/dietary-considerations-form.module';
import { ConferenceModalModule } from './modals/conference-modal/conference-modal.module';
import { SharedModule as CRMSharedModule } from '../../../shared/shared.module';
import { ConferenceExcursionStatisticModalModule } from './modals/conference-excursion-statistic-modal/conference-excursion-statistic-modal.module';
import { ConferenceTransactionsModalModule } from './modals/conference-transactions-modal/conference-transactions-modal.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    GenderSelectBoxModule,
    AgencyAddressesModule,
    AttendeesListModalModule,
    ConferenceModalModule,
    ConferenceExcursionStatisticModalModule,
    DietaryConsiderationsFormModule,
    CRMSharedModule,
    ConferenceTransactionsModalModule,
  ],
  declarations: [ConferencesComponent],
  exports: [ConferencesComponent],
  providers: [ConferenceService],
})
export class ConferencesModule {}
