import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../attendee-change-log-detail/attendee-change-log-detail.module';
import { AttendeeGuestsLogDetailsComponent } from './attendee-guests-log-details.component';
import { ChangeLogPipesModule } from '../pipes/change-log-pipes.module';
import { AttendeeChangeLogDetailByTypeModule } from '../attendee-change-log-detail-by-type/attendee-change-log-detail-by-type.module';
import { AttendeeFlightInfoLogDetailsModule } from '../attendee-flight-info-log-details/attendee-flight-info-log-details.module';
import { AttendeeExcursionsLogDetailsModule } from '../attendee-excursions-log-details/attendee-excursions-log-details.module';

@NgModule({
  declarations: [AttendeeGuestsLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    AttendeeChangeLogDetailModule,
    ChangeLogPipesModule,
    AttendeeChangeLogDetailByTypeModule,
    AttendeeFlightInfoLogDetailsModule,
    AttendeeExcursionsLogDetailsModule,
  ],
  exports: [AttendeeGuestsLogDetailsComponent],
})
export class AttendeeGuestsLogDetailsModule {}
