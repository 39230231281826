<div class="container p-0 m-0">
  <div class="row p-2 m-0">
    <div class="col-9 p-0 m-0">
      <dx-tabs style="width: 100%" [dataSource]="tabs" [selectedIndex]="0" (onItemClick)="selectTab($event)"> </dx-tabs>
    </div>
    <div class="col-3 p-0 m-0 text-end">
      <dx-drop-down-button
        class="mx-2"
        text="Actions"
        [width]="160"
        [dropDownOptions]="{ width: 230 }"
        [items]="emailOptions"
        (onItemClick)="emailOptionsClicked($event)"
      >
      </dx-drop-down-button>
    </div>
  </div>
  <div class="row p-0 m-0" *ngIf="session.selectedDistributionList">
    <div class="col-12 p-2 m-0">
      <ng-template [ngIf]="selectedTab == 'Emails'">
        <dx-data-grid
          #emailgrid
          class="dx-card wide-card"
          [dataSource]="emailsDataSource"
          [showBorders]="false"
          [focusedRowEnabled]="true"
          [focusedRowIndex]="0"
          [columnAutoWidth]="true"
          [columnHidingEnabled]="true"
          [scrollLeft]="true"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          [height]="'75vh'"
          (onRowDblClick)="showEmailDetails($event)"
        >
          <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>
          <dxi-column dataField="template.name" caption="Template Name"></dxi-column>
          <dxi-column dataField="date_created" [calculateCellValue]="getCreatedDate"></dxi-column>
          <dxi-column dataField="date_sent" [calculateCellValue]="getSentDate"></dxi-column>
          <dxi-column dataField="owner"></dxi-column>
          <dxi-column type="buttons" [width]="125" [fixed]="true">
            <dxi-button name="delete"></dxi-button>
            <dxi-button name="edit" [onClick]="editEmail.bind(this)"></dxi-button>
            <dxi-button
              name="send"
              icon="message"
              [onClick]="confirmSendEmail.bind(this)"
              [visible]="emailPreviouslySent.bind(this)"
            ></dxi-button>
          </dxi-column>
        </dx-data-grid>
      </ng-template>
      <ng-template [ngIf]="selectedTab == 'Notes'"> </ng-template>
    </div>
  </div>
</div>

<ng-container *ngIf="viewEmailResponseVisible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="View Email Details"
    [dragEnabled]="true"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="viewEmailResponseVisible"
  >
    <app-summary-email-view (onClose)="closeEmailDetails($event)" [email]="email"></app-summary-email-view>
  </dx-popup>
</ng-container>

<ng-container *ngIf="createEmailVisible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="Create / Edit Email"
    [dragEnabled]="true"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="createEmailVisible"
  >
    <app-email-form [isRecipientSet]="true" (onClose)="saveEmail($event)" [email]="email"></app-email-form>
  </dx-popup>
</ng-container>

<ng-container *ngIf="addNotesVisible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="Email Note"
    [dragEnabled]="true"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="addNotesVisible"
  >
    <app-note-form (onClose)="saveNote($event)"></app-note-form>
  </dx-popup>
</ng-container>
