import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalWindowModule } from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AddAttendeesModalComponent } from './add-attendees-modal.component';
import { AddAttendeesModalService } from './add-attendees-modal.service';
import { SuffixPipe } from 'ag-common-svc/shared/pipes/suffix.pipe';
import { ConferencesPipesModule } from '../../../pipes/conferences-pipes.module';
import {
    ConferenceRegistrationPipesModule
} from 'ag-common-svc/shared/pipes/conference-registration/conference-registration-pipes.module';

@NgModule({
    imports: [ CommonModule, SharedModule, ModalWindowModule, ConferencesPipesModule, ConferenceRegistrationPipesModule ],
  declarations: [AddAttendeesModalComponent],
  exports: [AddAttendeesModalComponent],
  providers: [AddAttendeesModalService, SuffixPipe],
})
export class AddAttendeesModalModule {}
