import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';
import { CampaignsComponent } from './campaigns.component';
import { DxValidatorModule } from 'devextreme-angular';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    ButtonWithIndicatorModule,
    DxValidatorModule,
    AgEditorToolbarModule,
  ],
  declarations: [CampaignsComponent],
  exports: [CampaignsComponent],
})
export class CampaignsModule {}
