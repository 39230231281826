import { Component, ElementRef, HostBinding, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { DocumentWriteLogElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services/documet-write-log-elastic-search.service';
import { Constants } from 'ag-common-lib/lib/constants/validation.constants';
import { RegistrantKeys } from 'ag-common-lib/lib/models/registration/registrants.model';
import { BehaviorSubject, firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import {
  changeLogsLookup,
  GuestData,
  GuestKeys,
  GUESTS_SEARCH_KEYS_CONFIG,
  LookupBase,
  LookupKeys,
  PERSONAL_INFO_SEARCH_KEYS_CONFIG,
  RSVP_SEARCH_KEYS_CONFIG,
} from 'ag-common-lib/public-api';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { AttendeeChangeLogService } from './attendee-change-log.service';
import { ConferenceGuestsService } from 'ag-common-svc/lib/services/conference-guests.service';
import { DOCUMENT } from '@angular/common';
import { fullscreen, setPopupsContainer } from 'ag-common-svc/shared/utils/full-screen';
import { getChangedLog } from './utils/normalize-log';

@Component({
  selector: 'ag-crm-change-log',
  templateUrl: './attendee-change-log.component.html',
  styleUrls: ['./attendee-change-log.component.scss'],
})
export class AttendeeChangeLogComponent implements OnInit {
  @HostBinding('class') className = 'ag-crm-attendee-change-log';
  @ViewChild('attendeeChangeLogGrid', { static: false }) attendeeChangeLogGridComponent!: DxDataGridComponent;
  @ViewChild('attendeeChangeLogContainer', { static: true }) attendeeChangeLogContainer: ElementRef;

  containerElement: HTMLElement;
  conferenceDbId: string;
  registrantDbId: string;
  agentsLookup$ = this.attendeeChangeLogService.agentsLookupBase$;
  registrantGuestsLookup$: Observable<LookupBase[]>;

  protected dataSource: CustomStore;
  protected updatedFieldsList: DataSource;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_TIME_FORMAT;
  protected readonly PERSONAL_INFO_SEARCH_KEYS_CONFIG = PERSONAL_INFO_SEARCH_KEYS_CONFIG;
  protected readonly GUESTS_SEARCH_KEYS_CONFIG = GUESTS_SEARCH_KEYS_CONFIG;
  protected readonly RSVP_SEARCH_KEYS_CONFIG = RSVP_SEARCH_KEYS_CONFIG;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly GuestKeys = GuestKeys;
  protected readonly getChangedLog = getChangedLog;
  readonly filterPanelText$ = new BehaviorSubject('');
  protected readonly changeLogsLookup = changeLogsLookup.map(item => {
    return {
      category: item.category,
      value: item.value,
      description: item.description.split(':').pop().trim(),
    };
  });

  @HostListener('document:fullscreenchange', ['$event']) fullScreenMode() {
    if (!document.fullscreenElement) {
      document.body.classList.remove('fullscreen-enable');
      setPopupsContainer();
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    protected documentWriteLogElasticSearchService: DocumentWriteLogElasticSearchService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private attendeeChangeLogService: AttendeeChangeLogService,
    conferenceGuestsService: ConferenceGuestsService,
  ) {
    this.dataSource = new CustomStore({
      key: 'wasabiPath',
      load: async loadOptions => {
        this.conferenceDbId = await firstValueFrom(this.attendeeDetailsModalService.conferenceDbId$);
        this.registrantDbId = await firstValueFrom(this.attendeeDetailsModalService.registrantDbId$);
        this.registrantGuestsLookup$ = conferenceGuestsService.getList(this.conferenceDbId, this.registrantDbId).pipe(
          map(
            (guests): Partial<LookupBase[]> =>
              Array.isArray(guests)
                ? guests.map((guest: GuestData) => {
                    return {
                      [LookupKeys.value]: guest?.dbId,
                      [LookupKeys.description]: [guest?.first_name, guest?.last_name].filter(Boolean).join(' '),
                    };
                  })
                : [],
          ),
          shareReplay(1),
        );
        return this.documentWriteLogElasticSearchService.getFromElastic(loadOptions);
      },
    });
    this.updatedFieldsList = new DataSource({
      store: new ArrayStore({
        data: this.changeLogsLookup,
        key: 'value',
      }),
      group: 'category',
    });
  }

  ngOnInit(): void {
    this.containerElement = this.attendeeChangeLogContainer?.nativeElement;
  }

  protected onOptionChanged = event => {
    if (event.name === 'searchPanel') {
      this.filterPanelText$.next(event.value);
    }
  };

  protected onRowPrepared(event): void {
    if (event.rowType === 'data' && !event.data.updatedFields?.length) {
      event.rowElement.style.display = 'none';
    }
  }

  protected onEditorPreparing(event): void {
    if (event.dataField == 'updatedFields' && event.parentType == 'filterRow') {
      event.editorName = 'dxTagBox';
      event.editorOptions = {
        placeholder: 'Select fields...',
        dataSource: this.updatedFieldsList,
        displayExpr: 'description',
        valueExpr: 'value',
        showClearButton: true,
        searchEnabled: true,
        applyValueMode: 'useButtons',
        showSelectionControls: true,
        maxDisplayedTags: 3,
        grouped: true,
        onSelectionChanged: this.onUpdatedFieldsSelectionChanged,
        dropDownOptions: { container: this.containerElement },
      };
    }
  }

  onUpdatedFieldsSelectionChanged = event => {
    const selectedItems = event?.component?.option('selectedItems');
    const selectedKeys = selectedItems.map(({ value }) => value);
    this.attendeeChangeLogGridComponent.instance.columnOption('updatedFields', 'filterValue', selectedKeys);
  };

  updatedFieldsCalcFilterExpr(filterValue, selectedFilterOperation) {
    let column = this as any;

    if (selectedFilterOperation === 'contains') {
      let filterExpr = [];
      filterValue.forEach(val => {
        filterExpr.length >= 1 ? filterExpr.push('or') : filterExpr.push(['updatedFields', 'contains', val]);
      });
      return filterExpr;
    }
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  fullscreen(container: HTMLElement): void {
    this.containerElement = container;
    fullscreen(container, this.document);
  }

  hasSelectedExcursion = logs => {
    // newly added = Array[null] without data
    return Array.isArray(logs) ? logs.filter(log => log !== null).length : !!logs;
  };
}
