<ag-shr-modal-window
  #setPrimaryEmailModalRef
  [title]="'Set Login Email'"
  [width]="'600px'"
  [height]="'auto'"
  [actionTitle]="selectedStep$ | async | setLoginEmailModalActionTitle"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleNextStepClick($event)"
  [extraToolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'bottom',
      visible: SetLoginEmailModalSteps.confirmEmail === (selectedStep$ | async),
      options: {
        text: 'BACK TO EMAIL LIST',
        onClick: backToEmailList
      }
    }
  ]"
>
  <dx-form
    *ngIf="setPrimaryEmailModalRef?.popupComponent?.visible ?? false"
    #setPrimaryEmailFormRef
    labelMode="floating"
    [formData]="formData"
  >
    <dxi-item [visible]="SetLoginEmailModalSteps.selectEmail === (selectedStep$ | async)">
      <div *dxTemplate>
        <ng-container> Select an Email Address to assign as <b>Login Email Address</b>.</ng-container>
      </div>
    </dxi-item>

    <dxi-item [visible]="SetLoginEmailModalSteps.confirmEmail === (selectedStep$ | async)">
      <div *dxTemplate>
        A confirmation code has been sent to
        <b>{{ formData?.emailAddress?.description }}</b>
        .
      </div>
    </dxi-item>

    <dxi-item
      [dataField]="'emailAddress'"
      [label]="{ text: 'Email Address' }"
      editorType="dxSelectBox"
      [visible]="SetLoginEmailModalSteps.selectEmail === (selectedStep$ | async)"
      [editorOptions]="{
        items: emailAddressesLookup$ | async,
        displayExpr: 'description',
        placeholder: ''
      }"
    >
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_EMAIL_TEXT"> </dxi-validation-rule>

      <dxi-validation-rule
        type="async"
        [message]="Messages.ALREADY_USED_EMAIL_TEXT"
        [validationCallback]="emailAddressAsyncValidation"
      >
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item [visible]="SetLoginEmailModalSteps.selectEmail === (selectedStep$ | async)">
      <div *dxTemplate>
        <p *ngIf="(isEmailExistOnOtherRecord$ | async) && (isEmailValid$ | async)">
          <b>Warning:</b> The same Email Address exist on the other Agent record.
        </p>

        <ng-container> We will send a one-tme confirmation code to the selected address.</ng-container>
      </div>
    </dxi-item>

    <dxi-item
      [dataField]="'otp'"
      [label]="{ text: 'One Time Code' }"
      [visible]="SetLoginEmailModalSteps.confirmEmail === (selectedStep$ | async)"
      [editorOptions]="{
        mask: '0 0 0 0 0 0',
        placeholder: '',
        buttons: [
          {
            name: 'sendOTP',
            location: 'after',
            options: {
              template: 'sendOTPButtonTemplate',
              elementAttr: {
                class: 'inline-editor-control'
              },
              type: 'default',
              stylingMode: 'text',

              hint: 'Resend Confirmation Code',
              disabled: !(isResendAvailable$ | async),
              onClick: sendOtp
            }
          }
        ]
      }"
    >
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_ONE_TIME_CODE_TEXT"> </dxi-validation-rule>

      <dxi-validation-rule
        type="stringLength"
        [min]="oneTimeCodeDigits"
        [max]="oneTimeCodeDigits"
        [message]="Messages.INVALID_ONE_TIME_CODE_DIGITS_TEXT"
      ></dxi-validation-rule>

      <dxi-validation-rule type="async" [message]="Messages.REQUIRED_ONE_TIME_CODE_TEXT" [validationCallback]="otpAsyncValidation">
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item [visible]="SetLoginEmailModalSteps.confirmEmail === (selectedStep$ | async)">
      <div *dxTemplate>Enter the confirmation code and press button below to set the new Login Email Address.</div>
    </dxi-item>

    <div *dxTemplate="let item of 'sendOTPButtonTemplate'" class="editor-progress-indicator">
      <ng-container *ngIf="isSendOTPInProgress$ | async; then loadIndicatorTmp; else saveIconTmp"></ng-container>
    </div>
  </dx-form>
</ag-shr-modal-window>

<ng-template #saveIconTmp>
  <div class="set-login-email-modal__indicator-container">
    <span>
      <ng-container *ngIf="!(isResendAvailable$ | async)">{{ resendTimeout$ | async }}</ng-container>
    </span>
    <span>
      Resend
    </span>
    <i class="dx-icon-agi-rotate-right set-login-email-modal__icon"></i>
  </div>
</ng-template>

<ng-template #loadIndicatorTmp>
  <dx-load-indicator
    class="editor-progress-indicator__indicator"
    [visible]="true"
    height="18"
    width="18"
  ></dx-load-indicator>
</ng-template>
