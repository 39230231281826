import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogEmailComponent } from './change-log-email.component';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogEmailComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule],
  exports: [ChangeLogEmailComponent],
})
export class ChangeLogEmailModule {}
