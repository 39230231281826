<div class="container p-0 m-0">
  <div class="row p-0 m-0">
    <div class="col-12 p-0 m-0">
      <dx-toolbar class="header-toolbar">
        <dxi-item location="before" cssClass="header-title" [text]="'Recipients'"></dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="auto"
          [options]="addButtonOptions"
          [height]="'10px'"
        ></dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="auto"
          [options]="addSummaryButtonOptions"
          [height]="'10px'"
        ></dxi-item>
      </dx-toolbar>
    </div>
  </div>
</div>
<div class="row p-4 m-0 justify-content-start" *ngIf="session.selectedDistributionList">
  <div class="col-12 p-2 m-0 align-self-center text-start">
    <dx-list
      [dataSource]="session.selectedDistributionList.to"
      [height]="400"
      [displayExpr]="'p_agent_name'"
      [allowItemDeleting]="true"
      [itemDeleteMode]="'static'"
      (onItemDeleted)="deleteRecipient($event)"
    >
    </dx-list>
  </div>
</div>

<ng-container *ngIf="selectAgentVisible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="Add Recipient"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="selectAgentVisible"
  >
    <app-agents-selection
      [selectedAgentKeys]="selectedAgents"
      (onAgentsSelected)="onAgentsSelected($event)"
      (onClose)="onClose()"
    ></app-agents-selection>
  </dx-popup>
</ng-container>

<ng-container *ngIf="selectSummaryVisible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="Add Recipient"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="selectSummaryVisible"
  >
    <app-summary-selection
      [selectedAgentKeys]="selectedAgents"
      (onAgentsSelected)="onAgentsSelected($event)"
      (onClose)="onClose()"
    ></app-summary-selection>
  </dx-popup>
</ng-container>
