import { Component } from '@angular/core';
import { AppInfoService } from 'src/app/shared/services/util/app-info.service';

@Component({
  selector: 'ag-crm-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  constructor(public appInfo: AppInfoService) {}
}
