import { Component } from '@angular/core';
import { Tab } from 'ag-common-lib/public-api';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent {
  generalItems = ['Profile', 'Email', 'Calendar'];

  tabs: Tab[] = [
    { id: 0, text: 'Profile', template: 'Profile' },
    { id: 1, text: 'Email', template: 'Email' },
    { id: 2, text: 'Calendar', template: 'Calendar' },
  ];

  selectedTab: string = 'Profile';

  selectTab(e) {
    this.selectedTab = e.itemData.template;
  }
}
