import {
  AssociationKeys, ConferenceKeys,
  ConferencePersonalStepsConfigurationSectionNameMap,
  ConferenceRegistrationAddressKeys,
  ConferenceRegistrationEmailKeys,
  ConferenceRegistrationPersonalInfoKeys,
  ConferenceRegistrationRSVPKeys,
  ConferenceRegistrationStepName,
  FLIGHT_INFO_KEYS_LOOKUP,
  FlightInfoKeys,
  GUEST_TITLE_LIST,
  GuestKeys,
  HOTEL_RESERVATION_KEYS_LOOKUP,
  HotelReservationKeys,
  RegistrantKeys,
  REGISTRANTS_TITLE_LIST,
  TShirtSizesKeys,
} from 'ag-common-lib/public-api';

export const rsvpFieldsList = ConferenceRegistrationRSVPKeys.map(key => ({
  id: key,
  name: REGISTRANTS_TITLE_LIST[key],
  category: 'RSVP',
}));

export const registrationPersonalInfoFieldsList = ConferenceRegistrationPersonalInfoKeys.map(key => ({
  id: key,
  name: REGISTRANTS_TITLE_LIST[key],
  category: 'About You',
}));
export const registrationEmailFieldsList = ConferenceRegistrationEmailKeys.map(key => ({
  id: key,
  name: REGISTRANTS_TITLE_LIST[key],
  category: 'Email',
}));
export const registrationAddressFieldsList = ConferenceRegistrationAddressKeys.map(key => ({
  id: key,
  name: REGISTRANTS_TITLE_LIST[key],
  category: 'Address',
}));
export const registrationTShirtSizesFieldsList = [
  {
    id: [RegistrantKeys.tShirtSizes, TShirtSizesKeys.tShirtSize].join('.'),
    name: 'T-Shirt Size',
    category: 'Shirt Sizes',
  },
  {
    id: [RegistrantKeys.tShirtSizes, TShirtSizesKeys.unisexTShirtSize].join('.'),
    name: 'Unisex T-Shirt Size',
    category: 'Shirt Sizes',
  },
];

export const registrationEmergencyContactFieldsList = [
  {
    id: [RegistrantKeys.emergencyContact, AssociationKeys.firstName].join('.'),
    name: 'Contact First Name',
    category: 'Emergency Contact',
  },
  {
    id: [RegistrantKeys.emergencyContact, AssociationKeys.lastName].join('.'),
    name: 'Contact Last Name',
    category: 'Emergency Contact',
  },
  {
    id: [RegistrantKeys.emergencyContact, AssociationKeys.associationType].join('.'),
    name: 'Contact Relation to You',
    category: 'Emergency Contact',
  },
  {
    id: [RegistrantKeys.emergencyContact, AssociationKeys.contactNumber].join('.'),
    name: 'Contact Phone Number',
    category: 'Emergency Contact',
  },
];

export const personalInfoFieldsList = [
  ...registrationPersonalInfoFieldsList,
  ...registrationEmailFieldsList,
  ...registrationAddressFieldsList,
  ...registrationTShirtSizesFieldsList,
  ...registrationEmergencyContactFieldsList,
];

export const guestFieldsList = [
  {
    id: RegistrantKeys.isGuestsAttended,
    name: 'Is Guests Attended',
    category: 'Guests Configuration',
  },
  {
    id: RegistrantKeys.additionalGuestRequested,
    name: 'Additional Guest Requested',
    category: 'Guests Configuration',
  },
  {
    id: ConferenceKeys.guestRates,
    name: 'Buy-In Rates',
    category: 'Guests Configuration',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.prefix].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.prefix],
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.firstName].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.firstName],
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.lastName].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.lastName],
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.suffix].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.suffix],
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.gender].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.gender],
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.dob].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.dob],
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.associationType].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.associationType],
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.tShirtSizes, TShirtSizesKeys.tShirtSize].join('.'),
    name: 'T-Shirt Size',
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.tShirtSizes, TShirtSizesKeys.unisexTShirtSize].join('.'),
    name: 'Unisex T-Shirt Size',
    category: 'Guests Data',
  },
  {
    id: [RegistrantKeys.isGuestsAttended, AssociationKeys.dietaryConsideration].join('.'),
    name: GUEST_TITLE_LIST[AssociationKeys.dietaryConsideration],
    category: 'Guests Data',
  },
  {
    id: GuestKeys.badgeName,
    name: 'Badge Name',
    category: 'Guests Data',
  },
  {
    id: GuestKeys.childOrAdult,
    name: 'Child Or Adult',
    category: 'Guests Data',
  },
];

export const hotelReservationFieldsList = HOTEL_RESERVATION_KEYS_LOOKUP.map(lookup => ({
  id: lookup.value,
  name: lookup.description,
})).filter(
  item =>
    ![
      HotelReservationKeys.checkInDate,
      HotelReservationKeys.checkOutDate,
      HotelReservationKeys.requestedCheckOutDate,
      HotelReservationKeys.requestDifferBookingDatesOutcome,
      HotelReservationKeys.bookingInfo,
    ].includes(item.id),
);

const addFlightInfoCategory = (key: FlightInfoKeys) => {
  switch (key) {
    case FlightInfoKeys.prefix:
    case FlightInfoKeys.firstName:
    case FlightInfoKeys.middleName:
    case FlightInfoKeys.lastName:
    case FlightInfoKeys.suffix:
    case FlightInfoKeys.passportNationality:
    case FlightInfoKeys.passportConfirmation:
    case FlightInfoKeys.passportNameConfirmation:
      return 'Passport Information';

    case FlightInfoKeys.driverLicenseFirstName:
    case FlightInfoKeys.driverLicenseMiddleName:
    case FlightInfoKeys.driverLicenseLastName:
    case FlightInfoKeys.driverLicenseSuffix:
    case FlightInfoKeys.driverLicenseNameConfirmation:
      return 'Driver License Information';

    default:
      return 'Flight Information';
  }
};

export const flightInfoFieldsList = FLIGHT_INFO_KEYS_LOOKUP.map(lookup => ({
  id: [RegistrantKeys.flightInformation, lookup.value].join('.'),
  name: lookup.description,
  category: addFlightInfoCategory(lookup.value),
})).filter(
  item =>
    ![
      [RegistrantKeys.flightInformation, FlightInfoKeys.prefix].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.travelDatesDifferRequestOutcome].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.passportNationality].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.bookingInfo].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.isBookOwnFlights].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.status].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.dateSent].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.arrivalDate].join('.'),
      [RegistrantKeys.flightInformation, FlightInfoKeys.departureDate].join('.'),
    ].includes(item.id),
);

export const fieldsMap = new Map<string, string>();
const fieldsList = [
  ...rsvpFieldsList,
  ...personalInfoFieldsList,
  ...guestFieldsList,
  ...hotelReservationFieldsList,
  ...flightInfoFieldsList,
];
fieldsList.forEach(field => {
  fieldsMap.set(field.id, field.name);
});

export const personalInfoExcludedSectionsList = [
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationEmailAddressStep,
    name: ConferencePersonalStepsConfigurationSectionNameMap.get(
      ConferenceRegistrationStepName.registrantPersonalInformationEmailAddressStep,
    ),
  },
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationAddressStep,
    name: ConferencePersonalStepsConfigurationSectionNameMap.get(
      ConferenceRegistrationStepName.registrantPersonalInformationAddressStep,
    ),
  },
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationPhoneNumberStep,
    name: ConferencePersonalStepsConfigurationSectionNameMap.get(
      ConferenceRegistrationStepName.registrantPersonalInformationPhoneNumberStep,
    ),
  },
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationShirtSizeStep,
    name: ConferencePersonalStepsConfigurationSectionNameMap.get(
      ConferenceRegistrationStepName.registrantPersonalInformationShirtSizeStep,
    ),
  },
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationHeadshotStep,
    name: ConferencePersonalStepsConfigurationSectionNameMap.get(
      ConferenceRegistrationStepName.registrantPersonalInformationHeadshotStep,
    ),
  },
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationDietaryConsiderationsStep,
    name: ConferencePersonalStepsConfigurationSectionNameMap.get(
      ConferenceRegistrationStepName.registrantPersonalInformationDietaryConsiderationsStep,
    ),
  },
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationEmergencyContactStep,
    name: ConferencePersonalStepsConfigurationSectionNameMap.get(
      ConferenceRegistrationStepName.registrantPersonalInformationEmergencyContactStep,
    ),
  },
];
