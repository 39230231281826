import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeDetailsHeaderComponent } from './attendee-details-header.component';
import { AttendeeDetailsHeaderService } from './attendee-details-header.service';
import { ProfilePictureModule } from 'ag-common-svc/lib/components/agent-editor/components/profile-picture/profile-picture.module';
import { RegistrationTypePipe } from './registration-type.pipe';
import { DxPopoverModule } from 'devextreme-angular';
import { RegistrantWizardStatePipe } from './registrant-wizard-state.pipe';
import { DatesRangePipe } from 'ag-common-svc/shared/pipes/dates-range.pipe';

@NgModule({
  imports: [CommonModule, SharedModule, ProfilePictureModule, DxPopoverModule],
  declarations: [AttendeeDetailsHeaderComponent, RegistrationTypePipe, RegistrantWizardStatePipe],
  exports: [AttendeeDetailsHeaderComponent],
  providers: [AttendeeDetailsHeaderService, DatesRangePipe],
})
export class AttendeeDetailsHeaderModule {}
