import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogTshirtSizeComponent } from './change-log-tshirt-size.component';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogTshirtSizeComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule,],
  exports: [ChangeLogTshirtSizeComponent],
})
export class ChangeLogTshirtSizeModule {}
