<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Personal Information'"
    [isEditVisible]="canEdit"
    (clickEdit)="personalInformationModalComponent.showModal()"
  ></ag-shr-editor-toolbar>

  <div class="row my-2 px-3">
    <!-- <div class="col-3 text-start"><b>Nick Name:</b></div>
    <div class="col-3">
      {{ personalInformationFormData?.p_nick_name }} {{ personalInformationFormData?.p_nick_last_name }}
    </div> -->
    <div class="col-3 text-start"><b>Race:</b></div>
    <div class="col-3">{{ personalInformationFormData?.race }}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Date Of Birth:</b></div>
    <div class="col-3">{{ personalInformationFormData?.dob | date : dateFormat }}</div>
    <div class="col-3 text-start"><b>Ethnicity:</b></div>
    <div class="col-3">{{ personalInformationFormData?.ethnicity }}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Gender:</b></div>
    <div class="col-3">{{ personalInformationFormData?.gender | gender | async }}</div>
    <div class="col-3 text-start"><b>Primary Language:</b></div>
    <div class="col-3">{{ personalInformationFormData?.primary_language }}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-6 text-start"></div>
    <div class="col-3 text-start"><b>Second Language:</b></div>
    <div class="col-3">{{ personalInformationFormData?.secondary_language }}</div>
  </div>
</div>

<ag-shr-modal-window
  #personalInformationModalRef
  [width]="700"
  [height]="'auto'"
  [title]="'Personal Information'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handelSaveClick()"
  (onPopupClose)="handleClosePopup($event)"
>
  <dx-form
    #personalInformationFormRef
    class="mt-2"
    [colCount]="2"
    [(formData)]="personalInformationFormData"
    labelMode="floating"
  >
    <!-- <dxi-item
      dataField="p_nick_name"
      [colSpan]="1"
      [label]="{ text: 'Nick Name - First' }"
      editorType="dxTextBox"
    ></dxi-item>

    <dxi-item
      dataField="p_nick_last_name"
      [colSpan]="1"
      [label]="{ text: 'Nick Name - Last' }"
      editorType="dxTextBox"
    ></dxi-item> -->

    <dxi-item
      dataField="dob"
      [colSpan]="1"
      editorType="dxDateBox"
      [label]="{ text: 'Date Of Birth' }"
      [editorOptions]="dateEditorOptions"
    >
    </dxi-item>

    <dxi-item dataField="gender" [colSpan]="1">
      <div *dxTemplate>
        <ag-shr-gender-select-box
          [(value)]="personalInformationFormData.gender"
          label="Gender"
          (selectedItemChange)="handleGenderSelect($event)"
        ></ag-shr-gender-select-box>
      </div>
    </dxi-item>

    <dxi-item dataField="primary_language" [colSpan]="1" [template]="'piPrimaryLanguageTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'piPrimaryLanguageTemplate'">
      <dx-select-box
        label="Primary Language"
        labelMode="floating"
        [(value)]="personalInformationFormData.primary_language"
        [items]="languages"
        [displayExpr]="'name'"
        [valueExpr]="'name'"
        [searchEnabled]="true"
        [showClearButton]="true"
      >
      </dx-select-box>
    </div>

    <dxi-item dataField="secondary_language" [colSpan]="1" [template]="'piSecondaryLanguageTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'piSecondaryLanguageTemplate'">
      <dx-select-box
        label="Second Language"
        labelMode="floating"
        [(value)]="personalInformationFormData.secondary_language"
        [items]="languages"
        [displayExpr]="'name'"
        [valueExpr]="'name'"
        [searchEnabled]="true"
        [showClearButton]="true"
      >
      </dx-select-box>
    </div>

    <dxi-item dataField="race" [colSpan]="1" [template]="'piRaceTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'piRaceTemplate'">
      <dx-text-box [(value)]="personalInformationFormData.race" labelMode="floating" label="Race"></dx-text-box>
    </div>

    <dxi-item dataField="ethnicity" [colSpan]="1" [template]="'piEthnicityTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'piEthnicityTemplate'">
      <dx-text-box
        [(value)]="personalInformationFormData.ethnicity"
        label="Ethnicity"
        labelMode="floating"
      ></dx-text-box>
    </div>
  </dx-form>
</ag-shr-modal-window>
