import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminCrmComponent } from './admin-crm.component';
import { AdminCrmRoutes } from './admin-crm.model';
import { RoleManagerGuard } from './role-manager/role-manager.guard';
import { GoogleAuthManagementGuard } from './google-auth-management/google-auth-management.guard';
import { UserRoleManagerGuard } from './user-roles-manager/user-role-manager.guard';
import { TaskListManagerGuard } from './task-list-manager/task-list-manager.guard';
import { SalesGoalsManagerGuard } from './sales-goals/sales-goals-manager.guard';
import { ReportsManagerGuard } from './reports/reports-manager.guard';
import { LogsManagerGuard } from './logs-manager/logs-manager.guard';
import { ImportRulesManagerGuard } from './import-rules-manager/import-rules-manager.guard';
import { ErrorsManagerGuard } from './errors-manager/errors-manager.guard';
import { LookupsManagerGuard } from './lookups-manager/lookups-manager.guard';
import { AuthStatsGuard } from './auth-stats/auth-stats.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminCrmComponent,
    children: [
      {
        path: AdminCrmRoutes.TaskListManager,
        loadChildren: () =>
          import('./task-list-manager/task-list-manager.module').then(mod => mod.TaskListManagerModule),
        canActivate: [TaskListManagerGuard],
      },
      {
        path: AdminCrmRoutes.LookupsManager,
        loadChildren: () => import('./lookups-manager/lookups-manager.module').then(mod => mod.LookupsManagerModule),
        canActivate: [LookupsManagerGuard],
      },
      {
        path: AdminCrmRoutes.ImportRulesManager,
        loadChildren: () =>
          import('./import-rules-manager/import-rules-manager.module').then(mod => mod.ImportRulesManagerModule),
        canActivate: [ImportRulesManagerGuard],
      },
      {
        path: AdminCrmRoutes.ErrorsManager,
        loadChildren: () => import('./errors-manager/errors-manager.module').then(mod => mod.ErrorsManagerModule),
        canActivate: [ErrorsManagerGuard],
      },
      {
        path: AdminCrmRoutes.LogsManager,
        loadChildren: () => import('./logs-manager/logs-manager.module').then(mod => mod.LogsManagerModule),
        canActivate: [LogsManagerGuard],
      },
      {
        path: AdminCrmRoutes.UserRolesManager,
        loadChildren: () =>
          import('./user-roles-manager/user-roles-manager.module').then(mod => mod.UserRolesManagerModule),
        canActivate: [UserRoleManagerGuard],
      },
      {
        path: AdminCrmRoutes.ReportsManager,
        loadChildren: () => import('./reports/reports.module').then(mod => mod.ReportsModule),
        canActivate: [ReportsManagerGuard],
      },
      {
        path: AdminCrmRoutes.AgentMergeTool,
        loadChildren: () => import('./agent-merge-tool/agent-merge-tool.module').then(mod => mod.AgentMergeToolModule),
      },
      {
        path: AdminCrmRoutes.SalesGoals,
        loadChildren: () => import('./sales-goals/sales-goals.module').then(mod => mod.SalesGoalsModule),
        canActivate: [SalesGoalsManagerGuard],
      },
      {
        path: AdminCrmRoutes.RoleManager,
        loadChildren: () => import('./role-manager/role-manager.module').then(mod => mod.RoleManagerModule),
      },
      {
        path: AdminCrmRoutes.GoogleAuthManagement,
        loadChildren: () =>
          import('./google-auth-management/google-auth-management.module').then(mod => mod.GoogleAuthManagementModule),
        canActivate: [GoogleAuthManagementGuard],
      },
      {
        path: AdminCrmRoutes.AuthStats,
        loadChildren: () => import('./auth-stats/auth-stats.module').then(mod => mod.AuthStatsModule),
        canActivate: [AuthStatsGuard],
      },
      // TODO Guard create
      {
        path: AdminCrmRoutes.AgentMergeTool,
        loadChildren: () => import('./agent-merge-tool/agent-merge-tool.module').then(mod => mod.AgentMergeToolModule),
        canActivate: [AuthStatsGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule],
})
export class AdminCrmRoutingModule {}
