<app-single-card>
  <dx-form #signInFormRef [formData]="formData" [disabled]="showLoader">
    <dxi-item
      dataField="email"
      editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }"
    >
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_EMAIL_TEXT"></dxi-validation-rule>
      <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item
      dataField="password"
      editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }"
    >
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_PASSWORD_TEXT"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <shr-button-with-indicator
          [title]="'Sign In'"
          [cssClass]="'login-form__button'"
          [isInProgress]="showLoader"
          (onClick)="onSubmit()"
        ></shr-button-with-indicator>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'signInTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="showLoader; else notLoading">
            <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
          </ng-container>
          <ng-template #notLoading>Sign In</ng-template>
        </span>
      </div>
    </ng-container>
  </dx-form>
</app-single-card>
