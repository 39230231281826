import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogHeadshotComponent } from './change-log-headshot.component';
import { ImageWithLoaderModule } from 'ag-common-svc/lib/components/image-with-loader/image-with-loader.module';

@NgModule({
  declarations: [ChangeLogHeadshotComponent],
  imports: [CommonModule, SharedModule, ImageWithLoaderModule],
  exports: [ChangeLogHeadshotComponent],
})
export class ChangeLogHeadshotModule {}
