import { Component, HostBinding, Input } from '@angular/core';
import {
  Conference,
  ConferenceKeys,
  Constants,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  Messages,
} from 'ag-common-lib/public-api';
import { CONFERENCES_TITLE_LIST, PHONE_NUMBER_OPTIONS } from '../../../config/conferences.config';

@Component({
  selector: 'ag-crm-conference-primary-info-form',
  templateUrl: './conference-primary-info-form.component.html',
  styleUrls: ['./conference-primary-info-form.component.scss'],
})
export class ConferencePrimaryInfoFormComponent {
  @HostBinding('class') className = 'conference-primary-info-form';
  @Input() conference: Partial<Conference>;
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT;
  protected readonly requiredMessage = Messages.REQUIRED_TEXT;
  protected readonly phoneNumberOptions = PHONE_NUMBER_OPTIONS;
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly Messages = Messages;
}
