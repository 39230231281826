import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogAssociationComponent } from './change-log-association.component';
import { ChangeLogEmailModule } from '../email-address/change-log-email.module';
import { ChangeLogTshirtSizeModule } from '../t-shirt-sizes/change-log-tshirt-size.module';
import { ChangeLogAddressModule } from '../address/change-log-address.module';
import { ChangeLogDietaryConsiderationModule } from '../dietary-consideration/change-log-dietary-consideration.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogAssociationComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogEmailModule,
    ChangeLogTshirtSizeModule,
    ChangeLogAddressModule,
    ChangeLogDietaryConsiderationModule,
    ChangeLogPipesModule,
  ],
  exports: [ChangeLogAssociationComponent],
})
export class ChangeLogAssociationModule {}
