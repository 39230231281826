import { Component, HostBinding } from '@angular/core';
import {
  FlightBookingKeys,
  FlightInfoKeys,
  TravelMode,
} from 'ag-common-lib/lib/models/registration/flight-information.model';

import {
  Agent,
  AgentKeys,
  AttendeeKeys,
  BaseModelKeys,
  CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP,
  ConferenceRegistrationCommonTaskStatus,
  Entity,
  EntityPermissionActivityKeys,
  LookupKeys,
  Role,
} from 'ag-common-lib/public-api';

import { LookupsService } from 'ag-common-svc/lib/services';
import { AttendeeDrivingDataKeys, AttendeeFlightService } from './attendee-flight.service';
import { LoadOptions } from 'devextreme/data';
import {
  AssignedOwnerDetails,
  AssignedOwnerDetailsKeys,
} from 'ag-common-svc/lib/components/ag-assign-owner-viewer/config/ag-assign-owner.config';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { map, shareReplay } from 'rxjs';

@Component({
  selector: 'ag-crm-attendee-flight',
  templateUrl: './attendee-flight.component.html',
  styleUrls: ['./attendee-flight.component.scss'],
})
export class AttendeeFlightComponent {
  @HostBinding('class') className = 'ag-crm-attendee-flight';

  disableArrowCssClass = '';
  protected flightDataSource$ = this.attendeeFlightService.flightDataSource$.pipe(
    map(dataSource => {
      const isDataSourceNotExist = !(
        dataSource?.flights?.length ||
        dataSource?.flightsBookedByOwn?.length ||
        dataSource?.driving?.length
      );

      this.attendeesTravelGroup = isDataSourceNotExist
        ? []
        : [
            {
              id: TravelMode.flying,
              title: 'Requested Flights',
              visible: !!dataSource?.flights?.length,
            },
            {
              id: 'bookedByOwn',
              title: 'Flights Booked By Own',
              visible: !!dataSource?.flightsBookedByOwn?.length,
            },
            {
              id: TravelMode.driving,
              title: 'Flight Not Requested',
              visible: !!dataSource?.driving?.length,
            },
          ];
      this.disableArrowCssClass =
        this.attendeesTravelGroup?.filter(({ visible }) => visible).length === 1 ? ' accordion-item--disabled' : '';
      return dataSource;
    }),
    shareReplay(1),
  );
  protected flyTaskStatus$ = this.attendeeFlightService.flyTaskStatus$;

  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly FlightBookingKeys = FlightBookingKeys;
  protected readonly AttendeeKeys = AttendeeKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly suffixesLookup$ = this.lookupsService.suffixesLookup$;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly AttendeeDrivingDataKeys = AttendeeDrivingDataKeys;
  protected readonly TravelMode = TravelMode;
  protected readonly CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP = CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP;
  attendeesTravelGroup = [];
  protected readonly agentsLoadOptions: LoadOptions<Agent> = {
    filter: [AgentKeys.role, '=', Role.CONFERENCE_MANAGER],
  };

  constructor(
    private attendeeFlightService: AttendeeFlightService,
    private lookupsService: LookupsService,
  ) {}

  calculateFullNameDisplayValue = data => {
    return [data?.[AttendeeKeys.firstName], data?.[AttendeeKeys.middleName], data?.[AttendeeKeys.lastName]]
      .filter(Boolean)
      .join(' ');
  };

  protected onOwnerChange = (data: AssignedOwnerDetails) => {
    return this.attendeeFlightService.updateFlyTask({
      assignedTo: data?.[AssignedOwnerDetailsKeys.assignedTo] ?? null,
      assignedPersonNote: data?.[AssignedOwnerDetailsKeys.assignedToNote] ?? null,
    });
  };

  protected onTaskStatusChanged = (flyTaskStatus: ConferenceRegistrationCommonTaskStatus, e: ValueChangedEvent) => {
    if (flyTaskStatus === e?.value) {
      return;
    }
    this.attendeeFlightService.updateFlyTask({
      status: e?.value ?? null,
    });
  };
}
