import { Injectable } from '@angular/core';
import { defer, firstValueFrom, Observable, of } from 'rxjs';
import { mergeMap, shareReplay, startWith } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CampaignsManagementTasksStatus } from 'ag-common-lib/lib/lists/campaign-management-tasks-status.enum';
import { AgentCampaignsManagementTasksService } from 'ag-common-svc/lib/services/agent-campaigns-management-tasks.service';
import {
  CampaignsManagementTasks,
  CampaignsManagementTasksKeys,
} from 'ag-common-lib/lib/models/utils/campaign-management-tasks.model';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { BaseModelKeys, CampaignsManagementTaskLogsAction } from 'ag-common-lib/public-api';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy()
@Injectable()
export class CampaignsManagementTaskBoardService extends BaseFormService<Partial<CampaignsManagementTasks>> {
  boardLists$: Observable<any>;

  constructor(
    private agentCampaignsManagementTasksService: AgentCampaignsManagementTasksService,
    private agentElasticSearchService: AgentElasticSearchService,
    private toastrService: ToastrService,
  ) {
    super();

    this.boardLists$ = this.agentCampaignTasks$.pipe(startWith([]), mergeMap(this.getBoardData));
  }

  async assignOwner(agentDbId: string, taskDbId: string, data: Partial<CampaignsManagementTasks>) {
    this.startProgress();
    return await this.agentCampaignsManagementTasksService
      .update(agentDbId, taskDbId, data, CampaignsManagementTaskLogsAction.assignOwner)
      .then(() => {
        this.onSuccessfulCreated();
        return true;
      })
      .catch(err => {
        this.toastrService.error('Campaigns Owner are not assigned!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  private getBoardData = async (tasks: CampaignsManagementTasks[]) => {
    const boardListsMap = new Map([
      [CampaignsManagementTasksStatus.newTasks, []],
      [CampaignsManagementTasksStatus.inProgress, []],
      [CampaignsManagementTasksStatus.contentIssues, []],
      [CampaignsManagementTasksStatus.technicalIssues, []],
      [CampaignsManagementTasksStatus.done, []],
    ]);

    const requiredAgents = new Set<string>();

    tasks.forEach(task => {
      requiredAgents.add(task?.[CampaignsManagementTasksKeys.agentDbId]);
    });
    const agents = await this.agentElasticSearchService.getByIds(Array.from(requiredAgents.values()));

    for (const task of tasks) {
      const agent = agents.find(
        agent => task?.[CampaignsManagementTasksKeys.agentDbId] === agent?.[BaseModelKeys.dbId],
      );

      switch (task?.[CampaignsManagementTasksKeys.taskStatus]) {
        case CampaignsManagementTasksStatus.newTasks:
          boardListsMap.get(CampaignsManagementTasksStatus.newTasks)?.push({ agent, task });
          break;
        case CampaignsManagementTasksStatus.inProgress:
          boardListsMap.get(CampaignsManagementTasksStatus.inProgress)?.push({ agent, task });
          break;
        case CampaignsManagementTasksStatus.contentIssues:
          boardListsMap.get(CampaignsManagementTasksStatus.contentIssues)?.push({ agent, task });
          break;
        case CampaignsManagementTasksStatus.technicalIssues:
          boardListsMap.get(CampaignsManagementTasksStatus.technicalIssues)?.push({ agent, task });
          break;
        case CampaignsManagementTasksStatus.done:
          boardListsMap.get(CampaignsManagementTasksStatus.done)?.push({ agent, task });
          break;

        default:
          break;
      }
    }

    this.stopProgress();
    return Array.from(boardListsMap.entries()).map(([status, list]) => {
      list.sort((left, right) => {
        return `${left.p_agent_name}`.localeCompare(`${right.p_agent_name}`, 'en', {
          numeric: true,
          sensitivity: 'base',
          ignorePunctuation: true,
        });
      });
      return {
        status,
        list,
      };
    });
  };

  private agentCampaignTasks$ = defer(() => {
    this.startProgress();
    return this.agentCampaignsManagementTasksService.getActiveTasks().pipe(shareReplay(1));
  });
}
