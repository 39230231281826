import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminCrmRoutingModule } from './admin-crm-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AdminCrmComponent } from './admin-crm.component';
import { TaskWorkflowService } from '../ag-crm/common/activity-info/tasks/task-workflow.service';
import { AgCrmCommonModule } from '../ag-crm/common/ag-crm-common.module';
import { ReportsModule } from './reports/reports.module';

@NgModule({
  declarations: [AdminCrmComponent],
  providers: [TaskWorkflowService],
  imports: [CommonModule, SharedModule, AdminCrmRoutingModule, AgCrmCommonModule, ReportsModule],
})
export class AdminCrmModule {}
