<div class="mt-2">
  <ag-shr-editor-toolbar [title]="'Campaigns'" [isEditVisible]="canEdit" (clickEdit)="campaignsModalComponent.showModal()"></ag-shr-editor-toolbar>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Campaigns Status:</b></div>
    <div class="col-3">{{ agentCampaigns?.campaignsStatus }}</div>

    <div *ngIf="isOutstandUser" class="col-3 text-start"><b>Outstand Site:</b></div>
    <div *ngIf="isOutstandUser" class="col-3 overflow-ellipsis">{{ agentCampaigns?.outstandSite }}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>User Since:</b></div>
    <div class="col-3">{{ agentCampaigns?.userSince | date: dateFormat }}</div>

    <div *ngIf="isOutstandUser"  class="col-3 text-start"><b>Outstand Emails Sent:</b></div>
    <div *ngIf="isOutstandUser" class="col-3">{{ agentCampaigns?.outstandEmailsSent }}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Stopped Campaigns:</b></div>
    <div class="col-3">{{ agentCampaigns?.stoppedCampaigns | date: dateFormat }}</div>

    <div *ngIf="isOutstandUser"  class="col-3 text-start"><b>Outstand Contacts :</b></div>
    <div *ngIf="isOutstandUser" class="col-3">{{ agentCampaigns?.outstandContacts}}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Original Outstand User:</b></div>
    <div class="col-3">{{ agentCampaigns?.originalOutstandUser | yesNo }}</div>

    <div *ngIf="isOutstandUser"  class="col-3 text-start"><b>Outstand Subscription Level :</b></div>
    <div *ngIf="isOutstandUser" class="col-3 overflow-ellipsis">{{ agentCampaigns?.outstandSubscriptionLevel }}</div>
  </div>
</div>

<ag-shr-modal-window
  #campaignsModalRef
  [width]="700"
  [height]="450"
  [title]="'Campaigns'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleSaveClick()"
  (onPopupClose)="handleClosePopup()"
>
  <dx-form #campaignsFormRef class="mt-2" [colCount]="1" [(formData)]="campaignsFormData" labelMode="floating">
    <dxi-item
      [dataField]="AgentCampaignsKeys.campaignsStatus"
      [editorType]="'dxSelectBox'"
      [editorOptions]="{ items: AGENT_CAMPAIGNS_STATUS_LOOKUP, valueExpr: 'value', displayExpr: 'description', placeholder: '' }"
    >
      <dxo-label text="Campaigns Status"></dxo-label>
    </dxi-item>

    <dxi-item
      [dataField]="AgentCampaignsKeys.userSince"
      editorType="dxDateBox"
      [label]="{text: 'User Since'}"
      [editorOptions]="dateEditorOptions"
    >
      <dxi-validation-rule
        type="custom"
        [validationCallback]="validateDate"
        [message]="Messages.INVALID_DATE_FORMAT_TEXT"
        [reevaluate]="true"
        [ignoreEmptyValue]="true"
      >
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item
      [dataField]="AgentCampaignsKeys.stoppedCampaigns"
      editorType="dxDateBox"
      [label]="{text: 'Stopped Campaigns'}"
      [editorOptions]="dateEditorOptions"
    >
      <dxi-validation-rule
        type="custom"
        [validationCallback]="validateDate"
        [message]="Messages.INVALID_DATE_FORMAT_TEXT"
        [reevaluate]="true"
        [ignoreEmptyValue]="true"
      >
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item
      [dataField]="AgentCampaignsKeys.originalOutstandUser"
      editorType="dxCheckBox"
      [label]="{ text: 'Original Outstand User'}"
      cssClass="form-items__checkbox--label-right"
    ></dxi-item>

    <dxi-item itemType="group" [colCount]="2">

    <dxi-item
      *ngIf="campaignsFormData?.originalOutstandUser"
      [dataField]="AgentCampaignsKeys.outstandSite"
      [label]="{ text: 'Outstand Site'}"
    >
      <dxi-validation-rule
        type="custom"
        [validationCallback]="validateUrlWithOrWithoutProtocol"
        [message]="Messages.INVALID_URL_FORMAT_TEXT"
      >
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item
      *ngIf="campaignsFormData?.originalOutstandUser"
      [dataField]="AgentCampaignsKeys.outstandEmailsSent"
      editorType="dxNumberBox"
      [label]="{ text: 'Outstand Emails Sent '}"
    >
    </dxi-item>

    <dxi-item
      *ngIf="campaignsFormData?.originalOutstandUser"
      [dataField]="AgentCampaignsKeys.outstandContacts"
      editorType="dxNumberBox"
      [label]="{ text: 'Outstand Contacts'}"
    >
    </dxi-item>

    <dxi-item
      *ngIf="isOutstandUser"
      [dataField]="AgentCampaignsKeys.outstandSubscriptionLevel"
      [label]="{ text: 'Outstand Subscription Level'}"
    >
    </dxi-item>
    </dxi-item>

  </dx-form>
</ag-shr-modal-window>
