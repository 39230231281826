import { Component } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Agent, CRMUser, EmailConnection } from 'ag-common-lib/public-api';
import { CrmUserService } from 'src/app/shared/services/crm-user.service';
import { AuthService } from 'ag-common-svc/public-api';
import { EncrDecrService } from 'src/app/shared/services/util/encr-decr.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-email-tab',
  templateUrl: './email-tab.component.html',
  styleUrls: ['./email-tab.component.scss'],
})
export class EmailTabComponent {
  dataSource: any = {};

  constructor(
    public crmUserService: CrmUserService,
    public authService: AuthService,
    public encryption: EncrDecrService,
  ) {
    this.dataSource = new CustomStore({
      key: 'email_address',
      loadMode: 'raw',
      load: async function (loadOptions: any) {
        const agent = await firstValueFrom(authService.loggedInAgent$);
        return crmUserService.getById(agent?.dbId).then(user => {
          user.emailConnections.forEach(ec => {
            ec.password = encryption.get(ec.password);
          });
          return user.emailConnections;
        });
      },
      insert: function (value: EmailConnection) {
        value.password = encryption.set(value.password);

        // let user: Agent = authService.getAuthUserData();
        // user.emailConnections.push(value);

        //return crmUserService.update(user).then(() => {
        //authService.setCRMUserData(user);
        return Promise.resolve(null);
        //});
      },
      update: function (key: any, value: EmailConnection) {
        value.password = encryption.set(value.password);

        // let index: number = user.emailConnections.findIndex((e) => e.email_address == key);
        // user.emailConnections[index] = value;
        //return crmUserService.update(user).then(() => {
        //authService.setCRMUserData(user);
        return firstValueFrom(this.authService.loggedInAgent$);
        //});
      },
      remove: function (id: any) {
        // let user: CRMUser = authService.getAuthUserData();
        // let index: number = user.emailConnections.findIndex((e) => e.email_address == id);

        // user.emailConnections.splice(index, 1);

        // return crmUserService.update(user).then(() => {
        //authService.setCRMUserData(user);
        return Promise.resolve(null);
        //});
      },
    });
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  hidePassword() {
    return '****';
  }
}
