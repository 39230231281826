import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ModalWindowModule } from 'ag-common-svc/public-api';
import { AttendeeDetailsModalService } from './attendee-details-modal.service';
import { AttendeeDetailsModalComponent } from './attendee-details-modal.component';
import { DxLoadPanelModule, DxTabPanelModule, DxTooltipModule, DxValidationSummaryModule } from 'devextreme-angular';
import { AttendeeGeneralInfoFormModule } from './attendee-general-info-form/attendee-general-info-form.module';
import {
  ProfilePictureModule
} from 'ag-common-svc/lib/components/agent-editor/components/profile-picture/profile-picture.module';
import { AttendeeDetailsHeaderModule } from './attendee-details-header/attendee-details-header.module';
import { AttendeeDetailsTitleIconPipe } from './attendee-details-title-icon.pipe';
import { AttendeeGuestsModule } from './attendee-guests/attendee-guests.module';
import { AttendeeHotelReservationModule } from './attendee-hotel-reservation/attendee-hotel-reservation.module';
import { AttendeeTransactionsModule } from './attendee-transactions/attendee-transactions.module';
import { AttendeeFlightModule } from './attendee-flight/attendee-flight.module';
import { AttendeeExcursionsModule } from './attendee-excursions/attendee-excursions.module';
import { AttendeeNotesModule } from './attendee-notes/attendee-notes.module';
import { AttendeeDetailsTitleIconHintPipe } from './attendee-details-title-icon-hint.pipe';
import { AttendeeDetailsModalRequestsService } from './attendee-details-modal-requests.service';
import { AttendeeDetailsSectionDescriptionPipe } from './attendee-details-section-description.pipe';
import { RegistrationSummaryEmailService } from 'ag-common-svc/lib/services/registration-summary-email.service';
import { AttendeeChangeLogModule } from './attendee-change-log/attendee-change-log.module';
import { ConferencesPipesModule } from '../../pipes/conferences-pipes.module';
import {
  ConferenceRegistrationPipesModule
} from 'ag-common-svc/shared/pipes/conference-registration/conference-registration-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    DxLoadPanelModule,
    AttendeeGeneralInfoFormModule,
    ProfilePictureModule,
    AttendeeDetailsHeaderModule,
    AttendeeGuestsModule,
    AttendeeHotelReservationModule,
    AttendeeExcursionsModule,
    AttendeeTransactionsModule,
    AttendeeFlightModule,
    DxTabPanelModule,
    AttendeeNotesModule,
    DxTooltipModule,
    DxValidationSummaryModule,
    AttendeeChangeLogModule,
    ConferencesPipesModule,
    ConferenceRegistrationPipesModule,
  ],
  declarations: [
    AttendeeDetailsModalComponent,
    AttendeeDetailsTitleIconPipe,
    AttendeeDetailsTitleIconHintPipe,
    AttendeeDetailsSectionDescriptionPipe,
  ],
  exports: [AttendeeDetailsModalComponent],
  providers: [AttendeeDetailsModalService, AttendeeDetailsModalRequestsService, RegistrationSummaryEmailService],
})
export class AttendeeDetailsModalModule {}
