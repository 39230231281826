<dx-form [(formData)]="conference" labelMode="floating" [validationGroup]="validationGroup">
  <dxi-item itemType="group" caption="Coordinator" [colCount]="2">
    <dxi-item
      [dataField]="ConferenceKeys.eventCoordinatorFirstName"
      [label]="{ text: titleList[ConferenceKeys.eventCoordinatorFirstName] }"
      [isRequired]="true"
    ></dxi-item>

    <dxi-item
      [dataField]="ConferenceKeys.eventCoordinatorLastName"
      [label]="{ text: titleList[ConferenceKeys.eventCoordinatorLastName] }"
    ></dxi-item>

    <dxi-item
      [dataField]="ConferenceKeys.eventCoordinatorPhone"
      [editorOptions]="phoneNumberOptions"
      [label]="{ text: titleList[ConferenceKeys.eventCoordinatorPhone] }"
    >
      <dxi-validation-rule
        type="stringLength"
        [min]="totalPhoneDigits"
        [max]="totalPhoneDigits"
        [ignoreEmptyValue]="true"
        [trim]="true"
        [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
      >
      </dxi-validation-rule>
    </dxi-item>
  </dxi-item>

  <dxi-item itemType="group" caption="Location">
    <ag-shr-address-form
      [address]="conference?.[ConferenceKeys.eventAddress]"
      [validationGroup]="validationGroup"
      [isReadonly]="false"
    ></ag-shr-address-form>
  </dxi-item>
</dx-form>
