<div class="container">
  <div class="row d-flex mt-5 justify-content-around" style="margin: 0 auto">
    <div class="col-12">
      <dx-data-grid
        class="dx-card wide-card my-2"
        [dataSource]="dataSource"
        [showBorders]="false"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [height]="550"
        [selectedRowKeys]="selectedAgentKeys"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onRowDblClick)="showContactInfo($event)"
      >
        <dxo-selection [selectAllMode]="'allPages'" [showCheckBoxesMode]="'always'" mode="multiple"></dxo-selection>
        <dxo-paging [pageSize]="50"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-editing mode="popup" [useIcons]="true">
          <dxo-popup title="Agent Information" [showTitle]="false" [width]="900" [height]="900"></dxo-popup>

          <dxo-form [colCount]="12">
            <dxi-item itemType="group" caption="Agent Information" [colCount]="12" [colSpan]="12">
              <dxi-item dataField="p_agent_first_name" [colSpan]="6"></dxi-item>
              <dxi-item dataField="p_agent_last_name" [colSpan]="6"></dxi-item>
              <dxi-item dataField="p_prefix" [colSpan]="4"></dxi-item>
              <dxi-item dataField="p_suffix" [colSpan]="4"></dxi-item>
              <dxi-item dataField="p_mobile_phone" [colSpan]="4"></dxi-item>
              <dxi-item dataField="p_email" [colSpan]="12" [disabled]="true"></dxi-item>
            </dxi-item>

            <dxi-item itemType="group" caption="Agent Credentials" [colCount]="12" [colSpan]="12">
              <dxi-item dataField="p_agent_id" [colSpan]="4"></dxi-item>
              <dxi-item dataField="p_agency_id" [colSpan]="4"></dxi-item>
              <dxi-item dataField="npn" [colSpan]="4"></dxi-item>
            </dxi-item>

            <dxi-item itemType="group" caption="Personal Information" [colCount]="12" [colSpan]="12">
              <dxi-item dataField="p_nick_name" [colSpan]="6"></dxi-item>
              <dxi-item dataField="p_nick_last_name" [colSpan]="6"></dxi-item>
              <dxi-item dataField="p_dob" [colSpan]="6"></dxi-item>
              <dxi-item dataField="gender" [colSpan]="6"></dxi-item>
              <dxi-item dataField="p_headshot_link" [colSpan]="8"></dxi-item>
              <dxi-item dataField="p_tshirt_size" [colSpan]="4"></dxi-item>
            </dxi-item>

            <dxi-item itemType="group" caption="Dietary Considerations" [colCount]="12" [colSpan]="12">
              <dxi-item
                dataField="dietary_consideration"
                editorType="dxTextArea"
                [colSpan]="12"
                [editorOptions]="{ height: 100 }"
              ></dxi-item>
              <dxi-item
                dataField="dietary_consideration_type"
                editorType="dxTextArea"
                [colSpan]="12"
                [editorOptions]="{ height: 100 }"
              ></dxi-item>
              <dxi-item
                dataField="dietary_or_personal_considerations"
                editorType="dxTextArea"
                [colSpan]="12"
                [editorOptions]="{ height: 100 }"
              ></dxi-item>
            </dxi-item>
          </dxo-form>
        </dxo-editing>

        <dxi-column dataField="p_agent_first_name" [caption]="'First Name'">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="p_agent_last_name" [caption]="'Last Name'">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="p_email" [caption]="'Email'"></dxi-column>

        <dxi-column dataField="p_agent_id" [caption]="'Agent ID'"></dxi-column>

        <dxi-column dataField="p_agency_id" [caption]="'Agency'">
          <dxo-lookup
            [dataSource]="agencies"
            [allowClearing]="false"
            [displayExpr]="'name'"
            [valueExpr]="'agency_id'"
          ></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="p_prefix" [caption]="'Prefix'" [visible]="false">
          <dxo-lookup
            [dataSource]="prefixesLookup$ | async"
            [valueExpr]="'dbId'"
            [displayExpr]="'description'"
            [allowClearing]="false"
          >
          </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="p_mobile_phone" [caption]="'Mobile Phone'" [visible]="false"></dxi-column>
        <dxi-column dataField="p_work_phone" [caption]="'Work Phone'" [visible]="false"></dxi-column>
        <dxi-column dataField="npn" [caption]="'NPN Number'" [visible]="false"></dxi-column>
        <dxi-column dataField="upline" [caption]="'Upline/MGA'" [visible]="false"></dxi-column>
        <dxi-column
          dataField="is_manager"
          [caption]="'Is Manager'"
          [showEditorAlways]="false"
          [dataType]="'boolean'"
          [visible]="false"
        ></dxi-column>
        <dxi-column
          dataField="is_rmd"
          [caption]="'Is RMD'"
          [showEditorAlways]="false"
          [dataType]="'boolean'"
          [visible]="false"
        ></dxi-column>
        <dxi-column
          dataField="is_credited"
          [caption]="'Is Credited'"
          [showEditorAlways]="false"
          [dataType]="'boolean'"
          [visible]="false"
        ></dxi-column>

        <dxi-column dataField="p_suffix" [caption]="'Suffix'" [visible]="false"> </dxi-column>
        <dxi-column dataField="p_nick_name" [caption]="'Nick Name'" [visible]="false"></dxi-column>
        <dxi-column dataField="p_nick_last_name" [caption]="'Nick Name (Last)'" [visible]="false"></dxi-column>

        <dxi-column
          dataField="dietary_consideration"
          [caption]="'Dietary or Personal Considerations?'"
          [visible]="false"
        ></dxi-column>
        <dxi-column dataField="dietary_consideration_type" [caption]="'Considerations Type'" [visible]="false">
          <dxo-lookup
            [dataSource]="dietaryConsiderationTypesLookup$ | async"
            [valueExpr]="'dbId'"
            [displayExpr]="'description'"
            [allowClearing]="false"
          >
          </dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="dietary_or_personal_considerations"
          [caption]="'Personal Considerations'"
          [visible]="false"
        ></dxi-column>

        <dxi-column dataField="p_tshirt_size" [caption]="'T Shirt Size'" [visible]="false"> </dxi-column>
        <dxi-column dataField="p_headshot_link" [caption]="'Headshot URL'" [visible]="false"></dxi-column>
        <dxi-column
          dataField="p_dob"
          [caption]="'Date of Birth'"
          [visible]="false"
          [editorOptions]="{ mask: '00/00/0000' }"
        ></dxi-column>
        <dxi-column dataField="gender" [caption]="'Gender'" [visible]="false"> </dxi-column>
      </dx-data-grid>
    </div>
  </div>
  <div class="row d-flex mt-5 justify-content-around" style="margin: 0 auto">
    <div class="col-lg-6 text-center">
      <dx-button width="100" stylingMode="contained" text="Save" type="default" (onClick)="selectAgents()"></dx-button>
    </div>
    <div class="col-lg-6 text-center">
      <dx-button
        width="100"
        stylingMode="contained"
        text="Cancel"
        type="default"
        (onClick)="closeClicked()"
      ></dx-button>
    </div>
  </div>
</div>
