import { Pipe, PipeTransform } from '@angular/core';
import { eachDayOfInterval, isValid, toDate } from 'date-fns';

@Pipe({ name: 'lockedDates' })
export class LockedDatesPipe implements PipeTransform {
  transform(start: Date, end: Date): Date[] {
    if (!isValid(toDate(start)) || !isValid(toDate(end))) {
      return [];
    }

    return eachDayOfInterval({
      start,
      end,
    });
  }
}
