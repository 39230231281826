export const BROADCAST_CHANEL = 'IDLE_TIME_SERVICE_CHANEL' as const;

export const DEFAULT_IDLE_TIME_DELAY = 300;

export const idleTimeDelaysLookup = [DEFAULT_IDLE_TIME_DELAY, DEFAULT_IDLE_TIME_DELAY * 2, DEFAULT_IDLE_TIME_DELAY * 3];

export enum IdleTimeServiceActions {
  logOut = 'LOG_OUT',
  pauseTimer = 'PAUSE_TIMER',
  resetTimer = 'RESET_TIMER',
  staySighIn = 'STAY_SIGH_IN',
}

export class IdleTimeServiceMessage {
  action: IdleTimeServiceActions;
  value: any;

  constructor(action: IdleTimeServiceActions, value?: any) {
    this.action = action;
    this.value = value;
  }
}
