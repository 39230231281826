<div
  class="attendee-details-header__container"
  *var="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
>
  <ag-shr-profile-picture
    [headshot]="formData?.[RegistrantKeys.headshot]"
    [showDropDownMenu]="false"
    [isRound]="false"
    [mediaPathPrefix]="mediaPathPrefix"
    [mediaItems]="agentMediaDataSource$ | async"
    [onProfileImageChanged]="onMediaChanged"
    [onImageCropped]="onImageCropped"
  ></ag-shr-profile-picture>

  <div class="attendee-details-header__registrant-info-container">
    <div class="attendee-details-header__data-column">
      <div class="attendee-details-header__data-container">
        <b class="attendee-details-header__title">
          {{
            [
              formData?.[RegistrantKeys.prefix] | prefix | async,
              formData?.[RegistrantKeys.firstName],
              formData?.[RegistrantKeys.middleName],
              formData?.[RegistrantKeys.lastName],
              formData?.[RegistrantKeys.suffix] | suffix | async,
            ] | fullName
          }}
        </b>

        <dx-button
          [visible]="(mayBeRefreshFromAgent$ | async) && canWrite"
          class="inline-editor-control attendee-details-header__unlock-button"
          icon="refresh"
          type="default"
          stylingMode="text"
          hint="The agent's information has been changed, click to update the data."
          (onClick)="updateAttendee()"
        ></dx-button>
      </div>
      <div *ngIf="formData?.[RegistrantKeys.mgaId] as mgaId" class="attendee-details-header__data-container">
        <b class="attendee-details-header__title">
          <span>{{ mgaId | agency | async }}</span>
          <span *ngIf="mgaId !== formData?.[RegistrantKeys.agencyId]">
            / {{ formData?.[RegistrantKeys.agencyId] | agency | async }}
          </span>
        </b>
      </div>
      <div class="attendee-details-header__data-container">
        <b class="attendee-details-header__title">
          {{ formData?.[RegistrantKeys.registrationType] | registrationType }}
        </b>
      </div>
    </div>

    <div
      class="attendee-details-header__data-column"
      *var="formData?.[RegistrantKeys.hotelReservation] as hotelReservation"
    >
      <div class="attendee-details-header__data-container">
        <b>Travel Dates:</b>

        <div class="attendee-details-header__travel-ranges-container">
          <div *ngFor="let item of travelDatesSummary$ | async" class="attendee-details-header__travel-range">
            <span title="{{ item?.title }}">
              {{ item?.interval | empty }}
            </span>
          </div>
        </div>

        <ng-container>
          <div *ngIf="hasNotResolvedFlightInfoSectionRequests$ | async">
            <i #travelDatesPopoverTarget class="dx-icon dx-icon-warning attendee-details-header__warning-icon"></i>

            <dx-popover
              [target]="travelDatesPopoverTarget"
              [showEvent]="'mouseenter'"
              [hideEvent]="'mouseleave'"
              position="right"
              [width]="'auto'"
              [height]="'auto'"
              [showTitle]="false"
              [wrapperAttr]="{ class: 'clipboard-button__popover-wrapper' }"
            >
              <div *dxTemplate="let data = model of 'content'">
                <div class="attendee-details-header__data-container">
                  Requested the Travel dates that differ from selected hotel check-in and check-out dates.
                </div>
              </div>
            </dx-popover>
          </div>
        </ng-container>
      </div>
      <div
        class="attendee-details-header__data-container"
        *var="formData?.[RegistrantKeys.hotelReservation] as hotelReservation"
      >
        <b>Booking Dates:</b>

        <span>
          {{
            hotelReservation?.[HotelReservationKeys.checkInDate]
              | datesRange: hotelReservation?.[HotelReservationKeys.checkOutDate]
              | empty
          }}
        </span>

        <ng-container>
          <div *ngIf="hasNotResolvedHotelReservationSectionRequests$ | async">
            <i #hotelBookingPopoverTarget class="dx-icon dx-icon-warning attendee-details-header__warning-icon"></i>
            <dx-popover
              [target]="hotelBookingPopoverTarget"
              [showEvent]="'mouseenter'"
              [hideEvent]="'mouseleave'"
              position="right"
              [width]="'auto'"
              [height]="'auto'"
              [showTitle]="false"
              [wrapperAttr]="{ class: 'clipboard-button__popover-wrapper' }"
            >
              <div *dxTemplate="let data = model of 'content'">
                <div class="attendee-details-header__data-container">
                  <b>Requested Check-in / Check-out Dates:</b>

                  <span>
                    {{
                      hotelReservation?.[HotelReservationKeys.requestedCheckInDate]
                        | datesRange: hotelReservation?.[HotelReservationKeys.requestedCheckOutDate]
                    }}
                  </span>
                </div>
              </div>
            </dx-popover>
          </div>
        </ng-container>
      </div>

      <div class="attendee-details-header__data-container">
        <b>Primary Email:</b>

        <span>{{ formData?.[RegistrantKeys.primaryEmailAddress]?.[EmailAddressKeys.address] | empty }}</span>
      </div>

      <div class="attendee-details-header__data-container">
        <b>CC Email:</b>

        <span>{{ formData?.[RegistrantKeys.ccEmail] | empty }}</span>
      </div>

      <div class="attendee-details-header__data-container">
        <b>Phone:</b>

        <span>{{ formData?.[RegistrantKeys.mobilePhone] | phoneNumberMask: true | empty }}</span>
      </div>

      <div
        class="attendee-details-header__data-container"
        *var="currentStepInProgress$ | async as currentStepInProgress"
      >
        <b>Wizard State:</b>

        <span>{{ currentStepInProgress?.title | empty }}</span>

        <dx-button
          *ngIf="
            currentStepInProgress?.name === ConferenceRegistrationStepName.registrationSummaryStep;
            else lockBtnTmp
          "
          [visible]="canWrite"
          class="inline-editor-control attendee-details-header__unlock-button"
          icon="undo"
          type="danger"
          stylingMode="text"
          hint="Unlock Wizard"
          (onClick)="unlockWizard()"
        ></dx-button>

        <ng-template #lockBtnTmp>
          <dx-button
            class="inline-editor-control attendee-details-header__unlock-button"
            icon="lock"
            type="success"
            stylingMode="text"
            hint="Lock Wizard"
            [visible]="canWrite"
            (onClick)="lockWizard()"
          ></dx-button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
