import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeesListModalComponent } from './attendees-list-modal.component';
import { ModalWindowModule } from 'ag-common-svc/public-api';
import {
  DxDateRangeBoxModule,
  DxDropDownButtonModule,
  DxLoadPanelModule,
  DxPopoverModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeDetailsModalModule } from '../attendee-details-modal/attendee-details-modal.module';
import { AddAttendeesModalModule } from './add-attendees-modal/add-attendees-modal.module';
import { NotificationSchedulerEditModalModule } from 'ag-common-svc/lib/components/administration/notifications-hub/notifications-scheduler/notification-scheduler-edit-modal/notification-scheduler-edit-modal.module';
import { ConferencesPipesModule } from '../../pipes/conferences-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    DxDropDownButtonModule,
    AttendeeDetailsModalModule,
    AddAttendeesModalModule,
    DxPopoverModule,
    DxDateRangeBoxModule,
    DxValidatorModule,
    DxLoadPanelModule,
    NotificationSchedulerEditModalModule,
    ConferencesPipesModule,
  ],
  declarations: [AttendeesListModalComponent],
  exports: [AttendeesListModalComponent],
})
export class AttendeesListModalModule {}
