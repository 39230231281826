import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import CustomStore from 'devextreme/data/custom_store';
import { Agent, Agency } from 'ag-common-lib/public-api';
import { AgencyService } from 'ag-common-svc/public-api';
import { ReportSummaryService } from 'src/app/shared/services/reports.service';
import { SessionControllerService } from 'src/app/shared/services/util/session-controller.service';

@Component({
  selector: 'app-summary-selection',
  templateUrl: './summary-selection.component.html',
  styleUrls: ['./summary-selection.component.scss'],
})
export class SummarySelectionComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAgentsSelected: EventEmitter<Agent[]> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @Input() selectedAgentKeys: string[] = [];

  selectedAgents: Agent[] = [];

  dataSource = {};

  agencies: Agency[] = [];

  constructor(
    public router: Router,
    public session: SessionControllerService,
    public reportSummaryService: ReportSummaryService,
    public agencyService: AgencyService,
  ) {
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: function (loadOptions: any) {
        return reportSummaryService.getReportSummaryByYear(new Date().getFullYear() - 1, 'name');
      },
    });
  }

  ngOnInit(): void {
    this.agencyService.getAll().then(agency => {
      agency = agency.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

      this.agencies = agency;
    });
  }

  showContactInfo(e) {
    this.session.selectAgent(e.data);
  }

  selectAgents() {
    this.onAgentsSelected.emit(this.selectedAgents);
    this.closeClicked();
  }

  closeClicked() {
    this.onClose.emit(true);
  }

  onSelectionChanged(e) {
    this.selectedAgents = e.selectedRowsData;
  }
}
