import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog, normalizeLog } from '../../utils/normalize-log';
import { EMAIL_ADDRESS_KEYS_LOOKUP, EmailAddressKeys, Lookups, TRUE_FALSE_LOOKUP } from 'ag-common-lib/public-api';
import { of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AttendeeChangeLogService } from '../../attendee-change-log.service';

@UntilDestroy()
@Component({
  selector: 'ag-crm-change-log-email-detail',
  templateUrl: './change-log-email.component.html',
  styleUrls: ['./change-log-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogEmailComponent {
  @Input() log: Delta;

  protected readonly emailAddressKeys = [
    EmailAddressKeys.address,
    EmailAddressKeys.emailType,
    EmailAddressKeys.isLogin,
  ];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly emailAddressLookup = EMAIL_ADDRESS_KEYS_LOOKUP;

  constructor(private attendeeChangeLogService: AttendeeChangeLogService) {}

  getLookup(key: EmailAddressKeys) {
    switch (key) {
      case EmailAddressKeys.isLogin:
        return of(TRUE_FALSE_LOOKUP);

      case EmailAddressKeys.emailType:
        return this.attendeeChangeLogService.getLookupByName(Lookups.EmailTypes);

      default:
        return null;
    }
  }
}
