import { Pipe, PipeTransform } from '@angular/core';
import {
  RequestOutcome,
  RequestOutcomeKeys,
  RequestOutcomeState,
} from 'ag-common-lib/lib/models/utils/request-outcome.model';

@Pipe({
  name: 'requestOutcomeResult',
})
export class RequestOutcomeResultPipe implements PipeTransform {
  transform(requestOutcome: RequestOutcome): string {
    switch (requestOutcome[RequestOutcomeKeys.state]) {
      case RequestOutcomeState.approved:
        return 'Request has been approved';
      case RequestOutcomeState.rejected:
        return 'Request has been rejected';

      default:
        return '';
    }
  }
}
