import { Pipe, PipeTransform } from '@angular/core';
import { AgentCampaignChanges, AgentCampaignStepName, AGMediaKeys, ImageCropperKeys } from 'ag-common-lib/public-api';

@Pipe({ name: 'campaignsDetailsModalGetAllImagesIds' })
export class CampaignsDetailsModalGetAllImagesIdsPipe implements PipeTransform {
  transform(changes: AgentCampaignChanges): string[] {
    const incomingStepData = changes[AgentCampaignStepName.logoHeadshot]?.incomingStepData;
    if (!incomingStepData) {
      return [];
    }

    return [
      incomingStepData?.favicon,
      incomingStepData?.logo,
      incomingStepData?.socialSharingImage,
      incomingStepData?.headshot,
    ]
      .filter(item => !!item?.[AGMediaKeys.wasabiPath])
      .map(item => {
        const cropperPayload = item?.[ImageCropperKeys.imageCropperPayload];

        const queryParams = new URLSearchParams();

        const relativeX1 = cropperPayload?.relativeCropperPosition?.relativeX1;
        const relativeX2 = cropperPayload?.relativeCropperPosition?.relativeX2;
        const relativeY1 = cropperPayload?.relativeCropperPosition?.relativeY1;
        const relativeY2 = cropperPayload?.relativeCropperPosition?.relativeY2;

        if ([relativeX1, relativeX2, relativeY1, relativeY2].every(Boolean)) {
          queryParams.append('cropperRelativeX1', relativeX1);
          queryParams.append('cropperRelativeX2', relativeX2);
          queryParams.append('cropperRelativeY1', relativeY1);
          queryParams.append('cropperRelativeY2', relativeY2);
        }

        let url = item?.[AGMediaKeys.wasabiPath];

       return [url, queryParams.toString()].join('?');
      });
  }
}
