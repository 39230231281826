import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationNotificationChannelModalComponent } from './notification-notification-channel-modal.component';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { DxValidatorModule } from 'devextreme-angular';
import { NotificationChannelSelectModule } from '../notification-channel-select/notification-channel-select.module';

@NgModule({
  declarations: [NotificationNotificationChannelModalComponent],
  imports: [CommonModule, SharedModule, ModalWindowModule, DxValidatorModule, NotificationChannelSelectModule],
  exports: [NotificationNotificationChannelModalComponent],
})
export class NotificationNotificationChannelModalModule {}
