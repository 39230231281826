<div class="row w-100 m-0 p-0 justify-content-center" style="width: 100vw; position: fixed; height: 100vh">
  <div
    class="col-3 m-0 p-0 d-flex justify-content-start"
    style="height: 85%; overflow-y: scroll; border-right: 1px solid darkgray"
  >
    <app-distribution-list-info style="width: 100%"></app-distribution-list-info>
  </div>
  <div class="col-6 m-0 p-0 d-flex justify-content-center" style="height: 85%; overflow-y: scroll">
    <app-distribution-list-activity-info style="width: 100%"></app-distribution-list-activity-info>
  </div>
  <div
    class="col-3 m-0 p-0 d-flex justify-content-end"
    style="height: 85%; overflow-y: scroll; border-left: 1px solid darkgray"
  >
    <app-distribution-list-detail-info style="width: 100%"></app-distribution-list-detail-info>
  </div>
</div>
