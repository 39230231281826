<ag-shr-modal-window
  #campaignsIssuesModalRef
  [width]="450"
  [height]="'auto'"
  title="Report Issue"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleCampaignsIssuesSaveClick()"
  [onCloseModal]="handleClosePopup"
>
  <dx-form
    #campaignsIssuesFormRef
    [formData]="campaignsIssuesFormData"
    labelMode="floating"
  >

    <dxi-item
      [dataField]="CampaignsManagementTaskIssuesKeys.issueType"
      editorType="dxSelectBox"
      [label]="{ text: 'Issue' }"
      [editorOptions]="{
        dataSource: CampaignsManagementTaskIssuesTypeLookup,
        valueExpr: LookupKeys.value,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="CampaignsManagementTaskIssuesKeys.issueField"
      editorType="dxSelectBox"
      [label]="{ text: 'Field' }"
      [editorOptions]="{
        dataSource: CampaignsManagementTaskIssuesFieldLookup,
        valueExpr: LookupKeys.value,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: ''
      }"
    >
      <dxi-validation-rule
        type="required"
        [message]="Messages.REQUIRED_TEXT"
      ></dxi-validation-rule>
    </dxi-item>

    <dxi-item
      [dataField]="CampaignsManagementTaskIssuesKeys.issueStatus"
      editorType="dxSelectBox"
      [label]="{ text: 'Status' }"
      [editorOptions]="{
        dataSource: CampaignsManagementTaskIssuesLookup,
        displayExpr: LookupKeys.description,
        valueExpr: LookupKeys.value,
        searchEnabled: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="CampaignsManagementTaskIssuesKeys.description"
      [label]="{ text: 'Issue Description' }"
      [editorType]="'dxTextArea'"
      [editorOptions]="{ height: 150 }"
    >
      <dxi-validation-rule
        type="required"
        [message]="Messages.REQUIRED_TEXT"
      ></dxi-validation-rule>
    </dxi-item>
  </dx-form>
</ag-shr-modal-window>
