import { Component, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { CampaignsManagementTasksStatus } from 'ag-common-lib/lib/lists/campaign-management-tasks-status.enum';
import { BehaviorSubject, Observable, take } from 'rxjs';
import {
  CampaignsManagementTasks,
  CampaignsManagementTasksKeys,
} from 'ag-common-lib/lib/models/utils/campaign-management-tasks.model';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { CampaignsManagementTaskIssues } from 'ag-common-lib/lib/models/utils/campaign-management-task-issues.model';
import { CampaignsIssuesGridComponent } from '../campaigns-issues-grid/campaigns-issues-grid.component';
import { map } from 'rxjs/operators';
import { CampaignsAddIssuesModalService } from '../campaigns-add-issues-modal/campaigns-add-issues-modal.service';
import { CampaignsManagementTaskBoardService } from '../campaigns-management-task-board.service';
import { CampaignsAddIssuesModalComponent } from '../campaigns-add-issues-modal/campaigns-add-issues-modal.component';
import { AgAssignOwnerViewerComponent, AgentCampaignsManagementTasksService } from 'ag-common-svc/public-api';
import { Entity } from "ag-common-lib/lib/lists/entity-permission.enum";

@Component({
  selector: 'ag-crm-campaigns-task-issues-modal',
  templateUrl: './campaigns-task-issues-modal.component.html',
  styleUrls: ['./campaigns-task-issues-modal.component.scss'],
  providers: [CampaignsAddIssuesModalService, CampaignsManagementTaskBoardService],
})
export class CampaignsTaskIssuesModalComponent {
  @ViewChild('campaignsIssuesModalRef', { static: true }) campaignsIssuesModalComponent: ModalWindowComponent;
  @ViewChild('campaignIssuesGridRef', { static: true }) campaignIssueGridComponent: CampaignsIssuesGridComponent;
  @ViewChild('campaignIssuesModalRef', { static: true }) campaignIssuesModalComponent: CampaignsAddIssuesModalComponent;
  @ViewChild('assignOwnerViewerRef', { static: false }) assignOwnerViewerComponent: AgAssignOwnerViewerComponent;

  inProgress$ = new BehaviorSubject<boolean>(false); // todo:
  selectedTask: CampaignsManagementTasks;
  taskIssues$ = new BehaviorSubject<CampaignsManagementTaskIssues[]>([]);

  protected readonly CampaignsManagementTasksStatus = CampaignsManagementTasksStatus;

  constructor(
    private campaignsIssuesModalService: CampaignsAddIssuesModalService,
    private agentCampaignsManagementTasksService: AgentCampaignsManagementTasksService,
    private campaignsManagementTaskBoardService: CampaignsManagementTaskBoardService,
  ) {}

  showModal = (selectedTask: CampaignsManagementTasks): void => {
    this.selectedTask = selectedTask;
    this.refreshIssues();
    this.campaignsIssuesModalComponent.showModal();
  };

  hideModal = (): void => {
    this.campaignsIssuesModalComponent.hideModal();
  };

  moveTaskConfirmation = (): void => {
    this.inProgress$.next(true);
    const agentCampaignDbId = this.selectedTask[CampaignsManagementTasksKeys.agentCampaignDbId];
    const taskId = this.selectedTask[BaseModelKeys.dbId];

    this.agentCampaignsManagementTasksService
      .update(agentCampaignDbId, taskId, {
        [CampaignsManagementTasksKeys.taskStatus]: this.selectedTask[CampaignsManagementTasksKeys.taskStatus],
      })
      .then(() => {
        this.hideModal();
      })
      .finally(() => {
        this.inProgress$.next(false);
      });
  };

  showAssignOwnerPopup = (): void => {
    // this.assignOwnerModalComponent.showModal(this.selectedTask);
    const assignToData = {
      assignedTo: this.selectedTask[CampaignsManagementTasksKeys.assignedTo],
      assignedToNote: this.selectedTask[CampaignsManagementTasksKeys.note],
    };
    this.assignOwnerViewerComponent.showAssignOwnerModalClick(assignToData);
  };

  showEditIssuePopup = (issue: CampaignsManagementTaskIssues): void => {
    this.campaignIssuesModalComponent?.showModal(this.selectedTask, issue);
  };

  refreshIssues(): void {
    this.getIssue$(this.selectedTask)
      .pipe(
        map(issues => {
          this.taskIssues$.next(issues ?? []);
          this.campaignIssueGridComponent?.refreshGrid();
        }),
      )
      .subscribe();
  }

  changeOwner = owner => {
    const agentDbId = this.selectedTask?.[CampaignsManagementTasksKeys.agentDbId];
    const selectedTaskDbId = this.selectedTask?.[BaseModelKeys.dbId];
    const update = {
      [CampaignsManagementTasksKeys.assignedAt]: new Date(),
      [CampaignsManagementTasksKeys.assignedTo]: owner?.assignedTo ?? '',
      [CampaignsManagementTasksKeys.note]: owner?.assignedToNote ?? '',
    };
    return this.campaignsManagementTaskBoardService.assignOwner(agentDbId, selectedTaskDbId, update).then(() => {
      this.selectedTask = { ...this.selectedTask, ...update };
    });
  };

  private getIssue$(task: CampaignsManagementTasks): Observable<CampaignsManagementTaskIssues[]> {
    const taskId = task[BaseModelKeys.dbId];
    return this.campaignsIssuesModalService.getIssuesById$(taskId).pipe(
      map(items => items.map(({ data }) => data)),
      take(1),
    );
  }

  protected readonly Entity = Entity;
}
