<dx-data-grid
  [dataSource]="selectedExcursionsDataSource$ | async"
  [showRowLines]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [height]="'100%'"
  [noDataText]="emptyMessage"
  (onExporting)="onExporting($event)"
  [columnHidingEnabled]="true"
>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

  <dxo-toolbar>
    <dxi-item location="before">
      <span *dxTemplate class="dx-form-group-caption">Seats Remaining</span>
    </dxi-item>

    <dxi-item name="exportButton" location="after"></dxi-item>

    <dxi-item name="columnChooserButton" location="after"></dxi-item>
  </dxo-toolbar>

  <dxi-column
    [dataField]="'activity'"
    [caption]="'Activity'"
    width="auto"
    [visible]="false"
    [showInColumnChooser]="false"
  ></dxi-column>

  <dxi-column
    [dataField]="AttendeeKeys.firstName"
    [caption]="'First Name'"
    width="auto"
    [visible]="false"
    [showInColumnChooser]="false"
  ></dxi-column>

  <dxi-column
    [dataField]="AttendeeKeys.lastName"
    [caption]="'Last Name'"
    width="auto"
    [visible]="false"
    [showInColumnChooser]="false"
  ></dxi-column>

  <dxi-column [dataField]="ExcursionInfoTableDataKeys.fullName" [caption]="'Full Name'" width="auto"></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.attendeeDob"
    [caption]="'Age'"
    [calculateCellValue]="calculateAge"
    [filterOperations]="['<', '<=', '>', '>=', 'between']"
    [selectedFilterOperation]="'<'"
    [width]="100"
  ></dxi-column>

  <dxi-column [dataField]="ExcursionInfoTableDataKeys.attendeeType" [width]="150" [caption]="'Attendee Type'">
    <dxo-lookup [dataSource]="attendeeTypeLookup" displayExpr="description" valueExpr="value"></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.inviteeEmail"
    [caption]="'Invitee Email'"
    width="auto"
  ></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.phoneNumber"
    [caption]="'Phone Number'"
    [editorOptions]="{ mask: phoneMask }"
    cellTemplate="phoneNumberCellTemplate"
    [visible]="false"
  ></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.excursionDate"
    [caption]="'Activity Date'"
    [dataType]="'date'"
    [format]="dateFormat"
    [groupIndex]="0"
    [sortIndex]="0"
    [sortOrder]="'asc'"
    groupCellTemplate="groupDateCellTemplate"
  >
  </dxi-column>

  <dxi-column [dataField]="ExcursionInfoTableDataKeys.excursionId" [visible]="false"></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.excursionName"
    caption="Activity"
    [alignment]="'left'"
    [groupIndex]="1"
    [minWidth]="180"
    width="auto"
    groupCellTemplate="groupExcursionNameCellTemplate"
  ></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.selectedPreferences"
    [calculateCellValue]="calculateSelectedPreferencesCellValue"
    [caption]="'Preferences'"
    width="auto"
    [minWidth]="250"
    cellTemplate="selectedExcursionDetailsCellTemplate"
    [setCellValue]="setExcursionPreferenceCellValue"
    [allowFiltering]="false"
    [allowSorting]="false"
  >
  </dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.configurations"
    [caption]="'Excursion Details'"
    width="auto"
    [minWidth]="250"
    [allowFiltering]="false"
    [allowSorting]="false"
    [calculateCellValue]="calculateExcursionConfigurationCellValue"
    cellTemplate="selectedExcursionDetailsCellTemplate"
    [setCellValue]="setExcursionDetailsCellValue"
  ></dxi-column>

  <dxi-column [dataField]="ExcursionInfoTableDataKeys.isPaid" [caption]="'Is Paid'" [width]="100">
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [displayExpr]="'description'"
      [valueExpr]="'value'"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="ExcursionInfoTableDataKeys.isAdminSelected" [caption]="'Is Admin Selected'" width="auto">
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [displayExpr]="'description'"
      [valueExpr]="'value'"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.bookingDate"
    [caption]="'Booking Date'"
    dataType="datetime"
    width="auto"
  >
  </dxi-column>

  <dxo-summary [calculateCustomSummary]="calculateSeatRemainingSummary">
    <dxi-group-item
      [showInColumn]="ExcursionInfoTableDataKeys.excursionName"
      name="excursionSeatsRemaining"
      summaryType="custom"
      displayFormat="Seats Remaining: {0}"
      [showInGroupFooter]="false"
    >
    </dxi-group-item>

    <dxi-group-item
      [showInColumn]="ExcursionInfoTableDataKeys.excursionName"
      [showInGroupFooter]="false"
      name="excursionCapacity"
      summaryType="custom"
      displayFormat="Capacity: {0}"
    >
    </dxi-group-item>

    <dxi-group-item [column]="ExcursionInfoTableDataKeys.excursionName" summaryType="count" displayFormat="{0}">
    </dxi-group-item>
  </dxo-summary>

  <!--  Templates-->
  <div *dxTemplate="let cell of 'groupDateCellTemplate'">
    <div>{{ cell.value | date : fullDateFormat }}</div>
  </div>

  <div *dxTemplate="let cell of 'groupExcursionNameCellTemplate'">
    <div *var="cell.data as data">
      <ng-container *ngIf="data.key !== AT_LEISURE_ITEM_TEXT; else activityTmp">
        Activity: {{ cell.value }} ( Seats Remaining: {{ data.aggregates[0] }}, Capacity: {{ data.aggregates[1] }} )
      </ng-container>

      <ng-template #activityTmp> Activity: {{ cell.value }} ({{ data.aggregates[2] }})</ng-template>
    </div>
  </div>

  <div *dxTemplate="let data of 'selectedExcursionDetailsCellTemplate'">
    <div *ngFor="let item of data.value">
      <span>{{ item?.title }}</span>
    </div>
  </div>

  <div *dxTemplate="let cell of 'phoneNumberCellTemplate'">
    <div>{{ [cell.value] | primaryPhoneNumber | phoneNumberMask }}</div>
  </div>
</dx-data-grid>
