import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmailTemplate } from 'ag-common-lib/public-api';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<EmailTemplate> = new EventEmitter();

  @Input() public emailTemplate: EmailTemplate;

  @Input() public showButtons: boolean = true;

  emailVals: string[] = ['Recipient First Name', 'Recipient Last Name', 'Sender First Name', 'Sender Last Name'];

  save() {
    this.onClose.next(this.emailTemplate);
  }

  close() {
    this.onClose.next(null);
  }
}
