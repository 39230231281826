import { Component, HostBinding, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BaseModelKeys, AgentKeys } from 'ag-common-lib/public-api';
import { Observable } from 'rxjs';
import { DocumentWriteLogElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services/documet-write-log-elastic-search.service';

@Component({
  selector: 'ag-crm-logs-manager',
  templateUrl: './logs-manager.component.html',
  styleUrls: ['./logs-manager.component.scss'],
})
export class LogsManagerComponent {
  @HostBinding('class') className = 'logs-manager';
  @ViewChild('logGrid') logGrid: DxDataGridComponent;

  protected dataSource: CustomStore;

  protected agentsDataSource$: Observable<any>;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AgentKeys = AgentKeys;

  constructor(protected documentWriteLogElasticSearchService: DocumentWriteLogElasticSearchService) {
    this.dataSource = new CustomStore({
      key: 'wasabiPath',
      load: loadOptions => {
        return documentWriteLogElasticSearchService.getFromElastic(loadOptions);
      },
    });
  }
}
