import { Component, OnInit } from '@angular/core';
import { AppInfoService } from '../../../shared/services/util/app-info.service';

@Component({
  selector: 'ag-crm-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent implements OnInit {
  constructor(public appInfo: AppInfoService) {}

  ngOnInit(): void {}
}
