<div class="container">
  <div class="row justify-content-between" style="padding: 20px 40px">
    <div class="col-12 align-self-center text-start">
      <h4>Distribution Lists</h4>
    </div>
  </div>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      [dataSource]="dataSource"
      [showBorders]="false"
      [focusedRowEnabled]="true"
      [focusedRowIndex]="0"
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [scrollLeft]="true"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      [height]="550"
      noDataText="Currently, no Distribution Lists exist. Hit the '+' button above to create one! "
      (onRowDblClick)="viewDistributionList($event)"
    >
      <dxo-paging [pageSize]="50"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-sorting mode="multiple"></dxo-sorting>

      >
      <dxo-toolbar>
        <dxi-item
          location="after"
          widget="dxButton"
          [options]="{ icon: 'plus', onClick: addDistributionList }"
        ></dxi-item>

        <dxi-item name="searchPanel"></dxi-item>
      </dxo-toolbar>

      <dxo-editing
        [allowDeleting]="Entity.emailDistributionLists | hasPermission : EntityPermissionActivityKeys.write | async"
        [useIcons]="true"
      ></dxo-editing>

      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxi-column dataField="name"></dxi-column>
      <dxi-column dataField="owner"></dxi-column>
      <dxi-column dataField="description"></dxi-column>
      <dxi-column dataField="date_created" [calculateCellValue]="getDate"></dxi-column>
    </dx-data-grid>
  </div>
</div>
