import { Injectable } from '@angular/core';
import {
  AttendeeKeys,
  AttendeeType,
  BaseModelKeys,
  Conference,
  ConferenceKeys,
  ConferenceRegistrationStepName,
  Excursion,
  InviteeStatus,
  RegistrantKeys,
  RegistrantModelKeys,
  WizardStepState,
} from 'ag-common-lib/public-api';
import {
  ExcursionInfoTableData,
  ExcursionInfoTableDataKeys,
} from 'ag-common-svc/lib/components/excursions-info-table/excursions-info-table.models';
import { convertSelectedExcursionsToList, getExcursionsMap } from 'ag-common-svc/lib/utils/excursions-data.utils';
import { ConferenceService } from 'ag-common-svc/public-api';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay } from 'rxjs';
import { AttendeesListService } from 'src/app/ag-crm/registrations/attendees-list.service';
import { differenceInYears, isValid, toDate } from 'date-fns';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';

@Injectable()
export class ConferenceExcursionStatisticService {
  selectedExcursionsDataSource$: Observable<ExcursionInfoTableData[]>;

  private _excursions$ = new BehaviorSubject<Excursion[]>([]);

  private _notSelectedExcursionsDataSource$ = new BehaviorSubject<ExcursionInfoTableData[]>([]);
  notSelectedExcursionsDataSource$ = this._notSelectedExcursionsDataSource$.asObservable();

  constructor(private conferenceService: ConferenceService, private attendeesListService: AttendeesListService) {
    this.selectedExcursionsDataSource$ = combineLatest({
      excursions: this._excursions$,
      attendees: this.attendeesListService.attendees$.pipe(
        map(attendees =>
          attendees?.filter(
            attendee =>
              attendee?.[AttendeeKeys.inviteeStatus] !== InviteeStatus.declined &&
              attendee?.[AttendeeKeys.inviteeOutcomeStatus] !== InviteeStatus.cancelled,
          ),
        ),
        shareReplay(1),
      ),
    }).pipe(
      map(({ excursions, attendees }) => {
        const attendeeExcursionsList: ExcursionInfoTableData[] = [];
        const { excursionsMap } = getExcursionsMap(excursions);
        this.createNotSelectedExcursionData(attendees);

        attendees.forEach(attendee => {
          const attendeeSelectedExcursions =
            attendee[AttendeeKeys.attendeeType] === AttendeeType.Invitee
              ? attendee?.registrant?.[RegistrantModelKeys.data]?.[RegistrantKeys.selectedExcursions]
              : attendee?.guest?.[RegistrantKeys.selectedExcursions];
          const attendeeData = {
            [ExcursionInfoTableDataKeys.attendeeId]: attendee?.[BaseModelKeys.dbId],
            [ExcursionInfoTableDataKeys.fullName]: [attendee[AttendeeKeys.firstName], attendee[AttendeeKeys.lastName]]
              .filter(Boolean)
              .join(' '),
            [ExcursionInfoTableDataKeys.inviteeEmail]: attendee?.[AttendeeKeys.inviteeEmail],
            [ExcursionInfoTableDataKeys.phoneNumber]: attendee?.[AttendeeKeys.phoneNumber],
            [ExcursionInfoTableDataKeys.attendeeType]: attendee[AttendeeKeys.attendeeType],
            [ExcursionInfoTableDataKeys.attendeeDob]: attendee[AttendeeKeys.dob],
          };

          attendeeExcursionsList.push(
            ...convertSelectedExcursionsToList(excursionsMap, attendeeSelectedExcursions, attendeeData),
          );
        });

        if (!attendeeExcursionsList?.length) {
          return null;
        }

        return attendeeExcursionsList;
      }),
      shareReplay(1),
    );
  }

  setConference(conference: Conference): void {
    this._excursions$.next(conference[ConferenceKeys.excursions]);
    this.attendeesListService.setConferenceDbId(conference[BaseModelKeys.dbId]);
  }

  calculateAge = (e): number => {
    if (!e?.attendeeDob) {
      return null;
    }

    const attendeeDob = toDate(e.attendeeDob);
    if (!isValid(attendeeDob)) {
      console.log('Invalid Date');
      return null;
    }
    return differenceInYears(new Date(), attendeeDob);
  };

  createNotSelectedExcursionData(attendees: Attendee[]) {
    let notSelectedData = [];
    attendees.forEach(attendee => {
      const isInvitee = attendee[AttendeeKeys.attendeeType] === AttendeeType.Invitee;
      const attendeeHasSelectedExcursions = isInvitee
        ? !!attendee?.registrant?.[RegistrantModelKeys.data]?.[RegistrantKeys.selectedExcursions]
        : !!attendee?.guest?.[RegistrantKeys.selectedExcursions];

      if (attendeeHasSelectedExcursions) {
        return;
      }
      const wizardState = attendee?.registrant?.[RegistrantModelKeys.wizardState];
      const excursionState = isInvitee
        ? wizardState?.[ConferenceRegistrationStepName.registrantExcursionYourSelectionStep]
        : wizardState?.[ConferenceRegistrationStepName.registrantExcursionsStep];
      const attendeeData = {
        [ExcursionInfoTableDataKeys.attendeeId]: attendee?.[BaseModelKeys.dbId],
        [ExcursionInfoTableDataKeys.fullName]: [attendee[AttendeeKeys.firstName], attendee[AttendeeKeys.lastName]]
          .filter(Boolean)
          .join(' '),
        [ExcursionInfoTableDataKeys.inviteeEmail]: attendee?.[AttendeeKeys.inviteeEmail],
        [ExcursionInfoTableDataKeys.phoneNumber]: attendee?.[AttendeeKeys.phoneNumber],
        [ExcursionInfoTableDataKeys.attendeeType]: attendee[AttendeeKeys.attendeeType],
        [ExcursionInfoTableDataKeys.attendeeDob]: attendee[AttendeeKeys.dob],
        [ExcursionInfoTableDataKeys.excursionState]: excursionState ?? WizardStepState.initial, // TODO check
      };
      notSelectedData.push(attendeeData);
    });
    this._notSelectedExcursionsDataSource$.next(notSelectedData);
  }
}
