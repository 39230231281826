import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeNotesComponent } from './attendee-notes.component';
import { AttendeeNotesService } from './attendee-notes.service';
import { AgAssignOwnerViewerModule, ModalWindowModule, TransactionsHistoryModule } from 'ag-common-svc/lib/components';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import {
  DxiColumnModule,
  DxiItemModule,
  DxoEditingModule,
  DxoFormItemModule,
  DxoLabelModule,
  DxoLoadPanelModule,
  DxoLookupModule,
  DxoMasterDetailModule,
  DxoToolbarModule,
} from 'devextreme-angular/ui/nested';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { ConferenceRegistrationLogsService } from 'ag-common-svc/lib/services/conference-registration-logs.service';
import { ButtonWithIndicatorModule } from '../../../../../../shared/components/button-with-indicator/button-with-indicator.module';
import { AttendeeNoteModalModule } from '../attendee-note-modal/attendee-note-modal.module';

@NgModule({
  declarations: [AttendeeNotesComponent],
  imports: [
    CommonModule,
    TransactionsHistoryModule,
    AgAssignOwnerViewerModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxiColumnModule,
    DxiItemModule,
    DxoEditingModule,
    DxoFormItemModule,
    DxoLabelModule,
    DxoLoadPanelModule,
    DxoLookupModule,
    DxoMasterDetailModule,
    DxoToolbarModule,
    PipesModule,
    ButtonWithIndicatorModule,
    DxButtonModule,
    DxPopupModule,
    DxScrollViewModule,
    ModalWindowModule,
    DxFormModule,
    AttendeeNoteModalModule,
  ],
  exports: [AttendeeNotesComponent],
  providers: [AttendeeNotesService, ConferenceRegistrationLogsService],
})
export class AttendeeNotesModule {}
