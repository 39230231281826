<dx-button
  class="attendee-details-group-controls__control"
  icon="edit"
  stylingMode="text"
  [visible]="!isInEditState"
  (onClick)="handleStartEditing.emit()"
></dx-button>

<dx-button
  class="attendee-details-group-controls__control"
  icon="undo"
  type="danger"
  stylingMode="text"
  [visible]="isInEditState"
  (onClick)="handleCancelEditing.emit()"
></dx-button>

<dx-button
  class="attendee-details-group-controls__control"
  icon="save"
  type="success"
  stylingMode="text"
  [visible]="isInEditState"
  (onClick)="handleSaveChanges.emit()"
></dx-button>
