import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgentGridRoutingModule } from './agent-grid-routing.module';
import { AgentGridComponent } from './agent-grid.component';
import { ApproveDenyReasonsModule, DeleteAgentModalModule, ModalWindowModule } from 'ag-common-svc/public-api';
import { CreateAgentModalModule } from 'ag-common-svc/lib/components/create-agent-modal/create-agent-modal.module';

import { HighlightFilterSearchTermPipe } from './highlight-filter-search-term.pipe';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { AgentSalesGoalsModalModule } from 'ag-common-svc/lib/components/agent-sales-goals-modal/agent-sales-goals-modal.module';
import { DxDataGridModule, DxDropDownBoxModule, DxDropDownButtonModule, DxLoadPanelModule } from 'devextreme-angular';
import { CalculateAddressFieldDisplayValuePipe } from './calculate-address-field-display-value.pipe';
import { PrimaryBillingAddressPipe } from 'ag-common-svc/shared/pipes/primary-billing-address.pipe';
import { PrimaryShippingAddressPipe } from 'ag-common-svc/shared/pipes/primary-shipping-address.pipe';
import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    DxLoadPanelModule,
    ApproveDenyReasonsModule,
    DeleteAgentModalModule,
    ModalWindowModule,
    AgentSalesGoalsModalModule,
    CreateAgentModalModule,
    AgentGridRoutingModule,
    DxDropDownButtonModule,
    DxDropDownBoxModule,
    DxDataGridModule,
    SharedModule,
  ],
  providers: [AgentElasticSearchService, PrimaryBillingAddressPipe, PrimaryShippingAddressPipe],
  declarations: [AgentGridComponent, HighlightFilterSearchTermPipe, CalculateAddressFieldDisplayValuePipe],
    exports: [
        HighlightFilterSearchTermPipe
    ],
})
export class AgentsGridModule {}
