<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <dx-form [(formData)]="conference" labelMode="floating" [validationGroup]="validationGroup" [readOnly]="isReadonlyMode">
    <dxi-item itemType="group" caption="Hotel Contact" [colCount]="2">
      <dxi-item
        [dataField]="ConferenceKeys.hotelCoordinatorFirstName"
        [label]="{ text: titleList[ConferenceKeys.hotelCoordinatorFirstName] }"
      ></dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.hotelCoordinatorLastName"
        [label]="{ text: titleList[ConferenceKeys.hotelCoordinatorLastName] }"
      ></dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.hotelPrimaryPhone"
        [editorOptions]="phoneNumberOptions"
        [label]="{ text: titleList[ConferenceKeys.hotelPrimaryPhone] }"
      >
        <dxi-validation-rule
          type="stringLength"
          [min]="totalPhoneDigits"
          [max]="totalPhoneDigits"
          [ignoreEmptyValue]="true"
          [trim]="true"
          [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
        >
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.hotelCoordinatorEmail"
        [label]="{ text: titleList[ConferenceKeys.hotelCoordinatorEmail] }"
      >
        <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Information" [colCount]="2">
      <dxi-item
        [dataField]="ConferenceKeys.hotelName"
        [label]="{ text: titleList[ConferenceKeys.hotelName] }"
      ></dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.hotelState"
        [label]="{ text: titleList[ConferenceKeys.hotelState] }"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Location">
      <dxi-item
        [dataField]="ConferenceKeys.hotelRegion"
        editorType="dxSelectBox"
        [label]="{ text: 'Hotel Region' }"
        [editorOptions]="{
          items: HOTEL_REGION_LOOKUP,
          valueExpr: LookupKeys.value,
          displayExpr: LookupKeys.description,
          placeholder: ''
        }"
      ></dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <ag-shr-address-form
            *ngIf="conference[ConferenceKeys.hotelRegion] === HotelRegion.domestic"
            [isAddress1Required]="false"
            [address]="conference?.[ConferenceKeys.hotelAddress]"
            [validationGroup]="validationGroup"
            [isReadonly]="isReadonlyMode"
          ></ag-shr-address-form>
        </div>
      </dxi-item>

      <dxi-item
        [visible]="conference[ConferenceKeys.hotelRegion] === HotelRegion.international"
        [dataField]="[ConferenceKeys.hotelAddress, AddressModelKeys.address1].join('.')"
        [label]="{ text: 'Hotel Address' }"
      ></dxi-item>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-data-grid
          #participantScheduleRangesGrid
          [dataSource]="conference?.[ConferenceKeys.participantScheduleRanges]"
          [showRowLines]="true"
          [rowAlternationEnabled]="true"
          [showColumnHeaders]="true"
          [columnAutoWidth]="true"
          (onInitNewRow)="onInitNewRow($event)"
          (onRowInserting)="onRowInserting($event)"
          (onRowUpdating)="onRowUpdating($event)"
          (onRowRemoving)="onRowRemoving($event)"
          height="100%"
        >
          <dxo-editing
            mode="row"
            [useIcons]="true"
            [allowUpdating]="!isReadonlyMode"
            [allowDeleting]="!isReadonlyMode"
            [allowAdding]="!isReadonlyMode"
            [newRowPosition]="'last'"
          >
          </dxo-editing>

          <dxo-toolbar>
            <dxi-item location="before">
              <span *dxTemplate class="dx-form-group-caption">Stay Periods</span>
            </dxi-item>

            <dxi-item name="addRowButton"></dxi-item>
          </dxo-toolbar>

          <dxi-column [dataField]="ConferencePossibleStayPeriodKey.registrationType" caption="Registration Type">
            <dxo-lookup
              [dataSource]="
                registrationTypeLookup
                  | filterRegistrationTypeLookup : conference[ConferenceKeys.participantScheduleRanges]
              "
              valueExpr="value"
              displayExpr="description"
            ></dxo-lookup>
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>

          <dxi-column
            caption="Primary Dates"
            [calculateCellValue]="calculatePrimaryDatesCellValue"
            cellTemplate="datesCellTmp"
            editCellTemplate="primaryDatesEditorTmp"
            [setCellValue]="setPrimaryDatesCellValue"
            [width]="250"
          >
            <dxi-validation-rule
              type="custom"
              [validationCallback]="validatePrimaryDates"
              [message]="Messages.REQUIRED_TEXT"
            >
            </dxi-validation-rule>
          </dxi-column>

          <dxi-column
            caption="Shoulder Dates"
            [calculateCellValue]="calculateShoulderDatesCellValue"
            cellTemplate="datesCellTmp"
            editCellTemplate="shoulderDatesEditorTmp"
            [setCellValue]="setShoulderDatesCellValue"
            [width]="250"
          >
            <dxi-validation-rule
              type="custom"
              [validationCallback]="validateShoulderDates"
              [message]="Messages.REQUIRED_TEXT"
            >
            </dxi-validation-rule>
          </dxi-column>

          <dxi-column type="buttons" *ngIf="!isReadonlyMode">
            <dxi-button name="edit"></dxi-button>

            <dxi-button
              icon="copy"
              [visible]="isCopyButtonVisible"
              [onClick]="copyAndAddStayPeriod.bind(this, participantScheduleRangesGrid)"
            ></dxi-button>

            <dxi-button name="delete"></dxi-button>
          </dxi-column>

          <div *dxTemplate="let cellInfo of 'datesCellTmp'">
            {{ cellInfo.value?.[0] | date : dateFormat | empty }} -
            {{ cellInfo.value?.[1] | date : dateFormat | empty }}
          </div>

          <div *dxTemplate="let cell of 'primaryDatesEditorTmp'">
            <dx-date-range-box
              [value]="cell?.value"
              (valueChange)="cell.setValue($event)"
              [displayFormat]="dateFormat"
              labelMode="hidden"
              applyValueMode="useButtons"
              [calendarOptions]="{
                onInitialized: navigateToDefaultCalendarDate
              }"
              [useMaskBehavior]="true"
              [dateSerializationFormat]="LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT"
            >
            </dx-date-range-box>
          </div>

          <div *dxTemplate="let cell of 'shoulderDatesEditorTmp'">
            <dx-date-range-box
              [value]="cell?.value"
              (valueChange)="cell.setValue($event)"
              [displayFormat]="dateFormat"
              labelMode="hidden"
              applyValueMode="useButtons"
              [readOnly]="!cell?.data?.[ConferencePossibleStayPeriodKey.primaryArrivalDate] || !cell?.data?.[ConferencePossibleStayPeriodKey.primaryDepartureDate]"
              [calendarOptions]="{
                onInitialized: navigateToDefaultCalendarDate,
                disabledDates: cell?.data?.[ConferencePossibleStayPeriodKey.primaryArrivalDate] | lockedDates: cell?.data?.[ConferencePossibleStayPeriodKey.primaryDepartureDate]
              }"
              [useMaskBehavior]="true"
              [dateSerializationFormat]="LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT"
            >
            </dx-date-range-box>
          </div>
        </dx-data-grid>
      </div>
    </dxi-item>
  </dx-form>
</dx-scroll-view>
