import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formFieldVisible',
})
export class FormFieldVisiblePipe implements PipeTransform {
  transform(excludedFields: string[], keys: string | string[]) {
    if (!excludedFields?.length && !keys?.length) {
      return true;
    }
    const registrantKeys = Array.isArray(keys) ? keys : [keys];
    const filtered = excludedFields?.filter(field => registrantKeys.includes(field));
    return filtered?.length !== registrantKeys.length;
  }
}
