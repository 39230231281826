<ag-shr-modal-window
  #notificationNotificationOptInGroupModalRef
  [width]="'500px'"
  [height]="'auto'"
  [title]="title"
  (onSaveClick)="addNotificationRecipients()"
  [actionTitle]="'ADD'"
  [useScrollView]="false"
>
  <ng-container *ngIf="notificationNotificationOptInGroupModalRef?.popupComponent?.visible ?? false">
    <dx-form
      class="ag-assign-owner__form"
      [(formData)]="notificationOptInGroup"
      labelMode="floating"
      [validationGroup]="validationGroup"
      *var="notificationOptInGroup?.[NotificationOptInGroupKeys.type] === NotificationOptInGroupType.registrant as isConferenceSource"
    >
      <dxi-item [itemType]="'group'" [colCount]="2">
        <dxi-item
          [colSpan]="isConferenceSource ? 1 : 2"
          [dataField]="NotificationOptInGroupKeys.type"
          [label]="{ text: 'From' }"
          [isRequired]="true"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            items: notificationOptInGroupTypeLookup,
            displayExpr: LookupKeys.description,
            valueExpr: LookupKeys.value
          }"
        >
        </dxi-item>

        <dxi-item
          [visible]="isConferenceSource"
          [dataField]="[NotificationOptInGroupKeys.payload, NotificationOptInGroupKeys.conferenceDbId] | path"
          [label]="{ text: 'Conference' }"
          [isRequired]="true"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            items: conferences$ | async,
            displayExpr: ConferenceKeys.eventName,
            valueExpr: BaseModelKeys.dbId,
            searchEnabled: true,
            dropDownOptions: { width: 320 }
          }"
        >
        </dxi-item>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-filter-builder
            #filterBuilderRef
            [(value)]="filterExpression"
            (onValueChanged)="onValueChanged($event)"
            [fields]="notificationOptInGroup?.[NotificationOptInGroupKeys.type] | optInGroupFilterBuilderFields "
            [disabled]="!notificationOptInGroup?.[NotificationOptInGroupKeys.type]"
          ></dx-filter-builder>
        </div>
      </dxi-item>
    </dx-form>
  </ng-container>
</ag-shr-modal-window>
