<div class="mt-2">
  <ag-shr-editor-toolbar [title]="'Dietary Considerations'" [isEditVisible]="canEdit" (clickEdit)="showEditorModal()"></ag-shr-editor-toolbar>

  <div class="row my-2 px-3">
    <div class="col-4 text-start">
      <b> Do you have a Dietary or Personal Consideration? </b>
    </div>
    <div class="col-3 text-start">
      <b>Consideration Type</b>
    </div>
    <div class="col-5 text-start">
      <b>Description of Consideration(s)</b>
    </div>
  </div>
  <div class="row my-2 px-3">
    <div class="col-4 text-start">
      {{ dietaryConsiderationFormDetails?.[DietaryConsiderationKeys.hasDietaryOrPersonalConsideration] }}
    </div>
    <div class="col-3 text-start">
      {{ dietaryConsiderationFormDetails?.[DietaryConsiderationKeys.dietaryConsiderationType] | dietaryConsiderationType | async }}
    </div>
    <div class="col-5 text-start">
      {{ dietaryConsiderationFormDetails?.[DietaryConsiderationKeys.dietaryConsideration] }}
    </div>
  </div>
</div>

<ag-shr-modal-window
  [width]="'50vw'"
  [height]="'auto'"
  #dietaryConsiderationModalRef
  [title]="'Dietary Considerations'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="saveAgentUpdates()"
  (onPopupClose)="handleClosePopup($event)"
>
  <ag-shr-dietary-considerations-form
    [validationGroup]="validationGroup"
    (handleDietaryConsiderationTypeSelect)="handleDietaryConsiderationTypeSelect($event)"
    [dietaryConsiderations]="dietaryConsiderationFormDetails"
  ></ag-shr-dietary-considerations-form>
</ag-shr-modal-window>
