import { Pipe, PipeTransform } from '@angular/core';
import {
  AgentCampaignChange,
  AgentCampaignStatistic,
  AgentCampaignStepName,
  CampaignsManagementTasks,
} from 'ag-common-lib/public-api';

@Pipe({ name: 'campaignsTaskStats' })
export class CampaignsTaskStatsPipe implements PipeTransform {
  transform(task: CampaignsManagementTasks): AgentCampaignStatistic {
    let total = 0;
    let itemsDone = 0;

    const {
      [AgentCampaignStepName.contactInfoAddress]: contactInfoAddress,
      [AgentCampaignStepName.contactInfoPhoneNumber]: contactInfoPhoneNumber,
      [AgentCampaignStepName.contactInfoEmailAddress]: contactInfoEmailAddress,
      ...restDetails
    } = task?.details ?? {};

    const contactInfoIncomingDataSubSteps = [contactInfoAddress, contactInfoPhoneNumber, contactInfoEmailAddress];
    const incomingContactInfoIncomingDataSubSteps = contactInfoIncomingDataSubSteps.filter(
      stepDetails => stepDetails?.incomingStepData,
    );
    const isStepDone = incomingContactInfoIncomingDataSubSteps.every(stepDetails => stepDetails?.isStepDone);

    if (incomingContactInfoIncomingDataSubSteps?.length) {
      total++;
    }

    if (incomingContactInfoIncomingDataSubSteps?.length && isStepDone) {
      itemsDone++;
    }

    Object.entries(restDetails).forEach(([step, stepDetails]: [AgentCampaignStepName, AgentCampaignChange<any>]) => {
      console.log('step', step);
      console.log('stepDetails', stepDetails);
      if (stepDetails.incomingStepData) {
        total++;
      }

      if (stepDetails?.incomingStepData && stepDetails?.isStepDone) {
        itemsDone++;
      }
    });

    return { total, itemsDone };
  }
}
