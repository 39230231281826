import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlightFilterSearchTerm' })
export class HighlightFilterSearchTermPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    if (!searchTerm) {
      return value;
    }

    return value?.replace(
      new RegExp(searchTerm.split('').join('(\\s?(\\(|\\)|\\-)\\s?)?'), 'i'),
      match => `<span class="dx-datagrid-search-text">${match}</span>`,
    );
  }
}
