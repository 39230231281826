import { Pipe, PipeTransform } from '@angular/core';
import { REGISTRANT_ISSUES_STATUS_LOOKUP, RegistrantIssuesStatus } from 'ag-common-lib/public-api';

@Pipe({ name: 'registrantIssueStatus' })
export class RegistrantIssueStatusPipe implements PipeTransform {
  transform(status: RegistrantIssuesStatus, type: 'class' | 'text' = 'text'): string {
    if (!status) {
      return '';
    }
    const lookupByStatus = REGISTRANT_ISSUES_STATUS_LOOKUP.find(lookup => lookup?.value === status);
    return type === 'class' ? lookupByStatus?.cssClass : lookupByStatus?.description;
  }
}
