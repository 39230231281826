import { Injectable } from '@angular/core';
import { BaseModelKeys, Registrant, RegistrantKeys, RegistrantModelKeys } from 'ag-common-lib/public-api';
import { firstValueFrom, map, mergeMap, Observable, shareReplay } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { ConferenceTransactionsService } from 'ag-common-svc/lib/services/conference-stripe-transactions.service';
import { StripeTransaction } from 'ag-common-lib/lib/models/stripe/stripe-transaction.model';
import {
  RegistrationTransaction,
  RegistrationTransactionKeys,
} from 'ag-common-lib/lib/models/registration/registration-transactions.model';
import { PaymentMethodCreateParams } from '@stripe/stripe-js';

@Injectable()
export class AttendeeTransactionsService {
  billingDetails$: Observable<PaymentMethodCreateParams.BillingDetails> =
    this.attendeeDetailsModalService.registrant$.pipe(
      map(registrant => {
        const registrantData = registrant?.[RegistrantModelKeys.data];
        const email = registrantData?.[RegistrantKeys.inviteeEmail];
        const name = [registrantData?.[RegistrantKeys.firstName], registrantData?.[RegistrantKeys.lastName]]
          .filter(Boolean)
          .join(' ');

        return { name, email };
      }),
    );
  registrantTransactions$: Observable<StripeTransaction[]> = this.attendeeDetailsModalService.registrant$.pipe(
    mergeMap((conferenceRegistration: Registrant) => {
      const conferenceDbId = conferenceRegistration?.[RegistrantModelKeys.conferenceDbId];
      const registrantDbId = conferenceRegistration?.[BaseModelKeys.dbId];

      return this.conferenceTransactionsService.getList(conferenceDbId, registrantDbId);
    }),
    shareReplay(1),
  );

  constructor(
    private conferenceTransactionsService: ConferenceTransactionsService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
  ) {}

  handleSaveTransaction = async (stripeTransaction: Partial<StripeTransaction>) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const conferenceDbId = registrant?.[RegistrantModelKeys.conferenceDbId];
    const registrantDbId = registrant?.[BaseModelKeys.dbId];
    const registrantTransactionDbI = stripeTransaction?.[BaseModelKeys.dbId];

    const registrantTransaction = Object.assign(
      {
        [RegistrationTransactionKeys.conferenceDbId]: conferenceDbId,
        [RegistrationTransactionKeys.registrantDbId]: registrantDbId,
      },
      stripeTransaction,
    ) as RegistrationTransaction;

    await (registrantTransactionDbI
      ? this.conferenceTransactionsService.update(
          conferenceDbId,
          registrantDbId,
          registrantTransactionDbI,
          registrantTransaction,
          true,
        )
      : this.conferenceTransactionsService.create(conferenceDbId, registrantDbId, registrantTransaction, true));
  };

  handleTransactionRemoving = async (registrantTransactionDbI: string) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const conferenceDbId = registrant?.[RegistrantModelKeys.conferenceDbId];
    const registrantDbId = registrant?.[BaseModelKeys.dbId];

    return this.conferenceTransactionsService.delete(conferenceDbId, registrantDbId, registrantTransactionDbI, true);
  };
}
