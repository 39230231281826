import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangeLogsType, PERSONAL_INFO_SEARCH_KEYS_CONFIG, RegistrantKeys } from 'ag-common-lib/public-api';

@Component({
  selector: 'ag-crm-personal-info-change-log',
  templateUrl: './attendee-personal-info-log-details.component.html',
  styleUrls: ['./attendee-personal-info-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendeePersonalInfoLogDetailsComponent {
  @Input()
  set changeLogs(delta: Delta) {
    const logs = {};
    Object.entries(delta).forEach(data => {
      const key = data[0];
      const value = data[1];
      if (key === RegistrantKeys.lastEvalDate) {
        const lastEvalDate = !Array.isArray(value)
          ? value.__time__
          : [value[0]?.__time__ ?? null, value[1]?.__time__ ?? null];
        Object.assign(logs, { [RegistrantKeys.lastEvalDate]: lastEvalDate });
        return;
      }
      Object.assign(logs, { [key]: value });
    });
    console.log('logs ', logs);
    this._changeLogs = logs;
  }
  get changeLogs(): Delta {
    return this._changeLogs;
  }
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly personalInfoConfig = PERSONAL_INFO_SEARCH_KEYS_CONFIG;
  private _changeLogs: Delta;
}
