import Popup from 'devextreme/ui/popup';

export const fullscreen = async (element: HTMLElement, doc): Promise<void> => {
  document.body.classList.toggle('fullscreen-enable');
  // webkitRequestFullscreen and webkitExitFullscreen use for Safari on IOS
  const docElmWithBrowsersFullScreenFunctions = element as HTMLElement & {
    webkitRequestFullscreen(): Promise<void>;
  };
  const requestFullScreen = element.requestFullscreen || docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen;
  const exitFullScreen = doc.exitFullscreen || doc.webkitExitFullscreen;

  if (!document.fullscreenElement && !doc.webkitFullscreenElement) {
    await requestFullScreen.bind(element)();
    return;
  }

  await exitFullScreen.bind(doc)();

  setPopupsContainer(element);
};

export const setPopupsContainer = (container?: HTMLElement): void => {
  Popup.defaultOptions({
    options: {
      container,
    },
  });
};
