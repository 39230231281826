import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { StateSelectBoxModule } from 'ag-common-svc/lib/components/state-select-box/state-select-box.module';
import { RelationshipTypeSelectBoxModule } from 'ag-common-svc/lib/components/relationship-type-select-box/relationship-type-select-box.module';
import { AssociationsFormComponent } from './associations-form.component';

@NgModule({
  declarations: [AssociationsFormComponent],
  imports: [CommonModule, SharedModule, StateSelectBoxModule, RelationshipTypeSelectBoxModule],
  exports: [AssociationsFormComponent],
})
export class AssociationsFormModule {}
