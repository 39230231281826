import { Component, HostBinding } from '@angular/core';
import { ATTENDEE_TYPE_LOOKUP, Constants, WizardStepState, WizardStepStateMap } from 'ag-common-lib/public-api';
import { ExcursionInfoTableDataKeys } from 'ag-common-svc/lib/components/excursions-info-table/excursions-info-table.models';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { getPhoneNumber } from 'ag-common-svc/lib/utils/phone-number-data.utils';
import { ConferenceExcursionStatisticService } from '../conference-excursion-statistic.service';

@Component({
  selector: 'ag-crm-conference-not-selected-grid',
  templateUrl: './conference-excursion-not-selected-grid.component.html',
  styleUrls: ['./conference-excursion-not-selected-grid.component.scss'],
})
export class ConferenceExcursionNotSelectedGridComponent {
  @HostBinding('class') className = 'conference-excursions-not-selected-grid';

  notSelectedExcursionsDataSource$ = this.conferenceExcursionStatisticService.notSelectedExcursionsDataSource$;

  protected readonly emptyMessage: string = 'No Unselected Excursions  Currently Exist';
  readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly attendeeTypeLookup = ATTENDEE_TYPE_LOOKUP;
  protected readonly WizardStepState = WizardStepState;
  protected readonly WizardStepStateMap = WizardStepStateMap;
  protected readonly ExcursionInfoTableDataKeys = ExcursionInfoTableDataKeys;

  constructor(private conferenceExcursionStatisticService: ConferenceExcursionStatisticService) {}

  calculateAge = (e): number => this.conferenceExcursionStatisticService.calculateAge(e);

  protected onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Excursions');
    const timeStamp = new Date().toJSON().slice(0, 19);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data' && gridCell.column.dataField === ExcursionInfoTableDataKeys.phoneNumber) {
          excelCell.value = gridCell.value?.is_primary ? getPhoneNumber(gridCell.value) : '';
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Persons_Without_Excursions_${timeStamp}.xlsx`,
        );
      });
    });
  }
}
