import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'ag-common-svc/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import Validator from 'devextreme/ui/validator';
import { Messages } from 'ag-common-lib/public-api';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @ViewChild('signInFormRef', { static: false }) signInFormComponent: DxFormComponent;

  formData: any = {};
  showLoader = false;
  readonly Messages = Messages;

  constructor(private authService: AuthService, private router: Router) {}

  onSubmit = () => {
    const validationResults = this.signInFormComponent.instance.validate();

    if (validationResults?.isValid) {
      this.showLoader = true;
      const { email, password } = this.formData;
      this.authService.signInWithEmailAndPassword(email, password).finally(() => {
        this.showLoader = false;
      });
    }
  };
}
