import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import CustomStore from 'devextreme/data/custom_store';
import { Timestamp } from 'firebase/firestore';
import {
  DistributionList,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from 'ag-common-lib/public-api';
import { AuthService } from 'ag-common-svc/public-api';
import { DistributionListService } from 'src/app/shared/services/distribution-list.service';
import { SessionControllerService } from 'src/app/shared/services/util/session-controller.service';
import { firstValueFrom, map } from 'rxjs';
import { ARMPermissionsService } from '../../../../shared/services/util/arm-permissions.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-distribution-lists-grid',
  templateUrl: './distribution-lists-grid.component.html',
  styleUrls: ['./distribution-lists-grid.component.scss'],
})
export class DistributionListsGridComponent {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  dataSource = {};
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    public router: Router,
    public session: SessionControllerService,
    public authService: AuthService,
    public distributionListService: DistributionListService,
    private armPermissionsService: ARMPermissionsService,
  ) {
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: function (loadOptions: any) {
        return distributionListService.getAll('name').then(lists => {
          return lists;
        });
      },
      insert: function (value: DistributionList) {
        return distributionListService.create(value);
      },
      update: function (key: any, value: DistributionList) {
        return distributionListService.update(value);
      },
      remove: function (id: any) {
        return distributionListService.delete(id);
      },
    });
  }

  async onToolbarPreparing(e) {
    const canAdd = await this.armPermissionsService.hasPermission(
      Entity.emailDistributionLists,
      EntityPermissionActivityKeys.create,
    );
    console.log('canAdd', canAdd);
    if (canAdd) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: this.addDistributionList.bind(this),
        },
      });
      this.dataGrid.instance.refresh();
    }
    return;
  }

  addDistributionList = async () => {
    const agentEmail = await firstValueFrom(this.authService.loggedInAgent$.pipe(map(agent => agent?.p_email)));
    this.session.selectedDistributionList = { ...new DistributionList() };
    this.session.selectedDistributionList.owner = agentEmail;
    this.session.selectedDistributionList.created_date = new Date();
    this.router.navigate(['Distribution-List-Management']);
  };

  viewDistributionList(e) {
    this.session.selectedDistributionList = e.data;
    this.router.navigate(['Distribution-List-Management']);
  }

  getDate(date: Timestamp) {
    if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    } else {
      return '';
    }
  }

  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
