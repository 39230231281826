import { NgModule } from '@angular/core';
import { AgentCampaignChangesListComponent } from './agent-campaign-changes-list.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';

@NgModule({
  declarations: [AgentCampaignChangesListComponent],
  imports: [SharedModule, ButtonWithIndicatorModule],
  exports: [AgentCampaignChangesListComponent],
})
export class AgentCampaignChangesListModule {}
