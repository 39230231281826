import { Inject, Injectable } from '@angular/core';
import { Contest } from 'ag-common-lib/public-api';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from 'ag-common-svc/public-api';

@Injectable({
  providedIn: 'root',
})
export class ContestService extends DataService<Contest> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    super.setCollection('contests');
  }
}
