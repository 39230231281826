import { Injectable } from '@angular/core';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import {
  AGMedia,
  AGMediaKeys,
  Agent,
  AgentMedia,
  AssociationKeys,
  BaseModelKeys,
  GuestKeys,
  HotelReservationKeys,
  MediaContentType,
  RegistrantKeys,
  RegistrantModelKeys,
} from 'ag-common-lib/public-api';
import { Observable, combineLatest, filter, firstValueFrom, map, mergeMap, shareReplay } from 'rxjs';
import { AgentImagesService } from 'ag-common-svc/public-api';
import { FlightInfoKeys } from 'ag-common-lib/lib/models/registration/flight-information.model';
import { DatesRangePipe } from 'ag-common-svc/shared/pipes/dates-range.pipe';

@Injectable()
export class AttendeeDetailsHeaderService {
  agentMediaDataSource$ = this.attendeeDetailsModalService.agent$.pipe(
    filter(Boolean),
    map((agent: Agent) => agent?.[BaseModelKeys.dbId]),
    mergeMap(agentDbId => this.agentImagesService.fetchAgentImages(agentDbId).pipe(shareReplay(1))),
  );

  travelDatesSummary$: Observable<any[]> = combineLatest({
    registrantData: this.attendeeDetailsModalService.registrant$.pipe(
      map(registrant => registrant?.[RegistrantModelKeys.data]),
    ),
    registrantGuests: this.attendeeDetailsModalService.registrantGuests$,
  }).pipe(
    map(({ registrantData, registrantGuests }) => {
      const existIntervalsMap = new Map();
      const hotelReservation = registrantData?.[RegistrantKeys.hotelReservation];
      const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
      const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
      const registrantFlightInformation = registrantData?.[RegistrantKeys.flightInformation];
      const registrantArrivalDate = registrantFlightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate;
      const registrantDepartureDate = registrantFlightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate;
      const registrantName = [
        registrantFlightInformation?.[FlightInfoKeys.firstName] ??
          registrantFlightInformation?.[FlightInfoKeys.driverLicenseFirstName] ??
          registrantData?.[RegistrantKeys.firstName],
        registrantFlightInformation?.[FlightInfoKeys.lastName] ??
          registrantFlightInformation?.[FlightInfoKeys.driverLicenseLastName] ??
          registrantData?.[RegistrantKeys.lastName],
      ]
        .filter(Boolean)
        .join(' ');

      if (registrantArrivalDate && registrantDepartureDate) {
        const interval = this.datesRangePipe.transform(registrantArrivalDate, registrantDepartureDate);

        existIntervalsMap.set(interval, [registrantName]);
      }

      registrantGuests.forEach(guest => {
        const guestFlightInformation = guest?.[GuestKeys.flightInformation];
        const guestArrivalDate = guestFlightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate;
        const guestDepartureDate = guestFlightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate;
        const guestName = [
          guestFlightInformation?.[FlightInfoKeys.firstName] ??
            guestFlightInformation?.[FlightInfoKeys.driverLicenseFirstName] ??
            guest?.[AssociationKeys.firstName],
          guestFlightInformation?.[FlightInfoKeys.lastName] ??
            guestFlightInformation?.[FlightInfoKeys.driverLicenseLastName] ??
            guest?.[AssociationKeys.lastName],
        ]
          .filter(Boolean)
          .join(' ');

        if (!guestArrivalDate || !guestDepartureDate) {
          return;
        }
        const guestInterval = this.datesRangePipe.transform(guestArrivalDate, guestDepartureDate);

        if (!existIntervalsMap.has(guestInterval)) {
          existIntervalsMap.set(guestInterval, [guestName]);
          return;
        }
        existIntervalsMap.get(guestInterval)?.push(guestName);
      });

      const dates = [];

      existIntervalsMap.forEach((group, interval) => {
        const formatter = new (Intl as any).ListFormat('en', {
          style: 'long',
          type: 'conjunction',
        });

        const title = formatter?.format(group);
        console.log('title', title);

        dates.push({ title, interval });
      });

      return dates;
    }),
  );

  constructor(
    private datesRangePipe: DatesRangePipe,
    private agentImagesService: AgentImagesService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
  ) {}

  onProfileImageChanged = async (mediaUrl: AGMedia) => {
    if (!mediaUrl?.[BaseModelKeys.dbId]) {
      const agent = await firstValueFrom(this.attendeeDetailsModalService.agent$);
      const agentId = agent?.[BaseModelKeys.dbId];
      const media = Object.assign({}, new AgentMedia(MediaContentType.image, agentId), {
        [AGMediaKeys.contentType]: mediaUrl?.contentType ?? null,
        [AGMediaKeys.wasabiPath]: mediaUrl?.wasabiPath ?? null,
        [AGMediaKeys.fileName]: mediaUrl?.fileName ?? null,
      });

      const response = await this.agentImagesService.addAgentMediaToImagesDirectory(agentId, media);
      Object.assign(mediaUrl, response);
    }
  };
}
