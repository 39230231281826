<div class="container">
  <div class="row justify-content-between" style="padding: 20px 40px">
    <div class="col-12 align-self-center text-start">
      <h4>Email Templates Lists</h4>
    </div>
  </div>
  <div>
    <dx-data-grid
      #templategrid
      class="dx-card wide-card"
      [dataSource]="templatesDataSource"
      [showBorders]="false"
      [focusedRowEnabled]="true"
      [focusedRowIndex]="0"
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [scrollLeft]="true"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      [height]="'75vh'"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onRowDblClick)="editEmailTemplate($event)"
    >
      <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>
      <dxi-column dataField="name"></dxi-column>
      <dxi-column dataField="date_created" [calculateCellValue]="getCreatedDate"></dxi-column>
      <dxi-column dataField="owner"></dxi-column>
      <dxi-column type="buttons" [width]="125" [fixed]="true">
        <dxi-button name="delete"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>
</div>

<ng-container *ngIf="viewTemplatelDetailsVisible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="Create / Edit Email"
    [dragEnabled]="true"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="viewTemplatelDetailsVisible"
  >
    <app-email-template (onClose)="saveEmailTemplate($event)" [emailTemplate]="emailTemplate"></app-email-template>
  </dx-popup>
</ng-container>
