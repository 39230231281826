import { Component, Input, Output, HostBinding, EventEmitter } from '@angular/core';

@Component({
  selector: 'ag-crm-attendee-details-group-controls',
  templateUrl: './attendee-details-group-controls.component.html',
  styleUrls: ['./attendee-details-group-controls.component.scss'],
})
export class AttendeeDetailsGroupControlsComponent {
  @HostBinding('class') className = 'attendee-details-group-controls';

  @Input() isInEditState: boolean;
  @Output() handleStartEditing = new EventEmitter<void>();
  @Output() handleCancelEditing = new EventEmitter<void>();
  @Output() handleSaveChanges = new EventEmitter<void>();

  constructor() {}
}
