import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceRegistrationStepName } from 'ag-common-lib/public-api';
import { CONFERENCE_STEPS_SECTIONS } from '../conference-steps-info-configuration.models';

@Pipe({
  name: 'conferenceStepName',
})
export class ConferenceStepNamePipe implements PipeTransform {
  transform(stepId: ConferenceRegistrationStepName): string {
    if (!stepId) {
      return null;
    }

    return CONFERENCE_STEPS_SECTIONS.find(section => section.id === stepId).title;
  }
}
