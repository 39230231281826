import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { AssociationKeys, ChangeLogsType, RegistrantKeys, RSVP_SEARCH_KEYS_CONFIG } from 'ag-common-lib/public-api';

@Component({
  selector: 'ag-crm-rsvp-change-log',
  templateUrl: './attendee-rsvp-log-details.component.html',
  styleUrls: ['./attendee-rsvp-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendeeRsvpLogDetailsComponent {
  @Input() changeLogs: Delta;

  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly rsvpKeysConfig = RSVP_SEARCH_KEYS_CONFIG;
  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly AssociationKeys = AssociationKeys;
}
