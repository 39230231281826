import { Component, HostBinding, Input } from '@angular/core';
import {
  Excursion,
  EXCURSION_DATA_TYPE_LOOKUP,
  ExcursionConfiguration,
  ExcursionConfigurationKeys,
  ExcursionKeys,
  LookupKeys,
  Patterns,
} from 'ag-common-lib/public-api';
import { InitNewRowEvent, RowInsertingEvent, RowRemovingEvent, RowUpdatingEvent } from 'devextreme/ui/data_grid';
import { DxoEditingComponent } from 'devextreme-angular/ui/nested';
import { ValidationCallbackData } from 'devextreme/common';
import { isEqual } from 'lodash';

@Component({
  selector: 'ag-crm-excursion-configuration-grid',
  templateUrl: './excursion-configuration-grid.component.html',
  styleUrls: ['./excursion-configuration-grid.component.scss'],
})
export class ExcursionConfigurationGridComponent {
  @HostBinding('class') className = 'excursion-confirmation-grid';
  @Input()
  set formData(data: Excursion) {
    this._formData = data;
    this.configurationsData = data?.[ExcursionKeys.configuration] ?? [];
  }
  @Input() onSaveConfigurations: (config: Excursion) => Promise<boolean>;
  @Input() isReadOnly = false;

  configurationsData: ExcursionConfiguration[] = [];
  private _formData: Excursion;

  protected readonly ExcursionConfigurationKeys = ExcursionConfigurationKeys;
  protected readonly ExcursionKeys = ExcursionKeys;
  protected readonly safeFirebaseFieldNameRegExp = Patterns.SAFE_FIREBASE_FIELD_NAME_WITH_SPACE_REG_EXP;
  protected readonly excursioDataTypeLookup = EXCURSION_DATA_TYPE_LOOKUP;
  protected readonly LookupKeys = LookupKeys;

  protected onInitNewExcursionConfiguration = (e: InitNewRowEvent): void => {
    Object.assign(e.data, new ExcursionConfiguration());
  };

  protected onExcursionInsertingConfiguration = (e: RowInsertingEvent): void => {
    const { __KEY__, ...configuration } = e.data;
    const configurations = [configuration];

    this.configurationsData?.forEach(configuration => {
      const activity = Object.assign({}, configuration);

      configurations.push(activity);
    });

    e.cancel = this.saveConfigurationChanges(configurations);
  };

  protected onExcursionUpdatingConfiguration = (e: RowUpdatingEvent) => {
    const configurations = [];

    this.configurationsData?.forEach(configuration => {
      const activity = Object.assign({}, configuration);
      if (e.key === configuration) {
        Object.assign(activity, e.newData);
      }
      configurations.push(activity);
    });

    e.cancel = this.saveConfigurationChanges(configurations);
  };

  protected onExcursionRemovingConfiguration = (e: RowRemovingEvent) => {
    const configurations = this.configurationsData.filter(configuration => !isEqual(configuration, e.key));
    e.cancel = this.saveConfigurationChanges(configurations);
  };

  protected checkIsExcursionConfigurationUniq = (
    editingComponent: DxoEditingComponent,
    { data }: ValidationCallbackData,
  ) => {
    const label = data?.[ExcursionConfigurationKeys.label];
    return this.configurationsData.every(configuration => {
      if (configuration === editingComponent.editRowKey) {
        return true;
      }

      return configuration?.[ExcursionConfigurationKeys.label]?.toLocaleLowerCase() !== label?.toLocaleLowerCase();
    });
  };

  private saveConfigurationChanges = (configurations: ExcursionConfiguration[]) => {
    Object.assign(this._formData, { [ExcursionKeys.configuration]: configurations });
    return this.onSaveConfigurations(this._formData);
  };
}
