import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncrDecrService {
  key = 'TUc0emRqRXpkdw==';

  //The set method is use for encrypt the value.
  set(value) {
    let encrypted = CryptoJS.AES.encrypt(value, this.key).toString();

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(value) {
    return CryptoJS.AES.decrypt(value, this.key).toString(CryptoJS.enc.Utf8);
  }
}
