import { Pipe, PipeTransform } from '@angular/core';
import { GuestRate, GuestRateKeys, MonthYearSelection } from 'ag-common-lib/public-api';

@Pipe({ name: 'maxGuestEndAgeForRate' })
export class MaxGuestEndAgeForRatePipe implements PipeTransform {
  transform(guestRate: GuestRate, rates: GuestRate[]): number | undefined {
    this.sortRates(rates);

    const nextRow = this.findNextRow(guestRate, rates);

    if (!nextRow) {
      return guestRate?.[GuestRateKeys.monthYearSelection] === MonthYearSelection.month ? 12 : undefined;
    }

    if (
      guestRate?.[GuestRateKeys.monthYearSelection] === nextRow?.[GuestRateKeys.monthYearSelection] ||
      nextRow?.[GuestRateKeys.monthYearSelection] === MonthYearSelection.month
    ) {
      return nextRow?.[GuestRateKeys.startAgeRange] - 1;
    }

    return 12;
  }

  private findNextRow(guestRate: GuestRate, rates: GuestRate[]): GuestRate | null {
    const startAgeRange = guestRate?.[GuestRateKeys.startAgeRange];

    const startAgeRangeMonth =
      guestRate?.[GuestRateKeys.monthYearSelection] === MonthYearSelection.month ? startAgeRange : 12 * startAgeRange;

    return rates.find(rate => {
      const rateStartAgeRange = rate?.[GuestRateKeys.startAgeRange];
      const rateStartAgeRangeMonth =
        rate?.[GuestRateKeys.monthYearSelection] === MonthYearSelection.month
          ? rateStartAgeRange
          : 12 * rateStartAgeRange;

      return startAgeRangeMonth < rateStartAgeRangeMonth;
    });
  }

  private sortRates(rates: GuestRate[]): GuestRate[] {
    return rates?.sort((leftRate, rightRate) => {
      const leftStartAgeRange = leftRate[GuestRateKeys.startAgeRange];
      const rightStartAgeRange = rightRate[GuestRateKeys.startAgeRange];

      const left =
        leftRate?.[GuestRateKeys.monthYearSelection] === MonthYearSelection.month
          ? leftStartAgeRange
          : 12 * leftStartAgeRange;
      const right =
        leftRate?.[GuestRateKeys.monthYearSelection] === MonthYearSelection.month
          ? rightStartAgeRange
          : 12 * rightStartAgeRange;

      return left - right;
    });
  }
}
