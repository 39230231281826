import { BaseModel } from '../base.model';

export class PolicyTransaction extends BaseModel {
  carrier_name?: string;
  agency_number?: string;
  mga_id?: string;
  mga_name?: string;
  agent_number?: string;
  agent_id?: string;
  agent_name?: string;
  transdate?: string;
  policy_number?: string;
  insured_name?: string;
  product_name?: string;
  face_amount?: number = 0;
  life_prem?: number = 0;
  target_prem?: number = 0;
  excess_prem?: number = 0;
  annuity?: number = 0;
  policies?: number = 0;
  weighted_prem?: number = 0;
  recruiter?: string;
  year?: number;
}
