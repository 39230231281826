import { Injectable } from '@angular/core';

export enum StorageKey {
  Agent = 'agent',
  Prospect = 'prospect',
  AgentId = 'agentId',
  AgencyId = 'agencyId',
  CarrierId = 'carrierId',
  ProspectId = 'prospectId',
}

@Injectable({
  providedIn: 'root',
})
export class StorageControllerService {
  public setToLocalStorage(storageKey: StorageKey, data: any) {
    if (!data) {
      localStorage.removeItem(storageKey);
      return;
    }
    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  public getFromLocalStorage(storageKey: StorageKey) {
    const jsonData = localStorage.getItem(storageKey);
    try {
      return JSON.parse(jsonData);
    } catch (error) {}
    return null;
  }
}
