import { Component, NgModule, Input } from '@angular/core';
import { Agent } from 'ag-common-lib/public-api';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent {
  @Input()
  menuItems: any;

  @Input()
  menuMode!: string;

  @Input()
  user!: Agent | null;
}
