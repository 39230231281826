import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeGeneralInfoFormComponent } from './attendee-general-info-form.component';
import { SelectEmailAddressModule } from 'ag-common-svc/lib/components/select-email-address/select-email-address.module';
import { AttendeeGeneralInfoOutcomeStatusPlaceholderPipe } from './attendee-general-info-outcome-status-placeholder.pipe';
import { AttendeeDetailsGroupControlsModule } from '../attendee-details-group-controls/attendee-details-group-controls.module';
import { TShirtSeizesFormModule } from 'ag-common-svc/lib/components/t-shirt-seizes-form/t-shirt-seizes-form.module';
import { DietaryConsiderationsFormModule } from 'ag-common-svc/lib/components/agent-editor/components/dietary-considerations-form/dietary-considerations-form.module';
import { SelectAssociationModule } from 'ag-common-svc/lib/components/select-association/select-association.module';
import { RelationshipTypeSelectBoxModule } from 'ag-common-svc/lib/components/relationship-type-select-box/relationship-type-select-box.module';
import { SelectPhoneNumberModule } from 'ag-common-svc/lib/components/select-phone-number/select-phone-number.module';
import { SelectAddressModule } from 'ag-common-svc/lib/components/select-address/select-address.module';
import { DxValidationSummaryModule } from 'devextreme-angular';
import { AssociationsFormModule } from './associations-form/associations-form.module';
import { GeneralInfoSectionVisiblePipe } from './general-info-section-visible.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SelectEmailAddressModule,
    AttendeeDetailsGroupControlsModule,
    TShirtSeizesFormModule,
    DietaryConsiderationsFormModule,
    SelectAssociationModule,
    RelationshipTypeSelectBoxModule,
    SelectPhoneNumberModule,
    SelectAddressModule,
    DxValidationSummaryModule,
    AssociationsFormModule,
  ],
  declarations: [
    AttendeeGeneralInfoFormComponent,
    AttendeeGeneralInfoOutcomeStatusPlaceholderPipe,
    GeneralInfoSectionVisiblePipe,
  ],
  exports: [AttendeeGeneralInfoFormComponent],
  providers: [],
})
export class AttendeeGeneralInfoFormModule {}
