import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeHotelReservationComponent } from './attendee-hotel-reservation.component';
import { DxDateRangeBoxModule } from 'devextreme-angular';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ConferenceRegistrationPipesModule } from 'ag-common-svc/shared/pipes/conference-registration/conference-registration-pipes.module';
import { AgAssignOwnerViewerModule, ModalWindowModule } from 'ag-common-svc/public-api';
import { AttendeeNoteModalModule } from "../attendee-note-modal/attendee-note-modal.module";
import { ConferencesPipesModule } from "../../../pipes/conferences-pipes.module";

@NgModule({
  imports: [ CommonModule, SharedModule, DxDateRangeBoxModule, ConferenceRegistrationPipesModule, ModalWindowModule, AttendeeNoteModalModule, ConferencesPipesModule, AgAssignOwnerViewerModule ],
  declarations: [AttendeeHotelReservationComponent],
  exports: [AttendeeHotelReservationComponent],
  providers: [],
})
export class AttendeeHotelReservationModule {}
