import { Inject, Injectable } from '@angular/core';
import { CRMUser } from 'ag-common-lib/public-api';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP, QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';

@Injectable({
  providedIn: 'root',
})
export class CrmUserService extends DataService<CRMUser> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    super.setCollection('crm-users');
  }

  getCRMUsersByEmail(email: string): Promise<CRMUser> {
    return this.getAllByValue([new QueryParam('email_address', WhereFilterOperandKeys.equal, email)]).then(
      crm_users => {
        if (crm_users.length == 0) {
          return null;
        } else if (crm_users.length == 1) {
          return crm_users[0];
        } else {
          console.error('More than 1 CRM User found with this email address');
          return null;
        }
      },
    );
  }
}
