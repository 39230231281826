import { Pipe, PipeTransform } from '@angular/core';
import { differenceInHours } from 'date-fns';

@Pipe({ name: 'campaignsPriority' })
export class CampaignsTaskBoardPriorityPipe implements PipeTransform {
  transform(statusUpdateDate: Date, noPriority = false): string {
    if (noPriority) {
      // In the done column is always green.
      return 'card-priority--low';
    }

    if (!statusUpdateDate) {
      return 'card-priority--none';
    }

    const duration = differenceInHours(new Date(), new Date(statusUpdateDate));
    if (duration >= 48) {
      return 'card-priority--hight';
    }
    // from 24 to 48 hrs since last update
    if (duration > 24) {
      return 'card-priority--medium';
    }

    return 'card-priority--low';
  }
}
