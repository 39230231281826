import { Pipe, PipeTransform } from '@angular/core';
import {
  CONFERENCE_REGISTRATIONS_TECKET_STATUS_LOOKUP,
  ConferenceRegistrationTicketState,
} from 'ag-common-lib/lib/lists/conference-registration-ticket-state.enum';

@Pipe({ name: 'conferenceRegistrationTicketStatus' })
export class ConferenceRegistrationTicketStatusPipe implements PipeTransform {
  transform(status: ConferenceRegistrationTicketState, type: 'class' | 'text' = 'text'): string {
    if (!status) {
      return '';
    }
    const lookupByStatus = CONFERENCE_REGISTRATIONS_TECKET_STATUS_LOOKUP.find(lookup => lookup?.value === status);
    return type === 'class' ? lookupByStatus?.cssClass : lookupByStatus?.description;
  }
}
