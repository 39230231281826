export const REGISTRATS_GRID__TITLE_LIST = {
  first_name: 'Full Name (email)',
  agency_id: 'Agency',
  mga_id: 'MGA',
  conferenceRegistrationStatus: 'Registration Status',
  hotelInfoAssignedTo: 'Owner',
  hotelInfoStatus: 'Status',
  flightInfoAssignedTo: 'Owner',
  flightInfoStatus: 'Status',
  excursionsAssignedTo: 'Owner',
  excursionInfoStatus: 'Status',
  reservationInfoAssignedTo: 'Owner',
  reservationInfoStatus: 'Status',
  shippingAddress: 'Shipping Address',
  awardName: 'Plaque Name',
  isSendPlaque: 'Is Send Plaque',
  isSendPlaqueOwner: 'Owner',
};
