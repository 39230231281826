import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseFormService } from '../../utils/base-form-service';
import { updateDoc } from 'firebase/firestore';
import { Lookup, LookupKeys, Website } from '@ag-common-lib/public-api';

@Injectable()
export class WebsitesService extends BaseFormService<Website> {
  selectedWebsiteType$ = new BehaviorSubject(null);

  constructor() {
    super();
  }

  async onUpdateWebsites(): Promise<void> {
    const selectedWebsiteType = await this.selectedWebsiteType$?.value;
    if (selectedWebsiteType && !selectedWebsiteType?.isAssigned) {
      updateDoc(selectedWebsiteType?.reference, { [LookupKeys.isAssigned]: true }).then();
    }
  }

  setSelectedWebsiteTypeChange(lookup: Lookup): void {
    this.selectedWebsiteType$.next(lookup || null);
  }
}
