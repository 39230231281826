import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeFlyingGridComponent } from './attendee-flying-grid.component';
import { AgAssignOwnerViewerModule } from 'ag-common-svc/lib/components';
import { DxDropDownButtonModule } from 'devextreme-angular';
import { AttendeeFlightDataModalModule } from '../attendee-flight-data-modal/attendee-flight-data-modal.module';
import { AttendeeItineraryModalModule } from '../attendee-itinerary-modal/attendee-itinerary-modal.module';
import { AttendeeFlightCopyTargetsPipe } from './attendee-flight-copy-targets.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AgAssignOwnerViewerModule,
    DxDropDownButtonModule,
    AttendeeFlightDataModalModule,
    AttendeeItineraryModalModule,
  ],
  declarations: [AttendeeFlyingGridComponent, AttendeeFlightCopyTargetsPipe],
  exports: [AttendeeFlyingGridComponent, AttendeeFlightCopyTargetsPipe],
})
export class AttendeeFlyingGridModule {}
