import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Note } from 'ag-common-lib/public-api';
import { NoteService } from 'src/app/ag-crm/common/activity-info/note-log/note.service';

@Component({
  selector: 'app-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.scss'],
})
export class NoteFormComponent {
  @Input() note: Note;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor(public toastrService: ToastrService, public notesService: NoteService) {}

  save() {
    if (this.note.dbId) {
      this.notesService.update(this.note).then(() => {
        this.toastrService.success('Note Successfully Saved');
        this.notesService.refreshList();
        this.close();
      });
    } else {
      this.notesService.create(this.note).then(() => {
        this.toastrService.success('Note Successfully Created');
        this.notesService.refreshList();
        this.close();
      });
    }
  }

  close() {
    this.onClose.emit(true);
  }
}
