<div class="christmas-card-list__grid-container">
  <dx-data-grid
    class="christmas-card-list__component"
    [dataSource]="dataSource$ | async"
    [showBorders]="true"
    [focusedRowEnabled]="true"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    (onExporting)="onExporting($event)"
    (onRowDblClick)="showAgentDetails($event)"
    height="100%"
    width="100%"
  >
    <dxo-toolbar>
      <dxi-item location="before" width="100px">
        <div *dxTemplate>
          <h5 class="christmas-card-list__toolbar-title">Christmas Card List</h5>
        </div>
      </dxi-item>

      <dxi-item
        name="exportButton"
        cssClass="christmas-card-list__toolbar-control"
        [options]="{ icon: 'agi-file-csv' }"
      ></dxi-item>

      <dxi-item name="searchPanel" cssClass="christmas-card-list__toolbar-control"></dxi-item>
    </dxo-toolbar>

    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>

    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>

    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-scrolling mode="infinite" [showScrollbar]="'always'"></dxo-scrolling>
    <dxo-export [enabled]="true" [formats]="['csv']"></dxo-export>

    <dxi-column [dataField]="AgentKeys.p_email" [caption]="'Email'"></dxi-column>

    <dxi-column
      [dataField]="AgentKeys.phone_numbers"
      [calculateCellValue]="calculatePrimaryPhoneNumberCellValue"
      [caption]="'Phone Number'"
    ></dxi-column>

    <dxi-column [dataField]="AgentKeys.p_mga_id" [caption]="'MGA'" [allowExporting]="false">
      <dxo-lookup
        [dataSource]="agencies$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AgentKeys.p_agency_id" [caption]="'Agency'" [allowExporting]="false">
      <dxo-lookup
        [dataSource]="agencies$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AgentKeys.p_agent_first_name" [caption]="'First Name'"> </dxi-column>
    <dxi-column [dataField]="AgentKeys.p_agent_last_name" [caption]="'Last Name'"> </dxi-column>

    <dxi-column
      dataField="addresses"
      [calculateCellValue]="calculateAddressCellValue"
      [caption]="'Address'"
      [allowExporting]="false"
    ></dxi-column>

    <dxi-column
      dataField="shippingAddress.address1"
      [calculateCellValue]="calculateAddress1CellValue"
      [caption]="'Address 1'"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="shippingAddress.address2"
      [calculateCellValue]="calculateAddress2CellValue"
      [caption]="'Address 2'"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="shippingAddress.city"
      [calculateCellValue]="calculateCityCellValue"
      [caption]="'City'"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="shippingAddress.state"
      [calculateCellValue]="calculateStateCellValue"
      [caption]="'State'"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="shippingAddress.zip"
      [calculateCellValue]="calculateZipCellValue"
      [caption]="'Zip'"
      [visible]="false"
    ></dxi-column>
  </dx-data-grid>
</div>

<ng-container *ngIf="isAgentDetailsVisible">
  <dx-popup
    [width]="'100%'"
    [height]="'100%'"
    [showTitle]="true"
    [showCloseButton]="true"
    title="Agent Details"
    [dragEnabled]="false"
    [(visible)]="isAgentDetailsVisible"
  >
    <app-agent-editor
      [selectedAgent]="selectedAgent"
      (fullScreenClosed)="isAgentDetailsVisible = false"
    ></app-agent-editor>
  </dx-popup>
</ng-container>
