import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  DxAccordionModule,
  DxButtonModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxLoadIndicatorModule,
  DxPopupModule,
  DxProgressBarModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeListModule,
} from 'devextreme-angular';

import { AgentPipe } from './pipes/agent.pipe';
import { MultipleClickDirective } from './directives/multiple-clicks.directive';
import { TaskSubcategoriesPipe } from './pipes/task-subcategories.pipe';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { TruncatePipe } from './pipes/truncate.pipe';
import { RegistrantFieldFormatterPipe } from './pipes/conference-registrant-field-formatter.pipe';
import { EditorPermittedTabsPipe } from './pipes/agent-editor-permitted-tabs.pipe';

@NgModule({
  imports: [PipesModule],
  declarations: [
    AgentPipe,
    MultipleClickDirective,
    TaskSubcategoriesPipe,
    TruncatePipe,
    RegistrantFieldFormatterPipe,
    EditorPermittedTabsPipe,
  ],
  exports: [
    CommonModule,
    RouterModule,
    PipesModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxFormModule,
    DxDateBoxModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTabsModule,
    DxAccordionModule,
    DxDataGridModule,
    DxToolbarModule,
    DxTreeListModule,
    DxTagBoxModule,
    DxHtmlEditorModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxProgressBarModule,
    DxFileUploaderModule,
    DxTextAreaModule,
    DxFormModule,
    AgentPipe,
    MultipleClickDirective,
    TaskSubcategoriesPipe,
    TruncatePipe,
    RegistrantFieldFormatterPipe,
    EditorPermittedTabsPipe,
  ],
})
export class SharedModule {}
