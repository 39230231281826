import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationChannelSelectComponent } from './notification-channel-select.component';
import { NotificationChannelDataSourcePipe } from './notification-notification-channel-modal-data-source.pipe';
import { DxValidatorModule } from 'devextreme-angular';

@NgModule({
  declarations: [NotificationChannelSelectComponent, NotificationChannelDataSourcePipe],
  imports: [CommonModule, SharedModule, DxValidatorModule],
  exports: [NotificationChannelSelectComponent],
})
export class NotificationChannelSelectModule {}
