import { Component, Input } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-email-accordian-body-template',
  templateUrl: './email-accordian-body-template.component.html',
  styleUrls: ['./email-accordian-body-template.component.scss'],
})
export class EmailAccordianBodyTemplateComponent {
  @Input() template: any;

  getDate(date: Timestamp) {
    if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    } else {
      return '';
    }
  }
}
