<dx-form
  [formData]="registrantData"
  labelMode="floating"
  [validationGroup]="validationGroup"
  [screenByWidth]="formSize$ | async | formScreenByWidth"
  (onInitialized)="onFormInitialized($event)"
>
  <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
    <dxi-item
      [colSpan]="3"
      [dataField]="[RegistrantKeys.emergencyContact, AssociationKeys.firstName].join('.')"
      [label]="{ text: 'First Name' }"
      [editorOptions]="{
        readOnly: hasSelectedAssociationKey || isReadonly,
        inputAttr: { autocomplete: 'disabled' }
      }"
    >
    </dxi-item>

    <dxi-item
      [colSpan]="3"
      [dataField]="[RegistrantKeys.emergencyContact, AssociationKeys.lastName].join('.')"
      [label]="{ text: 'Last Name' }"
      [editorOptions]="{
        readOnly: hasSelectedAssociationKey || isReadonly,
        inputAttr: { autocomplete: 'disabled' }
      }"
    >
    </dxi-item>
  </dxi-item>

  <dxi-item
    itemType="group"
    [colCountByScreen]="colCountByScreen"
    *var="registrantData?.[RegistrantKeys.emergencyContact]  as emergencyContact"
  >
    <dxi-item
      [colSpan]="3"
      [dataField]="[RegistrantKeys.emergencyContact, AssociationKeys.associationType].join('.')"
      [label]="{ showColon: true }"
      [editorOptions]="{
        readOnly: isReadonly
      }"
    >
      <div *dxTemplate>
        <ag-shr-relationship-type-select-box
          [readOnly]="isReadonly"
          [(value)]="emergencyContact && emergencyContact[AssociationKeys.associationType]"
          [isRequired]="true"
          [validationGroup]="emergencyContactRelationshipTypeValidationGroup"
        ></ag-shr-relationship-type-select-box>
      </div>
    </dxi-item>

    <dxi-item
      [colSpan]="3"
      [dataField]="[RegistrantKeys.emergencyContact, AssociationKeys.contactNumber].join('.')"
      [label]="{ text: 'Phone Number' }"
      [editorOptions]="{
        mask: phoneMask,
        readOnly: isReadonly,
        valueChangeEvent: 'input'
      }"
    >
      <dxi-validation-rule
        type="stringLength"
        [min]="totalPhoneDigits"
        [max]="totalPhoneDigits"
        [ignoreEmptyValue]="true"
        [trim]="true"
        [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
      >
      </dxi-validation-rule>

      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_PHONE_TEXT"></dxi-validation-rule>
    </dxi-item>
  </dxi-item>
</dx-form>
