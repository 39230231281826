<div class="container p-0 m-0">
  <div class="row p-2 m-0 justify-content-start">
    <div class="col-12 p-0 m-0 text-start">
      <dx-tabs style="width: 100%" [dataSource]="tabs" [selectedIndex]="0" (onItemClick)="selectTab($event)"> </dx-tabs>
    </div>
  </div>
  <ng-template [ngIf]="selectedTab == 'Profile'">
    <app-profile-tab></app-profile-tab>
  </ng-template>
  <ng-template [ngIf]="selectedTab == 'Email'">
    <app-email-tab></app-email-tab>
  </ng-template>
  <ng-template [ngIf]="selectedTab == 'Calendar'">
    <app-calendar-tab></app-calendar-tab>
  </ng-template>
</div>
