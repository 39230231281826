<div class="image-uploader__widget-container">
  <ag-shr-image-with-loader
    class="image-uploader__image-container"
    [wasabiPath]="imageData?.[AGMediaKeys.wasabiPath]"
    [mediaSize]="MediaSize.origin"
    [cropperPayload]="imageData?.[ImageCropperKeys.imageCropperPayload]"
    [withBorder]="true"
    [borderRounded]="false"
  ></ag-shr-image-with-loader>

  <div class="image-uploader__button-container" *ngIf="!isReadonlyMode">
    <dx-button
      class="image-uploader__button"
      icon="edit"
      stylingMode="text"
      type="default"
      [hint]="imageData?.wasabiPath ? 'Change Picture' : 'Add Picture'"
      (onClick)="onUploadClicked()"
    ></dx-button>

    <dx-button
      *ngIf="imageData?.wasabiPath"
      class="image-uploader__button"
      icon="agi-crop"
      stylingMode="text"
      type="default"
      [hint]="'Crop Picture'"
      (onClick)="onEditClicked()"
    ></dx-button>
  </div>
</div>

<ag-shr-media-uploader
  [mediaPathPrefix]="fileDirectory"
  (mediaChanged)="onMediaChanged($event)"
  [mediaSources]="[MediaUploaderTabs.FromFile]"
></ag-shr-media-uploader>

<ag-shr-image-cropper-modal
  [onImageCropped]="onImageCropped"
  [roundCropper]="false"
  [maintainAspectRatio]="false"
  [title]="'Edit Thumbnail'"
></ag-shr-image-cropper-modal>
