import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceRegistrationCategoryName, RegistrantModelKeys } from 'ag-common-lib/public-api';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';

@Pipe({ name: 'conferenceRegistrationOwner' })
export class ConferenceRegistrationOwnerPipe implements PipeTransform {
  transform(categoryName: ConferenceRegistrationCategoryName) {
    return function (this: any, attendee: Attendee) {
      return attendee?.registrant?.task?.[categoryName]?.assignedTo;
    };
  }
}
