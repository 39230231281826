import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeFlightDataModalComponent } from './attendee-flight-data-modal.component';
import { ModalWindowModule } from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeFlightDataModalService } from './attendee-flight-data-modal.service';
import { DxDateRangeBoxModule } from 'devextreme-angular';
import { ConferencesPipesModule } from "../../../../pipes/conferences-pipes.module";
import { AttendeeNoteModalModule } from "../../attendee-note-modal/attendee-note-modal.module";
import {
    ConferenceRegistrationPipesModule
} from 'ag-common-svc/shared/pipes/conference-registration/conference-registration-pipes.module';
import {
    AttendeeFlightPipesModule
} from 'src/app/ag-crm/registrations/conferences/modals/attendee-details-modal/attendee-flight/pipes/attendee-flight-pipes.module';

@NgModule({
    imports: [ CommonModule, SharedModule, ModalWindowModule, DxDateRangeBoxModule, ConferencesPipesModule, AttendeeNoteModalModule, ConferenceRegistrationPipesModule, AttendeeFlightPipesModule ],
  declarations: [AttendeeFlightDataModalComponent],
  exports: [AttendeeFlightDataModalComponent],
  providers: [AttendeeFlightDataModalService],
})
export class AttendeeFlightDataModalModule {}
