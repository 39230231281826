import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Timestamp } from '@firebase/firestore';
import { ToastrService } from 'ngx-toastr';
import { DistributionList } from 'ag-common-lib/public-api';
import { AuthService } from 'ag-common-svc/public-api';
import { DistributionListService } from 'src/app/shared/services/distribution-list.service';
import { SessionControllerService } from 'src/app/shared/services/util/session-controller.service';

@Component({
  selector: 'app-distribution-list-info',
  templateUrl: './distribution-list-info.component.html',
  styleUrls: ['./distribution-list-info.component.scss'],
})
export class DistributionListInfoComponent implements OnInit {
  backButtonOptions;

  dropDownOptions;

  selectedDistributionList: DistributionList;

  constructor(
    public session: SessionControllerService,
    public distributionListService: DistributionListService,
    public toastrService: ToastrService,
    public authService: AuthService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.selectedDistributionList = this.session.selectedDistributionList;

    this.backButtonOptions = {
      icon: 'back',
      text: 'Lists',
      onClick: () => {
        this.router.navigate(['Distribution-Lists']);
      },
    };

    this.dropDownOptions = {
      text: 'Actions',
      width: 160,
      dropDownOptions: { width: 230 },
      items: ['View All Properties', 'View Property History'],
      onItemClick: () => {
        console.log('option button has been clicked!');
      },
    };
  }

  saveDistributionList() {
    if (this.selectedDistributionList.dbId) {
      this.distributionListService.update(this.selectedDistributionList).then(() => {
        this.toastrService.success('Distribution List Saved Successfully');
        this.router.navigate(['Distribution-Lists']);
      });
    } else {
      this.distributionListService.create(this.selectedDistributionList).then(() => {
        this.toastrService.success('Distribution List Created Successfully');
        this.router.navigate(['Distribution-Lists']);
      });
    }
  }

  getDate(timestamp: Timestamp): string {
    if (timestamp) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    } else {
      return '';
    }
  }
}
