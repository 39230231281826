import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { mergeMap, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app.model';
import { ARMAuthService } from './arm-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: ARMAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let queryParams = undefined;
    const locationHash = window.location.hash;
    if (locationHash) {
      const returnUrl = `${locationHash}`.replace('#/', '');
      queryParams = { returnUrl };
    }

    return this.authService.currentUser$.pipe(
      tap(currentUser => {
        if (currentUser === null) {
          this.router.navigate([AppRoutes.LoginForm], { queryParams });
        }
      }),

      mergeMap(() => this.authService.checkARMPermissions()),
      tap(hasPermissions => {
        if (!hasPermissions) {
          this.router.navigate([AppRoutes.LoginForm], { queryParams });
        }
      }),
    );
  }
}
