import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeChangeLogComponent } from './attendee-change-log.component';
import { AttendeeChangeLogService } from './attendee-change-log.service';
import { DxDataGridModule, DxDropDownBoxModule, DxListModule, DxTreeViewModule } from 'devextreme-angular';
import { DxoFilterRowModule } from 'devextreme-angular/ui/nested';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { LogsManagerModule } from '../../../../../../admin-crm/logs-manager/logs-manager.module';
import { SharedModule } from '../../../../../../shared/shared.module';
import { AgentsGridModule } from '../../../../../contacts/grids/agents-grid/agent-grid.module';
import { AttendeePersonalInfoLogDetailsModule } from './attendee-personal-info-log-details/attendee-personal-info-log-details.module';
import { ChangeLogPipesModule } from './pipes/change-log-pipes.module';
import { AttendeeExcursionsLogDetailsModule } from './attendee-excursions-log-details/attendee-excursions-log-details.module';
import { AttendeeFlightInfoLogDetailsModule } from './attendee-flight-info-log-details/attendee-flight-info-log-details.module';
import { AttendeeHotelReservationLogDetailsModule } from './attendee-hotel-reservation-log-details/attendee-hotel-reservation-log-details.module';
import { AttendeeGuestsLogDetailsModule } from './attendee-guests-log-details/attendee-guests-log-details.module';
import { AttendeeRsvpLogDetailsModule } from './attendee-rsvp-log-details/attendee-rsvp-log-details.module';
import { AttendeeChangeLogDetailByTypeModule } from './attendee-change-log-detail-by-type/attendee-change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeeChangeLogComponent],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxoFilterRowModule,
    PipesModule,
    LogsManagerModule,
    SharedModule,
    AgentsGridModule,
    DxTreeViewModule,
    DxDropDownBoxModule,
    DxListModule,
    ChangeLogPipesModule,
    AttendeeRsvpLogDetailsModule,
    AttendeePersonalInfoLogDetailsModule,
    AttendeeExcursionsLogDetailsModule,
    AttendeeFlightInfoLogDetailsModule,
    AttendeeHotelReservationLogDetailsModule,
    AttendeeGuestsLogDetailsModule,
    AttendeeChangeLogDetailByTypeModule,
  ],
  exports: [AttendeeChangeLogComponent],
  providers: [AttendeeChangeLogService],
})
export class AttendeeChangeLogModule {}
