import { Component } from '@angular/core';
import { Timestamp } from '@firebase/firestore';
import CustomStore from 'devextreme/data/custom_store';
import { DateTest, DateTestService } from './datetest.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent {
  dataSource = {};

  constructor(public dateTestService: DateTestService) {
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: function (loadOptions: any) {
        return dateTestService.getAll().then(dates => {
          dates.forEach(d => {
            if (d.date instanceof Timestamp) {
              d.date = new Date(d.date['seconds'] * 1000);
            }
          });

          return dates;
        });
      },
      insert: function (value: DateTest) {
        return dateTestService.create(value);
      },
      update: function (key: any, value: DateTest) {
        console.log(value);

        return dateTestService.update(value);
      },
      remove: function (id: any) {
        return dateTestService.delete(id);
      },
    });
  }

  onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
  }
}
