import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { EmailRequest, EmailPerson } from 'ag-common-lib/public-api';
import { ACTIVITY_TYPE, TARGET_TYPE, EMAIL_STATUS } from 'ag-common-lib/public-api';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { AuthService, FIREBASE_APP } from 'ag-common-svc/public-api';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailRequestService extends DataService<EmailRequest> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, public authService: AuthService) {
    super(fireBaseApp);
    super.setCollection('email-requests');
  }

  saveEmail(email: EmailRequest, cc?: EmailPerson[], bcc?: EmailPerson[]) {
    email.status = EMAIL_STATUS.READY;
    if (cc) email.cc = cc;
    if (bcc) email.bcc = bcc;
    return this.create(email);
  }

  async createNewSingleEmail(ownerId: string, recipient: EmailPerson[]) {
    let email = { ...new EmailRequest() };
    const agent = await firstValueFrom(this.authService.loggedInAgent$);
    email.activity_type = ACTIVITY_TYPE.EMAIL;
    email.sender_id = agent?.dbId;
    email.status = EMAIL_STATUS.SAVED;
    email.target_type = TARGET_TYPE.AGENT;
    email.target = ownerId;
    email.to = recipient;
    return Promise.resolve(email);
  }

  createNewDistributionEmail(): Promise<EmailRequest> {
    let email = { ...new EmailRequest() };

    return Promise.resolve(email);
  }
}
