import { Component, HostBinding } from '@angular/core';
import {
  AT_LEISURE_ITEM_TEXT,
  ATTENDEE_TYPE_LOOKUP,
  AttendeeKeys,
  ConferenceKeys,
  Constants,
  ExcursionOptionKey,
  SelectedExcursionConfiguration,
  SelectedExcursionOptions,
  TRUE_FALSE_LOOKUP,
} from 'ag-common-lib/public-api';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import {
  ExcursionInfoTableData,
  ExcursionInfoTableDataKeys,
} from 'ag-common-svc/lib/components/excursions-info-table/excursions-info-table.models';
import { isDate } from 'date-fns';
import { DatePipe } from '@angular/common';
import { getPhoneNumber } from 'ag-common-svc/lib/utils/phone-number-data.utils';
import { ConferenceExcursionStatisticService } from '../conference-excursion-statistic.service';
import { ExcursionConfigurationDisplayValuePipe } from 'ag-common-svc/shared/pipes/excursion-configuration-display-value.pipe';
import { ExcursionConfigurationDisplayNamePipe } from 'ag-common-svc/shared/pipes/excursion-configuration-display-name.pipe';

@Component({
  selector: 'ag-crm-conference-excursions-statistic-grid',
  templateUrl: './conference-excursion-statistic-grid.component.html',
  styleUrls: ['./conference-excursion-statistic-grid.component.scss'],
})
export class ConferenceExcursionStatisticGridComponent {
  @HostBinding('class') className = 'conference-excursions-statistic-grid';

  selectedExcursionsDataSource$ = this.conferenceExcursionStatisticService.selectedExcursionsDataSource$;
  private readonly columnsForExport = ['activity', AttendeeKeys.firstName, AttendeeKeys.lastName];

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly ExcursionInfoTableDataKeys = ExcursionInfoTableDataKeys;
  protected readonly AttendeeKeys = AttendeeKeys;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly emptyMessage: string = 'No Seat Remaining Currently Exist';
  protected readonly TRUE_FALSE_LOOKUP = TRUE_FALSE_LOOKUP;
  protected readonly attendeeTypeLookup = ATTENDEE_TYPE_LOOKUP;
  protected readonly AT_LEISURE_ITEM_TEXT = AT_LEISURE_ITEM_TEXT;
  readonly phoneMask: string = Constants.PHONE_MASK;
  readonly fullDateFormat: string = 'EEEE, LLLL d, yyyy';

  constructor(
    private conferenceExcursionStatisticService: ConferenceExcursionStatisticService,
    private datePipe: DatePipe,
    private excursionConfigurationDisplayValuePipe: ExcursionConfigurationDisplayValuePipe,
    private excursionConfigurationDisplayNamePipe: ExcursionConfigurationDisplayNamePipe,
  ) {}

  protected onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Excursions');
    const timeStamp = new Date().toJSON().slice(0, 19);
    const fullNameColumnVisible = e.component.columnOption(ExcursionInfoTableDataKeys.fullName).visible;

    e.component.beginUpdate();
    this.columnsForExport.forEach(item => {
      e.component.columnOption(item, 'visible', true);
    });
    e.component.columnOption(ExcursionInfoTableDataKeys.fullName, 'visible', false);

    const calculatePreferences = selectedPreferences =>
      Object.values(selectedPreferences)
        .map(({ title }) => title)
        .filter(Boolean)
        .join(', ');

    const calculateConfigurations = selectedConfig =>
      Object.values(selectedConfig)
        .map(({ title }) => {
          return title;
        })
        .filter(Boolean)
        .join(', ');

    let cellsToMerge = [];

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'group') {
          switch (gridCell.column.dataField) {
            case ExcursionInfoTableDataKeys.excursionDate:
              cellsToMerge.push(excelCell.address);
              excelCell.value = this.datePipe.transform(gridCell.value, this.fullDateFormat);
              break;

            case ExcursionInfoTableDataKeys.excursionName:
              cellsToMerge.push(excelCell.address);
              if (gridCell.value === AT_LEISURE_ITEM_TEXT) {
                excelCell.value = gridCell.value;
              }
              break;

            default:
              break;
          }
        }

        if (gridCell.rowType === 'data') {
          switch (gridCell.column.dataField) {
            case AttendeeKeys.firstName:
              excelCell.value = gridCell.data?.[ExcursionInfoTableDataKeys.fullName]?.split(' ')[0];
              break;

            case AttendeeKeys.lastName:
              excelCell.value = gridCell.data?.[ExcursionInfoTableDataKeys.fullName]?.split(' ')[1];
              break;

            case ExcursionInfoTableDataKeys.phoneNumber:
              excelCell.value = gridCell.value?.is_primary ? getPhoneNumber(gridCell.value) : '';
              break;

            case ExcursionInfoTableDataKeys.selectedPreferences:
              excelCell.value = calculatePreferences(gridCell.value);
              break;

            case ExcursionInfoTableDataKeys.configurations:
              excelCell.value = calculateConfigurations(gridCell.value);
              break;

            default:
              break;
          }
        }
      },
    }).then(() => {
      cellsToMerge.forEach(address => {
        const endAddress = address.replace(/^.{1}/g, 'D');
        const addressToMerge = `${address}:${endAddress}`;
        worksheet.mergeCells(addressToMerge);
      });
      workbook.xlsx
        .writeBuffer()
        .then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `Excursions_With_Seats_Remaining_${timeStamp}.xlsx`,
          );
        })
        .then(() => {
          this.columnsForExport.forEach(item => {
            e.component.columnOption(item, 'visible', false);
          });
          e.component.columnOption(ExcursionInfoTableDataKeys.fullName, 'visible', fullNameColumnVisible);
          e.component.endUpdate();
        });
    });
  }

  protected calculateSelectedPreferencesCellValue = (e: ExcursionInfoTableData) => {
    const selectedPreferences = e?.[ExcursionInfoTableDataKeys.selectedPreferences] ?? {};
    if (selectedPreferences.hasOwnProperty('preferences')) {
      // fix undefined on editing
      delete selectedPreferences['preferences'];
    }
    return this.calculateExcursionTitleRowData(selectedPreferences);
  };

  private calculateExcursionTitleRowData = (selectedPreferences: SelectedExcursionOptions) => {
    return Object.entries(selectedPreferences).map(([preferenceName, preferenceOption]) => {
      const title = `${preferenceName}: ${preferenceOption?.[ExcursionOptionKey.name]}`;
      return { title };
    });
  };

  protected setExcursionPreferenceCellValue = (updatedData, [path, selectedPreferenceOption], currentRowData) => {
    Object.assign(updatedData, {
      [ExcursionInfoTableDataKeys.selectedPreferences]: Object.assign(
        currentRowData?.[ExcursionInfoTableDataKeys.selectedPreferences],
        {
          [path]: selectedPreferenceOption,
        },
      ),
    });
  };

  protected calculateSeatRemainingSummary = options => {
    const calculateSeatsRemaining = () => {
      const data = options.value;
      const grid = options.component;
      const capacity = data?.[ExcursionInfoTableDataKeys.capacity] ?? 0;
      const excursionKey = data?.[ExcursionInfoTableDataKeys.excursionId];
      let seatRemaining = capacity;

      grid
        ?.getDataSource()
        ?.store()
        .load()
        .then(result => {
          const itemsCount = result.filter(({ excursionId, isPaid, isAdminSelected, bookingDate }) => {
            const isBookingDateValid = isDate(new Date(bookingDate));
            return excursionId === excursionKey && (isPaid || isAdminSelected || !isBookingDateValid);
          }).length;
          seatRemaining = capacity - Number(itemsCount);
        });

      options.totalValue = seatRemaining;
    };
    switch (options.name) {
      case 'excursionSeatsRemaining':
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
          calculateSeatsRemaining();
        }
        break;

      case 'excursionCapacity':
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
          options.totalValue = options.value?.[ExcursionInfoTableDataKeys.capacity] ?? 0;
        }
        break;

      default:
        break;
    }
  };

  calculateAge = (e): number => this.conferenceExcursionStatisticService.calculateAge(e);

  protected calculateExcursionConfigurationCellValue = (e: ExcursionInfoTableData) => {
    const details = e?.[ExcursionInfoTableDataKeys.configurations] ?? {};
    if (details.hasOwnProperty('configurations')) {
      delete details['preferences'];
    }
    return this.calculateExcursionDetailsTitleRowData(details);
  };

  private calculateExcursionDetailsTitleRowData = (details: SelectedExcursionConfiguration) => {
    return Object.entries(details).map(([name, option]) => {
      const displayName = this.excursionConfigurationDisplayNamePipe.transform(name);
      const displayValue = this.excursionConfigurationDisplayValuePipe.transform(option?.value, option?.dataType);
      const title = `${displayName}: ${displayValue}`;
      return { title };
    });
  };

  protected setExcursionDetailsCellValue = (updatedData, [path, selectedOption], currentRowData) => {
    Object.assign(updatedData, {
      [ExcursionInfoTableDataKeys.configurations]: Object.assign(
        currentRowData?.[ExcursionInfoTableDataKeys.configurations],
        {
          [path]: selectedOption,
        },
      ),
    });
  };
}
