import { Component, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { NotificationSchedulerEditModalService } from './notification-scheduler-edit-modal.service';
import {
  NotificationOptInGroup,
  NotificationOptInGroupKeys,
  notificationOptInGroupTypeMap,
  PushNotificationCampaign,
  PushNotificationCampaignKeys,
  PushNotificationKeys,
} from '@ag-common-lib/lib';
import { BaseModelKeys, Constants, Entity, EntityPermissionActivityKeys } from '@ag-common-lib/public-api';
import { PushNotificationsCampaignService, ScheduledPushNotificationsService } from 'ag-common-svc/public-api';
import { DxListComponent } from 'devextreme-angular/ui/list';
import { CustomItemCreatingEvent } from 'devextreme/ui/select_box';
import { NotificationAddRecipientsModalComponent } from '../../notification-add-recipients-modal/notification-add-recipients-modal.component';
import { NotificationNotificationChannelModalComponent } from '../../notification-notification-channel-modal/notification-notification-channel-modal.component';
import { AddNotificationOptInGroupModalComponent } from '../../add-notification-opt-in-group-modal/add-notification-opt-in-group-modal.component';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';

@Component({
  selector: 'ag-shr-notification-scheduler-edit-modal',
  templateUrl: './notification-scheduler-edit-modal.component.html',
  styleUrls: ['./notification-scheduler-edit-modal.component.scss'],
  providers: [NotificationSchedulerEditModalService, ScheduledPushNotificationsService],
})
export class NotificationSchedulerEditModalComponent {
  @ViewChild('notificationSchedulerEditModalRef', { static: false })
  protected notificationSchedulerEditModalComponent: ModalWindowComponent;

  @ViewChild('addRecipientsModalRef', { static: false })
  protected notificationAddRecipientsModalComponent: NotificationAddRecipientsModalComponent;

  @ViewChild('notificationNotificationChannelModalRef', { static: false })
  protected notificationNotificationChannelModalComponent: NotificationNotificationChannelModalComponent;

  @ViewChild('addOptInGroupModalRef', { static: false })
  protected addNotificationOptInGroupModalComponent: AddNotificationOptInGroupModalComponent;

  @ViewChild(DxListComponent, { static: false }) listComponent: DxListComponent;

  protected inProgress$ = this.notificationSchedulerEditModalService.inProgress$;
  protected Entity = Entity;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected BaseModelKeys = BaseModelKeys;
  protected PushNotificationKeys = PushNotificationKeys;
  protected PushNotificationCampaignKeys = PushNotificationCampaignKeys;
  protected NotificationOptInGroupKeys = NotificationOptInGroupKeys;
  protected notificationOptInGroupTypeMap = notificationOptInGroupTypeMap;
  protected validationGroup = 'validationGroup';
  protected scheduledNotification: PushNotificationCampaign;
  protected Constants = Constants;
  protected dateNow = new Date();
  protected notificationsDataSource$ = this.pushNotificationsCampaignService.getList();
  protected addOptions = [
    { icon: 'checklist', text: 'From List', onClick: () => this.notificationAddRecipientsModalComponent.showModal() },
    {
      icon: 'agi-bullhorn',
      text: 'Channel',
      onClick: () => this.notificationNotificationChannelModalComponent.showModal(),
    },
    { icon: 'agi-people-group', text: 'Opt-In Group' },
  ];
  protected isDeliveredNotification$ = this.notificationSchedulerEditModalService.isDeliveredNotification$;

  constructor(
    private cloudFunctionsService: CloudFunctionsService,
    protected notificationSchedulerEditModalService: NotificationSchedulerEditModalService,
    private pushNotificationsCampaignService: PushNotificationsCampaignService,
  ) {}

  showModal = async (pushNotificationCampaign: PushNotificationCampaign): Promise<void> => {
    this.scheduledNotification = this.notificationSchedulerEditModalService.getFormData(pushNotificationCampaign);
    this.notificationSchedulerEditModalComponent?.showModal();
  };

  protected handleScheduleNotification = async () => {
    const isValid = await validateDxGroups(this.validationGroup);

    if (isValid) {
      await this.notificationSchedulerEditModalService.handleSave();
      this.notificationSchedulerEditModalComponent.forceCloseModal();
    }
  };

  protected sendNotification = async () => {
    await this.cloudFunctionsService.sendPushNotification(this.scheduledNotification);
  };

  protected cloneNotification = this.notificationSchedulerEditModalService.cloneNotification;

  protected onValueChanged = e => {
    const dataToCopy = [
      PushNotificationCampaignKeys.title,
      PushNotificationCampaignKeys.description,
      PushNotificationCampaignKeys.notification,
    ].reduce((acc, item) => {
      acc[item] = e[item] ?? null;
      return acc;
    }, {});
    Object.assign(this.scheduledNotification, dataToCopy);
  };

  protected onCustomItemCreating = (e: CustomItemCreatingEvent, notificationSelectBoxRef) => {
    const title = e?.text;

    if (!title) {
      Object.assign(e, { customItem: null });
      return;
    }

    const customItem = { [PushNotificationCampaignKeys.title]: title };
    [PushNotificationCampaignKeys.description, PushNotificationCampaignKeys.notification].forEach(key => {
      customItem[key] = this.scheduledNotification?.[key] ?? null;
    });

    Object.assign(e, { customItem });

    setTimeout(() => {
      notificationSelectBoxRef.selectedItem = customItem;
    });
  };

  protected showAddRecipientsModal = () => {
    this.notificationAddRecipientsModalComponent.showModal();
  };

  protected showAddChannelModal = () => {
    this.notificationNotificationChannelModalComponent.showModal();
  };

  protected showAddNotificationOptInGroupModal = () => {
    this.addNotificationOptInGroupModalComponent.showModal();
  };

  protected handleAddRecipients = newRecipients => {
    const recipientsSet = new Set(this.scheduledNotification?.[PushNotificationCampaignKeys.recipients] ?? []);
    newRecipients?.forEach(recipient => {
      recipientsSet.add(recipient);
    });

    this.scheduledNotification[PushNotificationCampaignKeys.recipients] = Array.from(recipientsSet);
  };

  protected handleAddChannel = (channel: string) => {
    const notificationChannels = this.scheduledNotification?.[PushNotificationCampaignKeys.channels] ?? [];

    this.scheduledNotification[PushNotificationCampaignKeys.channels] = [...notificationChannels, channel];
  };

  protected handleOptInGroup = (optInGroup: NotificationOptInGroup) => {
    const notificationChannels = this.scheduledNotification?.[PushNotificationCampaignKeys.optInGroups] ?? [];

    this.scheduledNotification[PushNotificationCampaignKeys.optInGroups] = [...notificationChannels, optInGroup];
  };
}
