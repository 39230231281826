<ng-container *var="selectedAgent$ | async as selectedAgent">
  <ag-shr-modal-window
    #salesGoalsModalRef
    [title]="'Sales Goals'"
    [width]="800"
    [height]="350"
    [showSaveButton]="false"
    [inProgress]="inProgress$ | async"
  >
    <dx-data-grid
      [width]="'100%'"
      height="100%"
      [dataSource]="selectedAgent | agentSalesGoalsGridSource : selectedTab$.value"
      [sorting]="{ mode: 'none' }"
      [showBorders]="true"
      [showRowLines]="true"
      [showColumnLines]="true"
      [noDataText]="emptyMessage"
      (onEditorPreparing)="onEditorPreparingGoals($event)"
      (onRowRemoving)="onGoalRowRemoving($event)"
      (onRowInserting)="onGoalInserting($event)"
      (onRowUpdating)="onGoalRowUpdating($event)"
    >
      <dxo-editing [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true"></dxo-editing>

      <dxo-toolbar>
        <dxi-item location="before" widget="dxSelectBox">
          <div *dxTemplate>
            <dx-select-box
              [value]="selectedTab$ | async"
              placeholder="Select..."
              width="300"
              [valueExpr]="LookupKeys.value"
              [displayExpr]="LookupKeys.description"
              [dataSource]="salesGoalsLookup"
              (onValueChanged)="selectedTab$.next($event.value)"
            ></dx-select-box>
          </div>
        </dxi-item>

        <dxi-item name="addRowButton"></dxi-item>
      </dxo-toolbar>

      <dxi-column [dataField]="GoalKeys.year" [alignment]="'center'" [sortOrder]="'desc'">
        <dxo-lookup [dataSource]="years"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column
        [dataField]="GoalKeys.amount"
        [alignment]="'center'"
        [dataType]="'number'"
        [format]="DX_USD_CURRENCY_FORMAT"
        [editorOptions]="{ format: DX_USD_CURRENCY_FORMAT, min: 0 }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>
  </ag-shr-modal-window>
</ng-container>
