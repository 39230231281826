import { Pipe, PipeTransform } from '@angular/core';
import { Conference, ConferenceRegistrationStepName } from 'ag-common-lib/public-api';
import { sectionControlKeysMap } from '../../conference-modal.models';

@Pipe({
  name: 'conferenceStepStateKey',
  pure: false,
})
export class ConferenceStepStateKeyPipe implements PipeTransform {
  transform(stepId: ConferenceRegistrationStepName, conference: Conference): any {
    if (!stepId) {
      return null;
    }

    const keys = sectionControlKeysMap[stepId];

    if (!keys) {
      return null;
    }

    const activeKey = keys.activeKey;
    const enableKey = keys.enableKey;
    const isActive = conference?.[activeKey];
    const isEnabled = conference?.[enableKey];

    return {
      activeKey,
      enableKey,
      isActive,
      isEnabled,
    };
  }
}
