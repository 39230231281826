<div class="mt-2">
  <ng-container *ngTemplateOutlet="grid; context: { isEdit: false }"></ng-container>
</div>

<ag-shr-modal-window
  #websitesEditorModalRef
  [title]="'Add / Edit Websites'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="false"
>
  <ng-container *ngTemplateOutlet="grid; context: { isEdit: true }"></ng-container>
</ag-shr-modal-window>

<ng-template #grid let-isEdit="isEdit">
  <dx-data-grid
    #dataGridRef
    class="websites__data-grid"
    [class.websites__data-grid--view]="!isEdit"
    [dataSource]="websites"
    [showRowLines]="isEdit"
    [showBorders]="isEdit"
    [showColumnLines]="isEdit"
    [showColumnHeaders]="isEdit"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
    height="100%"
  >
    <dxo-toolbar>
      <dxi-item [visible]="!isEdit" location="before">
        <div *dxTemplate>
          <div class="toolbar-label">
            <b>Websites</b>
          </div>
        </div>
      </dxi-item>

      <dxi-item
        [visible]="!isEdit && canEdit"
        location="after"
        widget="dxButton"
        [options]="{ icon: 'edit', stylingMode: 'text', onClick: showWebsitesEditorModal }"
      ></dxi-item>

      <dxi-item [visible]="isEdit && canCreate" name="addRowButton"></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowUpdating]="canEdit"
      [allowDeleting]="canDelete"
      [allowAdding]="canCreate"
    >
    </dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column [dataField]="WebsiteKeys.url" [width]="'50%'">
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_URL_TEXT"></dxi-validation-rule>
      <dxi-validation-rule
        type="custom"
        [validationCallback]="validateUrlWithOrWithoutProtocol"
        [message]="Messages.INVALID_URL_FORMAT_TEXT"
      >
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column
      [dataField]="WebsiteKeys.websiteType"
      caption="Type"
      [editorOptions]="{ onSelectionChanged: onWebsiteTypeChanged }"
    >
      <dxo-lookup
        [dataSource]="websiteTypesLookup"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="BaseModelKeys.dbId"
      ></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column type="buttons" [visible]="isEdit">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</ng-template>
