<ag-shr-modal-window
  #campaignsInfoModalRef
  [width]="'95vw'"
  [height]="'90vh'"
  title="Campaigns Info"
  [showSaveButton]="false"
  [useScrollView]="false"
  [extraToolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'bottom',
      visible: isActionsVisible$ | async,
      disabled: inProgress$ | async,
      options: {
        text: 'COMPLETE TASK',
        type: 'default',
        hint: 'All tasks should be done before complete',
        onClick: completeTaskConfirmation
      }
    }
  ]"
>
  <div *ngIf="campaignsInfoModalRef?.popupComponent?.visible ?? false" class="campaign-details-modal__container">
    <dx-tabs
      [items]="detailsTabs | editorPermittedTabs | async"
      [selectedIndex]="selectedIndex"
      (onItemClick)="setDetailsTab($event.itemData.template)"
    ></dx-tabs>

    <div class="ag-campaigns-details-modal campaign-details-modal__content-container">
      <ng-template [ngIf]="selectedDetailsTab == DetailsTab.campaignsInfo">
        <dx-scroll-view height="100%">
          <div class="ag-campaign-summary-list">
            <div *ngIf="details?.[AgentCampaignStepName.support]">
              <ag-shr-agent-campaign-summary-list-item [title]="'Support Request'">
                <ng-container agCampaignsActions>
                  <ng-container
                    *ngTemplateOutlet="stepControlsTmp; context: { stepName: AgentCampaignStepName.support }"
                    agCampaignsControls
                  ></ng-container>
                </ng-container>
                <p>
                  {{details?.[AgentCampaignStepName.support]?.incomingStepData }}
                </p>
              </ag-shr-agent-campaign-summary-list-item>
            </div>

            <ag-shr-agent-campaign-summary-web-site
              *ngIf="details | campaignsDetailsModalStepVisible : [AgentCampaignStepName.webSite]"
              [stepData]="details?.[AgentCampaignStepName.webSite]"
            >
              <ng-container
                *ngTemplateOutlet="stepControlsTmp; context: { stepName: AgentCampaignStepName.webSite }"
                agCampaignsControls
              ></ng-container>
            </ag-shr-agent-campaign-summary-web-site>

            <ag-shr-agent-campaign-summary-contact-info
              *ngIf="
                details
                  | campaignsDetailsModalStepVisible
                    : [
                        AgentCampaignStepName.contactInfoAddress,
                        AgentCampaignStepName.contactInfoPhoneNumber,
                        AgentCampaignStepName.contactInfoEmailAddress
                      ]
              "
              [stepDataAddress]="details?.[AgentCampaignStepName.contactInfoAddress]"
              [stepDataPhoneNumber]="details?.[AgentCampaignStepName.contactInfoPhoneNumber]"
              [stepDataEmailAddress]="details?.[AgentCampaignStepName.contactInfoEmailAddress]"
            >
              <ng-container
                *ngTemplateOutlet="
                  stepControlsTmp;
                  context: { stepName: AgentCampaignStepName.contactInfo, withIssuesControl: false }
                "
                agCampaignsControls
              ></ng-container>

              <ng-container
                *ngTemplateOutlet="
                  stepControlsTmp;
                  context: { stepName: AgentCampaignStepName.contactInfoAddress, withDoneControl: false }
                "
                agAddressControls
              ></ng-container>

              <ng-container
                *ngTemplateOutlet="
                  stepControlsTmp;
                  context: { stepName: AgentCampaignStepName.contactInfoPhoneNumber, withDoneControl: false }
                "
                agPhoneNumberControls
              ></ng-container>

              <ng-container
                *ngTemplateOutlet="
                  stepControlsTmp;
                  context: { stepName: AgentCampaignStepName.contactInfoEmailAddress, withDoneControl: false }
                "
                agEmailControls
              ></ng-container>
            </ag-shr-agent-campaign-summary-contact-info>

            <ag-shr-agent-campaign-summary-social-media
              *ngIf="details | campaignsDetailsModalStepVisible : [AgentCampaignStepName.socialMedia]"
              [stepData]="details?.[AgentCampaignStepName.socialMedia]"
            >
              <ng-container
                *ngTemplateOutlet="stepControlsTmp; context: { stepName: AgentCampaignStepName.socialMedia }"
                agCampaignsControls
              ></ng-container>
            </ag-shr-agent-campaign-summary-social-media>

            <ag-shr-agent-campaign-summary-bio
              *ngIf="details | campaignsDetailsModalStepVisible : [AgentCampaignStepName.bio]"
              [stepData]="details?.[AgentCampaignStepName.bio]"
            >
              <ng-container
                *ngTemplateOutlet="stepControlsTmp; context: { stepName: AgentCampaignStepName.bio }"
                agCampaignsControls
              ></ng-container>
            </ag-shr-agent-campaign-summary-bio>

            <div *ngIf="details?.[AgentCampaignStepName.logoHeadshot]" class="campaign-details-modal__images-step">
              <ag-shr-agent-campaign-summary-list-item [title]="'Images'">
                <ng-container agCampaignsActions>
                  <ng-template #imageExternalControls>
                    <dx-drop-down-button
                      class="campaign-details-modal__download-link"
                      [showArrowIcon]="false"
                      [dropDownOptions]="{ width: 230 }"
                      (onItemClick)="logAction($event)"
                      icon="download"
                      stylingMode="text"
                      [items]="downloadOptions"
                    >
                    </dx-drop-down-button>
                  </ng-template>

                  <ng-container
                    *ngTemplateOutlet="
                      stepControlsTmp;
                      context: { stepName: AgentCampaignStepName.logoHeadshot, externalControls: imageExternalControls }
                    "
                    agCampaignsControls
                  >
                  </ng-container>
                </ng-container>

                <div class="campaign-details-modal__images-container">
                  <ag-shr-agent-campaign-summary-logo-headshot
                    [stepData]="details?.[AgentCampaignStepName.logoHeadshot]"
                    [title]="'Logo Image'"
                    [imageKey]="'logo'"
                  ></ag-shr-agent-campaign-summary-logo-headshot>

                  <ag-shr-agent-campaign-summary-logo-headshot
                    [stepData]="details?.[AgentCampaignStepName.logoHeadshot]"
                    [title]="'Headshot'"
                    [imageKey]="'headshot'"
                  ></ag-shr-agent-campaign-summary-logo-headshot>

                  <ag-shr-agent-campaign-summary-logo-headshot
                    [stepData]="details?.[AgentCampaignStepName.logoHeadshot]"
                    [title]="'Favicon'"
                    [imageKey]="'favicon'"
                  ></ag-shr-agent-campaign-summary-logo-headshot>

                  <ag-shr-agent-campaign-summary-logo-headshot
                    [stepData]="details?.[AgentCampaignStepName.logoHeadshot]"
                    [title]="'Social Sharing Image'"
                    [imageKey]="'socialSharingImage'"
                  ></ag-shr-agent-campaign-summary-logo-headshot>
                </div>
              </ag-shr-agent-campaign-summary-list-item>
            </div>

            <ag-shr-agent-campaign-summary-appointment
              *ngIf="details | campaignsDetailsModalStepVisible : [AgentCampaignStepName.appointment]"
              [stepData]="details?.[AgentCampaignStepName.appointment]"
            >
              <ng-container
                *ngTemplateOutlet="stepControlsTmp; context: { stepName: AgentCampaignStepName.appointment }"
                agCampaignsControls
              ></ng-container>
            </ag-shr-agent-campaign-summary-appointment>

            <ag-shr-agent-campaign-summary-extra
              *ngIf="details | campaignsDetailsModalStepVisible : [AgentCampaignStepName.extras]"
              [stepData]="details?.[AgentCampaignStepName.extras]"
            >
              <ng-container
                *ngTemplateOutlet="stepControlsTmp; context: { stepName: AgentCampaignStepName.extras }"
                agCampaignsControls
              ></ng-container>
            </ag-shr-agent-campaign-summary-extra>
          </div>
        </dx-scroll-view>
      </ng-template>

      <ng-template [ngIf]="selectedDetailsTab == DetailsTab.issues">
        <ag-crm-campaign-issues-grid
          #campaignIssuesGridRef
          caption="Issues"
          [issues]="taskIssues$ | async"
          [canReportIssue]="isActionsVisible$ | async"
          (openEditIssueModal)="showEditIssuePopup($event)"
        ></ag-crm-campaign-issues-grid>
      </ng-template>
    </div>
  </div>
</ag-shr-modal-window>

<ng-template
  #stepControlsTmp
  let-stepName="stepName"
  let-withDoneControl="withDoneControl"
  let-withIssuesControl="withIssuesControl"
  let-externalControls="externalControls"
>
  <div class="ag-campaign-summary-list__controls" *ngIf="isActionsVisible$ | async">
    <dx-button
      [visible]="Entity.campaignsTaskIssues | hasPermission : EntityPermissionActivityKeys.create | async"
      *ngIf="withIssuesControl ?? true"
      class="report-button"
      icon="agi-tools"
      hint="Report Issue"
      stylingMode="outlined"
      type="danger"
      (onClick)="showAddIssuePopupByField(stepName)"
    ></dx-button>

    <ng-container *ngIf="externalControls">
      <ng-container *ngTemplateOutlet="externalControls"></ng-container>
    </ng-container>

    <dx-check-box
      *ngIf="withDoneControl ?? true"
      [value]="details | campaignsDetailsModalIsStepDone : stepName"
      hint="Is Step Done?"
      (onValueChanged)="onStepDoneChanged(stepName, $event.value)"
    ></dx-check-box>
  </div>
</ng-template>

<ag-crm-campaign-issues-modal #campaignIssuesModalRef (updateIssues)="refreshIssues()"></ag-crm-campaign-issues-modal>
