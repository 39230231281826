import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { PushNotificationChannelsKeys } from '@ag-common-lib/lib';
import { BaseModelKeys } from '@ag-common-lib/public-api';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';

@Component({
  selector: 'ag-shr-notification-notification-channel-modal',
  templateUrl: './notification-notification-channel-modal.component.html',
  styleUrls: ['./notification-notification-channel-modal.component.scss'],
})
export class NotificationNotificationChannelModalComponent {
  @HostBinding('class') className = 'notification-notification-channel-modal';

  @ViewChild('notificationNotificationChannelModalRef', { static: false })
  protected notificationNotificationChannelModalComponent: ModalWindowComponent;

  @Input() title = 'Add Channel';
  @Output() handleChannel = new EventEmitter<string>();

  protected channel = null;
  protected BaseModelKeys = BaseModelKeys;
  protected PushNotificationChannelsKeys = PushNotificationChannelsKeys;
  protected validationGroup = 'validationGroup';

  constructor() {}

  showModal = async (): Promise<void> => {
    this.channel = null;
    this.notificationNotificationChannelModalComponent?.showModal();
  };

  protected addNotificationRecipients = async () => {
    const isValid = await validateDxGroups(this.validationGroup);

    if (isValid) {
      this.handleChannel.emit(this.channel);
      this.notificationNotificationChannelModalComponent.forceCloseModal();
    }
  };
}
