import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { IdleComponent } from './idle.component';
import { IdleService } from './idle.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [IdleComponent],
  exports: [IdleComponent],
  providers: [IdleService],
})
export class IdleModule {}
