<ag-shr-transactions-history
  [transactions]="registrantTransactions$ | async"
  [editMode]="true"
  [canCreateTransaction]="Entity.conferenceTransactions | hasPermission : EntityPermissionActivityKeys.create | async"
  [canEditTransaction]="Entity.conferenceTransactions | hasPermission : EntityPermissionActivityKeys.write | async"
  [canDeleteTransaction]="Entity.conferenceTransactions | hasPermission : EntityPermissionActivityKeys.delete | async"
  [billingDetails]="billingDetails$ | async"
  [handleSaveTransaction]="handleSaveTransaction"
  [handleTransactionRemoving]="handleTransactionRemoving"
></ag-shr-transactions-history>
