import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeItineraryModalComponent } from './attendee-itinerary-modal.component';
import { ModalWindowModule } from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeItineraryModalService } from './attendee-itinerary-modal.service';
import { DxDateRangeBoxModule } from 'devextreme-angular';
import { ConferenceRegistrationPipesModule } from 'ag-common-svc/shared/pipes/conference-registration/conference-registration-pipes.module';
import { AttendeeFlightPipesModule } from '../pipes/attendee-flight-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    DxDateRangeBoxModule,
    ConferenceRegistrationPipesModule,
    AttendeeFlightPipesModule,
  ],
  declarations: [AttendeeItineraryModalComponent],
  exports: [AttendeeItineraryModalComponent],
  providers: [AttendeeItineraryModalService],
})
export class AttendeeItineraryModalModule {}
