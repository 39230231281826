import { Pipe, PipeTransform } from '@angular/core';
import { PushNotificationChannel, PushNotificationChannelsKeys } from '@ag-common-lib/lib';

@Pipe({
  name: 'notificationChannelDataSource',
})
export class NotificationChannelDataSourcePipe implements PipeTransform {
  transform(channels: PushNotificationChannel[] = [], selectedParentChannelDbId?: string): PushNotificationChannel[] {
    if (!channels) {
      return [];
    }
    return channels
      .filter(channel => {
        const parentChannelDbId = channel?.[PushNotificationChannelsKeys.parentChannelDbId];
        if (!selectedParentChannelDbId) {
          return !channel?.[PushNotificationChannelsKeys.parentChannelDbId];
        }

        return selectedParentChannelDbId === parentChannelDbId;
      })
      .sort((left, right) => {
        const leftValue = left?.[PushNotificationChannelsKeys.name] ?? '';
        const rightValue = right?.[PushNotificationChannelsKeys.name] ?? '';

        return leftValue.localeCompare(rightValue, 'en', {
          numeric: true,
          sensitivity: 'base',
          ignorePunctuation: true,
        });
      });
  }
}
