import { ConferenceRegistrationStepName, } from 'ag-common-lib/public-api';

export const CONFERENCE_STEPS_SECTIONS: {
  id: ConferenceRegistrationStepName;
  title: string;
}[] = [
  {
    id: ConferenceRegistrationStepName.rsvpStep,
    title: 'RSVP Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantPersonalInformationStep,
    title: 'Personal Information Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantGuestConfigurationStep,
    title: 'Guest Registration Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantHotelReservationStep,
    title: 'Hotel Reservation Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantFlightInformationStep,
    title: 'Flight Information Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantFlightYourSelectionStep,
    title: 'Your Flight Selection Step',
  },
  {
    id: ConferenceRegistrationStepName.registrationGuestFlightInformationStep,
    title: 'Guest Flight Information Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantExcursionsStep,
    title: 'Excursions Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantExcursionYourSelectionStep,
    title: 'Your Excursions Step',
  },
  {
    id: ConferenceRegistrationStepName.registrantPaymentStep,
    title: 'Payment Step',
  },
  {
    id: ConferenceRegistrationStepName.registrationConfirmationStep,
    title: 'Confirmation Step',
  },
  {
    id: ConferenceRegistrationStepName.registrationSummaryStep,
    title: 'Summary Step',
  },
  {
    id: ConferenceRegistrationStepName.registrationDeclinedSummaryStep,
    title: 'Declined Summary Step',
  },
];
