import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AgentService } from '../../services/agent.service';

@Injectable()
export class AgentSalesGoalsModalService {
  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  constructor(private toastr: ToastrService, private agentService: AgentService) {}

  async updateGoal(agentDbId: string, update: any): Promise<void> {
    this._inProgress$.next(true);
    return await this.agentService
      .updateFields(agentDbId, update)
      .then(() => {
        return;
      })
      .catch(err => {
        this.toastr.error('Agent Sales Goals Update Failed!');
        throw new Error();
      })
      .finally(() => {
        this._inProgress$.next(false);
      });
  }
}
