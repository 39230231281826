<ag-crm-attendee-guest-modal #attendeeGuestModalComponent></ag-crm-attendee-guest-modal>

<dx-data-grid
  #guestGridRef
  class="conference-registration-guests-configuration__guests-grid ag-portal-grid mt-2"
  [dataSource]="registrantGuests$ | async"
  keyExpr="dbId"
  (onRowRemoving)="onRowRemoving($event)"
  [showBorders]="true"
  height="100%"
>
  <dxo-paging [enabled]="false"></dxo-paging>

  <dxo-toolbar>
    <dxi-item
      location="before"
      [visible]="
        (isRequestedAdditionalGuest$ | async) && Entity.conferenceAttendeeDetails
          | hasPermission: EntityPermissionActivityKeys.write
          | async
      "
    >
      <div *dxTemplate class="attendee-details-modal__request-container">
        <b>Additional Guest Requested.</b>

        <dx-button
          type="success"
          icon="agi-thumbs-up"
          class="inline-editor-control ms-2"
          hint="Approve Request"
          [ngClass]="additionalGuestRequestOutcome$ | async | requestOutcomeIconClass: RequestOutcomeState.approved"
          (onClick)="handleApproveAdditionalGuestRequest()"
        ></dx-button>

        <dx-button
          type="danger"
          stylingMode="outlined"
          icon="agi-thumbs-down"
          class="inline-editor-control ms-2"
          hint="Reject Request"
          [ngClass]="additionalGuestRequestOutcome$ | async | requestOutcomeIconClass: RequestOutcomeState.rejected"
          (onClick)="handleRejectAdditionalGuestRequest()"
        ></dx-button>

        <i *ngIf="additionalGuestRequestOutcome$ | async">{{
          additionalGuestRequestOutcome$ | async | requestOutcomeResult
        }}</i>

        <ag-shr-modal-window
          #approveAdditionalGuestRequestModalRef
          [width]="450"
          [height]="'auto'"
          title="Approve Request"
          [actionTitle]="'Confirm'"
          [useScrollView]="false"
          [inProgress]="requestInProgress$ | async"
          (onSaveClick)="handleAdditionalGuestRequestOutcome(RequestOutcomeState.approved)"
        >
          <ng-container *ngIf="approveAdditionalGuestRequestModalRef?.popupComponent?.visible ?? false">
            <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
          </ng-container>
        </ag-shr-modal-window>

        <ag-shr-modal-window
          #rejectAdditionalGuestRequestModalRef
          [width]="450"
          [height]="'auto'"
          title="Reject Request"
          [actionTitle]="'Confirm'"
          [useScrollView]="false"
          [inProgress]="requestInProgress$ | async"
          (onSaveClick)="handleAdditionalGuestRequestOutcome(RequestOutcomeState.rejected)"
        >
          <ng-container *ngIf="rejectAdditionalGuestRequestModalRef?.popupComponent?.visible ?? false">
            <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
          </ng-container>
        </ag-shr-modal-window>
      </div>
    </dxi-item>

    <dxi-item
      location="after"
      widget="dxButton"
      [options]="{ icon: 'plus', onClick: showAddGuestPopup }"
      [visible]="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.create | async"
    ></dxi-item>
  </dxo-toolbar>

  <dxo-editing
    mode="popup"
    [useIcons]="true"
    [allowUpdating]="false"
    [allowDeleting]="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.delete | async"
    [allowAdding]="false"
  >
  </dxo-editing>

  <dxi-column [dataField]="BaseModelKeys.dbId" caption="Wizard Step State" cellTemplate="wizardGuestStepStateCellTmp">
    <dxo-lookup
      [dataSource]="wizardGuestStepStateLookup$ | async"
      [displayExpr]="LookupKeys.description"
      [valueExpr]="LookupKeys.value"
    ></dxo-lookup>

    <div *dxTemplate="let cell of 'wizardGuestStepStateCellTmp'">
      {{ WizardStepStateMap.get(cell.displayValue | empty: WizardStepState.initial) }}
    </div>
  </dxi-column>

  <dxi-column [dataField]="AssociationKeys.firstName" caption="First Name"></dxi-column>

  <dxi-column [dataField]="AssociationKeys.lastName" caption="Last Name"></dxi-column>

  <dxi-column [dataField]="GuestKeys.badgeName" caption="Badge Name"></dxi-column>

  <dxi-column
    [dataField]="AssociationKeys.dob"
    [caption]="'Date of Birth'"
    dataType="date"
    [format]="dateFormat"
    [visible]="false"
  ></dxi-column>

  <dxi-column
    [dataField]="GuestKeys.isComplimentary"
    caption="Complimentary or Additional?"
    width="250"
    alignment="center"
  >
    <dxo-lookup
      [dataSource]="complimentaryOrAdditionalLookup"
      [displayExpr]="LookupKeys.description"
      [valueExpr]="LookupKeys.value"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="AssociationKeys.associationType"
    caption="Relationship to Attendee:"
    [editCellTemplate]="'associationTypeTemp'"
    [visible]="false"
  >
    <div *dxTemplate="let cell of 'associationTypeTemp'">
      {{ cell.value }}
      <ag-shr-relationship-type-select-box
        [isRequired]="true"
        [value]="cell.value"
        (selectedItemChange)="cell.setValue($event)"
      ></ag-shr-relationship-type-select-box>
    </div>
  </dxi-column>

  <dxi-column
    type="buttons"
    *var="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
  >
    <dxi-button
      [icon]="canWrite ? 'edit' : 'agi-eye'"
      [hint]="canWrite ? 'Edit' : 'View'"
      [onClick]="showEditPopup"
    ></dxi-button>

    <dxi-button name="delete"></dxi-button>

    <dxi-button
      icon="check"
      hint="Mark step as Done"
      [visible]="getIsCompleteStepVisible && canWrite"
      [onClick]="completeStep"
    ></dxi-button>
  </dxi-column>
</dx-data-grid>
