import { Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { SELECTED_EXCURSION_PREFERENCES_CONFIG } from 'ag-common-lib/public-api';
import { logHasKey } from '../../utils/normalize-log';

@Component({
  selector: 'ag-crm-change-log-excursion-preferences',
  templateUrl: './change-log-excursion-preferences.component.html',
  styleUrls: ['./change-log-excursion-preferences.component.scss'],
})
export class ChangeLogExcursionPreferencesComponent {
  @Input() set log(delta: Delta) {
    this._log = !Array.isArray(delta) ? delta : (delta[0] as Delta);
    this.getExcursionsLogIndexes(this._log);
  }
  get log(): Delta {
    return this._log;
  }

  preferencesLogIndexes: string[] = [];

  protected readonly preferencesLookup = SELECTED_EXCURSION_PREFERENCES_CONFIG;
  protected readonly logHasKey = logHasKey;
  private _log: Delta;

  private getExcursionsLogIndexes = logs => {
    this.preferencesLogIndexes = [];
    Object.keys(logs).forEach(key => this.preferencesLogIndexes.push(key));
  };
}
