import { Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ToastrService } from 'ngx-toastr';
import { EmailRequest } from 'ag-common-lib/public-api';
import { EMAIL_STATUS } from 'ag-common-lib/public-api';
import { Tab } from 'ag-common-lib/public-api';
import { AuthService } from 'ag-common-svc/public-api';
import { DistributionListService } from 'src/app/shared/services/distribution-list.service';
import { EmailTemplateService } from 'src/app/shared/services/email-template.service';
import { EmailService } from 'src/app/shared/services/email.service';
import { SessionControllerService } from 'src/app/shared/services/util/session-controller.service';
import { EmailRequestService } from 'src/app/shared/services/email-request.service';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';
import { firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-distribution-list-activity-info',
  templateUrl: './distribution-list-activity-info.component.html',
  styleUrls: ['./distribution-list-activity-info.component.scss'],
})
export class DistributionListActivityInfoComponent {
  @ViewChild('emailgrid') emailgrid: DxDataGridComponent;
  @ViewChild('templategrid') templategrid: DxDataGridComponent;

  emailsDataSource: any = {};

  addNotesVisible: boolean = false;
  createEmailVisible: boolean = false;
  viewEmailResponseVisible: boolean = false;

  email: EmailRequest;

  selectedTab: string = 'Emails';

  emailOptions: string[] = ['New Email', 'New Note'];

  tabs: Tab[] = [
    { id: 0, text: 'Emails', template: 'Emails' },
    { id: 1, text: 'Notes', template: 'Notes' },
  ];

  constructor(
    public session: SessionControllerService,
    public authService: AuthService,
    public emailService: EmailService,
    public emailRequestService: EmailRequestService,
    public emailTemplateService: EmailTemplateService,
    public toastrService: ToastrService,
    public distributionListService: DistributionListService,
  ) {
    this.emailsDataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: async function (loadOptions: any) {
        let qp: QueryParam[] = [];
        const agentEmail = await firstValueFrom(authService.loggedInAgent$.pipe(map((agent) => agent?.p_email)));
        qp.push(new QueryParam('owner', WhereFilterOperandKeys.equal, agentEmail));
        qp.push(
          new QueryParam('distribution_list_id', WhereFilterOperandKeys.equal, session.selectedDistributionList.dbId),
        );
        return emailService.getAllByValue(qp, 'name');
      },
      remove: function (id: any) {
        return emailService.delete(id);
      },
    });
  }

  selectTab(e) {
    this.selectedTab = e.itemData.template;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: () => {
          this.createNewEmail();
        },
      },
    });
  }

  private createNewEmail() {
    this.emailRequestService.createNewDistributionEmail().then(email => {
      this.email = email;
      this.createEmailVisible = true;
    });
  }

  editEmail(e) {
    this.email = e.row.data;
    this.createEmailVisible = true;
  }

  confirmSendEmail(e) {
    let email: EmailRequest = e.row.data;

    email.status = EMAIL_STATUS.READY;
    email.sent_date = new Date().toLocaleDateString();

    this.emailRequestService.update(email).then(() => {
      this.toastrService.success('Email Sent Successfully');
      this.emailgrid.instance.refresh();
    });
  }

  saveEmail(e: EmailRequest) {
    if (e) {
      if (e.dbId) {
        this.emailRequestService.update(e).then(() => {
          this.toastrService.success('Email Saved Successfully!');
          this.emailgrid.instance.refresh();
          this.createEmailVisible = false;
        });
      } else {
        this.emailRequestService.create(e).then(() => {
          this.toastrService.success('Email Created Successfully!');
          this.emailgrid.instance.refresh();
          this.createEmailVisible = false;
        });
      }
    } else {
      this.createEmailVisible = false;
    }
  }

  showEmailDetails(e) {
    this.email = e.data;
    this.viewEmailResponseVisible = true;
  }

  closeEmailDetails(e) {
    this.viewEmailResponseVisible = false;
  }

  saveNote(e) {
    this.addNotesVisible = false;
  }

  emailOptionsClicked(e) {
    if (e.itemData == 'New Email') {
      this.createNewEmail();
    }

    if (e.itemData == 'New Note') {
      this.addNotesVisible = true;
    }
  }

  emailPreviouslySent(e) {
    return e.row.data.status != 2;
  }

  getCreatedDate(e) {
    if (e && e.date_created) {
      return new Date(e.date_created.seconds * 1000).toLocaleString();
    } else {
      return '';
    }
  }

  getSentDate(e) {
    if (e && e.date_sent) {
      return new Date(e.date_sent.seconds * 1000).toLocaleString();
    } else {
      return '';
    }
  }
}
