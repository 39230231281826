import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAssociationComponent } from './select-association.component';
import { SharedModule } from '../../../shared/shared.module';
import { RelationshipTypeSelectBoxModule } from '../relationship-type-select-box/relationship-type-select-box.module';
import { SelectAssociationService } from './select-association.service';

@NgModule({
  declarations: [SelectAssociationComponent],
  imports: [CommonModule, SharedModule, RelationshipTypeSelectBoxModule],
  exports: [SelectAssociationComponent],
})
export class SelectAssociationModule {}
