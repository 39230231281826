<ng-container
  *var="
    false ===
    (Entity.conference
      | hasPermission: (isCreateMode ? EntityPermissionActivityKeys.create : EntityPermissionActivityKeys.write)
      | async) as isReadonlyMode
  "
>
  <ag-shr-modal-window
    #conferenceFormModalRef
    [title]="conferenceFormData?.[ConferenceKeys.eventName] | eventNameTitle: eventNameTitleMask : caption"
    [width]="'100%'"
    [height]="'100%'"
    [isFullScreen]="true"
    [useScrollView]="false"
    [inProgress]="inProgress$ | async"
    [showSaveButton]="!isReadonlyMode && !!(sectionsWithWritePermission | editorPermittedTabs | async)?.length"
    (onSaveClick)="handleSaveConference($event)"
    [onCloseModal]="handleClosePopup"
  >
    <div class="conference-modal__container" *ngIf="conferenceFormModalRef?.popupComponent?.visible ?? false">
      <div class="conference-modal__tabs-container">
        <div class="conference-modal__validation-summary" *ngIf="!isReadonlyMode">
          <dx-button
            *ngFor="let item of errors$ | async"
            class="conference-modal__validation-summary-item"
            type="danger"
            stylingMode="outlined"
            [text]="item?.label | empty: 'Invalid field'"
            (onClick)="onValidationSummaryItemClick(item)"
          ></dx-button>
        </div>

        <dx-tab-panel
          #tabsRef
          class="conference-modal__tabs"
          width="100%"
          height="100%"
          [dataSource]="conferenceSectionsTabs | editorPermittedTabs | async"
          [animationEnabled]="false"
          [swipeEnabled]="false"
          [deferRendering]="false"
          [showNavButtons]="true"
        >
          <div *dxTemplate="let section of 'title'">
            <div
              *var="conferenceFormData?.[section.enableControlKey] ?? true as isEnabled"
              class="conference-modal__tabs-title-container"
              [class.opacity-50]="!isEnabled"
            >
              <p class="conference-modal__tabs-title">{{ section.title }}</p>

              <div class="conference-modal__tabs-actions" *ngIf="section?.enableControlVisible ?? false">
                <dx-button
                  class="conference-modal__tabs-control"
                  [icon]="isEnabled ? 'check' : 'agi-ban'"
                  [hint]="isEnabled ? 'Click to Disable Section' : 'Click to Enable Section'"
                  stylingMode="outlined"
                  [disabled]="isReadonlyMode"
                  [type]="isEnabled ? 'success' : 'danger'"
                  (onClick)="toggleSection($event, section.enableControlKey, isEnabled)"
                >
                </dx-button>

                <dx-button
                  *var="conferenceFormData?.[section.activeControlKey] ?? false as isActive"
                  [icon]="isActive ? 'agi-eye' : 'agi-eye-slash'"
                  class="conference-modal__tabs-control"
                  [hint]="isActive ? 'Click to Hide on Wizard' : 'Click to Show On Wizard'"
                  [visible]="isEnabled"
                  stylingMode="outlined"
                  [type]="isActive ? 'success' : 'danger'"
                  [disabled]="isReadonlyMode"
                  (onClick)="toggleSectionForWizard($event, section.activeControlKey, isActive)"
                >
                </dx-button>
              </div>
            </div>
          </div>

          <div class="conference-modal__tabs-item-container" *dxTemplate="let section of 'item'">
            <ng-container
              *ngIf="!section?.enableControlVisible || (conferenceFormData?.[section.enableControlKey] ?? false);
                else sectionDisabledTemplate"
              [ngSwitch]="section.id"
            >
              <ng-container
                *var="section.permissionId | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
              >
                <ag-crm-conference-welcome-info-form
                  *ngSwitchCase="ConferenceFormSections.conferenceWelcomeInformation"
                  [conference]="conferenceFormData"
                  [validationGroup]="welcomeInfoValidationGroup"
                  [isReadonlyMode]="isReadonlyMode || !canWrite"
                ></ag-crm-conference-welcome-info-form>

                <ag-crm-conference-steps-info-config-form
                  *ngSwitchCase="ConferenceFormSections.stepsInfoConfiguration"
                  [conference]="conferenceFormData"
                  [validationGroup]="stepsInfoValidationGroup"
                  [isReadonlyMode]="isReadonlyMode || !canWrite"
                ></ag-crm-conference-steps-info-config-form>

                <ag-crm-conference-primary-info-form
                  *ngSwitchCase="ConferenceFormSections.conferencePrimaryInformation"
                  [conference]="conferenceFormData"
                  [validationGroup]="primaryInfoValidationGroup"
                  [isReadonlyMode]="isReadonlyMode || !canWrite"
                ></ag-crm-conference-primary-info-form>

                <ag-crm-conference-email-sender-form
                  *ngSwitchCase="ConferenceFormSections.emailSender"
                  [conference]="conferenceFormData"
                  [validationGroup]="emailSenderFormValidationGroup"
                  [isReadonlyMode]="isReadonlyMode || !canWrite"
                ></ag-crm-conference-email-sender-form>

                <ag-crm-conference-hotel-info-form
                  *ngSwitchCase="ConferenceFormSections.hotel"
                  [conference]="conferenceFormData"
                  [validationGroup]="hotelValidationGroup"
                  [isReadonlyMode]="isReadonlyMode || !canWrite"
                ></ag-crm-conference-hotel-info-form>

                <ag-crm-conference-guests-form
                  *ngSwitchCase="ConferenceFormSections.guests"
                  [conference]="conferenceFormData"
                  [validationGroup]="guestsValidationGroup"
                  [isReadonlyMode]="isReadonlyMode || !canWrite"
                ></ag-crm-conference-guests-form>

                <ag-crm-conference-excursions-form
                  *ngSwitchCase="ConferenceFormSections.excursions"
                  [conference]="conferenceFormData"
                  [validationGroup]="excursionsValidationGroup"
                  [isReadonlyMode]="isReadonlyMode"
                ></ag-crm-conference-excursions-form>
              </ng-container>
            </ng-container>

            <ng-template #sectionDisabledTemplate>
              <p class="ag-empty-text">Section is not active.</p>
            </ng-template>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </ag-shr-modal-window>
</ng-container>
