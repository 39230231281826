import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeExcursionsComponent } from './attendee-excursions.component';
import { AgAssignOwnerViewerModule, ExcursionsInfoTableModule } from 'ag-common-svc/lib/components';

@NgModule({
  imports: [CommonModule, SharedModule, ExcursionsInfoTableModule, AgAssignOwnerViewerModule],
  declarations: [AttendeeExcursionsComponent],
  exports: [AttendeeExcursionsComponent],
  providers: [],
})
export class AttendeeExcursionsModule {}
