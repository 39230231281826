<div *ngIf="!template.email" class="row p-0 m-0">
  <div class="col-12 p-0 m-0">
    <p>{{ template.description }}</p>
  </div>
</div>
<div *ngIf="template.email" class="row p-0 m-0">
  <div class="col-12 p-0 m-0">
    <p style="font-size: large; font-weight: 600">{{ template.email.from.p_agent_name }}</p>

    <p style="font-size: small">to {{ template.email.to[0].p_agent_name }}</p>
  </div>
</div>
