<div class="container">
  <div class="row justify-content-between mt-3">
    <div class="row">
      <div class="col-6">
        <div class="dx-fieldset-header mb-5">Upload Paid File</div>
      </div>
      <div class="col-6">
        <dx-button
          class="mt-4"
          style="float: right"
          stylingMode="contained"
          text="Show Upload History"
          type="normal"
          [width]="240"
          (onClick)="showUploadHistory()"
        >
        </dx-button>
      </div>
    </div>
    <div class="row">
      <div class="col-6" id="upload">
        <div class="form">
          <div class="dx-field">
            <div class="dx-field-label"><h3>Year</h3></div>
            <div class="dx-field-value">
              <dx-select-box #year [items]="years"></dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><h3>"As Of" Date</h3></div>
            <div class="dx-field-value">
              <dx-date-box [(value)]="asOfDate"></dx-date-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><h3>Import Transactions</h3></div>
            <div class="dx-field-value">
              <dx-check-box [(value)]="importPolicyTransactions"></dx-check-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><h3>Import Summaries</h3></div>
            <div class="dx-field-value">
              <dx-check-box [(value)]="importSummaries"></dx-check-box>
            </div>
          </div>
          <div class="dx-field">
            <div id="fileuploader-container">
              <dx-file-uploader
                selectButtonText="Select File"
                accept="*"
                uploadMode="useButtons"
                [uploadFile]="onFileUploaded.bind(this)"
                [multiple]="true"
                name="files[]"
              >
              </dx-file-uploader>
            </div>
          </div>
        </div>

        <dx-button
          class="mt-4"
          stylingMode="contained"
          text="Import"
          type="normal"
          [width]="120"
          (onClick)="onImportClicked()"
          [visible]="showImportButton"
        >
        </dx-button>
      </div>
      <div class="col-6 ms-4">
        <h1>Messages</h1>
        <h2 *ngIf="importPolicyTransactions">Total Transactions: {{ paidFile.transactions }}</h2>
        <h2 *ngIf="importSummaries">Total Summaries: {{ paidFile.summaries }}</h2>
        <hr style="width: 100%" />
        <dx-scroll-view #scrollView id="scrollview" [height]="400" [width]="500">
          <ng-container *ngFor="let msg of messages; let i = index">
            <h3 *ngIf="i == 0">
              <b>{{ msg }}</b>
            </h3>
            <h3 *ngIf="i > 0">{{ msg }}</h3>
          </ng-container>
        </dx-scroll-view>
      </div>
    </div>
  </div>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#upload' }"
  [(visible)]="loadingVisible"
  [message]="'Please do not leave or refresh this page until complete!'"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>

<dx-popup
  [width]="900"
  [height]="500"
  [showTitle]="true"
  title="Upload History"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="displayUploadHistory"
>
  <div id="data-grid-demo">
    <dx-data-grid #reportsTable [dataSource]="dataSource" [showBorders]="true" [renderAsync]="true">
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxi-column dataField="fileName"></dxi-column>
      <dxi-column dataField="asOfDate" [width]="150"></dxi-column>
      <dxi-column dataField="uploadDate" [width]="150"></dxi-column>
      <dxi-column dataField="uploadedBy" [width]="200"></dxi-column>
      <dxi-column dataField="recordsImported" caption="Records" [width]="75"></dxi-column>
      <dxi-column dataField="summariesGenerated" caption="Summaries" [width]="100"></dxi-column>
    </dx-data-grid>
  </div>

  <div class="row d-flex m-3 config-buttons">
    <button class="btn btn-primary" (click)="closeUploadHistory()">Close</button>
  </div>
</dx-popup>
