import { Pipe, PipeTransform } from '@angular/core';
import { changeLogsLookup } from 'ag-common-lib/public-api';

@Pipe({
  name: 'changeLogFieldsName',
})
export class ChangeLogFiledsNamePipe implements PipeTransform {
  transform(fields: string[]) {
    const logsLookup = changeLogsLookup;
    if (!(Array.isArray(fields) && fields?.length) || !logsLookup?.length) {
      return '';
    }

    return fields
      .map(fieldName => {
        const lookup = logsLookup?.find(item => item.value === fieldName.trim());
        return lookup ? `${lookup?.description}` : fieldName;
      })
      .filter(Boolean)
      .join(', ');
  }
}
