import { Injectable } from '@angular/core';
import {
  AgentCampaignStepName,
  CampaignsManagementIssuesStatus,
  CampaignsManagementIssuesType,
  CampaignsManagementTaskIssues,
  CampaignsManagementTaskIssuesKeys,
  CampaignsManagementTaskLogsAction, RelatedCampaignsManagementTaskIssues,
} from 'ag-common-lib/public-api';
import {
  AgentCampaignsManagementTaskIssuesService
} from 'ag-common-svc/lib/services/agent-campaigns-management-task-issues.service';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { ToastrService } from 'ngx-toastr';
import {
  AgentCampaignsManagementTaskLogsService
} from 'ag-common-svc/lib/services/agent-campaigns-management-task-logs.service';
import { Observable } from 'rxjs';

@Injectable()
export class CampaignsAddIssuesModalService extends BaseFormService<CampaignsManagementTaskIssues> {
  constructor(
    private toastrService: ToastrService,
    private taskLogsService: AgentCampaignsManagementTaskLogsService,
    private agentCampaignsManagementTaskIssuesService: AgentCampaignsManagementTaskIssuesService,
  ) {
    super();
  }

  getFormData = async (issue?: CampaignsManagementTaskIssues): Promise<Partial<CampaignsManagementTaskIssues>> => {
    const initialData = Object.assign({}, issue);
    Object.assign(initialData, {
      [CampaignsManagementTaskIssuesKeys.issueStatus]: issue?.issueStatus ?? CampaignsManagementIssuesStatus.posted,
      [CampaignsManagementTaskIssuesKeys.issueType]: issue?.issueType ?? CampaignsManagementIssuesType.content,
      [CampaignsManagementTaskIssuesKeys.issueField]: issue?.issueField ?? AgentCampaignStepName.webSite,
    });
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });

    return this.formData;
  };

  async saveIssue(
    agentDbId: string,
    taskDbId: string,
    issues: CampaignsManagementTaskIssues,
    issueId?: string | null,
  ): Promise<void> {
    !issueId
      ? await this.createIssue(agentDbId, taskDbId, issues)
      : await this.updateIssues(agentDbId, taskDbId, issueId, issues);
  }

  async createIssue(
    agentDbId: string,
    taskDbId: string,
    issues: CampaignsManagementTaskIssues,
  ): Promise<void | CampaignsManagementTaskIssues> {
    this.startProgress();
    return this.agentCampaignsManagementTaskIssuesService
      .createIssues(agentDbId, taskDbId, issues)
      .then(() => {
        this.onSuccessfulCreated();
        this.taskLogsService.saveLogs(
          agentDbId,
          taskDbId,
          null,
          issues,
          CampaignsManagementTaskLogsAction.issueCreating,
        );
        return issues;
      })
      .catch(err => {
        this.toastrService.error('Campaigns Issues are not created!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  async updateIssues(
    agentDbId: string,
    taskDbId: string,
    issueId: string,
    issues: CampaignsManagementTaskIssues,
  ): Promise<void | CampaignsManagementTaskIssues> {
    this.startProgress();
    return this.agentCampaignsManagementTaskIssuesService
      .updateIssues(agentDbId, taskDbId, issueId, issues)
      .then(() => {
        this.onSuccessfulUpdated(Object.keys(issues));
        this.taskLogsService.saveLogs(
          agentDbId,
          taskDbId,
          null,
          issues,
          CampaignsManagementTaskLogsAction.issueUpdating,
        );
        return issues;
      })
      .catch(err => {
        this.toastrService.error('Campaigns Issues are not updated!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  getIssuesById$(taskId: string): Observable<RelatedCampaignsManagementTaskIssues[]> {
    return this.agentCampaignsManagementTaskIssuesService.getIssuesByTaskId(taskId);
  }
}
