<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <dx-form
    [(formData)]="conference"
    labelMode="floating"
    [validationGroup]="validationGroup"
    [readOnly]="isReadonlyMode"
  >
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        [dataField]="ConferenceKeys.maxGuests"
        editorType="dxNumberBox"
        [label]="{ text: 'Maximum number of guests' }"
        [editorOptions]="{ min: 0, format: '0#', name: ConferenceKeys.maxGuests }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.complimentaryGuests"
        editorType="dxNumberBox"
        [label]="{ text: 'Maximum number of complimentary guests' }"
        [editorOptions]="{ min: 0, format: '0#', name: ConferenceKeys.complimentaryGuests }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-data-grid
          [dataSource]="conference?.[ConferenceKeys.guestRates]"
          [showRowLines]="true"
          [rowAlternationEnabled]="true"
          (onInitNewRow)="onInitNewGuestBudgetRow($event)"
          (onRowInserting)="onRowInserting($event)"
          (onRowUpdating)="onRowUpdating($event)"
          (onRowRemoving)="onRowRemoving($event)"
          height="100%"
        >
          <dxo-sorting mode="disabled"></dxo-sorting>

          <dxo-editing
            #editingTmp
            mode="row"
            [useIcons]="true"
            [allowUpdating]="!isReadonlyMode"
            [allowDeleting]="!isReadonlyMode"
            [allowAdding]="!isReadonlyMode"
            [newRowPosition]="'last'"
          >
          </dxo-editing>

          <dxo-toolbar>
            <dxi-item location="before">
              <span *dxTemplate class="dx-form-group-caption">Buy-in Rates for Additional Guests</span>
            </dxi-item>

            <dxi-item name="addRowButton"></dxi-item>
          </dxo-toolbar>

          <dxi-column [dataField]="GuestRateKeys.guestTypeName" caption="Name" [alignment]="'left'">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>
          <dxi-column [dataField]="GuestRateKeys.monthYearSelection" caption="Age Type">
            <dxo-lookup
              [dataSource]="MonthYearSelectionLookup"
              valueExpr="value"
              displayExpr="description"
            ></dxo-lookup>
          </dxi-column>
          <dxi-column
            [dataField]="GuestRateKeys.startAgeRange"
            caption="Start Age"
            [sortOrder]="'asc'"
            [alignment]="'left'"
            editCellTemplate="editStartAgeRangeCellTemplate"
          >
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule
              type="custom"
              [reevaluate]="true"
              message="Age ranges overlap"
              [validationCallback]="startDateRangeValidation.bind(this, editingTmp)"
            ></dxi-validation-rule>
          </dxi-column>
          <dxi-column
            [dataField]="GuestRateKeys.endAgeRange"
            caption="End Age"
            [alignment]="'left'"
            editCellTemplate="editEndAgeRangeCellTemplate"
          >
            <dxi-validation-rule
              type="custom"
              [reevaluate]="true"
              message="Age ranges overlap"
              [validationCallback]="endDateRangeValidation.bind(this, editingTmp)"
              [ignoreEmptyValue]="true"
            ></dxi-validation-rule>
          </dxi-column>

          <dxi-column
            [dataField]="GuestRateKeys.budget"
            caption="Budget"
            [alignment]="'left'"
            [dataType]="'number'"
            [format]="DX_USD_CURRENCY_FORMAT"
            [editorOptions]="{ format: { type: 'currency', currency: 'USD', precision: 2 }, min: 0 }"
          >
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>

          <!-- Templates -->
          <div *dxTemplate="let cell of 'editStartAgeRangeCellTemplate'">
            <dx-number-box
              [value]="cell.value"
              (valueChange)="cell.setValue($event)"
              [showClearButton]="true"
              [min]="0"
              [max]="cell?.row?.data | maxGuestStartAgeForRate"
            >
            </dx-number-box>
          </div>

          <div *dxTemplate="let cell of 'editEndAgeRangeCellTemplate'">
            <dx-number-box
              [value]="cell.value"
              (valueChange)="cell.setValue($event)"
              [showClearButton]="true"
              [disabled]="cell?.row?.data?.[GuestRateKeys.startAgeRange] | maxGuestEndAgeForRateDisabled"
              [min]="cell?.row?.data?.[GuestRateKeys.startAgeRange]"
              [max]="cell?.row?.data | maxGuestEndAgeForRate: conference[ConferenceKeys.guestRates]"
            >
            </dx-number-box>
          </div>
        </dx-data-grid>
      </div>
    </dxi-item>

    <dxi-item [colCount]="2">
      <div *dxTemplate>
        <shr-html-editor
          class="mt-2"
          [name]="ConferenceKeys.roomsOccupancyHtmlContent"
          [label]="'Rooms Occupancy'"
          [isRequired]="true"
          [allowSoftLineBreak]="true"
          [validationGroup]="roomsOccupancyValidationGroup"
          [(value)]="conference && conference[ConferenceKeys.roomsOccupancyHtmlContent]"
          [isReadOnly]="isReadonlyMode"
          [toolbarItemType]="[
            HtmlEditorTypeKeys.font,
            HtmlEditorTypeKeys.fontStyle,
            HtmlEditorTypeKeys.paragraph,
            HtmlEditorTypeKeys.header,
            HtmlEditorTypeKeys.link,
            HtmlEditorTypeKeys.preview,
          ]"
          (onShowPreview)="onShowPreview($event)"
        ></shr-html-editor>
      </div>
    </dxi-item>
  </dx-form>
</dx-scroll-view>

<dx-popup
  [showTitle]="true"
  title="Preview"
  width="80%"
  height="auto"
  [(visible)]="popupVisible"
  [showCloseButton]="true"
>
  <div class="conference-steps-info-modal__template-container mat-typography">
    <dx-scroll-view class="conference-registration-wizard__scroll-view" [useNative]="false" [showScrollbar]="'always'">
      <span class="dx-widget mb-3" *ngIf="conference?.[ConferenceKeys.roomsOccupancyHtmlContent] as htmlContent">
        <span [innerHTML]="htmlContent | safeHTMLUrl"></span>
      </span>
    </dx-scroll-view>
  </div>
</dx-popup>
