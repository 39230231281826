import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ModalWindowModule } from 'ag-common-svc/lib/components';
import { ConferenceExcursionStatisticGridComponent } from './conference-excursion-statistic-grid/conference-excursion-statistic-grid.component';
import { ConferenceExcursionStatisticModalComponent } from './conference-excursion-statistic-modal.component';
import { ConferenceExcursionNotSelectedGridComponent } from './conference-excursion-not-selected-grid/conference-excursion-not-selected-grid.component';
import { AttendeesListService } from '../../../attendees-list.service';
import { ConferenceExcursionStatisticService } from './conference-excursion-statistic.service';
import { DxTabPanelModule } from 'devextreme-angular';
import { ConferencesPipesModule } from '../../pipes/conferences-pipes.module';

@NgModule({
  imports: [CommonModule, SharedModule, ModalWindowModule, DxTabPanelModule, ConferencesPipesModule],
  declarations: [
    ConferenceExcursionStatisticModalComponent,
    ConferenceExcursionStatisticGridComponent,
    ConferenceExcursionNotSelectedGridComponent,
  ],
  exports: [ConferenceExcursionStatisticModalComponent],
  providers: [AttendeesListService, ConferenceExcursionStatisticService],
})
export class ConferenceExcursionStatisticModalModule {}
