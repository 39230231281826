<div class="row p-0 m-0">
  <div class="col-12 p-2 m-0">
    <dx-scheduler
      #scheduler
      timeZone="America/Los_Angeles"
      [dataSource]="appointmentsDataSource"
      [views]="['agenda', 'week', 'month']"
      currentView="agenda"
      [currentDate]="currentDate"
      [startDayHour]="9"
      [height]="'75vh'"
      (onAppointmentAdded)="onDsChanges($event)"
      (onAppointmentUpdated)="onDsChanges($event)"
      (onAppointmentDeleted)="onDsChanges($event)"
    >
    </dx-scheduler>
  </div>
</div>
