import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignsManagementTaskBoardComponent } from './campaigns-management-task-board.component';
import { CampaignsManagementTaskBoardService } from './campaigns-management-task-board.service';
import { CampaignsManagementTaskBoardRoutingModule } from './campaigns-management-task-board-routing.module';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDropDownButtonModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSortableModule,
} from 'devextreme-angular';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { AgCrmCommonModule } from '../../ag-crm/common/ag-crm-common.module';
import { PrimaryPhoneNumberPipe } from 'ag-common-svc/shared/pipes/primary-phone-number.pipe';
import { AgAssignOwnerViewerModule, ModalWindowModule } from 'ag-common-svc/lib/components';
import { CampaignsTaskBoardStatusPipe } from './pipe/campaigns-task-board-status.pipe';
import { CampaignsTaskIssuesStatusPipe } from './pipe/campaigns-task-issues-status.pipe';
import { CampaignsTaskBoardPriorityPipe } from './pipe/campaigns-task-board-priority.pipe';
import { AgentCampaignChangesListModule } from 'ag-common-svc/lib/components/agent-campaign-wizard/agent-campaign-changes-list/agent-campaign-changes-list.module';
import { CampaignsDetailsModalComponent } from './campaigns-details-modal/campaigns-details-modal.component';
import { CampaignsAddIssuesModalComponent } from './campaigns-add-issues-modal/campaigns-add-issues-modal.component';
import { CampaignsIssuesGridComponent } from './campaigns-issues-grid/campaigns-issues-grid.component';
import { AgentCampaignSummaryListModule } from 'ag-common-svc/lib/components/agent-campaign-summary-list/agent-campaign-summary-list.module';
import { CampaignsTaskIssuesModalComponent } from './campaigns-task-issues-modal/campaigns-task-issues-modal.component';
import { CampaignsDetailsModalStepVisiblePipe } from './campaigns-details-modal/campaigns-details-modal-step-visible.pipe';
import { CampaignsDetailsModalIsStepDonePipe } from './campaigns-details-modal/campaigns-details-modal-is-step-done.pipe';
import { CampaignsTaskStatsPipe } from './pipe/campaigns-task-stats.pipe';
import { CampaignsDetailsModalGetAllImagesIdsPipe } from './campaigns-details-modal/campaigns-details-modal-get-all-images-ids.pipe';

@NgModule({
  imports: [
    CommonModule,
    CampaignsManagementTaskBoardRoutingModule,
    DxScrollViewModule,
    DxSortableModule,
    DxButtonModule,
    PipesModule,
    AgCrmCommonModule,
    DxPopupModule,
    ModalWindowModule,
    DxLoadPanelModule,
    AgentCampaignChangesListModule,
    AgentCampaignSummaryListModule,
    DxCheckBoxModule,
    DxDropDownButtonModule,
    AgAssignOwnerViewerModule,
  ],
  declarations: [
    CampaignsTaskBoardPriorityPipe,
    CampaignsTaskBoardStatusPipe,
    CampaignsTaskIssuesStatusPipe,
    CampaignsManagementTaskBoardComponent,
    CampaignsDetailsModalComponent,
    CampaignsAddIssuesModalComponent,
    CampaignsIssuesGridComponent,
    CampaignsTaskIssuesModalComponent,
    CampaignsDetailsModalStepVisiblePipe,
    CampaignsDetailsModalIsStepDonePipe,
    CampaignsTaskStatsPipe,
    CampaignsDetailsModalGetAllImagesIdsPipe,
  ],
  providers: [CampaignsManagementTaskBoardService, PrimaryPhoneNumberPipe],
  exports: [CampaignsDetailsModalComponent, CampaignsIssuesGridComponent, CampaignsTaskIssuesStatusPipe],
})
export class CampaignsManagementTaskBoardModule {}
