import { Component, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { SALES_GOALS_LOOKUP, SalesGoalsTab, tabToKey } from './agent-sales-goals.models';
import { BehaviorSubject } from 'rxjs';
import {
  Agent,
  AgentKeys,
  DX_USD_CURRENCY_FORMAT,
  Entity,
  Goal,
  GoalKeys,
  LookupBase,
  LookupKeys,
  YEARS_LIST,
} from '@ag-common-lib/public-api';
import { RowInsertingEvent, RowRemovingEvent, RowUpdatingEvent } from 'devextreme/ui/data_grid';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AgentService } from 'ag-common-svc/public-api';
import { AgentSalesGoalsModalService } from './agent-sales-goals-modal.service';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-sales-goals-modal',
  templateUrl: './agent-sales-goals-modal.component.html',
  styleUrls: ['./agent-sales-goals-modal.component.scss'],
  providers: [AgentSalesGoalsModalService],
})
export class AgentSalesGoalsModalComponent {
  @ViewChild('salesGoalsModalRef', { static: true }) salesGoalsModalComponent: ModalWindowComponent;

  selectedAgent$ = new BehaviorSubject<Agent>(null);
  selectedTab$ = new BehaviorSubject<SalesGoalsTab>(SalesGoalsTab.PERSONAL);
  private readonly _agentId$ = new BehaviorSubject<string>(undefined);

  readonly years = YEARS_LIST;
  salesGoalsLookup: LookupBase[] = [];
  protected readonly AgentKeys = AgentKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly emptyMessage = 'No Sales Goals Currently Exist. Please update filters.';
  protected readonly GoalKeys = GoalKeys;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;

  inProgress$ = this.agentSalesGoalsModalService.inProgress$;

  constructor(
    private toastr: ToastrService,
    private agentSalesGoalsModalService: AgentSalesGoalsModalService,
    private agentService: AgentService,
  ) {}

  showModal(agentId: string): void {
    this._agentId$.next(agentId);
    this.selectedTab$.next(SalesGoalsTab.PERSONAL);
    this.salesGoalsLookup = SALES_GOALS_LOOKUP;
    this.normalizeSalesGoals(agentId).then(agent => {
      this.selectedAgent$.next(agent);
    });
    this.salesGoalsModalComponent.showModal();
  }

  onGoalInserting(event: RowInsertingEvent): void {
    const key = tabToKey.get(this.selectedTab$.value);
    const goalByKey = this.selectedAgent$.value[key];
    const isCurrentYearGoalExist = goalByKey?.filter(goal => goal.year == event.data.year).length > 0;
    if (isCurrentYearGoalExist) {
      this.toastr.error(`Goal already set for year ${event.data.year}`);
      event.cancel = true;
      return;
    }

    const goal = Object.assign({}, new Goal(event.data.year, event.data.amount));
    const goals = [...(goalByKey ?? []), goal];

    event.cancel = this.agentSalesGoalsModalService
      .updateGoal(this._agentId$.value, { [key]: goals })
      .then(() => {
        this.toastr.success(`Goal Successfully set for year ${event.data.year}`);
        return false;
      })
      .catch(() => true);
  }

  onGoalRowUpdating(event: RowUpdatingEvent): void {
    const key = tabToKey.get(this.selectedTab$.value);
    const goalByKey = this.selectedAgent$.value[key];
    const updatedGoals =
      goalByKey?.map(goal => {
        if (goal?.year !== event.key) {
          return goal;
        }

        return Object.assign(goal, event?.newData);
      }) ?? [];

    event.cancel = this.agentSalesGoalsModalService
      .updateGoal(this._agentId$.value, { [key]: updatedGoals })
      .then(() => {
        this.toastr.success(`Goal Successfully updated for year ${event.oldData.year}`);
        return false;
      })
      .catch(() => true);
  }

  onGoalRowRemoving(event: RowRemovingEvent): void {
    const key = tabToKey.get(this.selectedTab$.value);
    const goalByKey = this.selectedAgent$.value[key];
    const updatedGoals = goalByKey?.filter(goal => goal?.year !== event.key) ?? [];

    event.cancel = this.agentSalesGoalsModalService
      .updateGoal(this._agentId$.value, { [key]: updatedGoals })
      .then(() => {
        this.toastr.success(`Goal for year ${event.data.year} removed`);
        return false;
      })
      .catch(() => true);
  }

  onEditorPreparingGoals(e): void {
    e.editorOptions.disabled = e.parentType == 'dataRow' && e.dataField == 'year' && !e.row.isNewRow;
  }

  private async normalizeSalesGoals(agentId: string): Promise<Agent> {
    const agent = (await this.agentService.getById(agentId)) ?? new Agent();
    if (!agent.is_manager) {
      this.salesGoalsLookup = this.salesGoalsLookup.filter(item => item.value !== SalesGoalsTab.MANAGER);
    }

    if (!agent.conference_goals) {
      agent.conference_goals = [];
    }

    if (agent.is_manager && !agent.manager_goals) {
      agent.manager_goals = [];
    }

    return agent;
  }
}
