<ng-container *ngFor="let ind of flightBookingLogIndexes">
  <ng-container *ngIf="logs[ind] as log">
    <!--  Asked to hide removed data -->
    <ag-crm-change-log-detail-by-type
      [keysConfig]="bookingInfoLookup"
      [changeLogs]="Array.isArray(log) ? log[0] : log"
      [showRemovedData]="false"
    >
      <p changeLogsAddedDescription class="fw-bold">Flight Booking Info was added for {{ attendeeName }}.</p>
      <p changeLogsRemovedDescription class="fw-bold">Flight Booking Info was removed for {{ attendeeName }}.</p>
    </ag-crm-change-log-detail-by-type>
  </ng-container>
</ng-container>
