<div class="container">
  <div class="row d-flex mt-5 justify-content-around" style="margin: 0 auto">
    <div class="col-12">
      <dx-data-grid
        class="dx-card wide-card my-2"
        [dataSource]="dataSource"
        [showBorders]="false"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [height]="550"
        [selectedRowKeys]="selectedAgentKeys"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onRowDblClick)="showContactInfo($event)"
      >
        <dxo-selection [selectAllMode]="'allPages'" [showCheckBoxesMode]="'always'" mode="multiple"> </dxo-selection>
        <dxo-paging [pageSize]="50"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-sorting mode="multiple"></dxo-sorting>

        <dxi-column dataField="id"></dxi-column>
        <dxi-column dataField="name"></dxi-column>
        <dxi-column dataField="type"></dxi-column>
        <dxi-column dataField="year"></dxi-column>

        <dxi-column dataField="agencyId" [caption]="'Agency'">
          <dxo-lookup
            [dataSource]="agencies"
            [allowClearing]="false"
            [displayExpr]="'name'"
            [valueExpr]="'agency_id'"
          ></dxo-lookup>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
  <div class="row d-flex mt-5 justify-content-around" style="margin: 0 auto">
    <div class="col-lg-6 text-center">
      <dx-button width="100" stylingMode="contained" text="Save" type="default" (onClick)="selectAgents()"></dx-button>
    </div>
    <div class="col-lg-6 text-center">
      <dx-button
        width="100"
        stylingMode="contained"
        text="Cancel"
        type="default"
        (onClick)="closeClicked()"
      ></dx-button>
    </div>
  </div>
</div>
