<div class="row p-0 m-0">
  <div class="col-6 p-0 m-0">
    <p>{{ template.title }}</p>
  </div>
  <div class="col-3 p-0 m-0">
    <p style="font-size: small">{{ template.created_by }}</p>
  </div>
  <div class="col-3 p-0 m-0 text-center">
    <p style="font-size: small">{{ getDate(template.created_date) | date: dateFormat }}</p>
  </div>
</div>
