import { Pipe, PipeTransform } from '@angular/core';
import {
  ActiveLookup,
  ConferencePossibleStayPeriod,
  ConferencePossibleStayPeriodKey,
  LookupKeys,
  RegistrationType,
} from 'ag-common-lib/public-api';

@Pipe({ name: 'filterRegistrationTypeLookup', pure: false })
export class FilterRegistrationTypeLookupPipe implements PipeTransform {
  transform(lookups: Partial<ActiveLookup>[], periods: ConferencePossibleStayPeriod[]): Partial<ActiveLookup>[] {
    const assignedRegistrationTypes = new Set(
      periods.map(period => period?.[ConferencePossibleStayPeriodKey.registrationType]),
    );

    return lookups.map(lookup =>
      Object.assign({}, lookup, {
        [LookupKeys.visible]: !assignedRegistrationTypes.has(lookup?.[LookupKeys.value] as RegistrationType),
      }),
    );
  }
}
