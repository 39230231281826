import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { EmailTemplate } from 'ag-common-lib/public-api';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { AuthService, FIREBASE_APP, QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateService extends DataService<EmailTemplate> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, public authService: AuthService) {
    super(fireBaseApp);
    super.setCollection('email-templates');
  }

  async createNewEmailTemplate(): Promise<EmailTemplate> {
    let emailTemplate = { ...new EmailTemplate() };
    const email = await firstValueFrom(this.authService.currentUser$.pipe(map((user) => user?.email)));
    emailTemplate.created_by = email;
    emailTemplate.created_date = new Date();

    return emailTemplate;
  }

  getEmailTemplatesByOwner(owner: string, sortField: string): Promise<EmailTemplate[]> {
    return this.getAllByValue([new QueryParam('owner', WhereFilterOperandKeys.in, owner)], sortField);
  }
}
