import { Inject, Injectable } from '@angular/core';
import { LookupBase, UserRole, UserRoleModelKeys } from '@ag-common-lib/public-api';
import { FIREBASE_APP } from '../injections/firebase-app';
import { DataService } from './data.service';
import { FirebaseApp } from 'firebase/app';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService extends DataService<UserRole> {
  private readonly userRolesCollectionPath = 'user-roles';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, UserRolesService.fromFirestore);
    super.setCollection(this.userRolesCollectionPath);
  }

  static readonly fromFirestore = (data): UserRole => {
    if (!!data?.created_date) {
      data.created_date = dateFromTimestamp(data?.created_date);
    }

    return Object.assign({}, data);
  };

  updateFields(documentId: string, data: Partial<UserRole>): Promise<UserRole> {
    return super.updateFields(documentId, data, true);
  }

  getRolesNameList(appId: string): Promise<LookupBase[]> {
    const qp: QueryParam[] = [
      new QueryParam(UserRoleModelKeys.isEnable, WhereFilterOperandKeys.equal, true),
      new QueryParam(UserRoleModelKeys.appId, WhereFilterOperandKeys.equal, appId),
    ];
    return this.getAllByValue(qp, 'role').then(roles => {
      let userRoles: LookupBase[] = [];
      if (roles.length) {
        userRoles = roles.map(item => ({
          value: item.dbId,
          description: item.role,
        }));
      }
      return userRoles;
    });
  }
}
