import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailRequest, EmailTemplate } from 'ag-common-lib/public-api';
import { AuthService } from 'ag-common-svc/public-api';
import { EmailTemplateService } from 'src/app/shared/services/email-template.service';
import { EmailConnection } from 'ag-common-lib/public-api';
import { mergeMap, Observable } from 'rxjs';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<EmailRequest> = new EventEmitter();

  @Input() isRecipientSet: boolean = false;

  @Input() email: EmailRequest;

  from_list: EmailConnection[] = [];

  emailTemplateList$: Observable<EmailTemplate[]>;

  selectedTemplate: EmailTemplate;

  showCc: boolean = false;
  showBcc: boolean = false;
  showEmailForm: boolean = false;

  @Input() canSend: boolean = false;

  emailVals: string[] = ['Recipient First Name', 'Recipient Last Name', 'Sender First Name', 'Sender Last Name'];

  constructor(public authService: AuthService, public emailTemplateService: EmailTemplateService) {}

  ngOnInit(): void {
    this.emailTemplateList$ = this.authService.loggedInAgent$.pipe(
      mergeMap(agent => {
        return this.emailTemplateService.getEmailTemplatesByOwner(agent?.p_email, 'name').then(templates => {
          let adhock: EmailTemplate = { ...new EmailTemplate() };
          adhock.name = 'Quick Email';
          templates.unshift(adhock);
          return templates;
        });
      }),
    );

    this.selectedTemplate = this.email.template;
  }

  selectTemplate(e) {
    this.selectedTemplate = e.selectedItem;

    if (this.selectedTemplate.name == 'Quick Email') {
      this.showEmailForm = true;
    }

    this.email.template = this.selectedTemplate;
  }

  save() {
    this.onClose.next(this.email);
  }

  close() {
    this.onClose.next(null);
  }

  showList = list => (Array.isArray(list) ? list.join(', ') : '');
}
