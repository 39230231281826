import { BaseModel } from '../base.model';

export enum AgentPermissionKeys {
  ownerId = 'owner_id',
  ownerName = 'owner_name',
  grantedToId = 'granted_to_id',
  grantedToName = 'granted_to_name',
}

export class AgentPermission extends BaseModel {
  [AgentPermissionKeys.ownerId]: string;
  [AgentPermissionKeys.ownerName]: string;
  [AgentPermissionKeys.grantedToId]: string;
  [AgentPermissionKeys.grantedToName]: string;
}
