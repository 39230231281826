<dx-data-grid
  #attendeeNotesGrid
  id="gridForm"
  class="ag-crm-attendee-notes__grid"
  [dataSource]="logsDataSource$ | async"
  [showColumnLines]="true"
  [showRowLines]="true"
  [rowAlternationEnabled]="false"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [columnHidingEnabled]="true"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowUpdating)="onGuestFlightUpdating($event)"
  height="100%"
>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="false" [useIcons]="true">
  </dxo-editing>

  <dxo-load-panel [enabled]="false"></dxo-load-panel>

  <dxo-toolbar>
    <dxi-item
      location="after"
      widget="dxButton"
      [options]="{ icon: 'plus', onClick: showAddLogPopup }"
      [visible]="Entity.conferenceAttendeeDetails | hasPermission : EntityPermissionActivityKeys.create | async"
    ></dxi-item>
  </dxo-toolbar>

  <dxi-column [dataField]="ConferenceRegistrationLogKeys.type" caption="Type">
    <dxo-lookup [dataSource]="REGISTRATION_LOG_TYPE_LOOKUP" valueExpr="value" displayExpr="description"> </dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationSupportLogKey.title].join('.')"
    caption="Title"
  >
  </dxi-column>
  <dxi-column
    [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationSupportLogKey.details].join('.')"
    caption="Details"
  >
  </dxi-column>

  <dxi-column
    [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationRequestLogKey.status].join('.')"
    caption="Status"
  >
    <dxo-lookup [dataSource]="REGISTRATION_LOG_STATUS_LOOKUP" valueExpr="value" displayExpr="description"> </dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationSupportLogKey.date].join('.')"
    caption="Date"
    [dataType]="'date'"
    [sortOrder]="'desc'"
    [format]="dateFormat"
  ></dxi-column>

  <dxi-column
    [dataField]="ConferenceRegistrationLogKeys.isArchived"
    caption="Is Archived"
    [groupIndex]="0"
    [calculateGroupValue]="calculateIsArchivedGroup"
    [customizeText]="customizeIsArchivedText"
  ></dxi-column>

  <dxi-column
    type="buttons"
    [width]="125"
    [fixed]="true"
    caption="Actions"
    [visible]="Entity.conferenceAttendeeDetails | hasPermission : EntityPermissionActivityKeys.write | async"
  >
    <dxi-button name="edit" [onClick]="editNote"></dxi-button>
    <dxi-button icon="box" [visible]="isActive" [onClick]="archiveLog" [hint]="'Archive'"></dxi-button>
    <dxi-button icon="revert" [visible]="isArchived" [onClick]="revertArchive" [hint]="'Make Active'"></dxi-button>
  </dxi-column>
</dx-data-grid>

<ag-crm-attendee-note-modal></ag-crm-attendee-note-modal>
