import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';
import { PushNotificationChannelsKeys } from '@ag-common-lib/lib';
import { NotificationSchedulerEditModalService } from './notification-scheduler-edit-modal.service';

@Pipe({
  name: 'notificationsChannelName',
})
export class NotificationsChannelNamePipe implements PipeTransform {
  constructor(private notificationSchedulerEditModalService: NotificationSchedulerEditModalService) {}

  transform(selectedChannelDbId: string): Observable<string> {
    return this.notificationSchedulerEditModalService.channelsMap$.pipe(
      map(channelsMap => {
        let title;

        const getChannel = (channelDbId: string) => {
          const channel = channelsMap.get(channelDbId);
          const channelName = channel?.[PushNotificationChannelsKeys.name];
          const parentChannelDbId = channel?.[PushNotificationChannelsKeys.parentChannelDbId];

          title = [channelName, title].filter(Boolean).join(' / ');

          if (parentChannelDbId) {
            getChannel(parentChannelDbId);
          }
        };

        getChannel(selectedChannelDbId);

        return title;
      }),
    );
  }
}
