import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'ag-common-svc/public-api';
import { PolicyTransaction } from 'ag-common-lib/lib/models/domain/policy-transaction.model';
import { ReportSummaryDetail } from 'ag-common-lib/public-api';
import { SummaryService } from 'ag-common-svc/lib/services/summary.service';
import { TransactionService } from 'ag-common-svc/lib/services/transactions.service';
import { ReportsService } from 'ag-common-svc/lib/services/reports.service';
import { Report } from 'ag-common-lib/lib/models/utils/report.model';
import { firstValueFrom } from 'rxjs';
import { ImportFileValidatorService } from 'ag-common-svc/lib/services/import-file-validator.service';
import { SummaryGeneratorService } from 'ag-common-svc/lib/services/summary-generator.service';
import { TransactionGrouperService } from 'ag-common-svc/lib/services/transaction-grouper.service';

@Component({
  selector: 'app-report-administration',
  templateUrl: './report-administration.component.html',
  styleUrls: ['./report-administration.component.scss'],
})
export class ReportAdministrationComponent implements OnInit {
  @ViewChild('append') append: DxSelectBoxComponent;
  @ViewChild('year') year: DxSelectBoxComponent;
  @ViewChild('reportsTable') reportsTable: DxDataGridComponent;

  dataSource: any = {};

  breadCrumbItems: Array<{}>;

  importPolicyTransactions: boolean = false;
  importSummaries: boolean = false;
  displayUploadHistory: boolean = false;

  asOfDate: any;

  paidFile: any = {};

  loadingVisible = false;

  transactionCompletionPercentage: string = '0%';
  summaryCompletionPercentage: string = '0%';

  years: number[] = [];

  files: File[] = [];

  showImportButton: boolean = false;

  messages: string[] = [];

  constructor(
    private authService: AuthService,
    private importFileValidatorService: ImportFileValidatorService,
    private summaryGeneratorService: SummaryGeneratorService,
    private summaryService: SummaryService,
    private reportsService: ReportsService,
    private transactionGrouperService: TransactionGrouperService,
    private transactionService: TransactionService,
    private toastrService: ToastrService,
  ) {
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: function (loadOptions: any) {
        return reportsService.getAll();
      },
      remove: function (id: any) {
        return reportsService.delete(id);
      },
    });
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Admin' }, { label: 'Report Administration', active: true }];

    this.getYears();
  }

  onFileUploaded(file: File, e, f) {
    this.addMessage('Uploading file... ' + file.name);
    this.files.push(file);
    this.showImportButton = true;
    this.addMessage('Uploading file... ' + file.name + '  complete');
  }

  onImportClicked() {
    this.loadingVisible = true;

    this.importFileValidatorService.importPaidFiles(this.files).then(async transactions => {
      if (!(await this.importFileValidatorService.evaluateImport(transactions, this.messages))) {
        this.addMessage('Stopping import until all records are corrected!');
        this.loadingVisible = false;
        return;
      } else {
        this.importTransactions(transactions).then(() => {
          this.loadingVisible = true;
        });
      }
    });
  }

  async importTransactions(transactions: PolicyTransaction[] = []) {
    this.paidFile.transactions = transactions.length;

    //ensure all transactions have year set
    transactions.forEach(transaction => {
      transaction.year = this.year.value;

      let transdatevals: string[] = transaction.transdate.split('/');

      transaction.transdate =
        transdatevals[2] + '/' + transdatevals[0].padStart(2, '0') + '/' + transdatevals[1].padStart(2, '0');
    });

    let transactionsWithoutAgentId = transactions.filter(transaction => transaction.agent_id == '');

    let transactionMapforAgentName: Map<string, ReportSummaryDetail> =
      this.transactionGrouperService.groupTransactionsByFieldValue(transactionsWithoutAgentId, 'agent_name');

    transactionMapforAgentName.forEach((v, k) => {
      let id = this.transactionGrouperService.uuidv4();
      v.transactions.forEach(t => {
        t.agent_id = id;
      });
    });

    //delete and save transactions
    if (this.importPolicyTransactions) {
      this.addMessage('Clearing out Transactions for ' + this.year.value);
      await this.transactionService.deleteTransactionByYear(this.year.value, this.messages).then(async val => {
        this.addMessage('Finished Clearing out Transactions');
        await this.transactionService
          .saveAllTransactions(transactions, this.messages, this.transactionCompletionPercentage)
          .catch(error => {
            console.error('Error in Report Admin.', error);
          });
      });
    }

    this.buildSummariesFromTransactions(transactions);
  }

  private async buildSummariesFromTransactions(transactions: PolicyTransaction[]) {
    this.summaryGeneratorService.generateSummaries([], transactions, this.year, this.messages).then(async summaries => {
      this.paidFile.summaries = summaries.length;

      //ensure all summaries have rmdId set
      summaries.forEach(s => {
        if (!s.rmds) s.rmds = [];

        if (!s.managerId) s.managerId = '';
      });

      //import Summaries
      if (this.importSummaries) {
        this.addMessage('Clearing out Summaries for ' + this.year.value);
        await this.summaryService.deleteSummariesByYear(this.year.value, this.messages).then(async val => {
          this.addMessage('Finished Clearing out Summaries');
          await this.summaryService
            .saveAllSummaries(summaries, this.messages, this.summaryCompletionPercentage)
            .catch(error => {
              console.error('Error in Report Admin.', error);
            });
        });
      }

      await this.wrapUpImport(transactions.length, summaries.length);
    });
  }

  private async wrapUpImport(transactioncount: number, summarycount: number) {
    //create Report
    this.addMessage('Import complete... ');
    const agent = await firstValueFrom(this.authService.loggedInAgent$);
    let r: Report = { ...new Report() };
    r.fileName = this.files[0].name;
    r.recordsImported = transactioncount;
    r.summariesGenerated = summarycount;
    r.asOfDate = this.asOfDate.toISOString();
    r.uploadDate = new Date().toISOString();
    r.uploadedBy = agent?.p_email;

    this.reportsService.create(r);

    this.loadingVisible = false;

    this.toastrService.success('Records Successfully Imported!');
  }

  showUploadHistory() {
    this.displayUploadHistory = true;
    this.reportsTable.instance.getDataSource().reload();
  }

  closeUploadHistory() {
    this.displayUploadHistory = false;
  }

  private getYears() {
    let years: number[] = [];
    let year: number = new Date().getUTCFullYear();

    for (let index = year; index > year - 3; index--) {
      years.push(index);
    }

    this.years = years;
  }

  private addMessage(message: string) {
    this.messages.unshift(message);
  }
}
