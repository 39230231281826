import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, map, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Pipe({
  name: 'editorPermittedTabs',
})
export class EditorPermittedTabsPipe implements PipeTransform {
  transform(tabs: { isPermitted?: Observable<boolean> }[], ...args: unknown[]): Observable<any[]> {
    if (!Array.isArray(tabs)) {
      return of(tabs);
    }
    return of(tabs).pipe(
      switchMap(tabs =>
        tabs.length === 0
          ? of([])
          : combineLatest(tabs.map(tab => tab.isPermitted || of(false))).pipe(
              map(permittedArray => tabs.filter((tab, index) => permittedArray[index])),
            ),
      ),
    );
  }
}
