import { Component, HostBinding, ViewChild } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  Conference,
  ConferenceKeys,
  DX_USD_CURRENCY_FORMAT,
  LookupKeys,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrationTransactionKeys,
  StripeTransactionKeys,
  TRANSACTIONS_STATUSES_LOOKUP,
  TRANSACTION_TYPE_LOOKUP, Constants,
} from 'ag-common-lib/public-api';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { ConferenceTransactionsService } from 'ag-common-svc/lib/services/conference-stripe-transactions.service';
import { ConferenceRegistrantsService } from 'ag-common-svc/public-api';
import { BehaviorSubject, mergeMap, of } from 'rxjs';

@Component({
  selector: 'ag-crm-conference-transactions-modal',
  templateUrl: './conference-transactions-modal.component.html',
  styleUrls: ['./conference-transactions-modal.component.scss'],
})
export class ConferenceTransactionsModalComponent {
  @HostBinding('class') className = 'conference-transactions-modal';
  @ViewChild('conferenceTransactionsModalRef', { static: true })
  conferenceTransactionsModalComponent: ModalWindowComponent;
  caption = 'Conference Transaction';
  eventNameTitleMask = this.caption + Constants.EVENT_NAME_TITLE;

  protected readonly emptyMessage: string = 'No Transactions';
  protected readonly TRANSACTION_TYPE_LOOKUP = TRANSACTION_TYPE_LOOKUP;
  protected readonly TRANSACTIONS_STATUSES_LOOKUP = TRANSACTIONS_STATUSES_LOOKUP;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly StripeTransactionKeys = StripeTransactionKeys;
  protected readonly RegistrationTransactionKeys = RegistrationTransactionKeys;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;

  protected conference: Conference;
  private _conferenceDbId$ = new BehaviorSubject<string>(null);

  protected transactions$ = this._conferenceDbId$.pipe(
    mergeMap(conferenceDbId => {
      if (!conferenceDbId) {
        return of([]);
      }

      return this.conferenceTransactionsService.getAllByConferenceDbId(conferenceDbId);
    }),
  );
  protected registrants$ = this._conferenceDbId$.pipe(
    mergeMap(conferenceDbId => {
      if (!conferenceDbId) {
        return of([]);
      }

      return this.conferenceRegistrantsService.getList(conferenceDbId);
    }),
  );

  constructor(
    private conferenceTransactionsService: ConferenceTransactionsService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
  ) {}

  showModal = (conference: Conference) => {
    const conferenceDbId = conference?.[BaseModelKeys.dbId];
    this._conferenceDbId$.next(conferenceDbId);
    this.conference = conference;
    this.conferenceTransactionsModalComponent.showModal();
  };

  protected registrantDisplayExpr = (registrant: Registrant) => {
    const registrantData = registrant?.[RegistrantModelKeys.data];
    const fullName =
      [registrantData[RegistrantKeys.firstName], registrantData[RegistrantKeys.lastName]].filter(Boolean).join(' ') +
      ` (${registrantData?.[RegistrantKeys.inviteeEmail]})`;
    return fullName;
  };

  protected async onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Transaction');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Conference ${
            this.conference?.[ConferenceKeys.eventName]
          } Transactions (${new Date().toLocaleDateString()}).xlsx`,
        );
      });
    });
  }

  protected readonly ConferenceKeys = ConferenceKeys;
}
