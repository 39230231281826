import { Pipe, PipeTransform } from '@angular/core';
import { isValid, isWithinInterval, toDate } from 'date-fns';
import { CellTemplateData } from 'devextreme/ui/calendar';
import { ConferencePossibleStayPeriod, ConferencePossibleStayPeriodKey } from '@ag-common-lib/public-api';

@Pipe({
  name: 'mainDateClass',
})
export class MainDateClassPipe implements PipeTransform {
  transform(data: CellTemplateData, conferencePossibleStayPeriod: ConferencePossibleStayPeriod): string {
    if (
      [
        data.date,
        conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryArrivalDate],
        conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryDepartureDate],
      ].some(date => !isValid(toDate(date)))
    ) {
      return '';
    }
    const isMainDate = isWithinInterval(data.date, {
      start: conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryArrivalDate],
      end: conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.primaryDepartureDate],
    });

    if (isMainDate) {
      return 'hotel-reservation-form__cell--main';
    }

    return '';
  }
}
