import { Injectable } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { AttendeeDetailsModalService } from './attendee-details-modal.service';
import { GuestKeys, HotelReservationKeys, RegistrantKeys, RegistrantModelKeys } from 'ag-common-lib/public-api';
import { FlightInfoKeys } from 'ag-common-lib/lib/models/registration/flight-information.model';

@Injectable()
export class AttendeeDetailsModalRequestsService {
  constructor(private attendeeDetailsModalService: AttendeeDetailsModalService) {}

  hasNotResolvedGuestSectionRequests$ = this.attendeeDetailsModalService.registrant$.pipe(
    map(registrant => registrant?.[RegistrantModelKeys.data]),
    map(
      registrantData =>
        registrantData?.[RegistrantKeys.additionalGuestRequested] &&
        !registrantData?.[RegistrantKeys.additionalGuestRequestOutcome],
    ),
    map(additionalGuestRequested => {
      if (additionalGuestRequested) {
        return true;
      }
      return false;
    }),
  );

  hasNotResolvedRoomRequest$ = this.attendeeDetailsModalService.registrant$.pipe(
    map(registrant => registrant?.[RegistrantModelKeys.data]),
    map(registrantData => registrantData?.[RegistrantKeys.hotelReservation]),
    map(hotelReservation => {
      const additionalRoomRequestOutcome = hotelReservation?.[HotelReservationKeys.additionalRoomRequestOutcome];
      const isAdditionalRoomRequested = hotelReservation?.[HotelReservationKeys.additionalRoomRequested];

      if (isAdditionalRoomRequested && !additionalRoomRequestOutcome) {
        return true;
      }
      return false;
    }),
  );

  hasNotResolvedBookingDatesRequests$ = combineLatest({
    isRequestedDifferentTravelDates: this.attendeeDetailsModalService.isRequestedDifferentHotelCheckInCheckOutDates$,
    requestDifferBookingDatesOutcome: this.attendeeDetailsModalService.registrant$.pipe(
      map(registrant => registrant?.[RegistrantModelKeys.data]),
      map(registrantData => registrantData?.[RegistrantKeys.hotelReservation]),
      map(hotelReservation => hotelReservation?.[HotelReservationKeys.requestDifferBookingDatesOutcome]),
    ),
  }).pipe(
    map(({ isRequestedDifferentTravelDates, requestDifferBookingDatesOutcome }) => {
      if (isRequestedDifferentTravelDates && !requestDifferBookingDatesOutcome) {
        return true;
      }
      return false;
    }),
  );

  hasNotResolvedHotelReservationSectionRequests$ = combineLatest([
    this.hasNotResolvedRoomRequest$,
    this.hasNotResolvedBookingDatesRequests$,
  ]).pipe(
    map(requests => {
      return requests?.some(Boolean);
    }),
  );

  hasNotResolvedFlightInfoSectionRequests$ = combineLatest({
    registrant: this.attendeeDetailsModalService.registrant$,
    registrantGuests: this.attendeeDetailsModalService.registrantGuests$,
  }).pipe(
    map(({ registrant, registrantGuests }) => {
      const flightInformation = registrant?.[RegistrantModelKeys.data]?.[RegistrantKeys.flightInformation];
      const flightsData = [flightInformation, ...registrantGuests?.map(guest => guest?.[GuestKeys.flightInformation])];

      if (
        flightsData?.length &&
        flightsData?.some(flightInformation => {
          return (
            flightInformation?.[FlightInfoKeys.travelDatesDiffer] &&
            !flightInformation?.[FlightInfoKeys.travelDatesDifferRequestOutcome]
          );
        })
      ) {
        return true;
      }
      return false;
    }),
  );
}
