import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../attendee-change-log-detail/attendee-change-log-detail.module';
import { ChangeLogPipesModule } from '../pipes/change-log-pipes.module';
import { AttendeeChangeLogDetailByTypeComponent } from './attendee-change-log-detail-by-type.component';

@NgModule({
  declarations: [AttendeeChangeLogDetailByTypeComponent],
  imports: [CommonModule, SharedModule, AttendeeChangeLogDetailModule, ChangeLogPipesModule],
  exports: [AttendeeChangeLogDetailByTypeComponent],
})
export class AttendeeChangeLogDetailByTypeModule {}
