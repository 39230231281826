import { NgModule } from '@angular/core';
import { ChangeLogExcursionConfigurationsComponent } from './change-log-excursion-configurations.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../../attendee-change-log-detail/attendee-change-log-detail.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogExcursionConfigurationsComponent],
    imports: [ CommonModule, SharedModule, AttendeeChangeLogDetailModule, ChangeLogPipesModule ],
  exports: [ChangeLogExcursionConfigurationsComponent],
})
export class ChangeLogExcursionConfigurationsModule {}
