import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AddNotificationOptInGroupModalComponent } from './add-notification-opt-in-group-modal.component';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { DxFilterBuilderModule, DxValidatorModule } from 'devextreme-angular';

@NgModule({
  declarations: [AddNotificationOptInGroupModalComponent],
  imports: [CommonModule, SharedModule, ModalWindowModule, DxValidatorModule, DxFilterBuilderModule],
  exports: [AddNotificationOptInGroupModalComponent],
})
export class AddNotificationOptInGroupModalModule {}
