import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxAccordionModule,
  DxButtonGroupModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxColorBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDrawerModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxFilterBuilderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxListModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxProgressBarModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSortableModule,
  DxSwitchModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeListModule,
} from 'devextreme-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgentsSelectionComponent } from './selections/agents-selection/agents-selection.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { DistributionListsGridComponent } from './email/grids/distribution-lists-grid/distribution-lists-grid.component';
import { DistributionListManagementComponent } from './email/details/distribution-list-management/distribution-list-management.component';
import { DistributionListInfoComponent } from './email/details/distribution-list-management/distribution-list-info/distribution-list-info.component';
import { DistributionListDetailInfoComponent } from './email/details/distribution-list-management/distribution-list-detail-info/distribution-list-detail-info.component';
import { DistributionListActivityInfoComponent } from './email/details/distribution-list-management/distribution-list-activity-info/distribution-list-activity-info.component';
import { EmailTemplatesGridComponent } from './email/grids/email-templates-grid/email-templates-grid.component';
import { EmailTemplateManagementComponent } from './email/details/email-template-management/email-template-management.component';
import { EmailTemplateActivityInfoComponent } from './email/details/email-template-management/email-template-activity-info/email-template-activity-info.component';
import { EmailTemplateDetailInfoComponent } from './email/details/email-template-management/email-template-detail-info/email-template-detail-info.component';
import { EmailTemplateInfoComponent } from './email/details/email-template-management/email-template-info/email-template-info.component';
import { ToastrModule } from 'ngx-toastr';
import { SummarySelectionComponent } from './selections/summary-selection/summary-selection.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { MyCalendarComponent } from './home/my-calendar/my-calendar.component';
import { EmailAccordianBodyTemplateComponent } from './common/shared/email-accordian-body-template/email-accordian-body-template.component';
import { EmailAccordianHeaderTemplateComponent } from './common/shared/email-accordian-header-template/email-accordian-header-template.component';
import { EmailSummaryViewComponent } from './common/email/email-summary-view/email-summary-view.component';
import { ProfileComponent } from './profile/profile.component';
import { GeneralSettingsComponent } from './profile/general-settings/general-settings.component';
import { SecuritySettingsComponent } from './profile/security-settings/security-settings.component';
import { ProfileTabComponent } from './profile/general-settings/profile-tab/profile-tab.component';
import { EmailTabComponent } from './profile/general-settings/email-tab/email-tab.component';
import { CalendarTabComponent } from './profile/general-settings/calendar-tab/calendar-tab.component';
import { RegistrationReportsComponent } from './reports/registration-reports/registration-reports.component';
import { StatusReportsComponent } from './reports/status-reports/status-reports.component';
import { TestComponent } from './contacts/test/test.component';
import { AgCrmCommonModule } from './common/ag-crm-common.module';
import { ChristmasCardListComponent } from './reports/christmas-card-list/christmas-card-list.component';
import { ConferencePostersListComponent } from './reports/conference-posters-list/conference-posters-list.component';

@NgModule({
  declarations: [
    AgentsSelectionComponent,
    DistributionListsGridComponent,
    DistributionListManagementComponent,
    DistributionListInfoComponent,
    DistributionListDetailInfoComponent,
    DistributionListActivityInfoComponent,
    EmailTemplatesGridComponent,
    EmailTemplateManagementComponent,
    EmailTemplateActivityInfoComponent,
    EmailTemplateDetailInfoComponent,
    EmailTemplateInfoComponent,
    SummarySelectionComponent,
    HomeComponent,
    MyCalendarComponent,
    EmailAccordianBodyTemplateComponent,
    EmailAccordianHeaderTemplateComponent,
    EmailSummaryViewComponent,

    ProfileComponent,
    GeneralSettingsComponent,
    SecuritySettingsComponent,
    ProfileTabComponent,
    EmailTabComponent,
    CalendarTabComponent,
    RegistrationReportsComponent,
    StatusReportsComponent,
    TestComponent,
    ChristmasCardListComponent,
    ConferencePostersListComponent,
  ],
  imports: [
    CommonModule,
    AgCrmCommonModule,
    LayoutsModule,
    FormsModule,
    ToastrModule.forRoot(),

    BrowserAnimationsModule,
    DxDataGridModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTabsModule,
    DxButtonModule,
    DxNumberBoxModule,
    DxSelectBoxModule,
    DxDropDownButtonModule,
    DxAccordionModule,
    DxScrollViewModule,
    DxToolbarModule,
    DxFormModule,
    DxPopupModule,
    DxFilterBuilderModule,
    DxListModule,
    DxButtonGroupModule,
    DxHtmlEditorModule,
    DxSwitchModule,
    DxSchedulerModule,
    DxTagBoxModule,
    DxColorBoxModule,
    DxSortableModule,
    DxTreeListModule,
    DxDrawerModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxFileUploaderModule,
    DxProgressBarModule,
  ],
})
export class AgCrmModule {}
