import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeLogFiledsNamePipe } from './change-log-fileds-name.pipe';
import { ChangeLogsDiffPipe } from './change-logs-diff.pipe';
import { ChangeLogsDiffHtmlPipe } from './change-logs-diff-html.pipe';
import { ChangeLogExcursionNameByIdPipe } from './change-log-excursion-name-by-id.pipe';
import { ChangeLogChildKeyPipe } from './change-log-child-key.pipe';
import { ChangeLogDiffByKeysPipe } from './change-log-diff-by-keys.pipe';

@NgModule({
  declarations: [
    ChangeLogFiledsNamePipe,
    ChangeLogsDiffPipe,
    ChangeLogsDiffHtmlPipe,
    ChangeLogExcursionNameByIdPipe,
    ChangeLogChildKeyPipe,
    ChangeLogDiffByKeysPipe,
  ],
  imports: [CommonModule],
  exports: [
    ChangeLogFiledsNamePipe,
    ChangeLogsDiffPipe,
    ChangeLogsDiffHtmlPipe,
    ChangeLogExcursionNameByIdPipe,
    ChangeLogChildKeyPipe,
    ChangeLogDiffByKeysPipe,
  ],
})
export class ChangeLogPipesModule {}
