import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogsManagerComponent } from './logs-manager.component';
import { LogsRouting } from './logs-routing.module';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { LogsManagerDiffPipe } from './logs-manager-diff.pipe';

@NgModule({
  declarations: [LogsManagerComponent, LogsManagerDiffPipe],
  imports: [LogsRouting, CommonModule, SharedModule],
    exports: [
        LogsManagerDiffPipe
    ],
})
export class LogsManagerModule {}
