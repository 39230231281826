import { Pipe, PipeTransform } from '@angular/core';
import { AddressModelKeys, AgentKeys, ElasticSearchAgent } from 'ag-common-lib/public-api';
import { PrimaryBillingAddressPipe } from 'ag-common-svc/shared/pipes/primary-billing-address.pipe';
import { PrimaryShippingAddressPipe } from 'ag-common-svc/shared/pipes/primary-shipping-address.pipe';

@Pipe({ name: 'calculateAddressFieldDisplayValue' })
export class CalculateAddressFieldDisplayValuePipe implements PipeTransform {
  constructor(
    private primaryBillingAddressPipe: PrimaryBillingAddressPipe,
    private primaryShippingAddressPipe: PrimaryShippingAddressPipe,
  ) {}
  transform(addressField: AddressModelKeys, type: 'shipping' | 'billing'): any {
    return (agent: ElasticSearchAgent) => {
      const addresses = agent?.[AgentKeys.addresses];

      if (type === 'billing') {
        return this.primaryBillingAddressPipe.transform(addresses)?.[addressField] ?? '';
      }
      if (type === 'shipping') {
        return this.primaryShippingAddressPipe.transform(addresses)?.[addressField] ?? '';
      }
      return '';
    };
  }
}
