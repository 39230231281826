import { Pipe, PipeTransform } from '@angular/core';
import {
  ConferenceRegistrationStepName,
  ConferenceStepsConfiguration,
  ConferenceStepsConfigurationKeys,
  DECLINED_SUMMARY_STEPS_TEMPLATE,
  EXCURSIONS_INFO_STEPS_TEMPLATE,
  YOUR_EXCURSIONS_INFO_STEPS_TEMPLATE,
  FLIGHT_INFO_STEPS_TEMPLATE,
  GUEST_STEPS_TEMPLATE,
  HOTEL_STEPS_TEMPLATE,
  CONFIRMATION_STEPS_TEMPLATE,
  STEPS_TEMPLATE,
  StepsTemplate,
  StepsTemplateKey,
  SUMMARY_STEPS_TEMPLATE,
} from '@ag-common-lib/public-api';

@Pipe({
  name: 'conferenceStepsDescription',
})
export class ConferenceStepsDescriptionPipe implements PipeTransform {
  transform(configuration: ConferenceStepsConfiguration): string {
    if (!configuration) {
      return null;
    }
    const stepId = configuration?.[ConferenceStepsConfigurationKeys.stepName];
    const template = configuration?.[ConferenceStepsConfigurationKeys.template];

    return this.getStepTemplateLookup(stepId).find(item => item[StepsTemplateKey.value] === template)[
      StepsTemplateKey.htmlContent
    ];
  }

  getStepTemplateLookup = (stepId: ConferenceRegistrationStepName): StepsTemplate[] => {
    switch (stepId) {
      case ConferenceRegistrationStepName.registrationSummaryStep:
        return SUMMARY_STEPS_TEMPLATE;

      case ConferenceRegistrationStepName.registrationDeclinedSummaryStep:
        return DECLINED_SUMMARY_STEPS_TEMPLATE;

      case ConferenceRegistrationStepName.registrantGuestConfigurationStep:
        return GUEST_STEPS_TEMPLATE;

      case ConferenceRegistrationStepName.registrantHotelReservationStep:
        return HOTEL_STEPS_TEMPLATE;

      case ConferenceRegistrationStepName.registrantFlightInformationStep:
        return FLIGHT_INFO_STEPS_TEMPLATE;

      case ConferenceRegistrationStepName.registrantExcursionsStep:
        return EXCURSIONS_INFO_STEPS_TEMPLATE;

      case ConferenceRegistrationStepName.registrantExcursionYourSelectionStep:
        return YOUR_EXCURSIONS_INFO_STEPS_TEMPLATE;

      case ConferenceRegistrationStepName.registrationConfirmationStep:
        return CONFIRMATION_STEPS_TEMPLATE;

      default:
        return STEPS_TEMPLATE;
    }
  };
}
