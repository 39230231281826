import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { Agent, AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { Agency, AgencyKeys } from 'ag-common-lib/lib/models/domain/agency.model';
import { MgaFormService } from './mga-form.service';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { AgencyService } from 'ag-common-svc/lib/services/agency.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { AGENCY_TYPE } from 'ag-common-lib/lib';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ag-crm-mga-editor-modal',
  templateUrl: './mga-editor-modal.component.html',
  styleUrls: ['./mga-editor-modal.component.scss'],
  providers: [MgaFormService],
})
export class MgaEditorModalComponent implements OnInit {
  @ViewChild('mgaFormRef', { static: false }) mgaFormComponent: DxFormComponent;
  @ViewChild('mgaModalRef', { static: true }) mgaModalComponent: ModalWindowComponent;
  @ViewChild('agencyList', { static: false }) agencySelectBoxComponent: DxSelectBoxComponent;
  @ViewChild('managerList', { static: false }) managerSelectBoxComponent: DxSelectBoxComponent;

  filteredManagers$ = this.mgaFormService.filteredManagers$;
  filteredAgencies$ = new BehaviorSubject<Agency[]>([]);
  filteredMGAs: Agent[] = [];

  agent: Agent;
  agencies: Agency[] = [];
  mgaFormData: Partial<Agent>;
  inProgress$ = this.mgaFormService.inProgress$;

  protected readonly AgentKeys = AgentKeys;

  constructor(
    public agentService: AgentService,
    public agencyService: AgencyService,
    private mgaFormService: MgaFormService,
  ) {}

  ngOnInit(): void {
    this.agencyService.getAll('name').then(agencies => {
      this.agencies = agencies;
      this.filteredMGAs = agencies.filter(agency => agency.agency_type == AGENCY_TYPE.MGA);
    });
  }

  onMGAChanged(e): void {
    //if MGA Box is changed - clear all select list boxes
    this.agencySelectBoxComponent.value = '';
    this.managerSelectBoxComponent.value = '';
    this.mgaFormData.p_mga_id = e.value ?? null;

    if (!e.value) {
      //if MGA Box is cleared - set lists to empty
      this.filteredAgencies$.next([]);
      this.filteredManagers$.next([]);
    } else {
      //if MGA Box is NOT cleared - build lists
      // get agencies belonging to MGA
      this.getFilteredAgenciesByMga(e.value);
      //refresh agency list
      this.agencySelectBoxComponent.instance.getDataSource().reload();

      //get all MGA managers
      this.mgaFormService.buildManagersList(Object.assign({}, this.agent, this.mgaFormData));
    }
  }

  onAgencyChanged(e): void {
    //if MGA Box is cleared or Agency is changed
    this.managerSelectBoxComponent.value = '';
    this.mgaFormData.p_agency_id = e.value ?? null;

    this.mgaFormService.buildManagersList(Object.assign({}, this.agent, this.mgaFormData));
  }

  onAfterRevertChanges = (): void => {
    this.mgaFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.mgaFormService.onCancelEdit.call(this, this.onAfterRevertChanges);

  handleSaveMga = e => {
    const validationResults = this.mgaFormComponent.instance.validate();
    if (validationResults.isValid) {
      const formData = this.mgaFormData;
      const changes = {
        [AgentKeys.p_mga_id]: formData?.p_mga_id,
        [AgentKeys.p_agency_id]: formData?.p_agency_id,
        [AgentKeys.is_manager]: formData?.is_manager,
        [AgentKeys.is_credited]: formData?.is_credited,
        [AgentKeys.upline]: formData?.upline ?? null,
        [AgentKeys.manager_id]: formData?.manager_id,
      };

      this.mgaFormService.updateMGA(this.agent?.[BaseModelKeys.dbId], changes).then(() => {
        this.hideModal();
      });
    }
  };

  showModal = async (data: Agent): Promise<void> => {
    this.agent = data;
    this.mgaFormService.buildManagersList(data);
    this.mgaFormData = await this.mgaFormService.getFormData(data);
    if (this.agent?.[AgentKeys.p_mga_id]) {
      this.getFilteredAgenciesByMga(this.agent[AgentKeys.p_mga_id]);
    }
    this.mgaModalComponent?.showModal();
  };

  hideModal = (): void => {
    this.mgaModalComponent.hideModal();
  };

  private getFilteredAgenciesByMga(mgaId: string): void {
    const agenciesByMga = this.agencies.filter(agency => this.filterByMGA(agency, mgaId)).sort(this.sortByAgencyType);
    this.filteredAgencies$.next(agenciesByMga ?? []);
  }

  private filterByMGA = (agency: Agency, mgaId: string): boolean => {
    switch (agency[AgencyKeys.agencyType]) {
      case AGENCY_TYPE.MGA:
        return agency[AgencyKeys.agencyId] == mgaId;
      case AGENCY_TYPE.AGENCY:
        return agency[AgencyKeys.mga] == mgaId;
    }
  };

  private sortByAgencyType = (a, b): number =>
    a[AgencyKeys.agencyType] > b[AgencyKeys.agencyType]
      ? -1
      : a[AgencyKeys.agencyType] < b[AgencyKeys.agencyType]
      ? 1
      : 0;
}
