import { AssociationKeys, AttendeeType, FlightInfoKeys, GuestKeys, RegistrantKeys } from '@ag-common-lib/public-api';
import { Pipe, PipeTransform } from '@angular/core';
import { FormFieldVisiblePipe } from 'ag-common-svc/shared/pipes/conference-registration/form-field-visible.pipe';
import { map, Observable, shareReplay } from 'rxjs';
import { AttendeeFlightService } from '../attendee-flight.service';

@Pipe({
  name: 'isFlightFieldsExcluded',
})
export class IsFlightFieldsExcludedPipe implements PipeTransform {
  constructor(
    private formFieldVisiblePipe: FormFieldVisiblePipe,
    private attendeeFlightService: AttendeeFlightService,
  ) {}

  transform(attendeeType: AttendeeType, fieldKey: FlightInfoKeys | 'dob' | 'gender'): Observable<boolean> {
    const isInviteeType = attendeeType === AttendeeType.Invitee;
    const flightInformationKey = isInviteeType ? RegistrantKeys.flightInformation : GuestKeys.flightInformation;
    let keys = [flightInformationKey, fieldKey].join('.');
    switch (fieldKey) {
      case 'dob':
        keys = [isInviteeType ? RegistrantKeys.dob : RegistrantKeys.isGuestsAttended, AssociationKeys.dob].join('.');
        break;
      case 'gender':
        keys = [isInviteeType ? RegistrantKeys.gender : RegistrantKeys.isGuestsAttended, AssociationKeys.gender].join(
          '.',
        );
        break;
    }
    return this.attendeeFlightService.getExcludedFields(attendeeType).pipe(
      map(excludedFields => this.formFieldVisiblePipe.transform(excludedFields, keys)),
      shareReplay(1),
    );
  }
}
