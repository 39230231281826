export const getJsonFromWasabiFile = async (path: string) => {
  if (!path) {
    return null;
  }

  const response = await fetch(path);

  if (!response.ok) {
    return null;
  }

  const json = await response.json();

  if (!json) {
    return null;
  }

  return json;
};
