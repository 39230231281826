import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { Email, EmailRequest } from 'ag-common-lib/public-api';
import { EmailService } from 'src/app/shared/services/email.service';

@Component({
  selector: 'app-summary-email-view',
  templateUrl: './email-summary-view.component.html',
  styleUrls: ['./email-summary-view.component.scss'],
})
export class EmailSummaryViewComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @Input() email: EmailRequest;

  emailResponses: Email[] = [];

  emailsSent: number = 0;
  emailsAccepted: number = 0;
  emailsRejected: number = 0;
  emailsViewed: number = 0;
  emailsNotViewed: number = 0;
  emailsUniqueViews: number = 0;

  constructor(public emailService: EmailService) {}

  ngOnInit(): void {
    this.emailService.getEmailByEmailId(this.email.dbId).then(responses => {
      this.emailResponses = responses;
      this.emailsSent = responses.length;

      this.emailResponses.forEach(response => {
        this.emailsAccepted = this.emailsAccepted + response.accepted;
        this.emailsRejected = this.emailsRejected + response.rejected;
        this.emailsViewed = this.emailsViewed + response.viewed;
        this.emailsNotViewed = this.emailsNotViewed + (response.viewed == 0 ? 0 : 1);
        this.emailsUniqueViews = this.emailsUniqueViews + (response.viewed > 0 ? 1 : 0);
      });
    });
  }

  getDate(date: Timestamp) {
    if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    } else {
      return '';
    }
  }
}
