import { Injectable } from '@angular/core';
import { RowUpdatingEvent } from 'devextreme/ui/data_grid';
import { Agent, AgentKeys, BaseModelKeys, Registrant, RegistrantModelKeys, Role } from 'ag-common-lib/public-api';
import { mergeMap, Observable, shareReplay } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { ConferenceRegistrationLogsService } from 'ag-common-svc/lib/services/conference-registration-logs.service';
import {
  ConferenceRegistrationLog,
  ConferenceRegistrationLogKeys,
} from 'ag-common-lib/lib/models/registration/registrant-notes';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { LoadOptions } from 'devextreme/data';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class AttendeeNotesService {
  registrantNotes$: Observable<ConferenceRegistrationLog[]>;
  agentsDataSource: DataSource;

  private _conferenceDbId: string;
  get conferenceDbId() {
    return this._conferenceDbId;
  }

  private _registrantDbId: string;
  get registrantId() {
    return this._registrantDbId;
  }

  protected readonly agentsLoadOptions: LoadOptions<Agent> = {
    filter: [{ terms: { [AgentKeys.role]: [Role.CONFERENCE_MANAGER] } }],
  };

  constructor(
    private conferenceRegistrationLogsService: ConferenceRegistrationLogsService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private agentElasticSearchService: AgentElasticSearchService,
    private agentService: AgentService,
  ) {
    this.registrantNotes$ = this.attendeeDetailsModalService.registrant$.pipe(
      mergeMap((conferenceRegistration: Registrant) => {
        this._conferenceDbId = conferenceRegistration?.[RegistrantModelKeys.conferenceDbId];
        this._registrantDbId = conferenceRegistration?.[BaseModelKeys.dbId];

        return this.conferenceRegistrationLogsService.getList(this._conferenceDbId, this._registrantDbId);
      }),
      shareReplay(1),
    );

    this.agentsDataSource = this.agentElasticSearchService.getDataSource(this.agentsLoadOptions);
  }

  onNoteUpdating = (e: RowUpdatingEvent) => {
    const registrationLog: ConferenceRegistrationLog = e.oldData;
    const updates: ConferenceRegistrationLog = e.newData;
    const registrantNoteDbI = registrationLog?.[BaseModelKeys.dbId];

    e.cancel = this.conferenceRegistrationLogsService
      .update(this._conferenceDbId, this._registrantDbId, registrantNoteDbI, updates, true)
      .then(() => undefined);
  };

  archive = (registrantNoteDbI: string) => {
    const updates = { [ConferenceRegistrationLogKeys.isArchived]: true };

    this.conferenceRegistrationLogsService
      .update(this._conferenceDbId, this._registrantDbId, registrantNoteDbI, updates, true)
      .then(() => undefined);
  };

  revertArchive = (registrantNoteDbI: string) => {
    const updates = { [ConferenceRegistrationLogKeys.isArchived]: false };

    this.conferenceRegistrationLogsService
      .update(this._conferenceDbId, this._registrantDbId, registrantNoteDbI, updates, true)
      .then(() => undefined);
  };
}
