import { Pipe, PipeTransform } from '@angular/core';
import {
  RequestOutcome,
  RequestOutcomeKeys,
  RequestOutcomeState,
} from 'ag-common-lib/lib/models/utils/request-outcome.model';

@Pipe({
  name: 'requestOutcomeIconClass',
})
export class RequestOutcomeIconClassPipe implements PipeTransform {
  transform(requestOutcome: RequestOutcome, requestOutcomeState: RequestOutcomeState): string {
    return !!requestOutcome?.[RequestOutcomeKeys.state] &&
      requestOutcome?.[RequestOutcomeKeys.state] !== requestOutcomeState
      ? 'request-button-muted'
      : '';
  }
}
