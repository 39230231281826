import { Component, ViewChild } from '@angular/core';
import { GUEST_TYPES_LOOKUP, GuestData, GuestKeys } from 'ag-common-lib/lib/models/registration/guest.model';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { AttendeeGuestModalService } from './attendee-guest-modal.service';
import { DxFormComponent } from 'devextreme-angular';
import { Association, AssociationKeys } from 'ag-common-lib/lib/models/utils/association.model';
import { LookupKeys } from 'ag-common-lib/lib/models/crm/lookup.model';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { format } from 'date-fns';
import {
  DATE_NOW,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  MIN_DATE,
} from 'ag-common-lib/lib/constants/date.constants';
import { Constants, EditorOptions, Messages, Patterns } from 'ag-common-lib/lib/constants/validation.constants';
import { LookupsService } from 'ag-common-svc/lib/services';
import { InviteeAgeGroup } from 'ag-common-lib/lib/models/registration/registrants.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { InitializedEvent } from 'devextreme/ui/form';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { ConferenceGuestsStepsConfigurationSectionName } from 'ag-common-lib/lib/models/registration/conference-steps-configuration.model';
import { Entity } from 'ag-common-lib/lib/lists/entity-permission.enum';
import { EntityPermissionActivityKeys } from 'ag-common-lib/lib/models/utils/entity-permission.model';

@Component({
  selector: 'ag-crm-attendee-guest-modal',
  templateUrl: './attendee-guest-modal.component.html',
  styleUrls: ['./attendee-guest-modal.component.scss'],
})
export class AttendeeGuestModalComponent {
  @ViewChild('guestModalComponentRef', { static: true }) guestModalComponent: ModalWindowComponent;
  @ViewChild('guestFormRef', { static: false }) guestFormComponent: DxFormComponent;

  guestFormData: GuestData;
  title = 'Guest Information';
  activity: EntityPermissionActivityKeys;

  inProgress$ = this.attendeeGuestModalService.inProgress$;
  conferenceGuestExcludedSections$ = this.attendeeGuestModalService.conferenceGuestExcludedSections$;

  protected readonly validationGroup = 'ConferenceRegistrationGuestDataValidationGroup';
  protected readonly dietaryConsiderationValidationGroup = 'dietaryConsiderationValidationGroup';
  protected readonly tShirtSizesValidationGroup = 'tShirtSizesValidationGroup';
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AssociationKeys = AssociationKeys;
  protected readonly GuestKeys = GuestKeys;
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly Messages = Messages;
  protected readonly LookupKeys = LookupKeys;
  protected readonly ConferenceGuestsStepsConfigurationSectionName = ConferenceGuestsStepsConfigurationSectionName;
  protected readonly gendersLookup$ = this.lookupsService.gendersLookup$;
  protected readonly maxDate: Date = DATE_NOW;
  protected readonly minDate: Date = MIN_DATE;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly dateValidation: string | RegExp = Patterns.DATE_PATTERN_MMDDYYYY;
  protected readonly dateEditorOptions = {
    ...EditorOptions.DATE,
    min: this.minDate,
    max: this.maxDate,
    dateSerializationFormat: LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
    dateOutOfRangeMessage: `Please enter a date between ${format(this.minDate, Constants.DISPLAY_DATE_FORMAT)} and
      ${format(this.maxDate, Constants.DISPLAY_DATE_FORMAT)}`,
  };
  protected readonly guestTypesLookup = GUEST_TYPES_LOOKUP;

  protected readonly agentAssociations$: Observable<Association[]>;
  protected selectedAssociationKey: string;

  protected readonly prefixesLookup$ = this.lookupsService.prefixesLookup$;
  protected readonly suffixesLookup$ = this.lookupsService.suffixesLookup$;
  protected readonly colCountByScreen = {
    lg: 12,
    md: 1,
  };
  protected readonly ageGroups = [
    { [LookupKeys.value]: InviteeAgeGroup.adult, [LookupKeys.description]: 'Adult Shirt' },
    { [LookupKeys.value]: InviteeAgeGroup.child, [LookupKeys.description]: 'Children Shirt' },
  ];
  protected readonly propertiesToAssign = new Set(Object.values(AssociationKeys));
  protected formSize$ = new BehaviorSubject(null);

  constructor(private attendeeGuestModalService: AttendeeGuestModalService, private lookupsService: LookupsService) {
    this.agentAssociations$ = attendeeGuestModalService.agentAssociations$;
  }

  showModal = async (conferenceId: string, registrantId: string, data?: GuestData): Promise<void> => {
    this.activity = !data ? EntityPermissionActivityKeys.create : EntityPermissionActivityKeys.write;
    this.guestFormData = await this.attendeeGuestModalService.getFormData(conferenceId, registrantId, data);
    this.guestModalComponent?.showModal();
  };

  handleSaveApproveDenyReason = async e => {
    const isValid = await this.validateGroupRules();

    if (isValid) {
      this.attendeeGuestModalService.saveGuest().then(() => {
        e.component.instance.hide();
      });
    }
  };

  onAfterRevertChanges = (): void => {
    this.guestFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.attendeeGuestModalService.onCancelEdit.call(this, this.onAfterRevertChanges);

  protected onAssociationChanged = this.attendeeGuestModalService.onAssociationChanged;

  protected getAssociationKey = this.attendeeGuestModalService.getAssociationKey;

  protected hotelRulesValidationCallback = this.attendeeGuestModalService.hotelRulesValidationCallback;

  protected onFormInitialized = (e: InitializedEvent) => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };

  private validateGroupRules(): Promise<boolean> {
    const validationGroups = [this.validationGroup];
    const formChangesDetector = this.attendeeGuestModalService.formChangesDetector;
    if (formChangesDetector?.hasChanges) {
      const changes = formChangesDetector.getAllChanges();
      changes.forEach(([key, value]) => {
        const keyIndex = key.indexOf('.');
        const registrationKey = keyIndex !== -1 ? key.substring(0, keyIndex) : key;
        switch (registrationKey) {
          case AssociationKeys.tShirtSizes:
            validationGroups.push(this.tShirtSizesValidationGroup);
            break;
          case AssociationKeys.dietaryConsideration:
            validationGroups.push(this.dietaryConsiderationValidationGroup);
            break;
          default:
            break;
        }
      });
    }
    return validateDxGroups(validationGroups);
  }

  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
}
