<dx-form [colCount]="12" [formData]="agent" [readOnly]="true" [labelLocation]="'top'">
  <dxi-item itemType="group" caption="Prospect Information" [colCount]="12" [colSpan]="12">
    <dxi-item dataField="p_agent_first_name" [colSpan]="6">
      <dxo-label text="First Name"></dxo-label>
    </dxi-item>
    <dxi-item dataField="p_agent_last_name" [colSpan]="6">
      <dxo-label text="Last Name"></dxo-label>
    </dxi-item>
  </dxi-item>
</dx-form>
<dx-form [colCount]="12" [formData]="selectedProspect" [labelLocation]="'top'">
  <dxi-item itemType="group" caption="Prospect Experience" [colCount]="12" [colSpan]="12">
    <dxi-item
      dataField="currently_licensed"
      [colSpan]="4"
      [editorType]="'dxSelectBox'"
      [editorOptions]="{ items: [yesNoType.Yes, yesNoType.No] }"
    >
      <dxo-label text="Is the prospeect currently licensed?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="years_in_industry" [colSpan]="4" [editorType]="'dxNumberBox'">
      <dxo-label text="How many years has the prospect been in the industry?"></dxo-label>
    </dxi-item>
    <dxi-item
      dataField="states_licensed_in"
      [colSpan]="4"
      [editorType]="'dxTagBox'"
      [editorOptions]="{
        items: statesLookup$ | async,
        displayExpr: 'description',
        valueExpr: 'value',
        searchEnabled: true
      }"
    >
      <dxo-label text="What states is the propect licensed in?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="prospect_agency_name" [colSpan]="4">
      <dxo-label text="What is the prospects current agency?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="prospect_current_imo" [colSpan]="4">
      <dxo-label text="What is the prospects current IMO?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="carriers_contracted_with" [colSpan]="4">
      <dxo-label text="Which Carriers is the prospect contracted with?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="agent_production" [colSpan]="4">
      <dxo-label text="What is the prospects annual production?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="product_specialty" [colSpan]="8">
      <dxo-label text="What product does the prospect specialize in?"></dxo-label>
    </dxi-item>
    <dxi-item
      dataField="fluent_in"
      [colSpan]="4"
      [editorType]="'dxTagBox'"
      [editorOptions]="{
        items: languages,
        displayExpr: 'name',
        valueExpr: 'code',
        searchEnabled: true
      }"
    >
      <dxo-label text="What languages is prospect fluent in?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="city" [colSpan]="4"></dxi-item>
    <dxi-item [colSpan]="4">
      <div *dxTemplate>
        <ag-shr-state-select-box [(value)]="selectedProspect.state"> </ag-shr-state-select-box>
      </div>
    </dxi-item>
  </dxi-item>

  <dxi-item itemType="group" caption="Investigation" [colCount]="12" [colSpan]="6">
    <dxi-item dataField="description" [colSpan]="12" [editorType]="'dxTextArea'" [editorOptions]="{ height: 100 }">
      <dxo-label text="Description"></dxo-label>
    </dxi-item>
    <dxi-item
      dataField="reaching_out_reason"
      [colSpan]="12"
      [editorType]="'dxTextArea'"
      [editorOptions]="{ height: 100 }"
    >
      <dxo-label text="Why is the prospect reaching out to us?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="hear_about_us" [colSpan]="12" [editorType]="'dxTextArea'" [editorOptions]="{ height: 100 }">
      <dxo-label text="How did prospect hear about us?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="needs_help_with" [colSpan]="12" [editorType]="'dxTextArea'" [editorOptions]="{ height: 100 }">
      <dxo-label text="What does the propect need help with?"></dxo-label>
    </dxi-item>
  </dxi-item>

  <dxi-item itemType="group" caption="Search Results" [colCount]="12" [colSpan]="6">
    <dxi-item dataField="bulletin_result" [colSpan]="12" [editorType]="'dxTextArea'" [editorOptions]="{ height: 100 }">
      <dxo-label text="Bulletin Results?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="search_results" [colSpan]="12" [editorType]="'dxTextArea'" [editorOptions]="{ height: 100 }">
      <dxo-label text="Search Results?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="doi_results" [colSpan]="12" [editorType]="'dxTextArea'" [editorOptions]="{ height: 100 }">
      <dxo-label text="DOI Results?"></dxo-label>
    </dxi-item>
  </dxi-item>
  <dxi-item itemType="group" caption="Agency Information" [colCount]="12" [colSpan]="6">
    <dxi-item dataField="agency_name" [colSpan]="6">
      <dxo-label text="What is the Agencies name?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="num_of_agents" [colSpan]="6">
      <dxo-label text="How many agents are part of the agency?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="agency_production" [colSpan]="6">
      <dxo-label text="What is the agencies annual production?"></dxo-label>
    </dxi-item>
  </dxi-item>
  <dxi-item itemType="group" caption="Inquiry Information" [colCount]="12" [colSpan]="6">
    <dxi-item dataField="inquiry_received_by" [colSpan]="6">
      <dxo-label text="Who received this Inquiry?"></dxo-label>
    </dxi-item>
    <dxi-item dataField="inquiry_received_date" [colSpan]="6" [editorType]="'dxDateBox'">
      <dxo-label text="When was Inquiry Received?"></dxo-label>
    </dxi-item>
    <dxi-item
      dataField="inquiry_received_how"
      [colSpan]="12"
      [editorType]="'dxTextArea'"
      [editorOptions]="{ height: 100 }"
    >
      <dxo-label text="How was Inquiry Received?"></dxo-label>
    </dxi-item>
  </dxi-item>
</dx-form>
