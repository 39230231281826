import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import {
  AGMedia,
  AGMediaKeys,
  Headshot,
  ImageCropperKeys,
  ImageCropperPayload,
  MediaSize,
} from 'ag-common-lib/public-api';
import { AgMediaUploaderComponent } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader.component';
import { MediaUploaderTabs } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader.models';
import { WasabiImgPipe } from 'ag-common-svc/shared/pipes/wasabi-img.pipe';
import { ImageCropperModalComponent } from 'ag-common-svc/lib/components/image-cropper-modal/image-cropper-modal.component';

@Component({
  selector: 'ag-crm-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  @HostBinding('class') className = 'image-uploader';
  @ViewChild(AgMediaUploaderComponent, { static: false }) mediaUploaderComponent: AgMediaUploaderComponent;
  @ViewChild(ImageCropperModalComponent, { static: false }) imageCropperModalComponent: ImageCropperModalComponent;

  @Input() caption: string;
  @Input() imageData: Headshot;
  @Input() isReadonlyMode: boolean = false;
  @Output() mediaChanged = new EventEmitter<AGMedia>();
  @Output() mediaCropped = new EventEmitter<ImageCropperPayload>();

  maxFileSizeToUpload = 256; // MiB
  allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];

  protected readonly fileDirectory = 'conference-welcome-description';
  protected readonly MediaSize = MediaSize;
  protected readonly MediaUploaderTabs = MediaUploaderTabs;
  protected readonly AGMediaKeys = AGMediaKeys;
  protected readonly ImageCropperKeys = ImageCropperKeys;

  constructor(private wasabiImgPipe: WasabiImgPipe) {}

  convertMiBToBytes = mib => mib * 1048576;
  convertMiBToMBytes = mib => (this.convertMiBToBytes(mib) / 1000000).toFixed(0);

  onUploadClicked(): void {
    this.mediaUploaderComponent.showModal();
  }

  onImageCropped = (imageCropperPayload: ImageCropperPayload) => {
    this.imageData.imageCropperPayload = imageCropperPayload;
    this.mediaCropped.emit(imageCropperPayload);
    return Promise.resolve();
  };

  onMediaChanged(mediaUrl: AGMedia) {
    this.imageData = mediaUrl;
    this.mediaChanged.emit(mediaUrl);
  }

  onEditClicked(): void {
    this.imageCropperModalComponent.showModal(
      this.wasabiImgPipe.transform(this.imageData[AGMediaKeys.wasabiPath]),
      this.imageData?.[ImageCropperKeys.imageCropperPayload],
    );
  }
}
