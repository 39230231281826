import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActiveLookup, AgentKeys, Constants, StateLicense } from '@ag-common-lib/public-api';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { AgentService, LookupsService } from 'ag-common-svc/public-api';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Entity
} from "../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum";
import {
  EntityPermissionModelKeys
} from "../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model";

@Component({
  selector: 'ag-shr-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
})
export class LicensesComponent {
  @ViewChild('licensesEditorModalRef', { static: true }) licensesEditorModalComponent: ModalWindowComponent;

  @Input() agentId: string;
  @Input() licenses: StateLicense[] = [];
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;
  @Output() licensesChange = new EventEmitter();

  readonly inProgress$ = new BehaviorSubject<boolean>(false);
  readonly AgentKeys = AgentKeys;
  readonly dateFormat = Constants.DISPLAY_DATE_FORMAT;
  readonly residencyTypes: string[] = ['Resident', 'Non-Resident'];
  readonly statesLookup$: Observable<ActiveLookup[]>;

  constructor(
    lookupsService: LookupsService,
    private toastrService: ToastrService,
    private agentService: AgentService,
  ) {
    this.statesLookup$ = lookupsService.statesLookup$;
  }

  showLicensesEditorModal = (): void => {
    this.licensesEditorModalComponent.showModal();
  };

  onInitNewRow = e => {
    e.data.residency = 'Resident';
  };

  onRowInserting = e => {
    const { __KEY__: key, ...data } = e?.data;

    const licenses = this.normalizeLicenses(data);

    licenses.push(Object.assign({ id: key }, data));
    e.cancel = this.updateLicenses(licenses).then(() => {
      this.toastrService.success('Agent State License Successfully added.');
      return;
    });
  };

  onRowUpdating = e => {
    const data = Object.assign({}, e?.oldData, e?.newData);

    const licenses = this.normalizeLicenses(data, e?.key);
    e.cancel = this.updateLicenses(licenses).then(() => {
      this.toastrService.success('Agent State License Successfully updated.');
      return;
    });
  };

  onRowRemoving = e => {
    const licenses = this.licenses.filter(address => {
      return address !== e.key;
    });
    e.cancel = this.updateLicenses(licenses).then(() => {
      this.toastrService.success('Agent State License removed.');
      return;
    });
  };

  private normalizeLicenses = (data, key?: StateLicense) => {
    return this.licenses.map(license => {
      if (key && license === key) {
        return data;
      }

      const normalizedLicense = Object.assign({}, license);

      return normalizedLicense;
    });
  };

  private updateLicenses = licenses => {
    this.inProgress$.next(true);
    return this.agentService
      .updateFields(this.agentId, { [AgentKeys.state_licenses]: licenses })
      .then(() => {
        this.licenses = licenses;
        this.licensesChange.emit(licenses);
      })
      .finally((): void => {
        this.inProgress$.next(false);
      });
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
