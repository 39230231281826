import { Pipe, PipeTransform } from '@angular/core';
import { AttendeeDetailsModalSection } from './attendee-details-modal.model';
import { combineLatest, map, Observable } from 'rxjs';
import { AttendeeDetailsModalService } from './attendee-details-modal.service';
import { AttendeeDetailsModalRequestsService } from './attendee-details-modal-requests.service';
import { RegistrantModelKeys } from 'ag-common-lib/public-api';

@Pipe({
  name: 'attendeeDetailsTitleIconHint',
})
export class AttendeeDetailsTitleIconHintPipe implements PipeTransform {
  constructor(
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private attendeeDetailsModalRequestsService: AttendeeDetailsModalRequestsService,
  ) {}
  transform(section: AttendeeDetailsModalSection): Observable<string> {
    switch (section) {
      case AttendeeDetailsModalSection.guests:
        return this.attendeeDetailsModalRequestsService.hasNotResolvedGuestSectionRequests$.pipe(
          map(additionalGuestRequested => {
            return additionalGuestRequested ? 'Additional guest requested' : '';
          }),
        );
      case AttendeeDetailsModalSection.generalInfo:
        return this.attendeeDetailsModalService.isFirstTimeAttendee$.pipe(
          map(isFirstTimeAttendee => {
            return isFirstTimeAttendee ? 'First time attendee' : '';
          }),
        );
      case AttendeeDetailsModalSection.flyInformation:
        return this.attendeeDetailsModalRequestsService.hasNotResolvedFlightInfoSectionRequests$.pipe(
          map(hasHotResolvedRequests => {
            return hasHotResolvedRequests ? 'Differ Travel Dates Requested' : '';
          }),
        );

      case AttendeeDetailsModalSection.hotelReservation:
        return combineLatest({
          hasNotResolvedBookingDatesRequests:
            this.attendeeDetailsModalRequestsService.hasNotResolvedBookingDatesRequests$,
          hasNotResolvedRoomRequest: this.attendeeDetailsModalRequestsService.hasNotResolvedRoomRequest$,
        }).pipe(
          map(({ hasNotResolvedBookingDatesRequests, hasNotResolvedRoomRequest }) => {
            let hints = [];

            if (hasNotResolvedBookingDatesRequests) {
              hints.push('Requested Differ Booking Dates');
            }
            if (hasNotResolvedRoomRequest) {
              hints.push('Additional Room Requested');
            }

            return hints.filter(Boolean).join('; ');
          }),
        );

      case AttendeeDetailsModalSection.notes:
        return this.attendeeDetailsModalService.registrant$.pipe(
          map(registrant => registrant?.[RegistrantModelKeys.statistic]),
          map(statistics => !!statistics?.supportItemsNotArchived),
          map(hasSupportTickets => (hasSupportTickets ? 'Support Requested' : '')),
        );

      default:
        return null;
    }
  }
}
