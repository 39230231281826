import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsFlightFieldsExcludedPipe } from './is-flight-fields-excluded.pipe';
import { FormFieldVisiblePipe } from 'ag-common-svc/shared/pipes/conference-registration/form-field-visible.pipe';

@NgModule({
  declarations: [IsFlightFieldsExcludedPipe],
  imports: [CommonModule],
  exports: [IsFlightFieldsExcludedPipe],
  providers: [FormFieldVisiblePipe],
})
export class AttendeeFlightPipesModule {}
