<div class="mt-2">
  <div class="row my-2 px-3">
    <div class="col-12 text-start editToolbar">
      <dx-toolbar>
        <dxi-item location="before" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">
              <b>Department Of Insurance Information</b>
            </div>
          </div>
        </dxi-item>
        <dxi-item location="after" locateInMenu="never">
          <div *dxTemplate>
            <dx-button
              [visible]="
                Entity.agentAgentInfoDepartmentOfInsuranceInfo
                  | hasPermission : EntityPermissionActivityKeys.write
                  | async
              "
              [icon]="'edit'"
              [stylingMode]="'text'"
              (onClick)="editDOI = true"
            ></dx-button>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
  </div>
  <div class="row my-2 px-3">
    <div class="col-3">
      <b>NPN:</b>
    </div>
    <div class="col-3">{{ agent.npn }}</div>
  </div>
</div>

<ng-container *ngIf="editDOI">
  <dx-popup
    [width]="'50%'"
    [height]="'70%'"
    [showTitle]="true"
    [showCloseButton]="true"
    title="Department of Insurance Information"
    [dragEnabled]="false"
    [(visible)]="editDOI"
  >
    <dx-scroll-view height="80%">
      <dx-form class="mt-2" [colCount]="12" [formData]="agent" [labelLocation]="'top'">
        <dxi-item dataField="npn" [colSpan]="6" [template]="'approveNPNTemplate'">
          <dxo-label [text]="'NPN Number'"></dxo-label>
        </dxi-item>
        <div *dxTemplate="let data of 'approveNPNTemplate'">
          <dx-text-box [(value)]="agent.npn"></dx-text-box>
        </div>
      </dx-form>
    </dx-scroll-view>
    <div class="row d-flex my-3 justify-content-around" style="margin: 0 auto">
      <div class="col-lg-12 text-end" style="text-align: right">
        <dx-button
          width="200"
          stylingMode="contained"
          text="Close"
          type="default"
          (onClick)="saveChanges()"
        ></dx-button>
      </div>
    </div>
  </dx-popup>
</ng-container>
