import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ActiveLookup,
  BaseModelKeys,
  EmailAddress,
  EmailAddressKeys,
  LookupKeys,
  Lookups,
  Messages,
} from '@ag-common-lib/public-api';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { SetLoginEmailModalComponent } from '../set-login-email-modal/set-login-email-modal.component';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { AgentEmailAddressesService } from 'ag-common-svc/lib/services/agent-email-addresses.service';
import { LookupsService } from 'ag-common-svc/public-api';
import { EmailAddressesService } from './email-addresses.service';

@Component({
  selector: 'ag-shr-email-addresses',
  templateUrl: './email-addresses.component.html',
  styleUrls: ['./email-addresses.component.scss'],
})
export class EmailAddressesComponent {
  @Input() set agentId(value) {
    this.agentId$.next(value);
  }
  @Input() agentUID: string;
  @Input() isSetAsLoginVisible: boolean = false;
  @Input() canEdit = true;
  @Input() canCreate = true;
  @Input() canDelete = true;

  @Output() emailAddressesChange = new EventEmitter();

  @ViewChild('emailAddressesEditorModalRef', { static: true }) emailAddressesEditorModalComponent: ModalWindowComponent;
  @ViewChild(SetLoginEmailModalComponent) setLoginEmailModalComponent: SetLoginEmailModalComponent;

  public Lookups = Lookups;
  public LookupKeys = LookupKeys;
  public BaseModelKeys = BaseModelKeys;
  public emailTypeLookup$: Observable<ActiveLookup[]>;

  readonly inProgress$ = this.emailAddressesService.inProgress$;
  readonly Messages = Messages;

  private defaultEmailTypeLookup: ActiveLookup;
  private readonly agentId$ = new BehaviorSubject<string>(undefined);

  emailAddresses$ = this.agentId$.pipe(
    filter(Boolean),
    switchMap((agentId: string) => this.agentEmailAddressesService.getList(agentId)),
    shareReplay(1),
  );
  emailAddressesDataSource$ = this.emailAddresses$.pipe(
    map((emailAddresses: EmailAddress[]) => {
      return new DataSource({
        store: new ArrayStore({
          key: 'dbId',
          data: Array.isArray(emailAddresses) ? emailAddresses : [],
        }),
      });
    }),
  );

  constructor(
    lookupsService: LookupsService,
    private agentEmailAddressesService: AgentEmailAddressesService,
    private emailAddressesService: EmailAddressesService,
  ) {
    this.emailTypeLookup$ = lookupsService.emailTypeLookup$.pipe(
      tap((items: ActiveLookup[]): void => {
        this.defaultEmailTypeLookup = items?.find(item => item?.isDefault);
      }),
    );
  }

  showEmailAddressesEditorModal = (): void => {
    this.emailAddressesEditorModalComponent.showModal();
  };

  onEditorPreparing = (e): void => {
    if (e.parentType !== 'dataRow') {
      return;
    }

    if (e.dataField === EmailAddressKeys.address) {
      e.editorOptions.disabled = e.row.data.is_login;
    }
  };

  onInitNewRow = (e): void => {
    e.data.is_login = false;
    e.data.email_type = this.defaultEmailTypeLookup?.dbId;
  };

  onRowInserting = (e): void => {
    const { __KEY__: key, ...data } = e?.data;

    e.cancel = this.emailAddressesService.createEmailAddress(this.agentId$.value, data);
  };

  onRowUpdating = (e): void => {
    e.cancel = this.emailAddressesService.updateEmailAddressChange(this.agentId$.value, e.key, e?.newData);
  };

  onRowRemoving = (e): void => {
    e.cancel = this.emailAddressesService.deleteEmailAddress(this.agentId$.value, e.key);
  };

  canDeleteEmailAddress = (e): boolean => !e.row.data.is_login;

  checkIsSetAsLoginVisible = (e): boolean => this.isSetAsLoginVisible && !e.row.data.is_login;

  showSetLoginEmailModal = (): void => {
    this.setLoginEmailModalComponent?.showModal(this.agentId$.value, this.agentUID);
  };

  asyncUniqEmailValidation = async ({ data, value }): Promise<boolean> => {
    const emailAddresses = await firstValueFrom(this.emailAddresses$);

    return !emailAddresses.some(
      (emailAddress: EmailAddress) => emailAddress?.address === value && data?.dbId !== emailAddress?.dbId,
    );
  };
}
