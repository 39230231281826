<ag-shr-modal-window
  #attendeeFlightDataModalRef
  *var="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
  [title]="'Attendee Flight Information'"
  [width]="'70vw'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleSave()"
  [onCloseModal]="handleClosePopup"
  [showSaveButton]="canWrite"
>
  <div
    *ngIf="attendeeFlightDataModalRef?.popupComponent?.visible ?? false"
    class="attendee-flight-data-modal__container"
  >
    <dx-form
      #formRef
      [formData]="formData"
      labelMode="floating"
      [validationGroup]="validationGroup"
      (onInitialized)="onInit($event)"
      [screenByWidth]="formWidth$ | async | formScreenByWidth: { md: 860 }"
      [readOnly]="!canWrite"
      *var="excludedFields$ | async as excludedFields"
    >
      <dxi-item itemType="group" [colCountByScreen]="colCountByScreen" caption="Travel Dates">
        <dxi-item [colSpan]="6">
          <ng-container *dxTemplate>
            <dx-date-range-box
              class="attendee-hotel-reservation__date-range-box"
              startDateLabel="Start"
              endDateLabel="End"
              labelMode="floating"
              [startDate]="formData?.[FlightInfoKeys.arrivalDate]"
              [endDate]="formData?.[FlightInfoKeys.departureDate]"
              (startDateChange)="formData[FlightInfoKeys.arrivalDate] = $any($event)"
              (endDateChange)="formData[FlightInfoKeys.departureDate] = $any($event)"
              [displayFormat]="dateFormat"
              [deferRendering]="false"
              [showClearButton]="true"
              [openOnFieldClick]="true"
              applyValueMode="useButtons"
              [dropDownOptions]="{ wrapperAttr: { class: 'attendee-hotel-reservation__date-range-box-drop-down' } }"
              [useMaskBehavior]="true"
              [dateSerializationFormat]="LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT"
              [disabled]="!canWrite"
            >
            </dx-date-range-box>

            <div
              *ngIf="
                formData?.[FlightInfoKeys.travelDatesDiffer] &&
                (excludedFields
                  | formFieldVisible: [RegistrantKeys.flightInformation, FlightInfoKeys.travelDatesDiffer].join('.'))
              "
              class="attendee-details-modal__request-container mt-2"
            >
              <b> Requested Differ travel Dates </b>

              <dx-button
                type="success"
                icon="agi-thumbs-up"
                class="inline-editor-control ms-2"
                hint="Approve Request"
                [ngClass]="
                  formData?.[FlightInfoKeys.travelDatesDifferRequestOutcome]
                    | requestOutcomeIconClass: RequestOutcomeState.approved
                "
                (onClick)="handleDifferTravelDatesRequest(RequestOutcomeState.approved)"
                [disabled]="!canWrite"
              ></dx-button>

              <dx-button
                type="danger"
                stylingMode="outlined"
                icon="agi-thumbs-down"
                class="inline-editor-control ms-2"
                hint="Reject Request"
                [ngClass]="
                  formData?.[FlightInfoKeys.travelDatesDifferRequestOutcome]
                    | requestOutcomeIconClass: RequestOutcomeState.rejected
                "
                (onClick)="handleDifferTravelDatesRequest(RequestOutcomeState.rejected)"
                [disabled]="!canWrite"
              ></dx-button>

              <i *ngIf="formData?.[FlightInfoKeys.travelDatesDifferRequestOutcome]">{{
                formData?.[FlightInfoKeys.travelDatesDifferRequestOutcome] | requestOutcomeResult
              }}</i>

              <ag-shr-modal-window
                #approveDifferTravelDatesRequestModalRef
                [width]="450"
                [height]="'auto'"
                title="Approve Request"
                [actionTitle]="'Confirm'"
                [useScrollView]="false"
                [inProgress]="requestInProgress$ | async"
                (onSaveClick)="handleDifferTravelDatesRequestOutcome(RequestOutcomeState.approved)"
              >
                <ng-container *ngIf="approveDifferTravelDatesRequestModalRef?.popupComponent?.visible ?? false">
                  <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
                </ng-container>
              </ag-shr-modal-window>

              <ag-shr-modal-window
                #rejectDifferTravelDatesRequestModalRef
                [width]="450"
                [height]="'auto'"
                title="Reject Request"
                [actionTitle]="'Confirm'"
                [useScrollView]="false"
                [inProgress]="requestInProgress$ | async"
                (onSaveClick)="handleDifferTravelDatesRequestOutcome(RequestOutcomeState.rejected)"
              >
                <ng-container *ngIf="rejectDifferTravelDatesRequestModalRef?.popupComponent?.visible ?? false">
                  <dx-text-area label="Note" labelMode="floating" [(value)]="note" [height]="250"></dx-text-area>
                </ng-container>
              </ag-shr-modal-window>
            </div>
          </ng-container>
        </dxi-item>
      </dxi-item>

      <dxi-item
        cssClass="mb-3"
        itemType="group"
        caption="Name as it appears on passport"
        [colCountByScreen]="colCountByScreen"
        [visible]="excludedFields | formFieldVisible: flightInfoPasportNameFieldsList"
      >
        <dxi-item
          *var="FlightInfoKeys.firstName as field"
          [colSpan]="3"
          [dataField]="field"
          [label]="{ text: 'First Name' }"
          [editorOptions]="{
            inputAttr: { autocomplete: 'disabled' },
            valueChangeEvent: 'input',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.middleName as field"
          [colSpan]="2"
          [dataField]="field"
          [label]="{ text: 'Middle Name' }"
          [editorOptions]="{
            inputAttr: { autocomplete: 'disabled' },
            valueChangeEvent: 'input',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.lastName as field"
          [colSpan]="3"
          [dataField]="field"
          [label]="{ text: 'Last Name' }"
          [editorOptions]="{
            inputAttr: { autocomplete: 'disabled' },
            valueChangeEvent: 'input',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.suffix as field"
          [colSpan]="2"
          [dataField]="field"
          [label]="{ text: 'Suffix' }"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            items: suffixesLookup$ | async,
            displayExpr: LookupKeys.description,
            valueExpr: BaseModelKeys.dbId,
            showClearButton: true,
            allowClearing: true,
            inputAttr: { autocomplete: 'disabled' },
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>
      </dxi-item>

      <dxi-item
        cssClass="mb-3"
        itemType="group"
        caption="Name as it appears on gov't issued i.d."
        [colCountByScreen]="colCountByScreen"
        [visible]="excludedFields | formFieldVisible: flightInfoDriverLicenseNameFieldsList"
      >
        <dxi-item
          *var="FlightInfoKeys.driverLicenseFirstName as field"
          [colSpan]="3"
          [dataField]="field"
          [label]="{ text: 'First Name' }"
          [editorOptions]="{
            inputAttr: { autocomplete: 'disabled' },
            valueChangeEvent: 'input',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.driverLicenseMiddleName as field"
          [colSpan]="2"
          [dataField]="field"
          [label]="{ text: 'Middle Name' }"
          [editorOptions]="{
            inputAttr: { autocomplete: 'disabled' },
            valueChangeEvent: 'input',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.driverLicenseLastName as field"
          [colSpan]="3"
          [dataField]="field"
          [label]="{ text: 'Last Name' }"
          [editorOptions]="{
            inputAttr: { autocomplete: 'disabled' },
            valueChangeEvent: 'input',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.driverLicenseSuffix as field"
          [colSpan]="2"
          [dataField]="field"
          [label]="{ text: 'Suffix' }"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            items: suffixesLookup$ | async,
            displayExpr: LookupKeys.description,
            valueExpr: BaseModelKeys.dbId,
            showClearButton: true,
            allowClearing: true,
            inputAttr: { autocomplete: 'disabled' },
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>
      </dxi-item>

      <dxi-item
        itemType="group"
        caption="Date of Birth"
        [colCountByScreen]="colCountByScreen"
        [visible]="attendeeType | isFlightFieldsExcluded: 'dob' | async"
      >
        <dxi-item
          [colSpan]="3"
          editorType="dxDateBox"
          [label]="{ text: 'Day of Birth' }"
          [editorOptions]="dateEditorOptions"
        >
        </dxi-item>
      </dxi-item>

      <dxi-item
        itemType="group"
        caption="Gender"
        [colCountByScreen]="colCountByScreen"
        [visible]="attendeeType | isFlightFieldsExcluded: 'gender' | async"
      >
        <dxi-item
          [colSpan]="3"
          [label]="{ text: 'Gender' }"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            value: gender,
            items: gendersLookup$ | async,
            displayExpr: LookupKeys.description,
            valueExpr: BaseModelKeys.dbId,
            showClearButton: true,
            allowClearing: true,
            readOnly: true,
            placeholder: '',
          }"
        ></dxi-item>
      </dxi-item>

      <dxi-item
        itemType="group"
        [colCountByScreen]="colCountByScreen"
        caption="Preferred Airline"
        *var="formData?.[FlightInfoKeys.preferredAirline] as preferredAirline"
        [visible]="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.preferredAirline | async"
      >
        <dxi-item
          *var="FlightInfoKeys.preferredAirline as field"
          [colSpan]="3"
          [dataField]="field"
          [editorType]="'dxSelectBox'"
          [label]="{
            text: 'Airline',
          }"
          [editorOptions]="{
            items: AIRLINES_LOOKUP,
            displayExpr: LookupKeys.description,
            valueExpr: LookupKeys.value,
            showClearButton: true,
            allowClearing: true,
            placeholder: '',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.otherPreferredAirline as field"
          [colSpan]="3"
          [visible]="preferredAirline === Airlines.Other && (attendeeType | isFlightFieldsExcluded: field | async)"
          [dataField]="field"
          [isRequired]="true"
          [label]="{
            text: 'Other Airline',
          }"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.preferredAirlineLoyaltyNumber as field"
          [colSpan]="3"
          [visible]="
            !!preferredAirline &&
            preferredAirline !== Airlines.None &&
            (attendeeType | isFlightFieldsExcluded: field | async)
          "
          [dataField]="field"
          [label]="{
            text: 'Loyalty #',
          }"
        ></dxi-item>
      </dxi-item>

      <dxi-item
        itemType="group"
        [colCountByScreen]="colCountByScreen"
        caption="Second Choice Airline"
        *var="formData?.[FlightInfoKeys.secondChoiceAirline] as secondChoiceAirline"
        [visible]="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.secondChoiceAirline | async"
      >
        <dxi-item
          *var="FlightInfoKeys.secondChoiceAirline as field"
          [colSpan]="3"
          [dataField]="field"
          [editorType]="'dxSelectBox'"
          [label]="{
            text: 'Airline',
          }"
          [editorOptions]="{
            items: AIRLINES_LOOKUP,
            displayExpr: LookupKeys.description,
            valueExpr: LookupKeys.value,
            showClearButton: true,
            placeholder: '',
            allowClearing: true,
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.otherSecondChoiceAirline as field"
          [colSpan]="3"
          [visible]="secondChoiceAirline === Airlines.Other && (attendeeType | isFlightFieldsExcluded: field | async)"
          [dataField]="field"
          [isRequired]="true"
          [label]="{
            text: 'Other Airline',
          }"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.secondChoiceAirlineLoyaltyNumber as field"
          [colSpan]="3"
          [visible]="
            !!secondChoiceAirline &&
            secondChoiceAirline !== Airlines.None &&
            (attendeeType | isFlightFieldsExcluded: field | async)
          "
          [dataField]="field"
          [label]="{
            text: 'Loyalty #',
          }"
        ></dxi-item>
      </dxi-item>

      <dxi-item
        itemType="group"
        caption="Other"
        [colCountByScreen]="colCountByScreen"
        [visible]="excludedFields | formFieldVisible: flightInfoOtherFieldsList"
      >
        <dxi-item
          *var="FlightInfoKeys.knownTravelerNumber as field"
          [colSpan]="3"
          [dataField]="field"
          [label]="{
            text: 'Known Traveler #',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.seatPreference as field"
          [colSpan]="3"
          [dataField]="field"
          [label]="{ text: 'Seat preference' }"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            items: SEAT_PREFERENCE_LOOKUP,
            displayExpr: LookupKeys.description,
            valueExpr: LookupKeys.value,
            showClearButton: true,
            placeholder: '',
            allowClearing: true,
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>

        <dxi-item
          *var="FlightInfoKeys.preferredDepartureAirport as field"
          [colSpan]="3"
          [dataField]="field"
          [label]="{
            text: 'Preferred Departure Airport',
          }"
          [visible]="attendeeType | isFlightFieldsExcluded: field | async"
        ></dxi-item>
      </dxi-item>

      <dxi-item
        *var="FlightInfoKeys.sameForAll as field"
        [dataField]="field"
        [editorType]="'dxRadioGroup'"
        [cssClass]="'flex-column mt-3'"
        [label]="{
          text: 'Is the departure city, state, airport, and preferred airline the same for qualifier and guests?',
          showColon: false,
        }"
        [editorOptions]="{
          items: TrueFalseLookup,
          displayExpr: 'description',
          valueExpr: 'value',
        }"
        [visible]="showSameForAll && (attendeeType | isFlightFieldsExcluded: field | async)"
      >
      </dxi-item>

      <dxi-item
        *var="FlightInfoKeys.additionalRequest as field"
        itemType="group"
        caption="Additional Requests"
        [visible]="!!formData?.[field] && (attendeeType | isFlightFieldsExcluded: field | async)"
      >
        <dxi-item>
          <div *dxTemplate>
            {{ formData?.[field] | empty }}
          </div>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </div>
</ag-shr-modal-window>

<ag-crm-attendee-note-modal></ag-crm-attendee-note-modal>
