import { Pipe, PipeTransform } from '@angular/core';
import { AT_LEISURE_EXCURSION_SUFFIX, AT_LEISURE_ITEM_TEXT, LookupBase } from 'ag-common-lib/public-api';

@Pipe({
  name: 'changeLogExcursionNameById',
})
export class ChangeLogExcursionNameByIdPipe implements PipeTransform {
  transform(ind: string, lookup: LookupBase[]) {
    if (!ind?.length) {
      return '';
    }

    if (ind.endsWith(AT_LEISURE_EXCURSION_SUFFIX)) {
      return AT_LEISURE_ITEM_TEXT;
    }

    return lookup.find(item => item.value === ind)?.description;
  }
}
