<dx-list
  #listRef
  [items]="associationsDataSource$ | async"
  [selectionMode]="'single'"
  [showSelectionControls]="true"
  [keyExpr]="keyExpr"
  (onInitialized)="onInitialized($event)"
  [(selectedItemKeys)]="selectedAssociationKeys"
  [disabled]="isReadonly"
  [visible]="isListVisible"
>
  <div *dxTemplate="let emergencyContact of 'item'">
    <ng-container *ngIf="emergencyContact?.dbId; else useNewAddress">
      <div>
        <span>
          {{ [emergencyContact?.[AssociationKeys.firstName], emergencyContact?.[AssociationKeys.lastName]] | fullName}}
        </span>
        <span
          *ngIf="emergencyContact?.[AssociationKeys.associationType] | lookupDescription : Lookups.AssociationType | async as associationType"
        >
          (&nbsp;{{ associationType }}&nbsp;)
        </span>
      </div>
    </ng-container>

    <ng-template #useNewAddress>
      <div>{{ newAssociationTitle }}</div>
    </ng-template>
  </div>
</dx-list>

<ng-content></ng-content>
