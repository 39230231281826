import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../../attendee-change-log-detail/attendee-change-log-detail.module';
import { ChangeLogFlightBookingInfoComponent } from './change-log-flight-booking-info.component';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import {
    AttendeeChangeLogDetailByTypeModule
} from '../../attendee-change-log-detail-by-type/attendee-change-log-detail-by-type.module';

@NgModule({
  declarations: [ChangeLogFlightBookingInfoComponent],
    imports: [ CommonModule, SharedModule, AttendeeChangeLogDetailModule, ChangeLogPipesModule, AttendeeChangeLogDetailByTypeModule ],
  exports: [ChangeLogFlightBookingInfoComponent],
})
export class ChangeLogFlightBookingInfoModule {}
