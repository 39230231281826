import { Inject, Injectable } from '@angular/core';
import { ActivityBaseKeys, BaseModelKeys, NoteLogKeys, TARGET_TYPE } from 'ag-common-lib/public-api';
import { Note } from 'ag-common-lib/public-api';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from 'ag-common-svc/public-api';
import { ActivityConfig, ACTIVITY_CONFIG, SHOULD_FETCH_TARGET_ACTIVITIES_SET } from '../activity-info.model';

@Injectable()
export class NoteService extends DataService<Note> {
  public noteList$: Observable<Note[]>;

  private _refreshTrigger$ = new BehaviorSubject<void>(undefined);

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    @Inject(ACTIVITY_CONFIG) activityConfig$: Observable<ActivityConfig>,
  ) {
    super(fireBaseApp);
    super.setCollection('crm-record-notes');

    this.noteList$ = combineLatest([activityConfig$, this._refreshTrigger$]).pipe(
      switchMap(([activityConfig]) => this._getNotesList(activityConfig)),
      shareReplay(1),
    );
  }

  public refreshList = () => {
    this._refreshTrigger$.next();
  };

  private _getNotesList = (activityConfig: ActivityConfig) =>
    defer(() => {
      const targetType = activityConfig[ActivityBaseKeys.targetType];
      const shouldFetchTargetActivities = SHOULD_FETCH_TARGET_ACTIVITIES_SET.has(targetType);
      const hostOwnerIdQp: QueryParam[] = [];
      const targetQp: QueryParam[] = [];

      hostOwnerIdQp.push(
        new QueryParam(
          NoteLogKeys.hostOwnerId,
          WhereFilterOperandKeys.equal,
          activityConfig[ActivityBaseKeys.targetId],
        ),
      );
      targetQp.push(
        new QueryParam(
          ActivityBaseKeys.targetId,
          WhereFilterOperandKeys.equal,
          activityConfig[ActivityBaseKeys.targetId],
        ),
      );

      const queries = [this.getList(hostOwnerIdQp)];

      if (shouldFetchTargetActivities) {
        queries.push(this.getList(targetQp));
      }

      return combineLatest(queries).pipe(
        map(items => {
          const itemsMap = new Map();

          items.forEach(tasks => {
            if (Array.isArray(tasks)) {
              tasks.forEach(task => {
                const dbId = task[BaseModelKeys.dbId];
                itemsMap.set(dbId, task);
              });
            }
          });

          return Array.from(itemsMap.values());
        }),
      );
    });
}
