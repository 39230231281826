import { Component, HostBinding, ViewChild } from '@angular/core';
import { AddAttendeesModalService } from './add-attendees-modal.service';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import CustomStore from 'devextreme/data/custom_store';
import {
  Agent,
  AgentKeys,
  BaseModelKeys, Constants,
  LookupKeys,
  QUALIFIED_AS_LOOKUP,
  QualifiedAs,
  RegistrantKeys,
  REGISTRATION_TYPE_LOOKUP,
  RegistrationType,
} from 'ag-common-lib/public-api';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { ColumnEditCellTemplateData, InitNewRowEvent } from 'devextreme/ui/data_grid';
import { AgencyService } from 'ag-common-svc/public-api';
import { shareReplay } from 'rxjs';
import { SelectionChangedEvent } from 'devextreme/ui/select_box';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';

@Component({
  selector: 'ag-crm-add-attendees-modal',
  templateUrl: './add-attendees-modal.component.html',
  styleUrls: ['./add-attendees-modal.component.scss'],

})
export class AddAttendeesModalComponent {
  @HostBinding('class') className = 'add-attendees-modal';
  @ViewChild('modalRef', { static: false }) modalComponent: ModalWindowComponent;
  @ViewChild('agentsGridRef', { static: false }) agentsGrid: DxDataGridComponent;


  protected caption = 'Invite Agents';
  protected eventNameTitleMask = this.caption + Constants.EVENT_NAME_TITLE;
  protected dataSource: CustomStore;
  protected agencies$ = this.agencyService.getList().pipe(shareReplay(1));
  protected agentsStore = this.agentElasticSearchService.getStore();
  protected agentsDataSource = this.agentElasticSearchService.getDataSource();

  protected readonly inProgress$ = this.addAttendeesModalService.inProgress$;
  protected readonly eventName$ = this.addAttendeesModalService.eventName$;
  protected readonly AgentKeys = AgentKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly qualifiedAsLookup = QUALIFIED_AS_LOOKUP;
  protected readonly registrationTypeLookup = REGISTRATION_TYPE_LOOKUP;

  private _attendees: Attendee[] = [];

  constructor(
    private agencyService: AgencyService,
    private addAttendeesModalService: AddAttendeesModalService,
    private agentElasticSearchService: AgentElasticSearchService,
    private toastrService: ToastrService,
  ) {}

  showModal = (conferenceDbId: string, attendees: Attendee[]): void => {
    this._attendees = attendees ?? [];
    this.addAttendeesModalService.setConferenceDbId(conferenceDbId);
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: () => [],
      insert: item => {
        return this.addAttendeesModalService.addAgentToConference(item);
      },
    });

    this.modalComponent?.showModal();
  };

  valueChange = (event: SelectionChangedEvent, cell: ColumnEditCellTemplateData) => {
    const agent: Agent = event?.selectedItem;
    const agentName = [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
      .filter(Boolean)
      .join(', ');
    const isUniq = this.checkIsAttendeeUniq(agent?.[BaseModelKeys.dbId]);
    if (!isUniq) {
      cell.setValue(null);
      this.toastrService.error(`Agent "${agentName}" already exist. Adding skipped.`);
      return;
    }

    Object.assign(cell.row.data, {
      [BaseModelKeys.dbId]: agent?.[BaseModelKeys.dbId],
      [RegistrantKeys.agencyId]: agent?.[AgentKeys.p_agency_id],
      [RegistrantKeys.mgaId]: agent?.[AgentKeys.p_mga_id],
    });
  };

  protected onInitNewRow = (e: InitNewRowEvent) => {
    Object.assign(e.data, {
      [RegistrantKeys.registrationType]: RegistrationType.agent,
      [RegistrantKeys.qualifiedAs]: QualifiedAs.agtMoreThan75,
    });
  };

  protected handleSave = async () => {
    await this.agentsGrid.instance.saveEditData().then(() => {
      this.modalComponent.hideModal();
    });
  };

  protected handleClosePopup = this.addAttendeesModalService.onCancelEdit;

  private checkIsAttendeeUniq = (key: string): boolean =>
    !this._attendees.some(attendee => attendee[BaseModelKeys.dbId] === key);
}
