import { Component, HostBinding, Input } from '@angular/core';
import { AGMedia, Conference, ConferenceKeys, HtmlEditorTypeKeys } from 'ag-common-lib/public-api';
import { ConferenceModalService } from '../conference-form.service';

@Component({
  selector: 'ag-crm-conference-welcome-info-form',
  templateUrl: './conference-welcome-info-form.component.html',
  styleUrls: ['./conference-welcome-info-form.component.scss'],
})
export class ConferenceWelcomeInfoFormComponent {
  @HostBinding('class') className = 'conference-welcome-info-form';
  @Input() conference: Conference;
  @Input() validationGroup: string;
  @Input() inProgress: boolean = false;
  @Input() isReadonlyMode: boolean = false;

  conferenceStepsTemplateArguments$ = this.conferenceModalService.conferenceStepsTemplateArguments$;

  popupVisible = false;
  protected readonly HtmlEditorTypeKeys = HtmlEditorTypeKeys;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly welcomeDescriptionValidationGroup = 'welcomeDescriptionValidationGroup';

  constructor(private conferenceModalService: ConferenceModalService) {}

  onMediaChanged = (mediaUrl: AGMedia) => {
    Object.assign(this.conference, {
      [ConferenceKeys.welcomeImagePath]: {
        wasabiPath: mediaUrl?.wasabiPath,
        imageCropperPayload: null,
      },
    });
  };

  onMediaCropped = imageCropperPayload => {
    Object.assign(this.conference?.[ConferenceKeys.welcomeImagePath], { imageCropperPayload });
  };

  onShowPreview(value: string) {
    this.popupVisible = true;
  }
}
