import { Injectable } from '@angular/core';
import {
  Airlines,
  FlightInfoKeys,
  FlightInformationModel,
} from 'ag-common-lib/lib/models/registration/flight-information.model';
import { AuthService, BaseFormService } from 'ag-common-svc/public-api';
import { AttendeeFlightDataKeys, AttendeeFlightGroup, AttendeeFlightService } from '../attendee-flight.service';
import { BaseModelKeys, RequestOutcomeKeys, RequestOutcomeState } from 'ag-common-lib/public-api';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AttendeeFlightDataModalService extends BaseFormService<FlightInformationModel> {
  private _flightGroup: AttendeeFlightGroup;

  constructor(private attendeeFlightService: AttendeeFlightService, private authService: AuthService) {
    super();
  }

  getFormData = (flightGroup: AttendeeFlightGroup) => {
    this._flightGroup = flightGroup;

    const initialData: FlightInformationModel = Object.assign(
      {
        [FlightInfoKeys.travelDatesDiffer]: false,
        [FlightInfoKeys.passportNameConfirmation]: false,
        [FlightInfoKeys.passportConfirmation]: false,
      },
      flightGroup?.[AttendeeFlightDataKeys.flightInformation],
    );

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
          switch (prop) {
            case FlightInfoKeys.firstName:
            case FlightInfoKeys.lastName:
            case FlightInfoKeys.middleName:
            case FlightInfoKeys.suffix:
              Object.assign(this.formData, { [FlightInfoKeys.passportNameConfirmation]: false });
              break;
          }
        }

        const isOther = value === Airlines.Other;
        const isPrevOther = prevValue === Airlines.Other;

        switch (prop) {
          case FlightInfoKeys.preferredAirline:
            if (isOther || (!isOther && isPrevOther)) {
              Reflect.set(target, FlightInfoKeys.otherPreferredAirline, null);
            }
            break;
          case FlightInfoKeys.secondChoiceAirline:
            if (isOther || (!isOther && isPrevOther)) {
              Reflect.set(target, FlightInfoKeys.otherSecondChoiceAirline, null);
            }
            break;
        }

        return true;
      },
    });

    return this.formData;
  };

  saveFlightUpdates = (): Promise<any> => {
    this.startProgress();
    return this.attendeeFlightService
      .saveFlightUpdates(this._flightGroup, this.formData)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  handleDifferBookingDatesRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    const loggedInAgent = await firstValueFrom(this.authService.loggedInAgent$);
    const loggedInAgentDbId = loggedInAgent?.[BaseModelKeys.dbId];

    return this.saveRequestOutcome({
      [FlightInfoKeys.travelDatesDifferRequestOutcome]: {
        [RequestOutcomeKeys.state]: requestOutcomeState,
        [RequestOutcomeKeys.processedBy]: loggedInAgentDbId,
      },
    });
  };

  private saveRequestOutcome = (updates: FlightInformationModel): Promise<any> => {
    return this.attendeeFlightService.saveFlightUpdates(this._flightGroup, Object.assign(this.formData, updates));
  };
}
