import { Injectable } from '@angular/core';
import { RoleEntityPermissionMap, UserRole, UserRoleModelKeys } from 'ag-common-lib/public-api';
import { map } from 'rxjs';
import { ARMAuthService } from './arm-auth.service';
import { PermissionsService } from 'ag-common-svc/lib/services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ARMPermissionsService extends PermissionsService {
  constructor(armAuthService: ARMAuthService) {
    super();
    this.permissions$ = armAuthService.armUserRoles$.pipe(
      map((userRoles: UserRole[]) => {
        // Initialize an empty object to store permissions
        const permissionsMap: RoleEntityPermissionMap = {};

        // Iterate over user roles
        userRoles.forEach(userRole => {
          // Get the activity permissions from userRole
          const activityPermissions = userRole[UserRoleModelKeys.activity] ?? {};

          // Iterate over activity permissions
          Object.keys(activityPermissions).forEach(entityKey => {
            // Check if entity key exists in permissionsMap, if not, initialize it
            if (!permissionsMap[entityKey]) {
              permissionsMap[entityKey] = {};
            }

            // Add or update permission values for each entity
            Object.keys(activityPermissions[entityKey]).forEach(permissionKey => {
              // If permission was already set to true, keep it as true otherwise use the current value
              permissionsMap[entityKey][permissionKey] =
                permissionsMap[entityKey][permissionKey] === true || activityPermissions[entityKey][permissionKey];
            });
          });
        });

        return permissionsMap;
      }),
    );
  }
}
