<ng-container *var="taskIssues$ | async as taskIssues">
  <ag-shr-modal-window
    #campaignsIssuesModalRef
    [width]="'95vw'"
    [height]="'auto'"
    title="Task Issues"
    [showSaveButton]="false"
    [inProgress]="inProgress$ | async"
    [extraToolbarItems]="[
      {
        widget: 'dxButton',
        location: 'before',
        toolbar: 'bottom',
        options: {
          text: 'MOVE',
          type: 'default',
          disabled: !taskIssues.length || !selectedTask?.assignedTo || (inProgress$ | async),
          onClick: moveTaskConfirmation
        }
      },
      {
        widget: 'dxButton',
        location: 'before',
        toolbar: 'bottom',
        visible: !selectedTask?.assignedTo && Entity.assignOwnerCampaign | hasPermission | async,
        disabled: (inProgress$ | async),
        options: {
          text: 'ASSIGN OWNER',
          type: 'default',
          onClick: showAssignOwnerPopup
        }
      }
    ]"
  >
    <ag-crm-campaign-issues-grid
      #campaignIssuesGridRef
      caption="Issues"
      [issues]="taskIssues"
      [canReportIssue]="selectedTask?.taskStatus !== CampaignsManagementTasksStatus.done"
      (openEditIssueModal)="showEditIssuePopup($event)"
    ></ag-crm-campaign-issues-grid>
  </ag-shr-modal-window>
</ng-container>

<ag-crm-campaign-issues-modal #campaignIssuesModalRef (updateIssues)="refreshIssues()"></ag-crm-campaign-issues-modal>

<ag-shr-assign-owner-viewer
  #assignOwnerViewerRef
  [style.visibility]="'hidden'"
  [onOwnerChange]="changeOwner"
></ag-shr-assign-owner-viewer>
