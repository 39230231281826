<ul>
  <ng-container *ngIf="agentCampaignChanges?.[AgentCampaignStepName.webSite] as webSiteChanges">
    <ng-container *ngTemplateOutlet="stepContainer; context: { title: 'Web Site Data', stepData: webSiteChanges, displayDataTemplate: websiteDataTemplate }"></ng-container>
  </ng-container>

  <!-- <ng-container *ngIf="changesToSubmit?.[AgentCampaignStepName.contactInfoAddress] as contactInfoAddressChanges">
      <p>
        Current value: {{contactInfoAddressChanges?.currentStepData | fullAddress}}
        IncomingValue: {{contactInfoAddressChanges?.incomingStepData | fullAddress}}
      </p>

    </ng-container> -->
</ul>

<ng-template #title></ng-template>

<ng-template
  #websiteDataTemplate
  let-data="data"
>
  <ng-container *ngIf="data?.domainOption !== null; else userDomainTmp">
    {{data?.domainOption}}/{{data?.subdomain}}
  </ng-container>

  <ng-template #userDomainTmp>Custom Domain: {{data?.subdomain}}</ng-template>
</ng-template>

<ng-template
  #stepContainer
  let-title="title"
  let-stepData="stepData"
  let-displayDataTemplate="displayDataTemplate"
>
  <li *var="stepData?.currentStepData as currentStepData">
    <p class="agent-campaign-changes-list__title">
      {{title}} is
      <b>
        {{currentStepData ? 'Updated' : 'Added'}}
      </b>
      :
    </p>

    <p class="agent-campaign-changes-list__data">
      <span
        *ngIf="currentStepData"
        class="agent-campaign-changes-list__current-data"
      >
        <ng-container *ngTemplateOutlet="displayDataTemplate; context: { data: currentStepData }"></ng-container>
      </span>

      <i
        *ngIf="currentStepData"
        class="dx-icon dx-icon-arrowright"
      ></i>

      <span
        *var="stepData?.incomingStepData as incomingStepData"
        class="agent-campaign-changes-list__incoming-data"
      >
        <ng-container *ngTemplateOutlet="displayDataTemplate; context: { data: incomingStepData }"></ng-container>
      </span>
    </p>
  </li>
</ng-template>
