import { AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';

export const AGENTS_GRID_TITLE_LIST = {
  [AgentKeys.p_agent_id]: 'Agent ID',
  [AgentKeys.email_addresses]: 'Email Addresses',
  [AgentKeys.p_agent_first_name]: 'First Name',
  [AgentKeys.p_agent_last_name]: 'Last Name',
  [AgentKeys.agent_status]: 'Agent Status',
  [AgentKeys.prospect_status]: 'Prospect Status',
  [AgentKeys.p_mga_id]: 'MGA',
  [AgentKeys.p_agency_id]: 'Agency',
  [AgentKeys.agent_type]: 'Agent Type',
  [AgentKeys.role]: 'Role',
  [AgentKeys.phone_numbers]: 'Phone Numbers',
  [AgentKeys.is_manager]: 'Is Manager',
  [AgentKeys.is_rmd]: 'Is RMD',
  [AgentKeys.christmas_card]: 'Christmas Card',
  [AgentKeys.conference_poster]: 'Conference Poster',
  [AgentKeys.addresses]: 'Addresses',
};

export const AGENTS_GRID_EXPORT_PHONE_TITLE_LIST = [
  {
    header: AGENTS_GRID_TITLE_LIST[AgentKeys.phone_numbers],
    key: AgentKeys.phone_numbers,
  },
  {
    header: 'Type',
    key: 'phone_type',
  },
  {
    header: 'Is Whatsapp',
    key: 'is_whatsapp',
    width: 10,
  },
];

export const AGENTS_GRID_EXPORT_ADDRESSES_TITLE_LIST = [
  {
    header: 'Shipping Address 1',
    key: 'shipping_address1',
    width: 17,
  },
  {
    header: 'Shipping Address 2',
    key: 'shipping_address2',
    width: 17,
  },
  {
    header: 'Shipping City',
    key: 'shipping_city',
    width: 17,
  },
  {
    header: 'Shipping State',
    key: 'shipping_state',
  },
  {
    header: 'Shipping Zip',
    key: 'shipping_zip',
  },
  {
    header: 'Shipping Country',
    key: 'shipping_country',
  },
  {
    header: 'Shipping County',
    key: 'shipping_county',
  },
  {
    header: 'Billing Address 1',
    key: 'billing_address1',
    width: 17,
  },
  {
    header: 'Billing Address 2',
    key: 'billing_address2',
    width: 17,
  },
  {
    header: 'Billing City',
    key: 'billing_city',
    width: 17,
  },
  {
    header: 'Billing State',
    key: 'billing_state',
  },
  {
    header: 'Billing Zip',
    key: 'billing_zip',
  },
  {
    header: 'Billing Country',
    key: 'billing_country',
  },
  {
    header: 'Billing County',
    key: 'billing_county',
  },
];
