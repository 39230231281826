import { format, isValid, toDate } from 'date-fns';
import { MMDDYYYY_DATE_FORMAT } from '@ag-common-lib/public-api';

export const stringFromDate = (date: Date | string, stringToFormat: string = MMDDYYYY_DATE_FORMAT): string => {
  if (!date) {
    return '';
  }

  if (!isValid(toDate(date))) {
    return '';
  }
  return format(date, stringToFormat);
};
