<header>
  <dx-toolbar class="header-toolbar" (onInitialized)="onToolbarInitialized($event)">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"
    >
    </dxi-item>
    <dxi-item location="before" [template]="'imagetemplate'"> </dxi-item>
    <div *dxTemplate="let data of 'imagetemplate'">
      <img class="logo_image" src="assets/ag-logo-sm-blue.png" (click)="navigateTo('Home')" />
    </div>
    <dxi-item location="before" *ngIf="title" [template]="'headerTemplate'"> </dxi-item>
    <div *dxTemplate="let data of 'headerTemplate'">
      <span class="header-title" (click)="navigateTo('Home')">
        {{ title }}
      </span>
    </div>
    <dxi-item
      location="before"
      widget="dxDropDownButton"
      locateInMenu="auto"
      [options]="contactsBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item>
    <!-- <dxi-item
      location="before"
      widget="dxDropDownButton"
      locateInMenu="auto"
      [options]="emailBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item> -->
    <dxi-item
      location="before"
      widget="dxDropDownButton"
      locateInMenu="auto"
      [options]="notificationsBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item>
    <dxi-item
      location="before"
      widget="dxDropDownButton"
      locateInMenu="auto"
      [options]="registrationBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item>

    <dxi-item
      location="before"
      widget="dxDropDownButton"
      locateInMenu="auto"
      [options]="campaignsBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item>

    <dxi-item
      location="before"
      widget="dxDropDownButton"
      locateInMenu="auto"
      [options]="reportsBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item>
    <dxi-item
      location="before"
      widget="dxDropDownButton"
      locateInMenu="auto"
      [options]="adminBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item>
    <dxi-item
      location="after"
      widget="dxDropDownButton"
      locateInMenu="never"
      cssClass="header__user-drop-down"
      [options]="userBoxOptions$ | async"
      [height]="'10px'"
    ></dxi-item>
  </dx-toolbar>
</header>
