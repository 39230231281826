import { Component, HostBinding, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxTabsComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import {
  BaseModelKeys,
  Conference,
  ConferenceKeys,
  Constants,
  DX_USD_CURRENCY_FORMAT,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Registrant,
  RegistrantKeys,
} from 'ag-common-lib/public-api';
import { ConferenceService } from 'ag-common-svc/lib/services/conference.service';
import { LookupsService } from 'ag-common-svc/public-api';
import { ConferenceModalComponent } from './modals/conference-modal/conference-modal.component';
import { CONFERENCES_TITLE_LIST } from './config/conferences.config';
import { AttendeesListModalComponent } from './modals/attendees-list-modal/attendees-list-modal.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';
import { ConferenceExcursionStatisticModalComponent } from './modals/conference-excursion-statistic-modal/conference-excursion-statistic-modal.component';
import { ConferenceTransactionsModalComponent } from './modals/conference-transactions-modal/conference-transactions-modal.component';

@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.scss'],
})
export class ConferencesComponent {
  @HostBinding('class') className = 'conferences grid';
  @ViewChild('conferenceModalRef') conferenceModalComponent: ConferenceModalComponent;
  @ViewChild('attendeesModalRef') attendeesModalComponent: AttendeesListModalComponent;
  @ViewChild('conferenceExcursionStatisticModalRef')
  conferenceExcursionStatisticModalComponent: ConferenceExcursionStatisticModalComponent;
  @ViewChild('conferenceTransactionsModalRef')
  conferenceTransactionsModalComponent: ConferenceTransactionsModalComponent;

  registrants: Promise<Registrant[]>;

  conferences$: Observable<Conference[]> = this.conferenceService.getList();

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly emptyMessage: string = 'No Conferences Currently Exist';
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private readonly lookupsService: LookupsService,
    private conferenceService: ConferenceService,
    private router: Router,
  ) {}

  protected onRowRemoving = (e: RowRemovingEvent) => {
    e.cancel = this.conferenceService.delete(e.data?.[BaseModelKeys.dbId]);
  };

  protected addConference = (): void => {
    this.conferenceModalComponent.showModal();
  };

  protected editConference = ({ row: { data } }): void => {
    this.conferenceModalComponent.showModal(data);
  };

  protected onRowDblClick({ data }): void {
    this.attendeesModalComponent.showModal(data);
  }

  protected showRegistrantsConfiguration({ row: { data } }): void {
    this.attendeesModalComponent.showModal(data);
  }

  protected showConferenceTasks = ({ row: { data } }): void => {
    this.router.navigate(['/conference-registrations'], { queryParams: { id: data.dbId } });
  };

  protected showSeatRemainingList = ({ row: { data } }): void => {
    this.conferenceExcursionStatisticModalComponent.showModal(data);
  };

  protected showTransactionsModal = ({ row: { data } }): void => {
    this.conferenceTransactionsModalComponent.showModal(data);
  };

  isSeatsRemainingVisible = ({ row: { data } }) => data?.[ConferenceKeys.excursionsEnabled];
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
