import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ARMAuthService } from '../../shared/services/util/arm-auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPermission, UserPermissionKeys } from 'ag-common-lib/public-api';
import { CRMRole } from 'ag-common-lib/lib/lists/crm-roles.enum';

@Injectable({ providedIn: 'root' })
export class AuthStatsGuard implements CanActivate {
  // todo: Update when user role management is created
  constructor(private authService: ARMAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.userPermissions$.pipe(
      map((userPermissions: UserPermission[]) => {
        if (!Array.isArray(userPermissions) || !userPermissions?.length) {
          return false;
        }

        return userPermissions?.some(
          permission =>
            permission[UserPermissionKeys.isEnabled] &&
            permission[UserPermissionKeys.role]?.length &&
            permission[UserPermissionKeys.role].some(role => role === CRMRole.SUPER_ADMIN),
        );
      }),
    );
  }
}
