import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eventNameTitle',
})
export class EventNameTitlePipe implements PipeTransform {
    transform(eventName: string, mask: string, defaultValue: string = ''): string {
        if (!eventName) {
            return defaultValue;
        }

        return mask.replace('{{EVENT_NAME}}', eventName)
    }
}
