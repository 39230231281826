<ag-shr-modal-window
  #modalRef
  [title]="eventName$ | async | eventNameTitle: eventNameTitleMask : caption"
  [width]="'80vw'"
  [height]="'590px'"
  [inProgress]="inProgress$ | async"
  [useScrollView]="false"
  (onSaveClick)="handleSave()"
  [onCloseModal]="handleClosePopup"
>
  <dx-data-grid
    *ngIf="modalRef?.popupComponent?.visible ?? false"
    #agentsGridRef
    class="editor__data-grid"
    [dataSource]="dataSource"
    [showBorders]="true"
    [showRowLines]="true"
    [columnAutoWidth]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="false"
    [allowColumnResizing]="true"
    [noDataText]="'No Agent Selected'"
    width="100%"
    height="100%"
    (onInitNewRow)="onInitNewRow($event)"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxo-editing
      mode="batch"
      [allowUpdating]="true"
      [allowDeleting]="true"
      [allowAdding]="true"
      [useIcons]="true"
    ></dxo-editing>

    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton"></dxi-item>
    </dxo-toolbar>

    <dxi-column
      [dataField]="BaseModelKeys.dbId"
      [caption]="'Agent'"
      [showEditorAlways]="true"
      [allowEditing]="true"
      editCellTemplate="agentEditCellTemplate"
      [width]="'30%'"
    >
      <dxi-validation-rule type="required"> </dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="RegistrantKeys.mgaId" [caption]="'MGA'" [allowEditing]="false">
      <dxo-lookup
        [dataSource]="agencies$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="RegistrantKeys.agencyId" [caption]="'Agency'" [allowEditing]="false">
      <dxo-lookup
        [dataSource]="agencies$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="RegistrantKeys.registrationType" [caption]="'Registration Type'" [width]="200">
      <dxo-lookup
        [dataSource]="registrationTypeLookup"
        [valueExpr]="LookupKeys.value"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>

      <dxi-validation-rule type="required"> </dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="RegistrantKeys.companyReference" [caption]="'Company Reference'" [width]="200">
    </dxi-column>

    <dxi-column [dataField]="RegistrantKeys.qualifiedAs" [caption]="'Qualified As'" [width]="200">
      <dxo-lookup
        [dataSource]="qualifiedAsLookup"
        [valueExpr]="LookupKeys.value"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>

      <dxi-validation-rule type="required"> </dxi-validation-rule>
    </dxi-column>

    <div *dxTemplate="let cell of 'agentEditCellTemplate'">
      <dx-select-box
        labelMode="hidden"
        [value]="cell.value"
        (onSelectionChanged)="valueChange($event, cell)"
        [dataSource]="agentsDataSource"
        [valueExpr]="'dbId'"
        [displayExpr]="AgentKeys.p_agent_name"
        [searchEnabled]="true"
        [searchExpr]="[AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name, AgentKeys.p_email]"
        [dropDownOptions]="{ wrapperAttr: { class: 'ag-select-box-drop-down' } }"
      >
        <!-- [dropDownOptions]="{ width: 320, wrapperAttr: { class: 'my-awesome-class' } }" -->
        <div *dxTemplate="let data of 'item'">
          {{ data.p_agent_name }}

          (<i>{{ data?.p_email | empty }}</i
          >)
        </div>
      </dx-select-box>
    </div>
  </dx-data-grid>
</ag-shr-modal-window>
