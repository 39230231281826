import { Component, Input, HostBinding } from '@angular/core';
import { Conference, ConferenceKeys, Constants, Messages } from 'ag-common-lib/public-api';
import { CONFERENCES_TITLE_LIST, PHONE_NUMBER_OPTIONS } from '../../../config/conferences.config';

@Component({
  selector: 'ag-crm-conference-event',
  templateUrl: './conference-event.component.html',
  styleUrls: ['./conference-event.component.scss'],
})
export class ConferenceEventFormComponent {
  @HostBinding('class') className = 'conference-event';
  @Input() conference: Partial<Conference>;
  @Input() isReadonly: boolean = false;
  @Input() validationGroup: string;

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly phoneNumberOptions = PHONE_NUMBER_OPTIONS;
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly Messages = Messages;
}
