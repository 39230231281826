import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { Agent, AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { CampaignsService } from './campaigns.service';
import { DxFormComponent } from 'devextreme-angular';
import { format } from 'date-fns';
import {
  AGENT_CAMPAIGNS_STATUS_LOOKUP,
  AgentCampaigns,
  AgentCampaignsKeys,
  Constants,
  DATE_NOW,
  EditorOptions,
  Messages,
  Patterns,
} from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
  providers: [CampaignsService],
})
export class CampaignsComponent {
  @ViewChild('campaignsModalRef') campaignsModalComponent: ModalWindowComponent;
  @ViewChild('campaignsFormRef', { static: false }) campaignsFormRef: DxFormComponent;
  @Output() campaignsChange = new EventEmitter<{ agentId: string; updates: Partial<Agent> }>();

  @Input() set agent(data: Agent) {
    this._agent = data;
    this.agentCampaigns = data?.campaigns;
    this.campaignsFormData = this.campaignsService.getFormData(data);
  }
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  agentCampaigns: AgentCampaigns;
  inProgress$ = this.campaignsService.inProgress$;
  campaignsFormData: Partial<AgentCampaigns>;
  readonly AgentKeys = AgentKeys;
  readonly Messages = Messages;
  protected readonly Date = Date;
  readonly urlValidation = Patterns.URL_PATTERN_WITH_OR_WITHOUT_PROTOCOL;
  readonly dateValidation = Patterns.DATE_PATTERN_MMDDYYYY;
  readonly now: Date = DATE_NOW;
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  readonly dateEditorOptions = { ...EditorOptions.DATE, ...EditorOptions.MIN_DATE };

  protected readonly AgentCampaignsKeys = AgentCampaignsKeys;
  protected readonly AGENT_CAMPAIGNS_STATUS_LOOKUP = AGENT_CAMPAIGNS_STATUS_LOOKUP;

  private _agent: Agent;

  constructor(private readonly campaignsService: CampaignsService) {}

  get isOutstandUser(): boolean {
    return this.campaignsFormData?.originalOutstandUser;
  }

  handleClosePopup = () => this.campaignsService.onCancelEdit.call(this, this.onAfterRevertChanges);

  handleSaveClick = async () => {
    const result = await this.campaignsService.save(this.campaignsModalComponent);
    if (result) {
      this._agent[AgentKeys.campaigns] = result[AgentKeys.campaigns];
    }
  };

  validateDate = (e): boolean => {
    const formDate: string = format(e.value, this.dateFormat);
    const re: RegExp = new RegExp(this.dateValidation);
    return re.test(formDate);
  };

  validateUrlWithOrWithoutProtocol = e => {
    const re = new RegExp(this.urlValidation);
    return re.test(e.value);
  };

  onAfterRevertChanges = (): void => {
    this.campaignsFormRef.instance.repaint();
  };
}
