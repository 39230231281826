import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxCheckBoxModule, DxLoadPanelModule } from 'devextreme-angular';
import { ConferenceRegistrationsGridComponent } from './conference-registration-grid.component';
import { ConferenceRegistrationTakStatusPipe } from './pipe/conference-registration-task-status.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { AgCrmCommonModule } from '../../common/ag-crm-common.module';
import { ConferencesModule } from '../conferences/conferences.module';
import { AgAssignOwnerViewerModule, ModalWindowModule } from 'ag-common-svc/lib/components';
import { InvitedParticipantsGridComponent } from './invited-participants-grid/invited-participants-grid.component';
import { RejectedParticipantsGridComponent } from './rejected-participants-grid/rejected-participants-grid.component';
import { ConferenceRegistrationStatusPipe } from './pipe/conference-registration-status.pipe';
import { RegistrantIssueStatusPipe } from './pipe/registrant-issue-status.pipe';
import { CampaignsManagementTaskBoardModule } from '../../../ag-campaigns/campaigns-management-task-board/campaigns-management-task-board.module';
import { ConferenceRegistrationTicketStatusPipe } from './pipe/conference-registration-ticket-status.pipe';
import { AttendeeDetailsModalModule } from '../conferences/modals/attendee-details-modal/attendee-details-modal.module';
import { ConferenceRegistrationOwnerPipe } from './pipe/conference-registration-owner.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ConferencesModule,
    AgAssignOwnerViewerModule,
    DxLoadPanelModule,
    ModalWindowModule,
    CampaignsManagementTaskBoardModule,
    AttendeeDetailsModalModule,
    AgCrmCommonModule,
    DxCheckBoxModule,
  ],
  declarations: [
    ConferenceRegistrationsGridComponent,
    InvitedParticipantsGridComponent,
    RejectedParticipantsGridComponent,

    ConferenceRegistrationTakStatusPipe,
    ConferenceRegistrationStatusPipe,
    RegistrantIssueStatusPipe,
    ConferenceRegistrationTicketStatusPipe,
    ConferenceRegistrationOwnerPipe,
  ],
  exports: [ConferenceRegistrationsGridComponent, InvitedParticipantsGridComponent, RejectedParticipantsGridComponent],
  providers: [],
})
export class ConferenceRegistrationGridModule {}
