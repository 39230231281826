import { Component, HostBinding, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { InitializedEvent } from 'devextreme/ui/form';
import { BehaviorSubject } from 'rxjs';
import { AttendeeItineraryModalService } from './attendee-itinerary-modal.service';
import { AttendeeFlightDataKeys, AttendeeFlightGroup } from '../attendee-flight.service';
import {
  AttendeeKeys,
  AttendeeType,
  EditorOptions,
  Entity,
  EntityPermissionActivityKeys,
  FlightBookingKeys,
  FlightBookingModel,
  FlightInfoKeys,
  FlightInformationModel,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  LocalDateTimeString,
  LookupId,
  MMDDYYYY_DATE_FORMAT,
  MILITARY_TIME_FORMAT,
  RegistrantKeys,
} from 'ag-common-lib/public-api';

@UntilDestroy()
@Component({
  selector: 'ag-crm-attendee-itinerary-modal',
  templateUrl: './attendee-itinerary-modal.component.html',
  styleUrls: ['./attendee-itinerary-modal.component.scss'],
})
export class AttendeeItineraryModalComponent {
  @HostBinding('class') className = 'attendee-itinerary-modal';
  @ViewChild('attendeeItineraryModalRef', { static: true }) attendeeItineraryModalComponent: ModalWindowComponent;

  activity: EntityPermissionActivityKeys;
  attendeeType: AttendeeType;
  protected readonly colCountByScreen = {
    lg: 12,
    md: 1,
  };
  protected formWidth$ = new BehaviorSubject<number>(0);
  protected validationGroup = 'attendeeItineraryModalValidationGroup';

  protected inProgress$ = this.attendeeItineraryModalService.inProgress$;
  protected formData: FlightBookingModel;
  protected FlightBookingKeys = FlightBookingKeys;
  protected flightInfo: FlightInformationModel;
  protected FlightInfoKeys = FlightInfoKeys;
  protected dob: LocalDateTimeString;
  protected gender: LookupId<'Genders'>;
  protected dateFormat = MMDDYYYY_DATE_FORMAT;
  readonly dateTimeEditorOptions = {
    ...EditorOptions.DATE,
    dateSerializationFormat: LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  };
  protected readonly timeEditorOptions = Object.assign(
    {},
    {
      type: 'time',
      interval: 15,
      useMaskBehavior: true,
      displayFormat: 'hh:mm a',
      dateSerializationFormat: MILITARY_TIME_FORMAT,
    },
  );
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected excludedFields: RegistrantKeys[] = [];

  constructor(private attendeeItineraryModalService: AttendeeItineraryModalService) {}

  showModal(data: AttendeeFlightGroup, flightBooking?: Partial<FlightBookingModel>): void {
    this.attendeeType = data?.[AttendeeKeys.attendeeType];
    this.activity = !flightBooking ? EntityPermissionActivityKeys.create : EntityPermissionActivityKeys.write;
    this.dob = data?.dob;
    this.gender = data?.gender;
    this.flightInfo = data?.[AttendeeFlightDataKeys.flightInformation];
    this.formData = this.attendeeItineraryModalService.getFormData(data, flightBooking);
    this.attendeeItineraryModalComponent?.showModal();
  }

  protected onInit = (e: InitializedEvent) => {
    const nativeElement = e.component.$element()?.[0];

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === nativeElement) {
          const width = entry.contentRect.width;

          this.formWidth$.next(width);
        }
      }
    });

    resizeObserver.observe(nativeElement);
  };

  protected handleSave = async () => {
    try {
      await this.attendeeItineraryModalService.saveItineraryUpdates();

      this.attendeeItineraryModalComponent.hideModal();
    } catch (error) {
      console.error(error);
    }
  };

  protected handleClosePopup = this.attendeeItineraryModalService.onCancelEdit;
}
