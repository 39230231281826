<ag-shr-modal-window
  #conferenceTransactionsModalRef
  [title]="conference?.[ConferenceKeys.eventName] | eventNameTitle  : eventNameTitleMask : caption"
  [width]="'80vw'"
  [height]="'80vh'"
  [useScrollView]="false"
  [showSaveButton]="false"
  [showFullScreenButton]="false"
>
  <dx-data-grid
    *ngIf="conferenceTransactionsModalRef?.popupComponent?.visible ?? false"
    [dataSource]="transactions$ | async"
    [showRowLines]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    width="100%"
    [height]="'100%'"
    [noDataText]="emptyMessage"
    (onExporting)="onExporting($event)"
    [columnHidingEnabled]="true"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>

    <dxi-column
      [dataField]="RegistrationTransactionKeys.registrantDbId"
      [caption]="'Registrant'"
      [showWhenGrouped]="true"
    >
      <dxo-lookup
        [dataSource]="registrants$ | async"
        [displayExpr]="registrantDisplayExpr"
        [valueExpr]="BaseModelKeys.dbId"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="StripeTransactionKeys.stripeId" caption="Transaction ID" [width]="270"></dxi-column>

    <dxi-column [dataField]="StripeTransactionKeys.type" [width]="130">
      <dxo-lookup
        [dataSource]="TRANSACTION_TYPE_LOOKUP"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="StripeTransactionKeys.status" [width]="100">
      <dxo-lookup
        [dataSource]="TRANSACTIONS_STATUSES_LOOKUP"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Created Date"
      [dataField]="BaseModelKeys.createdDate"
      dataType="datetime"
      [sortIndex]="1"
      [width]="150"
    ></dxi-column>

    <dxi-column caption="Made By" [dataField]="BaseModelKeys.createdByEmail"></dxi-column>

    <dxi-column caption="Description" [dataField]="StripeTransactionKeys.description"></dxi-column>

    <dxi-column
      [dataField]="StripeTransactionKeys.amount"
      caption="Amount"
      [dataType]="'number'"
      [format]="DX_USD_CURRENCY_FORMAT"
      [alignment]="'right'"
      [editorOptions]="{ format: DX_USD_CURRENCY_FORMAT, min: 0 }"
      width="auto"
      [minWidth]="150"
    >
    </dxi-column>
  </dx-data-grid>
</ag-shr-modal-window>
