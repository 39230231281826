import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import {
  NotificationOptInGroup,
  NotificationOptInGroupKeys,
  NotificationOptInGroupType,
  notificationOptInGroupTypeLookup,
} from '@ag-common-lib/lib';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { BaseModelKeys, ConferenceKeys, LookupKeys } from '@ag-common-lib/public-api';
import { ConferenceService } from 'ag-common-svc/public-api';
import { shareReplay } from 'rxjs';
import { convertFilter } from 'ag-common-svc/lib/utils/filter-builder-converter.utils';
import { DxFilterBuilderComponent } from 'devextreme-angular';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';

@Component({
  selector: 'ag-shr-add-notification-opt-in-group-modal',
  templateUrl: './add-notification-opt-in-group-modal.component.html',
  styleUrls: ['./add-notification-opt-in-group-modal.component.scss'],
})
export class AddNotificationOptInGroupModalComponent {
  @HostBinding('class') className = 'add-notification-opt-in-group-modal';

  @ViewChild('notificationNotificationOptInGroupModalRef', { static: false })
  protected notificationNotificationOptInGroupModalComponent: ModalWindowComponent;

  @ViewChild('filterBuilderRef', { static: false })
  protected filterBuilderComponent: DxFilterBuilderComponent;

  @Input() title = 'Add Opt-In Group';
  @Output() handleOptInGroup = new EventEmitter<NotificationOptInGroup>();

  protected BaseModelKeys = BaseModelKeys;
  protected LookupKeys = LookupKeys;
  protected ConferenceKeys = ConferenceKeys;
  protected NotificationOptInGroupType = NotificationOptInGroupType;
  protected NotificationOptInGroupKeys = NotificationOptInGroupKeys;
  protected filterExpression: any[] = [];
  protected notificationOptInGroup: NotificationOptInGroup;
  protected validationGroup = 'optInGroupValidationGroup';
  protected notificationOptInGroupTypeLookup = notificationOptInGroupTypeLookup;
  protected conferences$ = this.conferenceService.getList().pipe(shareReplay(1));

  constructor(private conferenceService: ConferenceService) {}

  showModal = async (): Promise<void> => {
    this.filterExpression = [];
    this.notificationOptInGroup = new NotificationOptInGroup(null);
    this.notificationNotificationOptInGroupModalComponent?.showModal();
  };

  protected addNotificationRecipients = async () => {
    const filterExpression = convertFilter(this.filterExpression);

    const isValid = await validateDxGroups(this.validationGroup);

    if (isValid) {
      this.handleOptInGroup.emit(
        Object.assign({}, this.notificationOptInGroup, {
          [NotificationOptInGroupKeys.filterExpression]: filterExpression,
        }),
      );

      this.notificationNotificationOptInGroupModalComponent.forceCloseModal();
    }
  };

  onValueChanged = console.log;
}
