import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeDetailsGroupControlsComponent } from './attendee-details-group-controls.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [AttendeeDetailsGroupControlsComponent],
  exports: [AttendeeDetailsGroupControlsComponent],
  providers: [],
})
export class AttendeeDetailsGroupControlsModule {}
