import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DietaryConsiderationsFormModule } from 'ag-common-svc/lib/components/agent-editor/components/dietary-considerations-form/dietary-considerations-form.module';
import { RelationshipTypeSelectBoxModule } from 'ag-common-svc/lib/components/relationship-type-select-box/relationship-type-select-box.module';
import { SelectAssociationModule } from 'ag-common-svc/lib/components/select-association/select-association.module';
import { TShirtSeizesFormModule } from 'ag-common-svc/lib/components/t-shirt-seizes-form/t-shirt-seizes-form.module';
import { StatusActivityModule, ModalWindowModule } from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ConferencesPipesModule } from '../../../pipes/conferences-pipes.module';
import { AttendeeNoteModalModule } from '../attendee-note-modal/attendee-note-modal.module';
import { AttendeeGuestModalComponent } from './attendee-guest-modal/attendee-guest-modal.component';
import { AttendeeGuestModalService } from './attendee-guest-modal/attendee-guest-modal.service';
import { AttendeeGuestsComponent } from './attendee-guests.component';
import { AttendeeGuestsService } from './attendee-guests.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TShirtSeizesFormModule,
    RelationshipTypeSelectBoxModule,
    StatusActivityModule,
    ModalWindowModule,
    DietaryConsiderationsFormModule,
    SelectAssociationModule,
    AttendeeNoteModalModule,
    ConferencesPipesModule,
  ],
  declarations: [AttendeeGuestsComponent, AttendeeGuestModalComponent],
  exports: [AttendeeGuestsComponent],
  providers: [AttendeeGuestModalService, AttendeeGuestsService],
})
export class AttendeeGuestsModule {}
