import { EmailAddress } from 'ag-common-lib/lib/models/utils/email-address.model';
import { Address } from 'ag-common-lib/lib/models/utils/address.model';
import { RegistrantKeys } from 'ag-common-lib/lib/models/registration/registrants.model';
import { PhoneNumber } from 'ag-common-lib/lib/models/utils/phone-number.model';
import { Association } from 'ag-common-lib/lib/models/utils/association.model';

export enum AttendeeDetailsModalSection {
  generalInfo = 'generalInfo',
  emailAddress = 'emailAddress',
  address = 'address',
  phoneNumber = 'phoneNumber',
  shirtSizes = 'shirtSizes',
  headshot = 'headshot',
  dietaryConsiderations = 'dietaryConsiderations',
  emergencyContacts = 'emergencyContacts',
  guests = 'guests',
  hotelReservation = 'hotelReservation',
  flyInformation = 'flyInformation',
  excursions = 'excursions',
  transactions = 'transactions',
  notes = 'notes',
  changeLog = 'changeLog',
}

export enum AttendeeSectionGroups {
  inviteeInfo = 'inviteeInfo',
  preferredName = 'preferredName',
  gender = 'gender',
  dob = 'dob',
  emailAddress = 'emailAddress',
  shippingAddress = 'shippingAddress',
  billingAddress = 'billingAddress',
  mobilePhone = 'mobilePhone',
  emergencyContact = 'emergencyContact',
}

export type AttendeeSectionGroupsData<T extends AttendeeSectionGroups> = T extends AttendeeSectionGroups.emailAddress
  ? Partial<EmailAddress>
  : T extends AttendeeSectionGroups.shippingAddress
  ? Partial<Address>
  : T extends AttendeeSectionGroups.billingAddress
  ? Partial<Address>
  : T extends AttendeeSectionGroups.emergencyContact
  ? PhoneNumber
  : T extends AttendeeSectionGroups.mobilePhone
  ? Association
  : never;

export const attendeeSectionGroupsToFieldsMap = new Map<AttendeeSectionGroups, RegistrantKeys>([
  [AttendeeSectionGroups.emailAddress, RegistrantKeys.primaryEmailAddress],
  [AttendeeSectionGroups.shippingAddress, RegistrantKeys.shippingAddress],
  [AttendeeSectionGroups.billingAddress, RegistrantKeys.billingAddress],
  [AttendeeSectionGroups.mobilePhone, RegistrantKeys.mobilePhone],
  [AttendeeSectionGroups.emergencyContact, RegistrantKeys.emergencyContact],
]);

export const ATTENDEE_DETAIL_MODAL_SECTIONS_CONFIGURATION = [
  { id: AttendeeDetailsModalSection.generalInfo, title: 'Qualifier Info' },
  { id: AttendeeDetailsModalSection.guests, title: 'Guests' },
  { id: AttendeeDetailsModalSection.hotelReservation, title: 'Hotel Registration' },
  { id: AttendeeDetailsModalSection.flyInformation, title: 'Flight Information' },
  { id: AttendeeDetailsModalSection.excursions, title: 'Excursions' },
  { id: AttendeeDetailsModalSection.notes, title: 'Notes' },
  { id: AttendeeDetailsModalSection.transactions, title: 'Transactions' },
  { id: AttendeeDetailsModalSection.changeLog, title: 'Change Log' },
];
