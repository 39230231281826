<div class="row w-100 m-0 p-0 justify-content-center" style="width: 100vw; position: fixed; height: 100vh">
  <div
    class="col-3 m-0 p-0 d-flex justify-content-start"
    style="height: 85%; overflow-y: scroll; border-right: 1px solid darkgray"
  >
    <dx-list [dataSource]="menuItems" [height]="400" (onItemClick)="selectMenuItem($event)"> </dx-list>
  </div>
  <div class="col-9 m-0 p-0 d-flex justify-content-start" style="height: 85%; overflow-y: scroll">
    <app-general-settings *ngIf="selectedMenuItem == 'General'"></app-general-settings>
    <app-security-settings *ngIf="selectedMenuItem == 'Security'"></app-security-settings>
  </div>
</div>
