<ng-container *ngIf="!!log; else emptyLog">
  <ng-container *ngFor="let key of dietaryConsiderationKeys">
    <div
      *ngIf="hasLog(key, log)"
      [innerHTML]="normalizeLog(key, log) | changeLogsDiffHTML : dietaryConsiderationLookup : (getLookup(key) | async)"
    ></div>
  </ng-container>
</ng-container>

<ng-template #emptyLog><span>&mdash;</span></ng-template>
