import { Injectable } from '@angular/core';
import {
  Agent,
  AgentKeys,
  AssociationKeys,
  BaseModelKeys,
  Conference,
  ConferenceKeys,
  ConferencePossibleStayPeriodKey,
  EmailAddressKeys,
  HotelReservationKeys,
  PhoneNumberKeys,
  QualifiedAs,
  RegistrantData,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrationType,
} from 'ag-common-lib/public-api';
import { AgentAssociationsService } from 'ag-common-svc/lib/services/agent-associations.service';
import {
  AgentEmailAddressesService,
  AgentService,
  BaseFormService,
  ConferenceRegistrantsService,
  ConferenceService,
} from 'ag-common-svc/public-api';
import { BehaviorSubject, firstValueFrom, map, mergeMap, Observable, of, shareReplay } from 'rxjs';
import { get, set } from 'lodash';
import { SuffixPipe } from 'ag-common-svc/shared/pipes/suffix.pipe';

@Injectable()
export class AddAttendeesModalService extends BaseFormService {
  private _conferenceDbId$ = new BehaviorSubject<string>(null);
  private _conference$: Observable<Conference> = this._conferenceDbId$.pipe(
    mergeMap(conferenceDbId => {
      if (!conferenceDbId) {
        return of(null);
      }

      return this.conferenceService.getDocumentData(conferenceDbId);
    }),
    shareReplay(1),
  );
  eventName$: Observable<string> = this._conference$.pipe(
    map(conference => conference?.[ConferenceKeys.eventName]),
    shareReplay(1),
  );
  private _registrationType: RegistrationType;

  constructor(
    private suffixPipe: SuffixPipe,
    private agentService: AgentService,
    private conferenceService: ConferenceService,
    private agentAssociationsService: AgentAssociationsService,
    private agentEmailAddressesService: AgentEmailAddressesService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
  ) {
    super();
  }

  setConferenceDbId = (conferenceDbId: string) => {
    this._conferenceDbId$.next(conferenceDbId);
  };

  addAgentToConference = async (registrant: Partial<RegistrantData>) => {
    this.startProgress();

    const agentDbId = registrant?.[BaseModelKeys.dbId];
    const agent = await this.agentService.getById(agentDbId);
    this._registrationType = registrant?.[RegistrantKeys.registrationType] ?? RegistrationType.agent;

    const inviteeData = Object.assign(
      {
        [RegistrantKeys.registrationType]: RegistrationType.agent,
        [RegistrantKeys.qualifiedAs]: QualifiedAs.agtMoreThan75,
      },
      new RegistrantData(),
    );

    await this.pickInviteeData(inviteeData, agent);

    Object.assign(
      inviteeData,

      {
        [RegistrantKeys.eventId]: this._conferenceDbId$.value,
      },
      registrant,
    );

    return this.conferenceRegistrantsService
      .create(this._conferenceDbId$.value, agentDbId, { [RegistrantModelKeys.data]: inviteeData }, true)
      .finally(() => {
        this.stopProgress();
      });
  };

  private pickInviteeData = async (inviteeData: RegistrantData, agent: Agent) => {
    return Promise.all([
      this.getFromAgent(inviteeData, agent),
      this.getBadgeName(inviteeData, agent),
      this.getIsFirstTimeAttendee(inviteeData, agent),
      this.getInviteeEmergencyContact(inviteeData, agent),
      this.getInviteeAddresses(inviteeData, agent),
      this.getInviteeEmailAddresses(inviteeData, agent),
      this.getInviteePhoneNumbers(inviteeData, agent),
      this.getTravelDates(inviteeData, agent),
    ]);
  };

  private getFromAgent = async (inviteeData: RegistrantData, agent: Agent) => {
    const map = new Map<RegistrantKeys, AgentKeys>([
      [RegistrantKeys.title, AgentKeys.title],
      [RegistrantKeys.agentId, AgentKeys.p_agent_id],
      [RegistrantKeys.dob, AgentKeys.dob],
      [RegistrantKeys.gender, AgentKeys.gender],
      [RegistrantKeys.headshot, AgentKeys.headshot],
      [RegistrantKeys.dietaryConsideration, AgentKeys.dietaryConsideration],
      [RegistrantKeys.tShirtSizes, AgentKeys.tShirtSizes],
      [RegistrantKeys.prefix, AgentKeys.p_prefix],
      [RegistrantKeys.firstName, AgentKeys.p_agent_first_name],
      [RegistrantKeys.middleName, AgentKeys.p_agent_middle_name],
      [RegistrantKeys.lastName, AgentKeys.p_agent_last_name],
      [RegistrantKeys.suffix, AgentKeys.p_suffix],
    ]);

    map.forEach((agentFieldKey, RegistrantFieldKey) => {
      const data = get(agent, agentFieldKey);
      set(inviteeData, RegistrantFieldKey, data);
    });
  };

  private getIsFirstTimeAttendee = async (inviteeData: RegistrantData, agent: Agent) => {
    const registrations = await firstValueFrom(
      this.conferenceRegistrantsService.getRegistrationsByAgentDbId(agent?.[BaseModelKeys.dbId]),
    );
    const prevRegistrations = registrations?.filter(
      registration => registration?.[RegistrantModelKeys.conferenceDbId] !== this._conferenceDbId$.value,
    );

    Object.assign(inviteeData, { [RegistrantKeys.firstTimeAttendee]: !prevRegistrations?.length });
  };

  private getBadgeName = async (inviteeData: RegistrantData, agent: Agent) => {
    const agentSuffixId = agent?.[AgentKeys.p_suffix];
    const agentFirstName = agent?.[AgentKeys.p_agent_first_name];
    const agentLastName = agent?.[AgentKeys.p_agent_last_name];
    const [suffix] = await Promise.all([firstValueFrom(this.suffixPipe.transform(agentSuffixId))]);
    const agentBadgeName = [agentFirstName, agentLastName, suffix].filter(Boolean).join(' ');

    Object.assign(inviteeData, { [RegistrantKeys.badgeName]: agentBadgeName });
  };

  private getInviteeEmergencyContact = async (inviteeData: RegistrantData, agent: Agent): Promise<void> => {
    const agentAssociations = await firstValueFrom(this.agentAssociationsService.getList(agent?.dbId));
    const emergencyContact =
      agentAssociations?.find(association => association[AssociationKeys.isEmergencyContact]) ?? null;

    Object.assign(inviteeData, { [RegistrantKeys.emergencyContact]: emergencyContact });
  };

  private getInviteeAddresses = async (inviteeData: RegistrantData, agent: Agent): Promise<void> => {
    const agentAddresses = agent[AgentKeys.addresses];
    const addresses = agentAddresses?.reduce(
      (acc, address) => {
        if (address.is_primary_billing) {
          Object.assign(acc, { [RegistrantKeys.billingAddress]: address });
        }
        if (address.is_primary_shipping) {
          Object.assign(acc, { [RegistrantKeys.shippingAddress]: address });
        }

        return acc;
      },
      {
        [RegistrantKeys.billingAddress]: null,
        [RegistrantKeys.shippingAddress]: null,
      },
    );

    Object.assign(inviteeData, addresses);
  };

  private getInviteeEmailAddresses = async (inviteeData: RegistrantData, agent: Agent): Promise<void> => {
    const agentEmailAddresses = await firstValueFrom(this.agentEmailAddressesService.getList(agent?.dbId));

    if (!agentEmailAddresses?.length) {
      Object.assign(inviteeData, {
        [RegistrantKeys.primaryEmailAddress]: null,
      });
      return;
    }

    if (agentEmailAddresses?.length === 1) {
      Object.assign(inviteeData, {
        [RegistrantKeys.inviteeEmail]: agentEmailAddresses[0]?.[EmailAddressKeys.address] ?? null,
        [RegistrantKeys.primaryEmailAddress]: agentEmailAddresses[0],
      });
      return;
    }

    const primaryEmailAddress = agentEmailAddresses.find(emailAddress => {
      return emailAddress?.[EmailAddressKeys.isLogin];
    });

    Object.assign(inviteeData, {
      [RegistrantKeys.inviteeEmail]: primaryEmailAddress?.[EmailAddressKeys.address] ?? null,
      [RegistrantKeys.primaryEmailAddress]: primaryEmailAddress ?? null,
    });
  };

  private getInviteePhoneNumbers = async (inviteeData: RegistrantData, agent: Agent): Promise<void> => {
    const phoneNumbers = agent?.[AgentKeys.phone_numbers];
    if (!phoneNumbers?.length) {
      Object.assign(inviteeData, {
        [RegistrantKeys.mobilePhone]: null,
      });
      return;
    }

    if (phoneNumbers?.length === 1) {
      Object.assign(inviteeData, {
        [RegistrantKeys.mobilePhone]: phoneNumbers[0] ?? null,
      });
      return;
    }

    const mobilePhone = phoneNumbers
      .sort((left, right) => {
        if (left?.[PhoneNumberKeys.is_primary]) {
          return -1;
        }
        if (right?.[PhoneNumberKeys.is_primary]) {
          return 1;
        }

        return 0;
      })
      .find(phoneNumber => phoneNumber[PhoneNumberKeys.is_primary]);

    Object.assign(inviteeData, {
      [RegistrantKeys.mobilePhone]: mobilePhone ?? null,
    });
  };

  private getTravelDates = async (inviteeData: RegistrantData, agent: Agent) => {
    const conference = await firstValueFrom(this._conference$);

    const participantScheduleRanges = conference?.[ConferenceKeys.participantScheduleRanges];
    const scheduleRange = participantScheduleRanges.find(
      range => range?.[ConferencePossibleStayPeriodKey.registrationType] === this._registrationType,
    );

    const arrivalDate =
      scheduleRange?.[ConferencePossibleStayPeriodKey.primaryArrivalDate] ?? conference?.[ConferenceKeys.startDate];
    const departureDate =
      scheduleRange?.[ConferencePossibleStayPeriodKey.primaryDepartureDate] ?? conference?.[ConferenceKeys.endDate];

    set(inviteeData, [RegistrantKeys.hotelReservation, HotelReservationKeys.checkInDate], arrivalDate);
    set(inviteeData, [RegistrantKeys.hotelReservation, HotelReservationKeys.checkOutDate], departureDate);
    set(inviteeData, [RegistrantKeys.hotelReservation, HotelReservationKeys.requestedCheckInDate], arrivalDate);
    set(inviteeData, [RegistrantKeys.hotelReservation, HotelReservationKeys.requestedCheckOutDate], departureDate);
  };
}
