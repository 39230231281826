import { Component, OnInit, ViewChild } from '@angular/core';
import { LookupsService } from 'ag-common-svc/lib/services';
import { AttendeeNoteModalService } from './attendee-note-modal.service';
import {
  ConferenceRegistrationLog,
  ConferenceRegistrationLogKeys,
  ConferenceRegistrationRequestLogKey,
  ConferenceRegistrationSupportLogKey,
  REGISTRATION_LOG_STATUS_LOOKUP,
  REGISTRATION_LOG_TYPE_LOOKUP,
} from 'ag-common-lib/lib/models/registration/registrant-notes';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { DxFormComponent } from 'devextreme-angular';
import { ExcursionKeys } from 'ag-common-lib/lib/models/registration/excursion.model';
import { Constants, EditorOptions, Messages } from 'ag-common-lib/lib/constants/validation.constants';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { LookupKeys } from 'ag-common-lib/lib/models/crm/lookup.model';
import { AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { AssignedOwnerDetailsKeys } from 'ag-common-svc/lib/components/ag-assign-owner-viewer/config/ag-assign-owner.config';

@Component({
  selector: 'ag-crm-attendee-note-modal',
  templateUrl: './attendee-note-modal.component.html',
  styleUrls: ['./attendee-note-modal.component.scss'],
  providers: [AttendeeNoteModalService],
})
export class AttendeeNoteModalComponent implements OnInit {
  @ViewChild('noteModalComponentRef', { static: true }) noteModalComponent: ModalWindowComponent;
  @ViewChild('noteFormRef', { static: false }) noteFormComponent: DxFormComponent;

  noteFormData: ConferenceRegistrationLog;

  inProgress$ = this.attendeeNoteModalService.inProgress$;
  isSpecialRequest$ = this.attendeeNoteModalService.isSpecialRequest$;
  agentsDataSource = this.attendeeNoteModalService.agentsDataSource;

  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  readonly dateEditorOptions = {
    ...EditorOptions.DATE,
  };

  constructor(private attendeeNoteModalService: AttendeeNoteModalService, private lookupsService: LookupsService) {}

  ngOnInit(): void {}

  showModal = async (conferenceId: string, registrantId: string, data?: ConferenceRegistrationLog): Promise<void> => {
    this.noteFormData = await this.attendeeNoteModalService.getFormData(conferenceId, registrantId, data);
    this.noteModalComponent?.showModal();
  };

  handleSaveNote = e => {
    const validationResults = this.noteFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.attendeeNoteModalService.saveNote().then(() => {
        e.component.instance.hide();
      });
    }
  };

  onAfterRevertChanges = (): void => {
    this.noteFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.attendeeNoteModalService.onCancelEdit.call(this, this.onAfterRevertChanges);

  // public handleChangesSave = (editors: SaveActionPayload | SaveActionPayload[]) => {
  //   let isValid = true;
  //   const values = {};
  //
  //   const validateAndCollect = ({ component, validator }: SaveActionPayload) => {
  //     const editorValue = component.value;
  //     const editorName = component?.name;
  //
  //     if (validator) {
  //       const isEditorValid = validator?.instance?.validate()?.isValid;
  //       isValid &&= isEditorValid;
  //     }
  //
  //     Object.assign(values, { [editorName]: editorValue });
  //   };
  //
  //   Array.isArray(editors) ? editors.forEach(validateAndCollect) : validateAndCollect(editors);
  //
  //   if (!isValid) {
  //     return;
  //   }
  //   this.attendeeNoteModalService.updateFieldsData(values);
  // };

  // public handleChangesRevert = (editors: FormEditors | Array<FormEditors>) => {
  //   const revertEditorValue = editor => {
  //     editor.value = this.attendeeNoteModalService.formChangesDetector.getInitialValue(editor.name);
  //   };
  //   Array.isArray(editors) ? editors.forEach(revertEditorValue) : revertEditorValue(editors);
  // };
  //
  // public handleChangesRevert = (editors: FormEditors | Array<FormEditors>) => {
  //   const revertEditorValue = editor => {
  //     editor.value = this.attendeeNoteModalService.formChangesDetector.getInitialValue(editor.name);
  //   };
  //   Array.isArray(editors) ? editors.forEach(revertEditorValue) : revertEditorValue(editors);
  // };

  protected readonly ConferenceRegistrationLogKeys = ConferenceRegistrationLogKeys;
  protected readonly ExcursionKeys = ExcursionKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly ConferenceRegistrationSupportLogKey = ConferenceRegistrationSupportLogKey;
  protected readonly REGISTRATION_LOG_TYPE_LOOKUP = REGISTRATION_LOG_TYPE_LOOKUP;
  protected readonly REGISTRATION_LOG_STATUS_LOOKUP = REGISTRATION_LOG_STATUS_LOOKUP;
  protected readonly LookupKeys = LookupKeys;
  protected readonly ConferenceRegistrationRequestLogKey = ConferenceRegistrationRequestLogKey;
  protected readonly AssignedOwnerDetailsKeys = AssignedOwnerDetailsKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly Messages = Messages;
}
