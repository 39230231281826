<dx-scroll-view
  height="100%"
  [useNative]="false"
  [showScrollbar]="'always'"
>
  <dx-form
    [(formData)]="conference"
    labelMode="floating"
    [validationGroup]="validationGroup"
    [readOnly]="isReadonlyMode"
  >
    <dxi-item>
      <div *dxTemplate>
        <ag-crm-conference-step-info-modal #conferenceStepModalRef></ag-crm-conference-step-info-modal>

        <dx-data-grid
          [dataSource]="conference?.[ConferenceKeys.stepsConfiguration]"
          [showRowLines]="true"
          [rowAlternationEnabled]="true"
          width="100%"
          height="100%"
        >
          <dxo-load-panel [enabled]="true"></dxo-load-panel>
          <dxo-sorting mode="disabled"></dxo-sorting>

          <dxo-editing
            mode="row"
            [useIcons]="true"
            [allowUpdating]="!isReadonlyMode && (Entity.conferenceStepsConfiguration | hasPermission: EntityPermissionActivityKeys.write | async)"
            [allowDeleting]="false"
            [allowAdding]="false"
          >
          </dxo-editing>

          <dxi-column
            [dataField]="ConferenceStepsConfigurationKeys.stepName"
            caption="Step Name"
            [alignment]="'left'"
          >
            <dxo-lookup
              [dataSource]="stepNameLookup"
              [valueExpr]="'id'"
              [displayExpr]="'title'"
            ></dxo-lookup>
          </dxi-column>

          <dxi-column
            [dataField]="ConferenceStepsConfigurationKeys.title"
            caption="Title"
            [alignment]="'left'"
          ></dxi-column>

          <dxi-column
            [dataField]="ConferenceStepsConfigurationKeys.template"
            caption="Template"
            [alignment]="'left'"
            cssClass="text-wrap"
          >
            <dxo-lookup
              [dataSource]="stepNameTemplateLookup"
              [valueExpr]="LookupKeys.value"
              [displayExpr]="LookupKeys.description"
            ></dxo-lookup>
          </dxi-column>

          <dxi-column
            [dataField]="ConferenceStepsConfigurationKeys.excludedFields"
            caption="Excluded Fields"
            [alignment]="'left'"
            cellTemplate="excludedFieldsCellTemplate"
          ></dxi-column>

          <dxi-column
            [dataField]="ConferenceStepsConfigurationKeys.excludedSections"
            caption="Excluded Sections"
            [alignment]="'left'"
            cellTemplate="excludedSectionsCellTemplate"
          ></dxi-column>

          <dxi-column
            caption="Visibility"
            cellTemplate="visibilityCellTemplate"
            [width]="250"
          ></dxi-column>

          <dxi-column
            type="buttons"
            [visible]="!isReadonlyMode"
          >
            <dxi-button
              icon="edit"
              [onClick]="showEditPopup"
            ></dxi-button>
          </dxi-column>
          <!-- Templates -->
          <div *dxTemplate="let cell of 'visibilityCellTemplate'">
            <ng-container *ngIf="cell.data?.[ConferenceStepsConfigurationKeys.stepName] | conferenceStepStateKey:conference as sectionState">

                <dx-button
                  class="conference-steps-info-form__control"
                  [text]="sectionState?.isEnabled ? 'Enabled' : 'Disabled'"
                  [hint]="sectionState?.isEnabled ? 'Click to Disable Section' : 'Click to Enable Section'"
                  stylingMode="outlined"
                  [disabled]="isReadonlyMode && !(Entity.conferenceStepsConfiguration | hasPermission: EntityPermissionActivityKeys.write | async)"
                  [type]="sectionState?.isEnabled ? 'success' : 'danger'"
                  (onClick)="toggleSection($event, sectionState?.enableKey, sectionState?.isEnabled)"
                >
                </dx-button>

                <dx-button
                  class="conference-steps-info-form__control"
                  [text]="sectionState?.isActive ? 'Visible on Wizard' : 'Hidden on Wizard'"
                  [hint]="sectionState?.isActive ? 'Click to Hide on Wizard' : 'Click to Show On Wizard'"
                  stylingMode="outlined"
                  [visible]="sectionState?.isEnabled"
                  [type]="sectionState?.isActive ? 'success' : 'danger'"
                  [disabled]="isReadonlyMode && !(Entity.conferenceStepsConfiguration | hasPermission: EntityPermissionActivityKeys.write | async)"
                  (onClick)="toggleSectionForWizard($event, sectionState?.activeKey, sectionState?.isActive)"
                >
                </dx-button>

              </ng-container>
          </div>

          <div
            class="text-wrap"
            *dxTemplate="let cell of 'excludedSectionsCellTemplate'"
          >
            {{ cell.value | conferenceExcludedSections : cell.data.stepName }}
          </div>

          <div
            class="text-wrap"
            *dxTemplate="let cell of 'excludedFieldsCellTemplate'"
          >
            {{ cell.value | conferenceExcludedFields }}
          </div>
        </dx-data-grid>
      </div>
    </dxi-item>
  </dx-form>
</dx-scroll-view>
