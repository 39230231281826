import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  BaseModelKeys,
  CAMPAIGNS_MANAGEMENT_ISSUES_FIELD_LOOKUP,
  CAMPAIGNS_MANAGEMENT_ISSUES_TYPE_LOOKUP,
  CampaignsManagementIssuesStatus,
  CampaignsManagementIssuesType,
  CampaignsManagementTaskIssues,
  CampaignsManagementTaskIssuesKeys,
  Constants,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  LookupKeys,
} from 'ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'ag-crm-campaign-issues-grid',
  templateUrl: './campaigns-issues-grid.component.html',
  styleUrls: ['./campaigns-issues-grid.component.scss'],
})
export class CampaignsIssuesGridComponent {
  @ViewChild('campaignsIssuesGridRef', { static: false }) campaignsIssuesGridComponent: DxDataGridComponent;
  @Output() openEditIssueModal = new EventEmitter<CampaignsManagementTaskIssues>();
  @Input() caption: string = 'Issues';
  @Input() issues: CampaignsManagementTaskIssues[];
  @Input() canReportIssue = false;

  protected readonly emptyMessage = 'No Campaigns Issues Currently Exist';
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_TIME_FORMAT;
  protected readonly LookupKeys = LookupKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly CampaignsManagementTaskIssuesKeys = CampaignsManagementTaskIssuesKeys;
  protected readonly CampaignsManagementTaskIssuesTypeLookup = CAMPAIGNS_MANAGEMENT_ISSUES_TYPE_LOOKUP;
  protected readonly CampaignsManagementTaskIssuesFieldLookup = CAMPAIGNS_MANAGEMENT_ISSUES_FIELD_LOOKUP;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor() {}

  onContentReady = (e): void => {
    e.component.option('loadPanel.enabled', false);
  };

  refreshGrid(): void {
    this.campaignsIssuesGridComponent.instance.refresh();
  }

  showAddPopup = (): void => {
    const update = { [CampaignsManagementTaskIssuesKeys.issueType]: CampaignsManagementIssuesType.technical };
    this.openEditIssueModal.emit(update);
  };

  showEditPopup = ({ row: { data } }): void => {
    this.openEditIssueModal.emit(data);
  };

  canEditIssue = (e): boolean => {
    const status = e.row.data.issueStatus;
    return ![CampaignsManagementIssuesStatus.closed, CampaignsManagementIssuesStatus.resolved].includes(status);
  };
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
