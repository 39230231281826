import { Injectable } from '@angular/core';
import { EmailTemplates, EmailToSendConfig, NotifyEmailKeys, RegistrationSummaryEmailKeys } from 'ag-common-lib/lib';
import {
  AttendeeKeys,
  BaseCampaignsEmail,
  BaseCampaignsEmailKeys,
  BaseModelKeys,
  LookupKeys,
  RegistrantModelKeys,
  RegistrantWizardStateKeys,
} from 'ag-common-lib/public-api';
import {
  ConferenceRegistrationCampaignEmail,
  ConferenceRegistrationEmailCampaignService,
} from 'ag-common-svc/lib/services/email-campaigns/conference-registration-email-campaign.service';
import { RegistrationSummaryEmailService } from 'ag-common-svc/lib/services/registration-summary-email.service';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import { confirm } from 'devextreme/ui/dialog';

@Injectable()
export class AttendeesEmailsService {
  readonly emailTemplates = [
    {
      [LookupKeys.value]: EmailTemplates.notifyEmailConferenceRegistrationSummary,
      [LookupKeys.description]: 'Registration Summary',
    },
    {
      [LookupKeys.value]: EmailTemplates.notifyEmailKnowBeforeYouGoToConference,
      [LookupKeys.description]: 'Know Before You Go',
    },
    {
      [LookupKeys.value]: EmailTemplates.notifyEmailInviteesActivitiesFirstDay,
      [LookupKeys.description]: 'Activities First Day',
    },
    {
      [LookupKeys.value]: EmailTemplates.notifyEmailInviteesActivitiesSecondDay,
      [LookupKeys.description]: 'Activities Second Day',
    },
  ];

  constructor(
    private conferenceRegistrationEmailCampaignService: ConferenceRegistrationEmailCampaignService,
    private registrationSummaryEmailService: RegistrationSummaryEmailService,
  ) {}

  sendEmails = async ({ value, description }: any, attendees: Attendee[]) => {
    const attendeesNumber = attendees.length;
    const isNotSubmitted = attendees.some(
      attendee => !attendee.registrant?.[RegistrantModelKeys.wizardState]?.[RegistrantWizardStateKeys.isSubmitted],
    );

    let confirmationMessage = '';
    if (isNotSubmitted) {
      confirmationMessage +=
        attendeesNumber > 1
          ? '<div>Not all registrants completed they registration.</div>'
          : '<div>Registration is not completed.</div>';
    }

    const inviteeText =
      attendeesNumber > 1 ? `(${attendeesNumber}) Invitees` : attendees?.[0]?.[AttendeeKeys.inviteeEmail];

    confirmationMessage += `<div>Are you sure you want to Send "${description}" Email to ${inviteeText}?</div>`;

    const isConfirmed = await confirm(confirmationMessage, 'Confirm');

    if (!isConfirmed) {
      return;
    }
    switch (value) {
      case EmailTemplates.notifyEmailConferenceRegistrationShortSummary:
      case EmailTemplates.notifyEmailConferenceRegistrationSummary:
        await this.sendRegistrationSummaryMassEmail(value, attendees);
        return;
      case EmailTemplates.notifyEmailKnowBeforeYouGoToConference:
        await this.sendBeforeYouGoMassEmail(attendees);
        return;
      case EmailTemplates.notifyEmailInviteesActivitiesFirstDay:
      case EmailTemplates.notifyEmailInviteesActivitiesSecondDay:
        await this.sendInviteesActivitiesByDayMassEmail(value, attendees);
        return;
    }
  };

  private sendInviteesActivitiesByDayMassEmail = async (
    template: EmailTemplates,
    attendees: Attendee[],
  ): Promise<void> => {
    const promises: Promise<any>[] = [];
    const subject = new Map([
      [EmailTemplates.notifyEmailInviteesActivitiesFirstDay, 'Cap Cana - Thursday Excursions'],
      [EmailTemplates.notifyEmailInviteesActivitiesSecondDay, 'Cap Cana - Friday Excursions'],
    ]).get(template);

    attendees.forEach(attendee => {
      const conferenceDbId = attendee?.[AttendeeKeys.conferenceDbId];
      const registrantDbId = attendee?.registrant?.[BaseModelKeys.dbId];

      const promise: Promise<ConferenceRegistrationCampaignEmail | void> = this.registrationSummaryEmailService
        .generateActivitiesEmail({ conferenceDbId, registrantDbId, template })
        .then((context): ConferenceRegistrationCampaignEmail => {
          const mailOptions = {
            to: context?.[NotifyEmailKeys.email],
            bcc: context?.[NotifyEmailKeys.bccEmail],
            subject,
          };
          if (context?.[NotifyEmailKeys.ccEmail]?.length) {
            Object.assign(mailOptions, { cc: context?.[NotifyEmailKeys.ccEmail] });
          }
          return Object.assign({}, new BaseCampaignsEmail(registrantDbId), {
            [BaseCampaignsEmailKeys.mailOptions]: mailOptions,
            [BaseCampaignsEmailKeys.context]: context,
            [BaseCampaignsEmailKeys.template]: EmailTemplates.notifyEmailConferenceRegistrationSummary,
          });
        })
        .then(data =>
          this.conferenceRegistrationEmailCampaignService.sendEmailToRegistrant(
            conferenceDbId,
            registrantDbId,
            data as any,
          ),
        );

      promises.push(promise);
    });

    await Promise.all(promises);
  };

  private sendRegistrationSummaryMassEmail = async (
    emailTemplate: EmailTemplates,
    attendees: Attendee[],
  ): Promise<void> => {
    const promises: Promise<EmailToSendConfig>[] = [];

    attendees.forEach(attendee => {
      const conferenceDbId = attendee?.[AttendeeKeys.conferenceDbId];
      const registrantDbId = attendee?.registrant?.[BaseModelKeys.dbId];

      const promise: Promise<any> = this.registrationSummaryEmailService
        .generateRegistrationSummaryEmail({ conferenceDbId, registrantDbId })
        .then((context): ConferenceRegistrationCampaignEmail => {
          const eventName = context?.[RegistrationSummaryEmailKeys.eventName];
          const mailOptions = {
            to: context?.[NotifyEmailKeys.email],
            bcc: context?.[NotifyEmailKeys.bccEmail],
            subject: `${eventName} - Registration Summary `,
          };
          if (context?.[NotifyEmailKeys.ccEmail]?.length) {
            Object.assign(mailOptions, { cc: context?.[NotifyEmailKeys.ccEmail] });
          }
          return Object.assign({}, new BaseCampaignsEmail(registrantDbId), {
            [BaseCampaignsEmailKeys.mailOptions]: mailOptions,
            [BaseCampaignsEmailKeys.context]: context,
            [BaseCampaignsEmailKeys.template]: emailTemplate,
          });
        })
        .then(data =>
          this.conferenceRegistrationEmailCampaignService.sendEmailToRegistrant(conferenceDbId, registrantDbId, data),
        );

      promises.push(promise);
    });

    await Promise.all(promises);
  };

  private sendBeforeYouGoMassEmail = async (attendees: Attendee[]): Promise<void> => {
    const promises: Promise<EmailToSendConfig>[] = [];

    attendees.forEach(attendee => {
      const conferenceDbId = attendee?.[AttendeeKeys.conferenceDbId];
      const registrantDbId = attendee?.registrant?.[BaseModelKeys.dbId];

      const promise: Promise<any> = this.registrationSummaryEmailService
        .generateRegistrationBeforeYouGoEmail({ conferenceDbId, registrantDbId })
        .then((context): ConferenceRegistrationCampaignEmail => {
          const mailOptions = {
            to: context?.[NotifyEmailKeys.email],
            subject: `Cap Cana - Know Before You Go`,
          };
          if (context?.[NotifyEmailKeys.ccEmail]?.length) {
            Object.assign(mailOptions, { cc: context?.[NotifyEmailKeys.ccEmail] });
          }
          return Object.assign({}, new BaseCampaignsEmail(registrantDbId), {
            [BaseCampaignsEmailKeys.mailOptions]: mailOptions,
            [BaseCampaignsEmailKeys.context]: context,
            [BaseCampaignsEmailKeys.template]: EmailTemplates.notifyEmailConferenceRegistrationSummary,
          });
        })
        .then(data =>
          this.conferenceRegistrationEmailCampaignService.sendEmailToRegistrant(
            conferenceDbId,
            registrantDbId,
            data as any,
          ),
        );

      promises.push(promise);
    });

    await Promise.all(promises);
  };
}
