import { Pipe, PipeTransform } from '@angular/core';
import {
  CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP,
  ConferenceRegistrationCommonTaskStatus,
} from 'ag-common-lib/public-api';

@Pipe({ name: 'conferenceTaskStatus' })
export class ConferenceRegistrationTakStatusPipe implements PipeTransform {
  transform(status: ConferenceRegistrationCommonTaskStatus) {
    const lookupByStatus = CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP.find(lookup => lookup?.value === status);
    return lookupByStatus ?? {};
  }
}
