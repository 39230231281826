import { Pipe, PipeTransform } from '@angular/core';
import { Agent } from 'ag-common-lib/public-api';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'agent' })
export class AgentPipe implements PipeTransform {
  constructor(private agentElasticSearchService: AgentElasticSearchService) {}

  transform(
    items: string[] | string = [],
    valueExp?: ((a: Agent) => string) | string,
  ): Observable<Agent[] | Agent | string> {
    const promises = Array.isArray(items) ? Promise.all(items.map(this.getAgent)) : this.getAgent(items);

    return from(promises).pipe(
      map(agents => {
        const getAgentIdentifier = ({ agent, agentId }) => {
          if (!agent) {
            return agentId;
          }

          if (!valueExp) {
            return agent;
          }

          if (typeof valueExp === 'string') {
            return agent[valueExp];
          }

          if (typeof valueExp === 'function') {
            return valueExp(agent);
          }

          return '';
        };
        return Array.isArray(agents) ? agents.map(getAgentIdentifier) : getAgentIdentifier(agents);
      }),
    );
  }

  private getAgent = agentId => {
    return this.agentElasticSearchService.getById(agentId).then(agent => ({ agentId, agent }));
  };
}
