import { Injectable } from '@angular/core';
import { SupportedCollections } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../cloud-functions.service';
import { BaseElasticSearchService } from './base-elastic-search-service';
import { AgencyService } from '../agency.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';
import { SearchRequest } from 'aws-sdk/clients/sagemaker';

@Injectable({ providedIn: 'root' })
export class DocumentWriteLogElasticSearchService extends BaseElasticSearchService<any> {
  constructor(protected cloudFunctionsService: CloudFunctionsService, agencyService: AgencyService) {
    super(SupportedCollections.documentWriteLog);

    this.defaultSorting = [{ date: 'desc' }, '_doc'];
  }

  getDataSource = (options?: LoadOptions) => {
    return new DataSource({
      paginate: true,
      pageSize: 50,
      store: this.getStore(options),
    });
  };

  getStore = (options?: LoadOptions) => {
    return new CustomStore({
      key: 'dbId',
      byKey: id => this.getById(id),
      load: loadOptions => {
        const params = { ...loadOptions, ...options };
        return this.getFromElastic(params);
      },
    });
  };
}
