<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'" *var="(Entity.conferenceExcursionsInfo | hasPermission : EntityPermissionActivityKeys.write | async) as canWrite">
  <dx-form [(formData)]="conference" labelMode="floating" [validationGroup]="validationGroup" [readOnly]="isReadonlyMode || !canWrite">
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        [dataField]="ConferenceKeys.excursionsBudget"
        editorType="dxNumberBox"
        [label]="{ text: titleList[ConferenceKeys.excursionsBudget] }"
        [editorOptions]="{ format: { type: 'currency', currency: 'USD', precision: 2 }, min: 0, name: ConferenceKeys.excursionsBudget}"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.excursionsBookingTime"
        editorType="dxNumberBox"
        [label]="{ text: 'Seat Booking Time' }"
        [editorOptions]="{ format: '#', min: 0 }"
      >
      </dxi-item>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <ag-crm-excursion-modal
          #conferenceModalRef
          [calendarDisplayDate]="conference?.[ConferenceKeys.startDate]"
        ></ag-crm-excursion-modal>

        <dx-data-grid
          #excursionsGridTmp
          [dataSource]="conference?.[ConferenceKeys.excursions]"
          [showRowLines]="true"
          [rowAlternationEnabled]="true"
          (onRowRemoving)="onRowRemoving($event)"
          height="100%"
        >
          <dxo-editing
            mode="row"
            [useIcons]="true"
            [allowUpdating]="!isReadonlyMode && canWrite"
            [allowDeleting]="!isReadonlyMode && (Entity.conferenceExcursionsInfo | hasPermission : EntityPermissionActivityKeys.delete | async)"
            [allowAdding]="!isReadonlyMode && (Entity.conferenceExcursionsInfo | hasPermission : EntityPermissionActivityKeys.create | async)"
          ></dxo-editing>

          <dxo-toolbar>
            <dxi-item location="before">
              <span *dxTemplate class="dx-form-group-caption">Activities</span>
            </dxi-item>

            <dxi-item location="after" widget="dxButton" [options]="{ icon: 'plus', onClick: showAddPopup }" *ngIf="!isReadonlyMode && (Entity.conferenceExcursionsInfo | hasPermission : EntityPermissionActivityKeys.create | async)"></dxi-item>
          </dxo-toolbar>

          <dxi-column [dataField]="ExcursionKeys.name" caption="Activity" [alignment]="'left'"></dxi-column>

          <dxi-column
            [dataField]="ExcursionKeys.cost"
            caption="Cost"
            [dataType]="'number'"
            [format]="DX_USD_CURRENCY_FORMAT"
          ></dxi-column>

          <dxi-column
            [dataField]="ExcursionKeys.date"
            caption="Activity Date"
            [allowEditing]="false"
            [dataType]="'date'"
            [sortOrder]="'desc'"
            [format]="dateFormat"
            [sortIndex]="1"
            [groupIndex]="0"
            width="250"
          ></dxi-column>

          <dxi-column type="buttons">
            <dxi-button
               [icon]="canWrite ? 'edit' : 'agi-eye'"
               [hint]="canWrite ? 'Edit' : 'View'"
               [onClick]="canWrite ? showEditPopup : showDetailsPopup"
            ></dxi-button>
            <dxi-button icon="copy" [onClick]="showCopyPopup" [visible]="canWrite"></dxi-button>
            <dxi-button name="delete"></dxi-button>
          </dxi-column>
        </dx-data-grid>
      </div>
    </dxi-item>
  </dx-form>
</dx-scroll-view>
