import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Agent, Agency, Carrier, BaseModelKeys } from 'ag-common-lib/public-api';
import { EmailRequest } from 'ag-common-lib/public-api';
import { DistributionList } from 'ag-common-lib/public-api';
import { QueryParam } from 'ag-common-svc/public-api';
import { StorageControllerService, StorageKey } from './storage-controller.service';

@Injectable({
  providedIn: 'root',
})
export class SessionControllerService {
  public selectedAgentId$: Observable<string>;
  private readonly _selectedAgentId$ = new BehaviorSubject<string>(
    this.storageControllerService.getFromLocalStorage(StorageKey.AgentId),
  );

  public selectedAgencyId$: Observable<string>;
  private readonly _selectedAgencyId$ = new BehaviorSubject<string>(
    this.storageControllerService.getFromLocalStorage(StorageKey.AgencyId),
  );

  public selectedCarrierId$: Observable<string>;
  private readonly _selectedCarrierId$ = new BehaviorSubject<string>(
    this.storageControllerService.getFromLocalStorage(StorageKey.CarrierId),
  );

  public selectedProspectId$: Observable<string>;
  private readonly _selectedProspectId$ = new BehaviorSubject<string>(
    this.storageControllerService.getFromLocalStorage(StorageKey.ProspectId),
  );

  private selectedContactScreen: string = '';
  private contactScreenSource = new BehaviorSubject(this.selectedContactScreen);
  selectedContactScreenListener = this.contactScreenSource.asObservable();

  public agentFilter: QueryParam[][];

  public selectedDistributionList: DistributionList;
  public selectedEmail: EmailRequest;

  constructor(private storageControllerService: StorageControllerService) {
    this.selectedAgentId$ = this._selectedAgentId$.asObservable();
    this.selectedAgencyId$ = this._selectedAgencyId$.asObservable();
    this.selectedCarrierId$ = this._selectedCarrierId$.asObservable();
    this.selectedProspectId$ = this._selectedProspectId$.asObservable();
  }

  selectContactScreen(type: string) {
    this.selectedContactScreen = type;
    this.contactScreenSource.next(this.selectedContactScreen);
  }

  selectAgent(agent: Agent) {
    const agentId = agent?.[BaseModelKeys.dbId];
    this._selectedAgentId$.next(agentId);

    if (!agentId) {
      this.storageControllerService.setToLocalStorage(StorageKey.AgentId, null);
      return;
    }
    this.storageControllerService.setToLocalStorage(StorageKey.AgentId, agentId);
  }

  selectProspect(prospectId: string) {
    this._selectedProspectId$.next(prospectId);
    this.storageControllerService.setToLocalStorage(StorageKey.ProspectId, prospectId);
  }

  selectAgency(agency: Agency) {
    const agencyId = agency?.[BaseModelKeys.dbId];
    this._selectedAgencyId$.next(agencyId);
    this.storageControllerService.setToLocalStorage(StorageKey.AgencyId, agencyId);
  }

  selectCarrier(carrier: Carrier) {
    const carrierId = carrier?.[BaseModelKeys.dbId];
    this._selectedCarrierId$.next(carrierId);
    this.storageControllerService.setToLocalStorage(StorageKey.CarrierId, carrierId);
  }
}
