<dx-data-grid
  [dataSource]="configurationsData"
  [showRowLines]="true"
  [rowAlternationEnabled]="true"
  (onInitNewRow)="onInitNewExcursionConfiguration($event)"
  (onRowInserting)="onExcursionInsertingConfiguration($event)"
  (onRowUpdating)="onExcursionUpdatingConfiguration($event)"
  (onRowRemoving)="onExcursionRemovingConfiguration($event)"
  height="100%"
>
  <dxo-editing
    #excursionConfigurationEditingTmp
    mode="row"
    [useIcons]="true"
    [allowUpdating]="!isReadOnly"
    [allowDeleting]="!isReadOnly"
    [allowAdding]="!isReadOnly"
    [newRowPosition]="'last'"
  >
  </dxo-editing>

  <dxo-toolbar>
    <dxi-item location="before">
      <b *dxTemplate>Lookups</b>
    </dxi-item>

    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <dxi-column [dataField]="ExcursionConfigurationKeys.label" caption="Label">
    <dxi-validation-rule type="required"></dxi-validation-rule>

    <dxi-validation-rule
      type="pattern"
      [pattern]="safeFirebaseFieldNameRegExp"
      message="Wrong format. Only use alphanumeric characters, hyphens, and underscores."
    ></dxi-validation-rule>

    <dxi-validation-rule
      type="custom"
      [validationCallback]="checkIsExcursionConfigurationUniq.bind(this, excursionConfigurationEditingTmp)"
      message="Same Label already exist."
    ></dxi-validation-rule>
  </dxi-column>

  <dxi-column [dataField]="ExcursionConfigurationKeys.instructions" caption="Instructions">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column [dataField]="ExcursionConfigurationKeys.dataType" caption="Type">
    <dxo-lookup
      [dataSource]="excursioDataTypeLookup"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    >
    </dxo-lookup>

    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
</dx-data-grid>
