<section class="campaigns-management ag-kanban">
  <dx-sortable class="sortable-lists" itemOrientation="horizontal" handle=".list-title">
    <div class="list" *ngFor="let boardList of boardLists$ | async">
      <div class="list-title dx-theme-text-color">
        {{ boardList?.status | campaignsStatus }}
      </div>

      <ng-container *ngIf="boardList?.list as list">
        <dx-scroll-view class="scrollable-list" direction="vertical" showScrollbar="always">
          <dx-sortable
            class="sortable-cards"
            group="cardsGroup"
            [data]="list"
            [allowReordering]="false"
            (onDragStart)="onDragStart($event)"
            (onAdd)="onTaskDrop($event, boardList?.status)"
          >
            <ng-container *ngFor="let item of list">
              <ng-container *var="item?.agent as agent">
                <div
                  *ngIf="item?.task as task"
                  class="ag-kanban__card card dx-card dx-theme-text-color dx-theme-background-color"
                  (dblclick)="viewCardInfo(task)"
                >
                  <div class="container-fluid">
                    <div
                      class="card-priority"
                      [ngClass]="
                        task?.updatedStatusAt
                          | campaignsPriority : boardList?.status === CampaignsManagementTasksStatus.done
                      "
                    ></div>

                    <header class="card__header row">
                      <span class="card__header-title ag-text-clickable col" (click)="viewAgentDetails(agent)">
                        {{
                          [
                            agent?.p_prefix | prefix | async,
                            agent?.p_agent_first_name,
                            agent?.p_agent_last_name,
                            agent?.p_suffix | suffix | async
                          ]
                            | fullName
                            | empty
                        }}
                      </span>
                    </header>

                    <div class="row">
                      <div class="col">
                        <span class="card-assignee">{{ agent?.p_email }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <span class="card-assignee">
                          {{ agent?.phone_numbers | primaryPhoneNumber | phoneNumberMask }}
                        </span>
                      </div>
                    </div>

                    <div class="row" *ngIf="agent?.p_agent_id">
                      <div class="col">
                        <span class="card-assignee">Agent ID: {{ agent?.p_agent_id }}</span>
                      </div>
                    </div>

                    <div class="card__footer row">
                      <div class="col-auto" *var="task | campaignsTaskStats as changes">
                        <dx-button
                          icon="paste"
                          class="card__footer-badge-button"
                          [text]="changes.itemsDone + '/' + changes.total"
                          [hint]="changes.itemsDone + ' of ' + changes.total + ' Changes Applied'"
                        ></dx-button>
                      </div>

                      <ng-container *ngIf="task?.statistic?.issuesChanges?.total > 0">
                        <div class="col-auto" *var="task?.statistic?.issuesChanges as changes">
                          <dx-button
                            *ngIf="changes.total > 0"
                            class="card__footer-badge-button"
                            icon="agi-tools"
                            [text]="changes.itemsDone + '/' + changes.total"
                            [hint]="changes.itemsDone + ' of ' + changes.total + ' Issues Posted'"
                          ></dx-button>
                        </div>
                      </ng-container>

                      <div class="col-auto ms-auto">
                        <ag-shr-assign-owner-viewer
                          #assignOwnerViewerRef
                          [owner]="{ assignedTo: task?.assignedTo ?? null, assignedToNote: task?.note ?? '' }"
                          [isDisabled]="
                            boardList?.status === CampaignsManagementTasksStatus.done ||
                            !(Entity.assignOwnerCampaign | hasPermission : EntityPermissionActivityKeys.write | async)
                          "
                          [onOwnerChange]="changeOwner"
                          (click)="selectedTask = task"
                        ></ag-shr-assign-owner-viewer>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </dx-sortable>
        </dx-scroll-view>
      </ng-container>
    </div>
  </dx-sortable>
</section>

<dx-load-panel
  shadingColor="rgba(205,205,205,0.7)"
  [position]="{ of: 'ag-crm-ag-campaigns' }"
  [visible]="inProgress$ | async"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
  [delay]="100"
></dx-load-panel>

<ng-container *ngIf="viewAgentVisible">
  <dx-popup
    title="Agent Details"
    [showTitle]="true"
    [fullScreen]="true"
    [showCloseButton]="true"
    [dragEnabled]="false"
    [wrapperAttr]="{ class: 'ag-overlay-popup' }"
    [(visible)]="viewAgentVisible"
  >
    <app-agent-editor [selectedAgent]="selectedAgent" (fullScreenClosed)="viewAgentVisible = false"></app-agent-editor>
  </dx-popup>
</ng-container>

<ag-crm-campaign-details-modal #campaignsDetailsModalRef></ag-crm-campaign-details-modal>
<ag-crm-campaign-issues-modal #campaignsIssueModalRef></ag-crm-campaign-issues-modal>
<ag-crm-campaigns-task-issues-modal #campaignsTaskIssuesModalRef></ag-crm-campaigns-task-issues-modal>
