import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../attendee-change-log-detail/attendee-change-log-detail.module';
import { AttendeeFlightInfoLogDetailsComponent } from './attendee-flight-info-log-details.component';
import { ChangeLogFlightBookingInfoModule } from './booking-info/change-log-flight-booking-info.module';
import { ChangeLogRequestOutcomeModule } from '../attendee-change-log-detail/request-outcome/change-log-request-outcome.module';
import { ChangeLogPipesModule } from '../pipes/change-log-pipes.module';
import { AttendeeChangeLogDetailByTypeModule } from '../attendee-change-log-detail-by-type/attendee-change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeeFlightInfoLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    AttendeeChangeLogDetailModule,
    ChangeLogFlightBookingInfoModule,
    ChangeLogRequestOutcomeModule,
    ChangeLogPipesModule,
    AttendeeChangeLogDetailByTypeModule,
  ],
  exports: [AttendeeFlightInfoLogDetailsComponent],
})
export class AttendeeFlightInfoLogDetailsModule {}
