import { Pipe, PipeTransform } from '@angular/core';
import { InviteeStatus } from 'ag-common-lib/public-api';

@Pipe({ name: 'attendeeGeneralInfoOutcomeStatusPlaceholder' })
export class AttendeeGeneralInfoOutcomeStatusPlaceholderPipe implements PipeTransform {
  transform(inviteeStatus: InviteeStatus): string {
    if (inviteeStatus === InviteeStatus.declined) {
      return 'Declined';
    }
    return '';
  }
}
