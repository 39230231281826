import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { AttendeeNotesService } from './attendee-notes.service';
import {
  ConferenceRegistrationLogKeys,
  ConferenceRegistrationLogType,
  ConferenceRegistrationRequestLogKey,
  ConferenceRegistrationSupportLogKey,
  REGISTRATION_LOG_STATUS_LOOKUP,
  REGISTRATION_LOG_TYPE_LOOKUP,
} from 'ag-common-lib/lib/models/registration/registrant-notes';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import { AttendeeNoteModalComponent } from '../attendee-note-modal/attendee-note-modal.component';
import { Constants } from 'ag-common-lib/lib/constants/validation.constants';
import { Entity } from 'ag-common-lib/lib/lists/entity-permission.enum';
import { EntityPermissionActivityKeys } from 'ag-common-lib/lib/models/utils/entity-permission.model';

@Component({
  selector: 'ag-crm-attendee-notes',
  templateUrl: './attendee-notes.component.html',
  styleUrls: ['./attendee-notes.component.scss'],
})
export class AttendeeNotesComponent implements OnInit {
  @HostBinding('class') className = 'ag-crm-attendee-notes';
  @ViewChild('attendeeNotesGrid', { static: false }) attendeeNotesGrid!: DxDataGridComponent;
  @ViewChild(AttendeeNoteModalComponent, { static: false }) attendeeNoteModalComponent!: AttendeeNoteModalComponent;

  protected logsDataSource$;
  protected selectedLogType$ = new BehaviorSubject<ConferenceRegistrationLogType>(null);

  protected readonly ConferenceRegistrationLogKeys = ConferenceRegistrationLogKeys;
  protected readonly REGISTRATION_LOG_TYPE_LOOKUP = REGISTRATION_LOG_TYPE_LOOKUP;
  protected readonly REGISTRATION_LOG_STATUS_LOOKUP = REGISTRATION_LOG_STATUS_LOOKUP;
  protected readonly ConferenceRegistrationSupportLogKey = ConferenceRegistrationSupportLogKey;
  protected readonly ConferenceRegistrationRequestLogKey = ConferenceRegistrationRequestLogKey;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;

  constructor(private attendeeNotesService: AttendeeNotesService) {
    this.logsDataSource$ = combineLatest({
      registrantLogs: this.attendeeNotesService.registrantNotes$.pipe(filter(Boolean)),
      selectedLogType: this.selectedLogType$,
    }).pipe(
      map(({ registrantLogs, selectedLogType }) => {
        return !!selectedLogType
          ? registrantLogs?.filter(log => log[ConferenceRegistrationLogKeys.type] === selectedLogType)
          : registrantLogs;
      }),
      shareReplay(1),
    );
  }

  ngOnInit(): void {}

  isArchived = (e): boolean => {
    return e.row.data[ConferenceRegistrationLogKeys.isArchived] === true;
  };

  isActive = (e): boolean => {
    return !e.row.data[ConferenceRegistrationLogKeys.isArchived];
  };

  calculateIsArchivedGroup(data: any) {
    return !!data?.[ConferenceRegistrationLogKeys.isArchived] ? 'Archived Logs' : 'Active Logs';
  }

  customizeIsArchivedText(cellInfo: any) {
    return cellInfo.value;
  }

  onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.row.data.isArchived === true) {
      e.cellElement.style.backgroundColor = '#f7f7f7'; // Change color for archived group cells
    }
  };

  showAddLogPopup = (): void => {
    this.attendeeNoteModalComponent.showModal(
      this.attendeeNotesService.conferenceDbId,
      this.attendeeNotesService.registrantId,
    );
  };

  editNote = ({ row: { data } }): void => {
    this.attendeeNoteModalComponent.showModal(
      this.attendeeNotesService.conferenceDbId,
      this.attendeeNotesService.registrantId,
      data,
    );
  };

  protected onGuestFlightUpdating = this.attendeeNotesService.onNoteUpdating;

  protected archiveLog = ({ row: { data } }) => {
    this.attendeeNotesService.archive(data[BaseModelKeys.dbId]);
  };

  protected revertArchive = ({ row: { data } }) => {
    this.attendeeNotesService.revertArchive(data[BaseModelKeys.dbId]);
  };
    protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
}
