import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../attendee-change-log-detail/attendee-change-log-detail.module';
import { AttendeeHotelReservationLogDetailsComponent } from './attendee-hotel-reservation-log-details.component';
import { ChangeLogBookingInfoModule } from './booking-info/change-log-booking-info.module';
import { ChangeLogRequestOutcomeModule } from '../attendee-change-log-detail/request-outcome/change-log-request-outcome.module';
import { ChangeLogPipesModule } from '../pipes/change-log-pipes.module';
import {
    AttendeeChangeLogDetailByTypeModule
} from '../attendee-change-log-detail-by-type/attendee-change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeeHotelReservationLogDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        AttendeeChangeLogDetailModule,
        ChangeLogBookingInfoModule,
        ChangeLogRequestOutcomeModule,
        ChangeLogPipesModule,
        AttendeeChangeLogDetailByTypeModule,
    ],
  exports: [AttendeeHotelReservationLogDetailsComponent],
})
export class AttendeeHotelReservationLogDetailsModule {}
