import { Tab } from 'ag-common-lib/lib/models/ui/tab.model';
import { ConferenceKeys } from 'ag-common-lib/lib/models/registration/conference.model';
import { ActiveLookup, InviteeStatus, RegistrantFieldKeys } from 'ag-common-lib/public-api';

export enum ConferenceTabs {
  AgentInfo = 'Agent Info',
  ConferenceInfo = 'Conference Info',
}

export const ConferenceTabsDescriptionMap = new Map([
  [ConferenceTabs.AgentInfo, 'Agent Info'],
  [ConferenceTabs.ConferenceInfo, 'Conference Info'],
]);

export const CONFERENCE_TABS_LOOKUP: Tab[] = [
  {
    id: 0,
    text: ConferenceTabsDescriptionMap.get(ConferenceTabs.AgentInfo),
    template: ConferenceTabsDescriptionMap.get(ConferenceTabs.AgentInfo),
  },
  {
    id: 1,
    text: ConferenceTabsDescriptionMap.get(ConferenceTabs.ConferenceInfo),
    template: ConferenceTabsDescriptionMap.get(ConferenceTabs.ConferenceInfo),
  },
];

export enum ApprovedTabs {
  Approved = 'Approved',
  NotApproved = 'Not Approved',
}

export const ApprovedTabsDescriptionMap = new Map([
  [ApprovedTabs.Approved, 'Approved'],
  [ApprovedTabs.NotApproved, 'Not Approved'],
]);

export const PHONE_NUMBER_OPTIONS = {
  mask: '+1 (X00) 000-0000',
  maskRules: { X: /[02-9]/ },
};

export const CONFERENCES_TITLE_LIST = {
  [ConferenceKeys.eventId]: 'Event ID',
  [ConferenceKeys.eventName]: 'Event Name',
  [ConferenceKeys.startDate]: 'Start Date',
  [ConferenceKeys.endDate]: 'End Date',
  [ConferenceKeys.registrationStartDate]: 'Registration Start Date',
  [ConferenceKeys.registrationEndDate]: 'Registration End Date',
  [ConferenceKeys.facilityName]: 'Facility Name',
  [ConferenceKeys.hotelName]: 'Hotel Name',
  [ConferenceKeys.hotelState]: 'Hotel State',
  [ConferenceKeys.conferenceType]: 'Conference Type',
  // Event Coordinator
  [ConferenceKeys.eventCoordinatorFirstName]: 'Coordinator First Name',
  [ConferenceKeys.eventCoordinatorLastName]: 'Coordinator Last Name',
  [ConferenceKeys.eventCoordinatorPhone]: 'Coordinator Phone Number',
  [ConferenceKeys.eventCoordinatorEmail]: 'Coordinator Email',
  // Event Location
  eventAddress1: 'Address 1',
  eventAddress2: 'Address 2',
  eventCity: 'City',
  eventState: 'State',
  eventZip: 'Zip',
  eventCountry: 'Country',
  // Hotel Location
  hotelAddress1: 'Address 1',
  hotelAddress2: 'Address 2',
  hotelCity: 'City',
  hotelState: 'State',
  hotelZip: 'Zip',
  hotelCountry: 'Country',
  // Hotel Coordinator
  [ConferenceKeys.hotelCoordinatorFirstName]: 'Hotel Contact First Name',
  [ConferenceKeys.hotelCoordinatorLastName]: ' Hotel Contact  Last Name',
  [ConferenceKeys.hotelPrimaryPhone]: ' Hotel Contact Phone Number',
  [ConferenceKeys.hotelCoordinatorEmail]: ' Hotel Contact Email',
  [ConferenceKeys.hotelRoomRate]: 'Room Rate',
  [ConferenceKeys.hotelNumRoomsBlocked]: 'Rooms Blocked',
  // Budget Information
  [ConferenceKeys.printMarketingBudget]: 'Print Marketing Budget',
  [ConferenceKeys.foodBudget]: 'Food Budget',
  [ConferenceKeys.excursionsBudget]: 'Excursions Budget',
  // Configure Registrants tab
  [RegistrantFieldKeys.label]: 'Label',
  [RegistrantFieldKeys.dataType]: 'Data Type',
  [RegistrantFieldKeys.options]: 'Options',
};
