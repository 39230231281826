import { Injectable } from '@angular/core';
import {
  AuthService,
  ConferenceGuestsService,
  ConferenceRegistrantsService,
  ConferenceRegistrationLogsService,
} from 'ag-common-svc/public-api';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';
import {
  BaseModelKeys,
  ConferenceRegistrationLog,
  ConferenceRegistrationStepName,
  GuestData,
  GuestKeys,
  LookupKeys,
  Registrant,
  RegistrantData,
  RegistrantGuestConfigurationSteps,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrantWizardState,
  RequestOutcome,
  RequestOutcomeKeys,
  RequestOutcomeState,
  WizardStepState,
} from 'ag-common-lib/public-api';
import { BehaviorSubject, firstValueFrom, mergeMap, Observable, shareReplay } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AttendeeGuestsService {
  registrantGuests$: Observable<GuestData[]>;
  wizardGuestStepStateLookup$: Observable<any[]>;

  conferenceDbId$ = new BehaviorSubject<string>(null);
  registrantDbId$ = new BehaviorSubject<string>(null);

  private _isRequestedAdditionalGuest$ = new BehaviorSubject(false);
  isRequestedAdditionalGuest$ = this._isRequestedAdditionalGuest$.asObservable();

  private _additionalGuestRequestOutcome$ = new BehaviorSubject<RequestOutcome>(null);
  additionalGuestRequestOutcome$ = this._additionalGuestRequestOutcome$.asObservable();

  conferenceGuestExcludedSections$ = this.attendeeDetailsModalService.conferenceGuestExcludedSections$;

  constructor(
    private authService: AuthService,
    private conferenceGuestsService: ConferenceGuestsService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private conferenceRegistrationLogsService: ConferenceRegistrationLogsService,
  ) {
    this.registrantGuests$ = this.attendeeDetailsModalService.registrant$.pipe(
      tap((conferenceRegistration: Registrant) => {
        this.conferenceDbId$.next(conferenceRegistration?.[RegistrantModelKeys.conferenceDbId]);
        this.registrantDbId$.next(conferenceRegistration?.[BaseModelKeys.dbId]);
        this._isRequestedAdditionalGuest$.next(
          !!conferenceRegistration?.[RegistrantModelKeys.data]?.[RegistrantKeys.additionalGuestRequested],
        );
        this._additionalGuestRequestOutcome$.next(
          conferenceRegistration?.[RegistrantModelKeys.data]?.[RegistrantKeys.additionalGuestRequestOutcome],
        );
      }),
      mergeMap((conferenceRegistration: Registrant) => {
        return this.conferenceGuestsService.getList(
          conferenceRegistration?.[RegistrantModelKeys.conferenceDbId],
          conferenceRegistration?.[BaseModelKeys.dbId],
        );
      }),
      shareReplay(1),
    );

    this.wizardGuestStepStateLookup$ = this.attendeeDetailsModalService.registrant$.pipe(
      map(registrant => {
        const wizardState = registrant?.[RegistrantModelKeys.wizardState] ?? {};
        const lookup = [];
        Object.entries(wizardState)?.forEach(([stepId, stepState]) => {
          const isGuestState = stepId?.startsWith(ConferenceRegistrationStepName.registrantGuestConfigurationStep);
          if (isGuestState) {
            lookup.push({
              [LookupKeys.value]: stepId.replace(
                `${ConferenceRegistrationStepName.registrantGuestConfigurationStep}_`,
                '',
              ),
              [LookupKeys.description]: stepState,
            });
          }
        });

        return lookup;
      }),
    );
  }

  onGuestRemoving = (e: RowRemovingEvent) => {
    const guestData: GuestData = e.data;
    const registrantGuestDbI = guestData?.[BaseModelKeys.dbId];
    const conferenceDbId = guestData?.[GuestKeys.conferenceDbId];
    const registrantDbId = guestData?.[GuestKeys.registrantDbId];

    e.cancel = this.conferenceGuestsService.delete(conferenceDbId, registrantDbId, registrantGuestDbI, true);
  };

  setWizardStepStateToDone = async (guestData: GuestData) => {
    const registrantGuestDbI = guestData?.[BaseModelKeys.dbId];
    const conferenceDbId = guestData?.[GuestKeys.conferenceDbId];
    const registrantDbId = guestData?.[GuestKeys.registrantDbId];
    const stepKey: RegistrantGuestConfigurationSteps = `${ConferenceRegistrationStepName.registrantGuestConfigurationStep}_${registrantGuestDbI}`;

    await this.conferenceRegistrantsService.update(
      conferenceDbId,
      registrantDbId,
      {
        [RegistrantModelKeys.wizardState]: {
          [stepKey]: WizardStepState.done,
        },
      },
      true,
    );
  };

  handleAdditionalGuestRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    const loggedInAgent = await firstValueFrom(this.authService.loggedInAgent$);
    const loggedInAgentDbId = loggedInAgent?.[BaseModelKeys.dbId];

    return this.saveRequestOutcome({
      [RegistrantKeys.additionalGuestRequestOutcome]: {
        [RequestOutcomeKeys.state]: requestOutcomeState,
        [RequestOutcomeKeys.processedBy]: loggedInAgentDbId,
      },
    });
  };

  saveLog = (log: ConferenceRegistrationLog) => {
    return this.conferenceRegistrationLogsService.create(this.conferenceDbId$.value, this.registrantDbId$.value, log);
  };

  private saveRequestOutcome = async (registrantData: Partial<RegistrantData>) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const conferenceDbId = registrant?.[RegistrantModelKeys.conferenceDbId];
    const registrantDbId = registrant?.[BaseModelKeys.dbId];
    return this.conferenceRegistrantsService.update(
      conferenceDbId,
      registrantDbId,
      {
        [RegistrantModelKeys.data]: registrantData,
      },
      true,
    );
  };
}
