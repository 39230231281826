import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { LayoutsModule } from 'src/app/layouts/layouts.module';
import { ReportAdministrationComponent } from './report-administration/report-administration.component';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { ReportsRouting } from './reports-routing.module';

@NgModule({
  declarations: [ReportAdministrationComponent],
  imports: [
    ReportsRouting,
    CommonModule,
    FormsModule,
    LayoutsModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxFileUploaderModule,
    DxDateBoxModule,
    DxScrollViewModule,
    DxLoadPanelModule,
    DxPopupModule,
    DxDataGridModule,
  ],
  exports: [],
})
export class ReportsModule {}
