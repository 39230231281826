import { NgModule } from '@angular/core';
import { ModalWindowModule, AgEditorToolbarModule } from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { DxValidatorModule } from 'devextreme-angular';
import { DietaryConsiderationsFormModule } from '../dietary-considerations-form/dietary-considerations-form.module';
import { DietaryConsiderationsComponent } from './dietary-considerations.component';

@NgModule({
  imports: [SharedModule, ModalWindowModule, DxValidatorModule, AgEditorToolbarModule, DietaryConsiderationsFormModule],
  declarations: [DietaryConsiderationsComponent],
  exports: [DietaryConsiderationsComponent],
})
export class DietaryConsiderationsModule {}
