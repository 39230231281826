<div class="row justify-content-start align-items-center mt-2" *ngIf="!!toHeadshot">
  <div class="col-auto">
    <ag-shr-image-with-loader
      class="ag-main-info__image"
      [wasabiPath]="fromHeadshot?.[HeadshotKeys.wasabiPath]"
      [cropperPayload]="fromHeadshot?.[HeadshotKeys.imageCropperPayload]"
    ></ag-shr-image-with-loader>
  </div>

  <ng-container *ngIf="toHeadshot?.[HeadshotKeys.wasabiPath]">
    <div class="col-auto"><i class="dx-icon-arrowright"></i></div>

    <div class="col-auto">
      <ag-shr-image-with-loader
        class="ag-main-info__image"
        [wasabiPath]="toHeadshot?.[HeadshotKeys.wasabiPath]"
        [cropperPayload]="toHeadshot?.[HeadshotKeys.imageCropperPayload]"
      ></ag-shr-image-with-loader>
    </div>
  </ng-container>
</div>
