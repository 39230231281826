import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogRequestOutcomeComponent } from './change-log-request-outcome.component';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogRequestOutcomeComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule,],
  exports: [ChangeLogRequestOutcomeComponent],
})
export class ChangeLogRequestOutcomeModule {}
