import { NgModule } from '@angular/core';
import { AttendeeNoteModalComponent } from './attendee-note-modal.component';
import { ModalWindowModule } from 'ag-common-svc/lib/components';
import { DxFormModule } from 'devextreme-angular';
import { SharedModule } from 'ag-common-svc/shared/shared.module';

@NgModule({
  declarations: [AttendeeNoteModalComponent],
  imports: [ModalWindowModule, DxFormModule, SharedModule],
  exports: [AttendeeNoteModalComponent],
})
export class AttendeeNoteModalModule {}
