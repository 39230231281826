import { NgModule } from '@angular/core';
import { DxDropDownButtonModule, DxValidatorModule } from 'devextreme-angular';
import { SharedModule } from '../../../../../shared/shared.module';
import { DropZoneModule } from '../../../drop-zone/drop-zone.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { PrefixSelectBoxModule } from '../../../prefix-select-box/prefix-select-box.module';
import { SuffixSelectBoxModule } from '../../../suffix-select-box/suffix-select-box.module';
import { AgentHeadersComponent } from './agent-header.component';
import { AgMediaUploaderModule } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader.module';
import { ImageCropperModalModule } from 'ag-common-svc/lib/components/image-cropper-modal/image-cropper-modal.module';
import { ImageWithLoaderModule } from 'ag-common-svc/lib/components/image-with-loader/image-with-loader.module';
import { ProfilePictureModule } from '../profile-picture/profile-picture.module';

@NgModule({
  imports: [
    SharedModule,
    ModalWindowModule,
    DxValidatorModule,
    SuffixSelectBoxModule,
    PrefixSelectBoxModule,
    DropZoneModule,
    DxDropDownButtonModule,
    AgMediaUploaderModule,
    ImageCropperModalModule,
    ImageWithLoaderModule,
    ProfilePictureModule,
  ],

  declarations: [AgentHeadersComponent],
  exports: [AgentHeadersComponent],
})
export class AgentHeadersModule {}
