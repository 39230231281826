import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PersonalInformationService } from './personal-information.service';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { Agent, AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { Constants, DATE_NOW, EditorOptions, MIN_DATE, Messages, Patterns, languages } from '@ag-common-lib/public-api';
import { subYears, format, isAfter, isEqual } from 'date-fns';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'ag-shr-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  providers: [PersonalInformationService],
})
export class PersonalInformationComponent {
  @ViewChild('personalInformationModalRef') personalInformationModalComponent: ModalWindowComponent;
  @ViewChild('personalInformationFormRef', { static: false }) personalInformationFormRef: DxFormComponent;
  @Output() personalInformationChange = new EventEmitter<{ agentId: string; updates: Partial<Agent> }>();
  @Input() set agent(data: Agent) {
    this.personalInformationFormData = this.personalInformationService.getFormData(data);
  }
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;

  inProgress$ = this.personalInformationService.inProgress$;
  selectedGender$ = this.personalInformationService.selectedGender$;
  personalInformationFormData: Partial<Agent>;
  readonly maxDate: Date = subYears(DATE_NOW, 1);
  readonly minDate: Date = MIN_DATE;
  readonly languages = languages;
  readonly AgentKeys = AgentKeys;
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  readonly dateEditorOptions = {
    ...EditorOptions.DATE,
    min: this.minDate,
    max: this.maxDate,
    dateOutOfRangeMessage: `Please enter a date between ${format(this.minDate, Constants.DISPLAY_DATE_FORMAT)} and
      ${format(this.maxDate, Constants.DISPLAY_DATE_FORMAT)}`,
  };
  readonly Messages = Messages;
  readonly dateValidation = Patterns.DATE_PATTERN_MMDDYYYY;

  constructor(private readonly personalInformationService: PersonalInformationService) {}

  handelSaveClick = async (): Promise<void> => {
    const validationResults = this.personalInformationFormRef.instance.validate();
    if (validationResults.isValid) {
      const results = await this.personalInformationService?.savePersonalInfo(this.personalInformationModalComponent);

      if (results) {
        this.personalInformationChange.emit(results);
      }
    }
  };

  handleClosePopup = (e): void => this.personalInformationService.cancelPersonalInfo(e);

  handleGenderSelect = (gender): void => this.selectedGender$.next(gender);
}
