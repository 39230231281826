<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Addresses'"
    [isEditVisible]="canEdit"
    (clickEdit)="addressesModalComponent.showModal()"
  ></ag-shr-editor-toolbar>

  <div class="row my-2 px-3" *ngIf="addresses.length > 0">
    <div class="col-5 text-start"></div>
    <div class="col-3 text-start"></div>
    <div class="col-2 text-center">
      <span style="font-weight: bold">Shipping</span>
    </div>
    <div class="col-2 text-center">
      <span style="font-weight: bold">Billing</span>
    </div>
  </div>
  <div class="row my-2 px-3 text-center" *ngIf="addresses.length == 0">
    <p class="col editor-empty-text">No Addresses Currently Exist</p>
  </div>
  <ng-container *ngFor="let address of addresses">
    <div class="row mb-2 px-3 py-1">
      <div class="col-5 text-start">
        {{ address | fullAddress }}
      </div>
      <div class="col-3 text-start">
        {{ address.address_type }}
      </div>
      <div class="col-2 text-center">
        <i *ngIf="address.is_primary_shipping" class="dx-icon-check"></i>
      </div>
      <div class="col-2 text-center">
        <i *ngIf="address.is_primary_billing" class="dx-icon-check"></i>
      </div>
    </div>
  </ng-container>
</div>

<ag-shr-modal-window
  #addressesModalRef
  [title]="'Addresses'"
  [showSaveButton]="false"
  [inProgress]="inProgress$ | async"
>
  <dx-data-grid
    #addressesGridREf
    [dataSource]="addresses"
    [showRowLines]="true"
    [showBorders]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
  >
    <dxo-toolbar>
      <dxi-item name="addRowButton" [visible]="canCreate"></dxi-item>
    </dxo-toolbar>
    <dxo-editing mode="popup" [useIcons]="true" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
      <dxo-popup title="Address" [showTitle]="true" [width]="'80vw'" [height]="'auto'">
        <dxi-toolbar-item
          widget="dxButton"
          location="after"
          toolbar="bottom"
          *ngIf="!!addressesGridREf.instance"
          [options]="{
            text: 'CANCEL',
            onClick: onCancel,
            elementAttr: {
              class: 'modal-window__toolbar-button'
            }
          }"
        >
        </dxi-toolbar-item>

        <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom">
          <div *dxTemplate>
            <shr-button-with-indicator
              [isInProgress]="inProgress$ | async"
              (onClick)="addressesGridREf.instance.saveEditData()"
            ></shr-button-with-indicator>
          </div>
        </dxi-toolbar-item>
      </dxo-popup>

      <dxo-form [colCount]="12" [labelMode]="'floating'">
        <dxi-item dataField="address_type" [colSpan]="4"></dxi-item>
        <dxi-item
          dataField="is_primary_shipping"
          [alignItemLabels]="'left'"
          [colSpan]="4"
          editorType="dxSwitch"
          [editorOptions]="{ switchedOffText: 'No', switchedOnText: 'Yes' }"
        ></dxi-item>
        <dxi-item
          dataField="is_primary_billing"
          [alignItemLabels]="'left'"
          [colSpan]="4"
          editorType="dxSwitch"
          [editorOptions]="{ switchedOffText: 'No', switchedOnText: 'Yes' }"
        ></dxi-item>
        <dxi-item dataField="address1" [colSpan]="12"></dxi-item>
        <dxi-item dataField="address2" [colSpan]="12"></dxi-item>
        <dxi-item dataField="city" [colSpan]="4"></dxi-item>
        <dxi-item dataField="state" [colSpan]="4"></dxi-item>
        <dxi-item dataField="zip" [colSpan]="4"></dxi-item>
        <dxi-item dataField="country" [colSpan]="4"></dxi-item>
        <dxi-item dataField="county" [colSpan]="4"></dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxi-column dataField="address1" [calculateDisplayValue]="fullAddressPipe.transform" [caption]="'Address'">
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_ADDRESS_ONE_TEXT"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="address2" [visible]="false"></dxi-column>
    <dxi-column dataField="city" [visible]="false"></dxi-column>
    <dxi-column dataField="state" [visible]="false" editCellTemplate="stateEditorTmp">
      <dxo-lookup [dataSource]="statesLookup$ | async" [displayExpr]="'description'" [valueExpr]="'value'"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="zip" [visible]="false"></dxi-column>
    <dxi-column dataField="country" [visible]="false">
      <dxo-lookup [dataSource]="countries" [displayExpr]="'name'" [valueExpr]="'code'"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="county" [visible]="false"></dxi-column>
    <dxi-column dataField="address_type" caption="Type">
      <dxo-lookup [dataSource]="businessPersonalTypes"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="is_primary_shipping" caption="Shipping"></dxi-column>
    <dxi-column dataField="is_primary_billing" caption="Billing"></dxi-column>
    <dxi-column type="buttons">
      <dxi-button name="edit" [visible]="canEdit" style="color: #091d45b5 !important"></dxi-button>
      <dxi-button name="delete" [visible]="canDeleteRow && canDelete"></dxi-button>
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'stateEditorTmp'">
      <ag-shr-state-select-box [value]="cell.value" (valueChange)="cell.setValue($event)"> </ag-shr-state-select-box>
    </div>
  </dx-data-grid>
</ag-shr-modal-window>
