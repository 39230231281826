import { Injectable } from '@angular/core';
import { BaseFormService } from 'ag-common-svc/public-api';
import { AttendeeFlightDataKeys, AttendeeFlightGroup, AttendeeFlightService } from '../attendee-flight.service';
import { AttendeeType, FlightBookingKeys, FlightBookingModel, FlightInfoKeys } from 'ag-common-lib/public-api';

@Injectable()
export class AttendeeItineraryModalService extends BaseFormService<FlightBookingModel> {
  private _flightGroup: AttendeeFlightGroup;

  constructor(private attendeeFlightService: AttendeeFlightService) {
    super();
  }

  getFormData = (flightGroup: AttendeeFlightGroup, flightBooking?: Partial<FlightBookingModel>) => {
    this._flightGroup = flightGroup;
    const initialData: FlightBookingModel = Object.assign({}, new FlightBookingModel(), flightBooking);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
          switch (prop) {
            case FlightBookingKeys.airline:
              break;
          }
        }

        return true;
      },
    });

    return this.formData;
  };

  saveItineraryUpdates = (): Promise<any> => {
    this.startProgress();
    const updatedBookingInfo = this.getUpdateBookingInfo();

    return this.attendeeFlightService
      .saveFlightUpdates(this._flightGroup, {
        [FlightInfoKeys.bookingInfo]: updatedBookingInfo,
      })
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private getUpdateBookingInfo = () => {
    const updatedBookingInfo = [];
    const bookingInfo =
      this._flightGroup?.[AttendeeFlightDataKeys.flightInformation]?.[FlightInfoKeys.bookingInfo] ?? [];

    let isNewItem = true;
    bookingInfo.forEach((flightItinerary: FlightBookingModel) => {
      const updatedFlightItinerary = Object.assign({}, flightItinerary);
      const formFlightItineraryId = this.formData?.[FlightBookingKeys.id];
      const flightItineraryId = flightItinerary?.[FlightBookingKeys.id];

      if (formFlightItineraryId === flightItineraryId) {
        isNewItem = false;
        Object.assign(updatedFlightItinerary, this.formData);
      }

      updatedBookingInfo.push(updatedFlightItinerary);
    });

    if (isNewItem) {
      updatedBookingInfo.push(Object.assign({}, this.formData));
    }

    return updatedBookingInfo;
  };
}
