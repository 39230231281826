import { Pipe, PipeTransform } from '@angular/core';
import {
  CAMPAIGNS_MANAGEMENT_TASKS_STATUS_LOOKUP,
  CampaignsManagementTasksStatus,
} from 'ag-common-lib/lib/lists/campaign-management-tasks-status.enum';

@Pipe({ name: 'campaignsStatus' })
export class CampaignsTaskBoardStatusPipe implements PipeTransform {
  private readonly campaignsManagementTasksStatusLookup = CAMPAIGNS_MANAGEMENT_TASKS_STATUS_LOOKUP;

  transform(status: CampaignsManagementTasksStatus): string {
    return this.campaignsManagementTasksStatusLookup.find(item => item.value === status).description;
  }
}
