<ag-shr-modal-window
  #notificationNotificationChannelModalRef
  [width]="'auto'"
  [height]="'auto'"
  [title]="title"
  (onSaveClick)="addNotificationRecipients()"
  [actionTitle]="'ADD'"
  [useScrollView]="false"
>
  <ng-container *ngIf="notificationNotificationChannelModalRef?.popupComponent?.visible ?? false">
    <ag-shr-notification-channel-select
      [(value)]="channel"
      [validationGroup]="validationGroup"
    ></ag-shr-notification-channel-select>
  </ng-container>
</ag-shr-modal-window>
