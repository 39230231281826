<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <ng-container
    *var="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
  >
    <dx-form
      #formRef
      [(formData)]="registrantData"
      labelMode="floating"
      *var="sectionsInEditState$ | async as sectionsInEditState"
      [screenByWidth]="formRef?.instance?.element()?.clientWidth | formScreenByWidth: { md: 820 }"
      [validationGroup]="validationGroup"
      [readOnly]="!canWrite"
    >
      <dxi-item itemType="group" caption="Invitee Info" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="3"
          [dataField]="RegistrantKeys.inviteeStatus"
          [label]="{ text: 'Invitee Status' }"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: inviteeStatusLookup,
            valueExpr: LookupKeys.value,
            displayExpr: LookupKeys.description,
            searchEnabled: true,
            placeholder: '',
            readOnly: !canWrite,
          }"
        ></dxi-item>

        <dxi-item
          [colSpan]="3"
          [dataField]="RegistrantKeys.inviteeOutcomeStatus"
          [label]="{ text: 'Outcome Status' }"
          editorType="dxSelectBox"
          [editorOptions]="{
            readOnly: InviteeStatus.accepted !== registrantData?.[RegistrantKeys.inviteeStatus] && !canWrite,
            items: inviteeOutcomeStatusLookup,
            valueExpr: LookupKeys.value,
            displayExpr: LookupKeys.description,
            searchEnabled: true,
            showClearButton: true,
            placeholder: '',
            disabled: !canWrite,
          }"
        >
        </dxi-item>

        <dxi-item
          [colSpan]="3"
          [dataField]="RegistrantKeys.registrationType"
          [label]="{ text: 'Registration Type' }"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: registrationTypeLookup,
            valueExpr: LookupKeys.value,
            displayExpr: LookupKeys.description,
            searchEnabled: true,
            placeholder: '',
          }"
        >
        </dxi-item>

        <dxi-item
          [colSpan]="3"
          [dataField]="RegistrantKeys.qualifiedAs"
          [label]="{ text: 'Qualified As' }"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: qualifiedAsLookup,
            valueExpr: LookupKeys.value,
            displayExpr: LookupKeys.description,
            searchEnabled: true,
            placeholder: '',
          }"
        >
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Preferred Name" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="2"
          [dataField]="RegistrantKeys.prefix"
          [editorType]="'dxSelectBox'"
          [label]="{ text: 'Prefix' }"
          [editorOptions]="{
            items: prefixesLookup$ | async,
            displayExpr: LookupKeys.description,
            valueExpr: BaseModelKeys.dbId,
            showClearButton: true,
            allowClearing: true,
          }"
        >
        </dxi-item>

        <dxi-item [colSpan]="3" [dataField]="RegistrantKeys.firstName" [label]="{ text: 'First Name' }"></dxi-item>

        <dxi-item [colSpan]="3" [dataField]="RegistrantKeys.lastName" [label]="{ text: 'Last Name' }"></dxi-item>

        <dxi-item
          [colSpan]="2"
          [dataField]="RegistrantKeys.suffix"
          [label]="{ text: 'Suffix' }"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            items: suffixesLookup$ | async,
            displayExpr: LookupKeys.description,
            valueExpr: BaseModelKeys.dbId,
            showClearButton: true,
            allowClearing: true,
          }"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Badge Name" [colCountByScreen]="colCountByScreen">
        <dxi-item [colSpan]="3" [dataField]="RegistrantKeys.badgeName" [label]="{ text: 'Badge Name' }"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Award Name" [colCountByScreen]="colCountByScreen">
        <dxi-item [colSpan]="3" [dataField]="RegistrantKeys.awardName" [label]="{ text: 'Award Name' }"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Are You a first time attendee?" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="3"
          [dataField]="RegistrantKeys.firstTimeAttendee"
          [label]="{ visible: false }"
          [editorType]="'dxRadioGroup'"
          [cssClass]="'flex-column'"
          [editorOptions]="{
            items: trueFalseLookup,
            displayExpr: 'description',
            valueExpr: 'value',
          }"
        >
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Gender" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="3"
          [dataField]="RegistrantKeys.gender"
          [label]="{ text: 'Gender' }"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            items: gendersLookup$ | async,
            displayExpr: LookupKeys.description,
            valueExpr: BaseModelKeys.dbId,
            showClearButton: true,
            allowClearing: true,
          }"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Date of Birth" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="3"
          [dataField]="RegistrantKeys.dob"
          editorType="dxDateBox"
          [label]="{ text: 'Day of Birth' }"
          [editorOptions]="dateEditorOptions"
        >
        </dxi-item>
      </dxi-item>

      <ng-container
        *var="
          ConferenceRegistrationStepName.registrantPersonalInformationEmailAddressStep
            | conferenceGeneralInfoSectionVisible
            | async as isEmailSectionVisible
        "
      >
        <dxi-item [colSpan]="12" [visible]="isEmailSectionVisible">
          <div *dxTemplate class="attendee-general-info-form__form-group-caption dx-widget dx-form-group-caption">
            <span> Email Addresses </span>

            <ng-container
              *ngTemplateOutlet="controls; context: { sectionId: AttendeeSectionGroups.emailAddress, canWrite: canWrite }"
            ></ng-container>
          </div>
        </dxi-item>

        <dxi-item
          [colSpan]="12"
          *var="sectionsInEditState.has(AttendeeSectionGroups.emailAddress) as isEmailAddressInEditState"
          itemType="group"
          [visible]="isEmailSectionVisible"
        >
          <div *dxTemplate>
            <ag-shr-select-email-address
              [pathPrefix]="RegistrantKeys.primaryEmailAddress"
              [isRequired]="false"
              [isReadonly]="!isEmailAddressInEditState"
              [emailAddress]="registrantData?.[RegistrantKeys.primaryEmailAddress]"
              [emailAddresses]="agentEmailAddresses$ | async"
              [validationGroup]="primaryEmailValidationGroup"
            ></ag-shr-select-email-address>
          </div>
        </dxi-item>

        <dxi-item itemType="group" [colCountByScreen]="emailsColCountByScreen" [visible]="isEmailSectionVisible">
          <dxi-item
            [colSpan]="3"
            [name]="RegistrantKeys.ccEmail"
            [dataField]="RegistrantKeys.ccEmail"
            caption="CC Email"
          >
            <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>
      </ng-container>

      <ng-container
        *var="
          ConferenceRegistrationStepName.registrantPersonalInformationAddressStep
            | conferenceGeneralInfoSectionVisible
            | async as isAddressSectionVisible
        "
      >
        <dxi-item [colSpan]="12" [visible]="isAddressSectionVisible">
          <div *dxTemplate class="attendee-general-info-form__form-group-caption dx-widget dx-form-group-caption">
            <span> Shipping Address </span>

            <ng-container
              *ngTemplateOutlet="controls; context: { sectionId: AttendeeSectionGroups.shippingAddress, canWrite: canWrite }"
            ></ng-container>
          </div>
        </dxi-item>

        <dxi-item
          [colSpan]="12"
          *var="sectionsInEditState.has(AttendeeSectionGroups.shippingAddress) as isShippingAddressInEditState"
          itemType="group"
          [visible]="isAddressSectionVisible"
        >
          <div *dxTemplate>
            <ag-shr-select-address
              [pathPrefix]="RegistrantKeys.shippingAddress"
              [isAddress1Required]="false"
              [selectorHidden]="!isShippingAddressInEditState"
              [isReadonly]="!isShippingAddressInEditState"
              [address]="registrantData?.[RegistrantKeys.shippingAddress]"
              [addresses]="agentAddresses$ | async"
              [validationGroup]="shippingAddressValidationGroup"
            ></ag-shr-select-address>
          </div>
        </dxi-item>

        <dxi-item
          [dataField]="RegistrantKeys.isBillingDifferentFromShippingAddress"
          editorType="dxCheckBox"
          [label]="{ visible: false }"
          [editorOptions]="{ text: 'The Billing Address is different from the Shipping Address.' }"
          [visible]="isAddressSectionVisible"
        ></dxi-item>
        <dxi-item itemType="empty"></dxi-item>

        <dxi-item
          [colSpan]="12"
          [visible]="
            registrantData &&
            registrantData[RegistrantKeys.isBillingDifferentFromShippingAddress] &&
            isAddressSectionVisible
          "
        >
          <div *dxTemplate class="attendee-general-info-form__form-group-caption dx-widget dx-form-group-caption">
            <span> Billing Address </span>

            <ng-container
              *ngTemplateOutlet="controls; context: { sectionId: AttendeeSectionGroups.billingAddress, canWrite: canWrite }"
            ></ng-container>
          </div>
        </dxi-item>

        <dxi-item
          [colSpan]="12"
          [visible]="
            registrantData &&
            registrantData[RegistrantKeys.isBillingDifferentFromShippingAddress] &&
            isAddressSectionVisible
          "
          *var="sectionsInEditState.has(AttendeeSectionGroups.billingAddress) as isBillingAddressInEditState"
          itemType="group"
        >
          <div *dxTemplate>
            <ag-shr-select-address
              [pathPrefix]="RegistrantKeys.billingAddress"
              [isAddress1Required]="false"
              [selectorHidden]="!isBillingAddressInEditState"
              [isReadonly]="!isBillingAddressInEditState"
              [address]="registrantData?.[RegistrantKeys.billingAddress]"
              [addresses]="agentAddresses$ | async"
              [validationGroup]="billingAddressValidationGroup"
            ></ag-shr-select-address>
          </div>
        </dxi-item>
      </ng-container>

      <ng-container
        *var="
          ConferenceRegistrationStepName.registrantPersonalInformationPhoneNumberStep
            | conferenceGeneralInfoSectionVisible
            | async as isPhoneNumberSectionVisible
        "
      >
        <dxi-item [colSpan]="12" [visible]="isPhoneNumberSectionVisible">
          <div *dxTemplate class="attendee-general-info-form__form-group-caption dx-widget dx-form-group-caption">
            <span> Phone Number </span>

            <ng-container
              *ngTemplateOutlet="controls; context: { sectionId: AttendeeSectionGroups.mobilePhone, canWrite: canWrite }"
            ></ng-container>
          </div>
        </dxi-item>

        <dxi-item
          [colSpan]="12"
          *var="sectionsInEditState.has(AttendeeSectionGroups.mobilePhone) as isMobilePhoneInEditState"
          itemType="group"
          [visible]="isPhoneNumberSectionVisible"
        >
          <div *dxTemplate>
            <ag-shr-select-phone-number
              [pathPrefix]="RegistrantKeys.mobilePhone"
              [selectorHidden]="!isMobilePhoneInEditState"
              [isReadonly]="!isMobilePhoneInEditState"
              [validationGroup]="mobilePhoneValidationGroup"
              [phoneNumber]="registrantData?.[RegistrantKeys.mobilePhone]"
              [phoneNumbers]="agentPhoneNumbers$ | async"
            >
            </ag-shr-select-phone-number>
          </div>
        </dxi-item>
      </ng-container>

      <dxi-item
        itemType="group"
        [caption]="'T-Shirt Sizes'"
        [visible]="
          ConferenceRegistrationStepName.registrantPersonalInformationShirtSizeStep
            | conferenceGeneralInfoSectionVisible
            | async
        "
      >
        <div *dxTemplate>
          <ag-shr-t-shirt-seizes-form
            [pathPrefix]="RegistrantKeys.tShirtSizes"
            [tShirtSizes]="registrantData?.[RegistrantKeys.tShirtSizes]"
            [screenLimits]="{ md: 1400 }"
            [validationGroup]="tShirtSizesValidationGroup"
            [isReadonly]="!canWrite"
          ></ag-shr-t-shirt-seizes-form>
        </div>
      </dxi-item>

      <dxi-item
        itemType="group"
        [caption]="'Dietary Considerations'"
        [visible]="
          ConferenceRegistrationStepName.registrantPersonalInformationDietaryConsiderationsStep
            | conferenceGeneralInfoSectionVisible
            | async
        "
      >
        <div *dxTemplate>
          <ag-shr-dietary-considerations-form
            [isReadOnly]="!canWrite"
            [pathPrefix]="RegistrantKeys.dietaryConsideration"
            [dietaryConsiderations]="registrantData?.[RegistrantKeys.dietaryConsideration]"
            [validationGroup]="dietaryConsiderationValidationGroup"
          ></ag-shr-dietary-considerations-form>
        </div>
      </dxi-item>

      <dxi-item
        [colSpan]="12"
        itemType="group"
        *var="sectionsInEditState.has(AttendeeSectionGroups.emergencyContact) as isEmergencyContactInEditState"
        [visible]="
          ConferenceRegistrationStepName.registrantPersonalInformationEmergencyContactStep
            | conferenceGeneralInfoSectionVisible
            | async
        "
      >
        <dxi-item>
          <div *dxTemplate class="attendee-general-info-form__form-group-caption dx-widget dx-form-group-caption">
            <span> Emergency Contact </span>

            <ng-container
              *ngTemplateOutlet="controls; context: { sectionId: AttendeeSectionGroups.emergencyContact, canWrite: canWrite }"
            ></ng-container>
          </div>
        </dxi-item>

        <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
          <dxi-item
            [colSpan]="3"
            [name]="[RegistrantKeys.emergencyContact, AssociationKeys.firstName] | path"
            [dataField]="[RegistrantKeys.emergencyContact, AssociationKeys.firstName] | path"
            [label]="{ text: 'First Name' }"
            [editorOptions]="{
              readOnly: !!selectedAssociationKey || !isEmergencyContactInEditState,
              inputAttr: { autocomplete: 'disabled' },
            }"
          >
            <dxi-validation-rule
              type="custom"
              [validationCallback]="emergencyContactValidationCallback"
              [message]="Messages.REQUIRED_TEXT"
              [reevaluate]="true"
            ></dxi-validation-rule>
          </dxi-item>

          <dxi-item
            [colSpan]="3"
            [name]="[RegistrantKeys.emergencyContact, AssociationKeys.lastName] | path"
            [dataField]="[RegistrantKeys.emergencyContact, AssociationKeys.lastName] | path"
            [label]="{ text: 'Last Name' }"
            [editorOptions]="{
              readOnly: !!selectedAssociationKey || !isEmergencyContactInEditState,
              inputAttr: { autocomplete: 'disabled' },
            }"
          >
            <dxi-validation-rule
              type="custom"
              [validationCallback]="emergencyContactValidationCallback"
              [message]="Messages.REQUIRED_TEXT"
              [reevaluate]="true"
            ></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item
          itemType="group"
          [colCountByScreen]="colCountByScreen"
          *var="registrantData?.[RegistrantKeys.emergencyContact] as emergencyContact"
        >
          <dxi-item
            [colSpan]="3"
            [label]="{ showColon: true }"
            [editorOptions]="{
              readOnly: !isEmergencyContactInEditState,
            }"
          >
            <div *dxTemplate>
              <ag-shr-relationship-type-select-box
                [name]="[RegistrantKeys.emergencyContact, AssociationKeys.associationType] | path"
                [isRequired]="
                  emergencyContact?.[AssociationKeys.firstName] || emergencyContact?.[AssociationKeys.lastName]
                "
                [readOnly]="!isEmergencyContactInEditState"
                [(value)]="emergencyContact && emergencyContact[AssociationKeys.associationType]"
                [validationGroup]="emergencyContactRelationshipTypeValidationGroup"
              ></ag-shr-relationship-type-select-box>
            </div>
          </dxi-item>

          <dxi-item
            [colSpan]="3"
            [name]="[RegistrantKeys.emergencyContact, AssociationKeys.contactNumber] | path"
            [dataField]="[RegistrantKeys.emergencyContact, AssociationKeys.contactNumber].join('.')"
            [label]="{ text: 'Phone Number' }"
            [editorOptions]="{
              mask: phoneMask,
              readOnly: !isEmergencyContactInEditState,
              valueChangeEvent: 'input',
            }"
          >
            <dxi-validation-rule
              type="stringLength"
              [min]="totalPhoneDigits"
              [max]="totalPhoneDigits"
              [ignoreEmptyValue]="true"
              [trim]="true"
              [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
            >
            </dxi-validation-rule>

            <dxi-validation-rule
              type="custom"
              [validationCallback]="emergencyContactValidationCallback"
              [message]="Messages.REQUIRED_TEXT"
              [reevaluate]="true"
            ></dxi-validation-rule>
          </dxi-item>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </ng-container>

  <ng-template #controls let-sectionId="sectionId" let-canWrite="canWrite">
    <ag-crm-attendee-details-group-controls
      *ngIf="canWrite"
      [isInEditState]="(sectionsInEditState$ | async).has(sectionId)"
      (handleStartEditing)="startEditing(sectionId)"
      (handleCancelEditing)="cancelEditing(sectionId)"
      (handleSaveChanges)="saveEditing(sectionId)"
    ></ag-crm-attendee-details-group-controls>
  </ng-template>
</dx-scroll-view>
