<div class="container">
  <div class="row justify-content-between" style="padding: 20px 40px">
    <div class="col-12 align-self-center text-start">
      <h4>Connect your personal email accounts to AG-CRM to log, track, and send.</h4>
    </div>
  </div>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      [dataSource]="dataSource"
      [showBorders]="false"
      [focusedRowEnabled]="true"
      [focusedRowIndex]="0"
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [scrollLeft]="true"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [height]="550"
    >
      <dxo-editing [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>

      <dxi-column dataField="first_name"></dxi-column>
      <dxi-column dataField="last_name"></dxi-column>
      <dxi-column dataField="smtp_url"></dxi-column>
      <dxi-column dataField="port"></dxi-column>
      <dxi-column dataField="secure" dataType="boolean" caption="Secured?"></dxi-column>
      <dxi-column dataField="email_address"></dxi-column>
      <dxi-column dataField="password" dataType="password" [customizeText]="hidePassword"></dxi-column>
    </dx-data-grid>
  </div>
</div>
