import { Component, Input } from '@angular/core';
import {
  Agent,
  AgentKeys,
  BaseModelKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from 'ag-common-lib/public-api';
import { AgentService } from 'ag-common-svc/public-api';

@Component({
  selector: 'ag-crm-doi',
  templateUrl: './doi.component.html',
  styleUrls: ['./doi.component.scss'],
})
export class DoiComponent {
  @Input() agent: Agent;

  editDOI: boolean = false;

  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private agentService: AgentService) {}

  saveChanges = () => {
    this.agentService
      .updateFields(this.agent?.[BaseModelKeys.dbId], { [AgentKeys.npn]: this.agent?.[AgentKeys.npn] })
      .then(() => {
        this.editDOI = false;
      });
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
