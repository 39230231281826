import { NgModule } from '@angular/core';
import { DxCheckBoxModule, DxListModule, DxTabPanelModule } from 'devextreme-angular';
import { DxoMediaResizingModule } from 'devextreme-angular/ui/nested';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgentEditorComponent } from './domain/agent-editor/agent-editor.component';
import { EmailFormComponent } from './email/email-form/email-form.component';
import { EmailTemplateComponent } from './email/email-template/email-template.component';
import { NoteFormComponent } from './note/note-form/note-form.component';
import { MgaComponent } from './domain/mga/mga.component';
import { MgaEditorModalComponent } from './domain/mga/modal/mga-editor-modal.component';
import { DoiComponent } from './domain/doi/doi.component';
import { PermissionsComponent } from './domain/permissions/permissions.component';
import { ProspectComponent } from './domain/prospect/prospect.component';
import {
  AgAssignOwnerViewerModule,
  AgEditorToolbarModule,
  AgencyAddressesModule,
  AgencyCarriersModule,
  AgencyHeaderModule,
  AgencyMediaModule,
  AgencyRelationshipModule,
  AgentCampaignWizardModule,
  AgentCarriersModule,
  AgentMediaModule,
  ApproveDenyReasonsModule,
  AssociationsModule,
  ButtonWithIndicatorModule,
  DietaryConsiderationTypeSelectBoxModule,
  GenderSelectBoxModule,
  ModalWindowModule,
  NeedToKnowModule,
  OfficeEmailAddressesModule,
  OfficePhoneNumberModule,
  PortalModule,
  SizesModule,
  SocialsModule,
  StatesLicensedInModule,
  StatusActivityModule,
  SuffixSelectBoxModule,
  TShortSizeSelectBoxModule,
} from 'ag-common-svc/public-api';
import { AddressesModule } from 'ag-common-svc/lib/components/agent-editor/components/addresses/addresses.module';
import { StateSelectBoxModule } from 'ag-common-svc/lib/components/state-select-box/state-select-box.module';
import { PrefixSelectBoxModule } from 'ag-common-svc/lib/components/prefix-select-box/prefix-select-box.module';
import { DietaryConsiderationsModule } from 'ag-common-svc/lib/components/agent-editor/components/dietary-considerations/dietary-considerations.module';
import { AgentHeadersModule } from 'ag-common-svc/lib/components/agent-editor/components/agent-header/agent-header.module';
import { PhoneNumbersModule } from 'ag-common-svc/lib/components/agent-editor/components/phone-numbers/phone-numbers.module';
import { EmailAddressesModule } from 'ag-common-svc/lib/components/agent-editor/components/email-addresses/email-addresses.module';
import { CampaignsModule } from 'ag-common-svc/lib/components/agent-editor/components/campaigns/campaigns.module';
import { PersonalInformationModule } from 'ag-common-svc/lib/components/agent-editor/components/personal-information/personal-information.module';
import { AgencyEditorComponent } from './domain/agency-editor/agency-editor.component';
import { WebsitesModule } from 'ag-common-svc/lib/components/websites/websites.module';
import { LicensesModule } from 'ag-common-svc/lib/components/agent-editor/components/licenses/licenses.module';

@NgModule({
  imports: [
    SharedModule,
    DxoMediaResizingModule,
    DxListModule,
    AssociationsModule,
    ApproveDenyReasonsModule,
    NeedToKnowModule,
    ModalWindowModule,
    ButtonWithIndicatorModule,
    AddressesModule,
    PortalModule,
    StateSelectBoxModule,
    PrefixSelectBoxModule,
    DietaryConsiderationTypeSelectBoxModule,
    TShortSizeSelectBoxModule,
    SocialsModule,
    GenderSelectBoxModule,
    SuffixSelectBoxModule,
    SizesModule,
    DietaryConsiderationsModule,
    AgentHeadersModule,
    PhoneNumbersModule,
    EmailAddressesModule,
    DxCheckBoxModule,
    AgEditorToolbarModule,
    WebsitesModule,
    CampaignsModule,
    PersonalInformationModule,
    AgencyHeaderModule,
    AgentCarriersModule,
    LicensesModule,
    AgencyCarriersModule,
    StatusActivityModule,
    AgencyAddressesModule,
    OfficeEmailAddressesModule,
    OfficePhoneNumberModule,
    AgencyRelationshipModule,
    StatesLicensedInModule,
    DxTabPanelModule,
    AgentCampaignWizardModule,
    AgencyMediaModule,
    AgentMediaModule,
    AgAssignOwnerViewerModule,
  ],
  declarations: [
    NoteFormComponent,
    EmailFormComponent,
    EmailTemplateComponent,
    AgentEditorComponent,
    MgaComponent,
    DoiComponent,
    PermissionsComponent,
    ProspectComponent,
    AgencyEditorComponent,
    MgaEditorModalComponent,
  ],
  exports: [
    SharedModule,
    NoteFormComponent,
    EmailFormComponent,
    EmailTemplateComponent,
    AgentEditorComponent,
    AgencyEditorComponent,
  ],
})
export class AgCrmCommonModule {}
