import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { Timestamp } from 'firebase/firestore';
import { ToastrService } from 'ngx-toastr';
import { Entity, EntityPermissionActivityKeys, EntityPermissionModelKeys, Webinar } from 'ag-common-lib/public-api';
import { WebinarService } from 'src/app/shared/services/webinar.service';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss'],
})
export class WebinarsComponent {
  @ViewChild('webinarGrid') webinarGrid: DxDataGridComponent;

  selectedWebinar: Webinar;

  webinarRegistrationListVisible: boolean = false;
  webinarDetailsVivible: boolean = false;

  startDateEditorOptions: any = {};
  endDateEditorOptions: any = {};

  dataSource = {};

  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(public router: Router, public webinarService: WebinarService, public toastrService: ToastrService) {
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: function (loadOptions: any) {
        return webinarService.getAll('name');
      },
      insert: function (value: Webinar) {
        return webinarService.create(value);
      },
      update: function (key: any, value: Webinar) {
        return webinarService.update(value);
      },
      remove: function (id: any) {
        return webinarService.delete(id);
      },
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: this.viewWebinarDetails.bind(this),
      },
    });
  }

  viewWebinarDetails(e) {
    if (e.row && e.row.data) {
      this.selectedWebinar = e.row.data;
    } else {
      this.selectedWebinar = { ...new Webinar() };
    }

    this.startDateEditorOptions = {
      placeholder: this.getStartDate(this.selectedWebinar),
      onValueChanged: e => {
        var d = new Date(e.value);
        this.selectedWebinar.start_date = new Timestamp(d.getTime() / 1000, 0);
      },
    };

    this.endDateEditorOptions = {
      placeholder: this.getEndDate(this.selectedWebinar),
      onValueChanged: e => {
        var d = new Date(e.value);
        this.selectedWebinar.start_date = new Timestamp(d.getTime() / 1000, 0);
      },
    };
    this.webinarDetailsVivible = true;
  }

  saveWebinarDetails() {
    if (this.selectedWebinar.dbId) {
      this.webinarService.update(this.selectedWebinar).then(() => {
        this.toastrService.success('Webinar Successfully Saved');
        this.webinarDetailsVivible = false;
        this.webinarGrid.instance.refresh();
      });
    } else {
      this.webinarService.create(this.selectedWebinar).then(() => {
        this.toastrService.success('Webinar Successfully Created');
        this.webinarDetailsVivible = false;
        this.webinarGrid.instance.refresh();
      });
    }
  }

  viewWebinarRegistrationList(e) {
    this.webinarRegistrationListVisible = true;
  }

  showWebinarInfo(e) {
    this.router.navigate(['Contact-Management']);
  }

  getStartDate(e): string {
    if (e.start_date) {
      return new Date(e.start_date.seconds * 1000).toLocaleDateString();
    } else {
      return new Date().toLocaleDateString();
    }
  }

  getEndDate(e): string {
    if (e.end_date) {
      return new Date(e.end_date.seconds * 1000).toLocaleDateString();
    } else {
      return new Date().toLocaleDateString();
    }
  }

  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
