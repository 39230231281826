import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogAddressComponent } from './change-log-address.component';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';

@NgModule({
  declarations: [ChangeLogAddressComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule],
  exports: [ChangeLogAddressComponent],
})
export class ChangeLogAddressModule {}
