<ng-container *ngIf="!!log; else emptyLog">
  <ng-container *ngFor="let key of addressKeys">
    <ng-container *ngIf="hasLog(key, log)">
      <div
        [innerHTML]="normalizeLog(key, log) | changeLogsDiffHTML : addressLookup : addressKeyWithLookup[key] ?? null"
      ></div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyLog><span>&mdash;</span></ng-template>
