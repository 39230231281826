<dx-data-grid
  #campaignsIssuesGridRef
  [height]="'100%'"
  [dataSource]="issues ?? []"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [allowColumnResizing]="true"
  [scrollLeft]="true"
  [wordWrapEnabled]="true"
  [noDataText]="emptyMessage"
  (onContentReady)="onContentReady($event)"
>
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <h5 class="toolbar-label fw-bold">{{ caption }}</h5>
      </div>
    </dxi-item>

    <dxi-item
      location="after"
      widget="dxButton"
      [options]="{
        icon: 'plus',
        hint: 'Report Issue',
        onClick: showAddPopup
      }"
      [visible]="canReportIssue && Entity.campaignsTaskIssues | hasPermission : EntityPermissionActivityKeys.create | async"
    ></dxi-item>
  </dxo-toolbar>

  <dxo-editing mode="popup" [useIcons]="true" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false">
  </dxo-editing>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxi-column
    [dataField]="CampaignsManagementTaskIssuesKeys.issueField"
    caption="Field"
    [allowHiding]="false"
    [width]="210"
  >
    <dxo-lookup
      [dataSource]="CampaignsManagementTaskIssuesFieldLookup"
      displayExpr="description"
      valueExpr="value"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="CampaignsManagementTaskIssuesKeys.issueType"
    caption="Issue"
    [width]="110"
    [hidingPriority]="1"
    [allowHiding]="true"
  >
    <dxo-lookup
      [dataSource]="CampaignsManagementTaskIssuesTypeLookup"
      displayExpr="description"
      valueExpr="value"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="description" caption="Description" [allowHiding]="false"></dxi-column>

  <dxi-column
    [dataField]="CampaignsManagementTaskIssuesKeys.issueStatus"
    caption="Status"
    [width]="110"
    [allowHiding]="true"
    [hidingPriority]="0"
    cellTemplate="diffCellTemplate"
  ></dxi-column>

  <dxi-column
    [dataField]="BaseModelKeys.createdDate"
    caption="Created Date"
    [sortOrder]="'desc'"
    [visible]="false"
    [width]="110"
    dataType="date"
    [format]="dateFormat"
    [allowEditing]="false"
  ></dxi-column>

  <dxi-column type="buttons" [visible]="canReportIssue">
    <dxi-button
      icon="edit"
      hint="Edit Issue"
      [onClick]="showEditPopup"
      [visible]="canEditIssue && Entity.campaignsTaskIssues | hasPermission : EntityPermissionActivityKeys.write | async"
    ></dxi-button>
  </dxi-column>

  <div *dxTemplate="let item of 'diffCellTemplate'">
    <ng-container *ngIf="item?.data?.issueStatus as status">
      <span class="badge" [ngClass]="status | campaignsIssueStatus : 'class'">
        {{ status | campaignsIssueStatus }}
      </span>
    </ng-container>
  </div>
</dx-data-grid>
