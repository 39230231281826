import { NgModule } from '@angular/core';
import { IsMultiViewPipe } from './hotel-registration/is-multi-view.pipe';
import { IsOutsideMainDatesPipe } from './hotel-registration/is-outside-main-dates.pipe';
import { MainDateClassPipe } from './hotel-registration/main-date-class.pipe';
import { ConferenceStepsDescriptionPipe } from './conference-steps-description.pipe';
import { ConferenceStepsTitlePipe } from './conference-steps-title.pipe';
import { FormFieldVisiblePipe } from './form-field-visible.pipe';
import { IsFormFieldVisiblePipe } from './is-form-field-visible.pipe';
import { EventNameTitlePipe } from './event-name-title.pipe';

@NgModule({
  declarations: [
    IsMultiViewPipe,
    IsOutsideMainDatesPipe,
    MainDateClassPipe,
    ConferenceStepsDescriptionPipe,
    ConferenceStepsTitlePipe,
    FormFieldVisiblePipe,
    IsFormFieldVisiblePipe,
    EventNameTitlePipe,
  ],
  exports: [
    IsMultiViewPipe,
    IsOutsideMainDatesPipe,
    MainDateClassPipe,
    ConferenceStepsDescriptionPipe,
    ConferenceStepsTitlePipe,
    FormFieldVisiblePipe,
    IsFormFieldVisiblePipe,
    EventNameTitlePipe,
  ],
})
export class ConferenceRegistrationPipesModule {}
