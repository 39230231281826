<dx-data-grid
  class="invited-grid ag-grid__component"
  #conferenceRegistrationsGrid
  [dataSource]="participatedRegistrants$ | async"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [scrollLeft]="true"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [noDataText]="emptyMessage"
  [syncLookupFilterValues]="false"
  width="100%"
  height="100%"
  (onRowDblClick)="showAttendeeModal($event.data)"
>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-scrolling mode="virtual" [useNative]="true" [showScrollbar]="'always'"></dxo-scrolling>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

  <dxo-state-storing
    [enabled]="true"
    type="localStorage"
    [storageKey]="StorageKeys.invitedParticipants"
  ></dxo-state-storing>

  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>

  <dxo-toolbar>
    <dxi-item location="before" name="groupPanel"></dxi-item>

    <dxi-item location="after" name="columnChooserButton" cssClass="ag-grid__toolbar-control"></dxi-item>
  </dxo-toolbar>

  <dxi-column
    [caption]="titleList.first_name"
    [calculateCellValue]="calculateFullNameCellValue"
    [calculateDisplayValue]=""
    [allowEditing]="false"
    [allowFiltering]="true"
    [allowGrouping]="true"
    [allowSearch]="true"
    showWhenGrouped="true"
    cssClass="conference-registration-grid__cell-vertical-aligned"
    cellTemplate="agentCellTmp"
  ></dxi-column>

  <dxi-column
    [dataField]="AttendeeKeys.agencyId"
    [caption]="titleList.agency_id"
    [allowEditing]="false"
    [visible]="false"
    cssClass="conference-registration-grid__cell-vertical-aligned"
    showWhenGrouped="true"
  >
    <dxo-lookup
      [dataSource]="agencies$ | async"
      [allowClearing]="false"
      [displayExpr]="'name'"
      [valueExpr]="'agency_id'"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="AttendeeKeys.mgaId"
    [caption]="titleList.mga_id"
    [allowEditing]="false"
    showWhenGrouped="true"
    cssClass="conference-registration-grid__cell-vertical-aligned"
  >
    <dxo-lookup
      [dataSource]="agencies$ | async"
      [allowClearing]="false"
      [displayExpr]="'name'"
      [valueExpr]="'agency_id'"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="AttendeeKeys.registrationState"
    [caption]="'Registration State'"
    [groupIndex]="0"
    [sortIndex]="0"
    cssClass="conference-registration-grid__cell-vertical-aligned"
    [allowGrouping]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [sortOrder]="'desc'"
    groupCellTemplate="registrationStateGroupCellTemplate"
  >
    <dxo-lookup
      [dataSource]="attendeeWizardStateLookup"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [caption]="'Hotel Information'" showWhenGrouped="true">
    <dxi-column
      [caption]="'Hotel Information Status'"
      showWhenGrouped="true"
      [calculateCellValue]="
        ConferenceRegistrationCategoryName.registrantHotelReservationCategory | conferenceRegistrationStatus
      "
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      cellTemplate="hotelStatusCellTmp"
      headerCellTemplate="statusHeaderTemplate"
      groupCellTemplate="statusGroupCellTemplate"
      [minWidth]="80"
    >
      <dxo-lookup
        [dataSource]="taskStatus"
        [allowClearing]="false"
        displayExpr="description"
        valueExpr="value"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [caption]="'Hotel Information Owner'"
      [calculateCellValue]="
        ConferenceRegistrationCategoryName.registrantHotelReservationCategory | conferenceRegistrationOwner
      "
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      [showWhenGrouped]="true"
      cellTemplate="hotelOwnerCellTmp"
      headerCellTemplate="ownerHeaderTemplate"
      groupCellTemplate="ownerGroupCellTemplate"
      [minWidth]="80"
    >
      <dxo-lookup [dataSource]="agentsStore" [valueExpr]="'dbId'" [displayExpr]="getAgentFullName"></dxo-lookup>
    </dxi-column>
  </dxi-column>

  <dxi-column [caption]="'Flight Information'" name="flightInfo" showWhenGrouped="true">
    <dxi-column
      [caption]="'Flight Information Status'"
      [showWhenGrouped]="true"
      [calculateCellValue]="
        ConferenceRegistrationCategoryName.registrantFlightInformationCategory | conferenceRegistrationStatus
      "
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      cellTemplate="flightStatusCellTmp"
      headerCellTemplate="statusHeaderTemplate"
      groupCellTemplate="statusGroupCellTemplate"
      [minWidth]="80"
    >
      <dxo-lookup
        [dataSource]="taskStatus"
        [allowClearing]="false"
        displayExpr="description"
        valueExpr="value"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [caption]="'Flight Information Owner'"
      [calculateCellValue]="
        ConferenceRegistrationCategoryName.registrantFlightInformationCategory | conferenceRegistrationOwner
      "
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      [showWhenGrouped]="true"
      cellTemplate="flightOwnerCellTmp"
      headerCellTemplate="ownerHeaderTemplate"
      groupCellTemplate="ownerGroupCellTemplate"
      [minWidth]="80"
    >
      <dxo-lookup [dataSource]="agentsStore" [valueExpr]="'dbId'" [displayExpr]="getAgentFullName"></dxo-lookup>
    </dxi-column>
  </dxi-column>

  <dxi-column [caption]="'Excursions'" showWhenGrouped="true">
    <dxi-column
      [caption]="'Excursions Status'"
      showWhenGrouped="true"
      [calculateCellValue]="
        ConferenceRegistrationCategoryName.registrantExcursionCategory | conferenceRegistrationStatus
      "
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      cellTemplate="excursionStatusCellTmp"
      headerCellTemplate="statusHeaderTemplate"
      groupCellTemplate="statusGroupCellTemplate"
      [minWidth]="80"
    ></dxi-column>

    <dxi-column
      [caption]="'Excursions Owner'"
      [calculateCellValue]="
        ConferenceRegistrationCategoryName.registrantExcursionCategory | conferenceRegistrationOwner
      "
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      [showWhenGrouped]="true"
      cellTemplate="excursionOwnerCellTmp"
      headerCellTemplate="ownerHeaderTemplate"
      groupCellTemplate="ownerGroupCellTemplate"
      [minWidth]="80"
    >
      <dxo-lookup [dataSource]="agentsStore" [valueExpr]="'dbId'" [displayExpr]="getAgentFullName"></dxo-lookup>
    </dxi-column>
  </dxi-column>

  <dxi-column [caption]="'Reservation Information'" showWhenGrouped="true" name="reservationInfo">
    <dxi-column
      [caption]="'Reservation Information Status'"
      [calculateCellValue]="ConferenceRegistrationCategoryName.reservationCategory | conferenceRegistrationStatus"
      [showWhenGrouped]="true"
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      cellTemplate="reservationStatusCellTmp"
      headerCellTemplate="statusHeaderTemplate"
      groupCellTemplate="statusGroupCellTemplate"
      [minWidth]="80"
    ></dxi-column>

    <dxi-column
      [caption]="'Reservation Information Owner'"
      [calculateCellValue]="ConferenceRegistrationCategoryName.reservationCategory | conferenceRegistrationOwner"
      [allowEditing]="false"
      [allowFiltering]="true"
      [allowGrouping]="true"
      [allowSearch]="true"
      [showWhenGrouped]="true"
      cellTemplate="excursionOwnerCellTmp"
      headerCellTemplate="ownerHeaderTemplate"
      groupCellTemplate="ownerGroupCellTemplate"
      [minWidth]="80"
    >
      <dxo-lookup [dataSource]="agentsStore" [valueExpr]="'dbId'" [displayExpr]="getAgentFullName"></dxo-lookup>
    </dxi-column>
  </dxi-column>

  <!--Templates-->
  <div *dxTemplate="let data of 'statusHeaderTemplate'">
    <div>Status</div>
  </div>

  <div *dxTemplate="let data of 'ownerHeaderTemplate'">
    <div>Owner</div>
  </div>

  <div *dxTemplate="let cell of 'statusGroupCellTemplate'">
    {{ cell.column.caption }}:
    {{ cell.displayValue }}
  </div>

  <div *dxTemplate="let cell of 'ownerGroupCellTemplate'">
    {{ cell.column.caption }}:
    {{ cell.displayValue | empty: (cell.value | agent: "p_agent_name" | async | empty: " n/a") }}
  </div>

  <div *dxTemplate="let cell of 'headerRegistrationStatusTemplate'">
    <span class="text-wrap">{{ titleList[RegistrantModelKeys.conferenceRegistrationStatus] }}</span>
  </div>

  <div *dxTemplate="let cell of 'registrationStateGroupCellTemplate'">
    <div>
      Registration State: {{ cell.displayValue }}

      <ng-container *ngFor="let summaryItem of cell.summaryItems">
        <span *ngIf="summaryItem.summaryType === 'count'">(Total: {{ summaryItem.value }})</span>
      </ng-container>
    </div>
  </div>

  <div *dxTemplate="let cell of 'agentCellTmp'">
    <ng-container>
      <div class="ag-text-clickable" (click)="viewAgentDetails(cell.data?.dbId)">
        {{ cell.value }}
      </div>
    </ng-container>
  </div>

  <div *dxTemplate="let cell of 'flightOwnerCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        ownerButton;
        context: {
          data: cell.data,
          step: cell.data?.registrant?.task?.registrantFlightInformationCategory,
          categoryName: ConferenceRegistrationCategoryName.registrantFlightInformationCategory,
        }
      "
    ></ng-container>
  </div>

  <div *dxTemplate="let cell of 'hotelOwnerCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        ownerButton;
        context: {
          data: cell.data,
          step: cell.data?.registrant?.task?.registrantHotelReservationCategory,
          categoryName: ConferenceRegistrationCategoryName.registrantHotelReservationCategory,
        }
      "
    ></ng-container>
  </div>

  <div *dxTemplate="let cell of 'excursionOwnerCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        ownerButton;
        context: {
          data: cell.data,
          step: cell.data?.registrant?.task?.registrantExcursionCategory,
          categoryName: ConferenceRegistrationCategoryName.registrantExcursionCategory,
        }
      "
    ></ng-container>
  </div>

  <div *dxTemplate="let cell of 'reservationOwnerCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        ownerButton;
        context: {
          data: cell.data,
          step: cell.data?.registrant?.task?.reservationCategory,
          categoryName: ConferenceRegistrationCategoryName.reservationCategory,
        }
      "
    ></ng-container>
  </div>

  <div *dxTemplate="let cell of 'flightStatusCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        statusButton;
        context: {
          data: cell.data,
          section: AttendeeDetailsModalSection.flyInformation,
          status: cell.data?.registrant?.task?.registrantFlightInformationCategory?.status | conferenceTaskStatus,
          icon: 'agi-flight_takeoff',
        }
      "
    ></ng-container>
  </div>

  <div *dxTemplate="let cell of 'hotelStatusCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        statusButton;
        context: {
          data: cell.data,
          section: AttendeeDetailsModalSection.hotelReservation,
          status: cell.data?.registrant?.task?.registrantHotelReservationCategory?.status | conferenceTaskStatus,
          icon: 'agi-hotel',
        }
      "
    ></ng-container>
  </div>

  <div *dxTemplate="let cell of 'excursionStatusCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        statusButton;
        context: {
          data: cell.data,
          section: AttendeeDetailsModalSection.excursions,
          status: cell.data?.registrant?.task?.registrantExcursionCategory?.status | conferenceTaskStatus,
          icon: 'agi-rock-and-roll',
        }
      "
    ></ng-container>
  </div>

  <div *dxTemplate="let cell of 'reservationStatusCellTmp'">
    <ng-container
      *ngTemplateOutlet="
        statusButton;
        context: {
          data: cell.data,
          section: AttendeeDetailsModalSection.generalInfo,
          status: cell.data?.registrant?.task?.reservationCategory?.status | conferenceTaskStatus,
          icon: 'agi-map-location-dot-solid',
        }
      "
    ></ng-container>
  </div>
</dx-data-grid>

<ng-template #statusButton let-icon="icon" let-section="section" let-status="status" let-data="data">
  <dx-button
    [icon]="icon"
    class="conference-registration-grid__button"
    [ngClass]="status?.cssClass"
    [hint]="status?.description"
    (onClick)="showAttendeeModal(data, section)"
  ></dx-button>
</ng-template>

<ng-template #ownerButton let-step="step" let-categoryName="categoryName" let-data="data">
  <div class="conference-registration-grid__button">
    <ag-shr-assign-owner-viewer
      [owner]="{ assignedTo: step?.assignedTo ?? null, assignedToNote: step?.assignedToNote ?? '' }"
      [isDisabled]="step?.status === ConferenceRegistrationCommonTaskStatus.done"
      [onOwnerChange]="changeOwner.bind(this, data, categoryName)"
      [listLoadOptions]="agentsLoadOptions"
    ></ag-shr-assign-owner-viewer>
  </div>
</ng-template>
