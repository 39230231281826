export enum AppRoutes {
  LoginForm = 'login-form',
  Agents = 'agents',
  Agencies = 'agencies',
  Prospects = 'prospects',
  Carriers = 'carriers',
  Campaign = 'campaign',
  Registrants = 'registrants',
  Administration = 'administration',
  NotificationsHub = 'notifications-hub',
}
