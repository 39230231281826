import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { Agent, BaseModelKeys, Lookup, LookupKeys } from 'ag-common-lib/public-api';
import { finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AgentService } from 'ag-common-svc/public-api';

@Injectable({ providedIn: 'root' })
export class AgentsService {
  public allAgentsList$: Observable<Agent[]>;
  public agentsLookup$: Observable<any[]>;
  public isAllAgentsListFetchInProgress$: Observable<boolean>;
  private readonly _isAllAgentsListFetchInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(private agentService: AgentService) {
    this.isAllAgentsListFetchInProgress$ = this._isAllAgentsListFetchInProgress$.asObservable();

    this.allAgentsList$ = this.getAllAgentsList();

    this.agentsLookup$ = this.allAgentsList$.pipe(
      map((response): Partial<Lookup>[] =>
        Array.isArray(response)
          ? response.map((agent: Agent) => {
              const description = [agent?.p_agent_first_name, agent?.p_agent_last_name].filter(Boolean).join(' ');
              const value = agent?.dbId;
              const reference = agent[BaseModelKeys.firebaseRef] as any;

              return {
                key: value,
                group: 'Agents',
                [LookupKeys.value]: value,
                [LookupKeys.description]: description,
                [LookupKeys.reference]: reference,
              };
            })
          : [],
      ),
    );
  }

  private getAllAgentsList = () =>
    defer(() => {
      this._isAllAgentsListFetchInProgress$.next(true);
      return this.agentService.getList([], { includeRef: true, sortField: 'p_agent_first_name' }).pipe(
        tap(() => {
          this._isAllAgentsListFetchInProgress$.next(false);
        }),
        shareReplay(1),
      );
    });
}
