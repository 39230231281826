import { Injectable } from '@angular/core';
import {
  Excursion,
  ExcursionConfigurationKeys,
  ExcursionDataType,
  ExcursionKeys,
  ExcursionOptionKey,
  ExcursionOptionsType,
  ExcursionPreferenceKeys,
  ExcursionType,
} from 'ag-common-lib/lib/models/registration/excursion.model';
import { BaseFormService } from 'ag-common-svc/public-api';

@Injectable()
export class ExcursionModalService extends BaseFormService<Excursion> {
  constructor() {
    super();
  }

  getFormData = (excursion: Excursion) => {
    const initialData = Object.assign({}, new Excursion(), excursion);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        switch (prop) {
          case ExcursionKeys.type:
            this.setPreferences(value);
            this.setConfigurations(value);
            break;
        }

        return true;
      },
    });
    return this.formData;
  };

  private setConfigurations = (value: ExcursionType) => {
    this.formData[ExcursionKeys.configuration] = [];

    switch (value) {
      case ExcursionType.golf:
        this.setDefaultGolfConfigurations();
        return;
      case ExcursionType.spa:
        this.setDefaultSpaConfigurations();
        return;
    }
  };

  private setPreferences = (value: ExcursionType) => {
    this.formData[ExcursionKeys.preferences] = [];

    switch (value) {
      case ExcursionType.golf:
        this.setDefaultGolfPreferences();
        return;
      case ExcursionType.spa:
        this.setDefaultSpaPreferences();
        return;
      case ExcursionType.genTime:
        this.setDefaultGenTimePreferences();
        return;
    }
  };

  private setDefaultGolfPreferences = () => {
    this.formData[ExcursionKeys.preferences] = [
      {
        [ExcursionPreferenceKeys.label]: 'Clubs',
        [ExcursionPreferenceKeys.selectionType]: ExcursionOptionsType.single,
        [ExcursionPreferenceKeys.instructions]: 'Provide information about clubs',
        [ExcursionPreferenceKeys.items]: [
          {
            [ExcursionOptionKey.name]: 'Right Handed Clubs',
            [ExcursionOptionKey.cost]: 50,
          },
          {
            [ExcursionOptionKey.name]: 'Left Handed Clubs',
            [ExcursionOptionKey.cost]: 50,
          },
          {
            [ExcursionOptionKey.name]: 'No Clubs',
            [ExcursionOptionKey.cost]: null,
          },
        ],
      },
    ];
  };

  private setDefaultSpaPreferences = () => {
    this.formData[ExcursionKeys.preferences] = [
      {
        [ExcursionPreferenceKeys.label]: 'Appointment',
        [ExcursionPreferenceKeys.selectionType]: ExcursionOptionsType.single,
        [ExcursionPreferenceKeys.instructions]: 'Provide information about preferences for a daytime and a therapist',
        [ExcursionPreferenceKeys.items]: [
          {
            [ExcursionOptionKey.name]: 'Morning',
            [ExcursionOptionKey.cost]: null,
          },
          {
            [ExcursionOptionKey.name]: 'Afternoon',
            [ExcursionOptionKey.cost]: null,
          },
        ],
      },
      {
        [ExcursionPreferenceKeys.label]: 'Preferences for a therapist',
        [ExcursionPreferenceKeys.selectionType]: ExcursionOptionsType.single,
        [ExcursionPreferenceKeys.instructions]: null,
        [ExcursionPreferenceKeys.items]: [
          {
            [ExcursionOptionKey.name]: 'Males',
            [ExcursionOptionKey.cost]: null,
          },
          {
            [ExcursionOptionKey.name]: 'Female',
            [ExcursionOptionKey.cost]: null,
          },
          {
            [ExcursionOptionKey.name]: 'No Preference',
            [ExcursionOptionKey.cost]: null,
          },
        ],
      },
    ];
  };

  private setDefaultGenTimePreferences = () => {
    this.formData[ExcursionKeys.preferences] = [
      {
        [ExcursionPreferenceKeys.label]: 'Preferences for a therapist',
        [ExcursionPreferenceKeys.selectionType]: ExcursionOptionsType.single,
        [ExcursionPreferenceKeys.instructions]: 'Provide information about preferences for a male or female specialist',
        [ExcursionPreferenceKeys.items]: [
          {
            [ExcursionOptionKey.name]: 'Males',
            [ExcursionOptionKey.cost]: null,
          },
          {
            [ExcursionOptionKey.name]: 'Female',
            [ExcursionOptionKey.cost]: null,
          },
          {
            [ExcursionOptionKey.name]: 'No Preference',
            [ExcursionOptionKey.cost]: null,
          },
        ],
      },
    ];
  };

  private setDefaultSpaConfigurations = () => {
    this.formData[ExcursionKeys.configuration] = [
      {
        [ExcursionConfigurationKeys.label]: 'SPA Appointment Time',
        [ExcursionConfigurationKeys.instructions]: 'Provide SPA Appointment Time',
        [ExcursionConfigurationKeys.dataType]: ExcursionDataType.time,
      },
      {
        [ExcursionConfigurationKeys.label]: 'SPA Confirmation Number',
        [ExcursionConfigurationKeys.instructions]: 'Provide SPA Confirmation Number',
        [ExcursionConfigurationKeys.dataType]: ExcursionDataType.string,
      },
    ];
  };

  private setDefaultGolfConfigurations() {
    this.formData[ExcursionKeys.configuration] = [
      {
        [ExcursionConfigurationKeys.label]: 'Golf Or Pickleball Team Name',
        [ExcursionConfigurationKeys.instructions]: 'Provide information about Golf Or Pickleball teams',
        [ExcursionConfigurationKeys.dataType]: ExcursionDataType.string,
      },
    ];
  }
}
