<div #attendeeChangeLogContainer class="h-100">
  <dx-data-grid
    #attendeeChangeLogGrid
    class="ag-crm-attendee-change-log__grid"
    [dataSource]="dataSource"
    [showBorders]="false"
    [remoteOperations]="true"
    [syncLookupFilterValues]="false"
    [columnAutoWidth]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [focusedRowEnabled]="false"
    [focusedColumnIndex]="0"
    [autoNavigateToFocusedRow]="false"
    height="100%"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onRowPrepared)="onRowPrepared($event)"
  >
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder [allowHierarchicalFields]="true"></dxo-filter-builder>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

    <dxo-toolbar>
      <dxi-item name="searchPanel"></dxi-item>

      <dxi-item location="after">
        <div *dxTemplate>
          <button
            type="button"
            class="btn btn-default waves-effect"
            data-toggle="fullscreen"
            (click)="fullscreen(attendeeChangeLogContainer)"
          >
            <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i>
          </button>
        </div>
      </dxi-item>
    </dxo-toolbar>

    <dxi-column dataField="date" dataType="datetime" [format]="dateFormat"></dxi-column>

    <dxi-column dataField="action"></dxi-column>

    <dxi-column dataField="updatedBy" caption="Who Change" cellTemplate="whoChangeCellTmp"></dxi-column>

    <dxi-column
      dataField="updatedFields"
      caption="Updated Fields"
      dataType="string"
      cellTemplate="updatedFieldsCellTemplate"
      [filterOperations]="['contains']"
      [calculateFilterExpression]="updatedFieldsCalcFilterExpr"
    ></dxi-column>

    <dxi-column
      dataField="wasabiPath"
      caption="Wasabi Data"
      [allowFiltering]="true"
      [visible]="false"
      [filterValues]="[[['wasabiPath', 'contains', conferenceDbId], 'and', ['wasabiPath', 'contains', registrantDbId]]]"
    ></dxi-column>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <div *dxTemplate="let masterDetails of 'detail'">
      <ng-container *ngIf="masterDetails.data as data">
        <ng-container *var="data.documentIds?.registrantGuestDbId as guestId">
          <ng-container *ngIf="data.wasabiPath | wasabiFile | changeLogsDiff : !!guestId | async as changeLogs">
            <ng-container *ngIf="!guestId; else guestsLogs">
              <ag-crm-rsvp-change-log
                *ngIf="changeLogs | logsByKey : RSVP_SEARCH_KEYS_CONFIG as logs"
                [changeLogs]="logs"
              ></ag-crm-rsvp-change-log>

              <ag-crm-personal-info-change-log
                *ngIf="changeLogs | logsByKey : PERSONAL_INFO_SEARCH_KEYS_CONFIG as logs"
                [changeLogs]="logs"
              ></ag-crm-personal-info-change-log>

              <ag-crm-excursions-change-log
                *ngIf="changeLogs?.[RegistrantKeys.selectedExcursions] as logs"
                [changeLogs]="getChangedLog(logs)"
                [attendeeName]="registrantDbId | lookupDescriptionText : (agentsLookup$ | async)"
              ></ag-crm-excursions-change-log>

              <ag-crm-flight-info-change-log
                *ngIf="changeLogs?.[RegistrantKeys.flightInformation] as logs"
                [changeLogs]="getChangedLog(logs)"
                [agentsLookup]="agentsLookup$ | async"
                [attendeeName]="registrantDbId | lookupDescriptionText : (agentsLookup$ | async)"
              ></ag-crm-flight-info-change-log>

              <ag-crm-hotel-reservation-change-log
                *ngIf="changeLogs?.[RegistrantKeys.hotelReservation] as logs"
                [changeLogs]="getChangedLog(logs)"
                [agentsLookup]="agentsLookup$ | async"
                [attendeeName]="registrantDbId | lookupDescriptionText : (agentsLookup$ | async)"
              ></ag-crm-hotel-reservation-change-log>
            </ng-container>

            <ng-template #guestsLogs>
              <ng-container *var="guestId | lookupDescriptionText : (registrantGuestsLookup$ | async) as guestName">
                <ag-crm-guests-change-log
                  [action]="data.action"
                  [guestName]="guestName"
                  [changeLogs]="getChangedLog(changeLogs) | logsByKey : GUESTS_SEARCH_KEYS_CONFIG"
                ></ag-crm-guests-change-log>

                <ng-container *ngIf="changeLogs?.[GuestKeys.selectedExcursions] as logs">
                  <ag-crm-excursions-change-log
                    *ngIf="hasSelectedExcursion(logs)"
                    [changeLogs]="getChangedLog(logs)"
                    [attendeeName]="guestName"
                  ></ag-crm-excursions-change-log>
                </ng-container>

                <ng-container *ngIf="changeLogs?.[GuestKeys.flightInformation] as logs">
                  <ag-crm-flight-info-change-log
                    [changeLogs]="getChangedLog(logs)"
                    [agentsLookup]="registrantGuestsLookup$ | async"
                    [attendeeName]="guestName"
                  ></ag-crm-flight-info-change-log>
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'whoChangeCellTmp'">
      <ng-container *ngIf="cell.value | agent : 'p_agent_name' | async as fullName">
        {{ fullName }}
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'updatedFieldsCellTemplate'">
      <ng-container *ngFor="let item of [cell.value]">
        <p class="ag-crm-attendee-change-log__grid-row text-wrap" *var="item | changeLogFieldsName as fields">
          <span [innerHTML]="fields | highlightFilterSearchTerm : (filterPanelText$ | async)"></span>
        </p>
      </ng-container>
    </div>
  </dx-data-grid>
</div>
