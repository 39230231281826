import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../attendee-change-log-detail/attendee-change-log-detail.module';
import { ChangeLogExcursionConfigurationsModule } from './configurations/change-log-excursion-configurations.module';
import { AttendeeExcursionsLogDetailsComponent } from './attendee-excursions-log-details.component';
import { ChangeLogExcursionPreferencesModule } from './preferences/change-log-excursion-preferences.module';
import { ChangeLogPipesModule } from '../pipes/change-log-pipes.module';
import { AttendeeChangeLogDetailByTypeModule } from '../attendee-change-log-detail-by-type/attendee-change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeeExcursionsLogDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    AttendeeChangeLogDetailModule,
    ChangeLogExcursionConfigurationsModule,
    ChangeLogExcursionPreferencesModule,
    ChangeLogPipesModule,
    AttendeeChangeLogDetailByTypeModule,
  ],
  exports: [AttendeeExcursionsLogDetailsComponent],
})
export class AttendeeExcursionsLogDetailsModule {}
