import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeTransactionsComponent } from './attendee-transactions.component';
import { DxScrollViewModule, DxTabPanelModule, DxValidationSummaryModule, DxValidatorModule } from 'devextreme-angular';
import { ExcursionModalModule } from '../../excursion-modal/excursion-modal.module';
import {
  AgStripeFormModule,
  AttendeeTransactionModalModule,
  HtmlEditorModule,
  ModalWindowModule,
  TransactionsHistoryModule,
} from 'ag-common-svc/lib/components';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeTransactionsService } from './attendee-transactions.service';

@NgModule({
  declarations: [AttendeeTransactionsComponent],
  exports: [AttendeeTransactionsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ExcursionModalModule,
    TransactionsHistoryModule,
    DxScrollViewModule,
    DxTabPanelModule,
    DxValidationSummaryModule,
    HtmlEditorModule,
    ModalWindowModule,
    DxValidatorModule,
    AgStripeFormModule,
    AttendeeTransactionModalModule,
  ],
  providers: [AttendeeTransactionsService],
})
export class AttendeeTransactionsModule {}
