import { Pipe, PipeTransform } from '@angular/core';
import {
  ATTENDEE_DETAIL_MODAL_SECTIONS_CONFIGURATION,
  AttendeeDetailsModalSection,
} from './attendee-details-modal.model';

@Pipe({
  name: 'attendeeDetailsSectionDescription',
})
export class AttendeeDetailsSectionDescriptionPipe implements PipeTransform {
  transform(sectionId: AttendeeDetailsModalSection) {
    return ATTENDEE_DETAIL_MODAL_SECTIONS_CONFIGURATION.find(({ id }) => id === sectionId)?.title;
  }
}
