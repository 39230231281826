import { Component, Input, HostBinding, ViewChild } from '@angular/core';
import {
  BaseModelKeys,
  Conference,
  ConferenceKeys,
  Constants,
  DX_USD_CURRENCY_FORMAT,
  Entity, EntityPermissionActivityKeys
} from 'ag-common-lib/public-api';
import { CONFERENCES_TITLE_LIST } from '../../../config/conferences.config';
import { Excursion, ExcursionKeys } from 'ag-common-lib/lib/models/registration/excursion.model';
import { ExcursionModalComponent } from '../../excursion-modal/excursion-modal.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { format, isSameDay } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { ConferenceService } from 'ag-common-svc/public-api';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';
import * as uuid from 'uuid';
@Component({
  selector: 'ag-crm-conference-excursions-form',
  templateUrl: './conference-excursions-form.component.html',
  styleUrls: ['./conference-excursions-form.component.scss'],
})
export class ConferenceExcursionsFormComponent {
  @HostBinding('class') className = 'conference-excursions-form';
  @ViewChild('conferenceModalRef') excursionModalComponent: ExcursionModalComponent;
  @ViewChild('excursionsGridTmp') excursionsGridComponent: DxDataGridComponent;
  @Input() conference: Conference;
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly ExcursionKeys = ExcursionKeys;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private toastrService: ToastrService, private conferenceService: ConferenceService) {}

  protected showAddPopup = () => {
    this.excursionModalComponent.showModal({} as any, this.conference?.[BaseModelKeys.dbId], this.onRowAdding);
  };

  protected showCopyPopup = ({ row: { data } }) => {
    const copy = Object.assign({}, data, { [ExcursionKeys.date]: null, [ExcursionKeys.id]: uuid.v4() });
    this.excursionModalComponent.showModal(copy, this.conference?.[BaseModelKeys.dbId], this.onRowAdding);
  };

  protected showDetailsPopup = ({ row: { data } }) => {
    this.excursionModalComponent.showModal(data, this.conference?.[BaseModelKeys.dbId], null);
  };

  protected showEditPopup = ({ row: { data } }) => {
    this.excursionModalComponent.showModal(data, this.conference?.[BaseModelKeys.dbId], this.onRowUpdate);
  };

  private onRowAdding = async (data: Excursion) => {
    const excursions = this.conference?.[ConferenceKeys.excursions];
    const isUniq = excursions?.every(excursion => !this.checkIsEqualExcursion(excursion, data));

    if (!isUniq) {
      this.toastrService.error(
        `The "${data?.[ExcursionKeys.name]}" excursion already exists on ${format(
          data?.[ExcursionKeys.date],
          this.dateFormat,
        )}!`,
      );
      return false;
    }

    return this.addRow(data);
  };

  private onRowUpdate = async (data: Excursion) => {
    const excursions = this.conference?.[ConferenceKeys.excursions];
    const isUniq = excursions?.every(
      excursion =>
        data?.[ExcursionKeys.id] === excursion?.[ExcursionKeys.id] || !this.checkIsEqualExcursion(excursion, data),
    );

    if (!isUniq) {
      this.toastrService.error(
        `The "${data?.[ExcursionKeys.name]}" excursion already exists on ${format(
          data?.[ExcursionKeys.date],
          this.dateFormat,
        )}!`,
      );
      return false;
    }

    return this.updateRow(data);
  };

  private addRow = async (data: Excursion) => {
    const excursion = Object.assign({}, data);
    const activities = [excursion];

    this.conference[ConferenceKeys.excursions]?.forEach(excursion => {
      const activity = Object.assign({}, excursion);

      activities.push(activity);
    });

    return this.saveChanges(activities);
  };

  private updateRow = async (updates: Excursion) => {
    const activities = [];

    this.conference[ConferenceKeys.excursions]?.forEach(excursion => {
      const activity = Object.assign({}, excursion);
      if (updates?.[ExcursionKeys.id] === excursion?.[ExcursionKeys.id]) {
        Object.assign(activity, updates);
      }
      activities.push(activity);
    });

    return this.saveChanges(activities);
  };

  protected onRowRemoving = (e: RowRemovingEvent) => {
    const activities = [];

    this.conference[ConferenceKeys.excursions]?.forEach(excursion => {
      if (e.key !== excursion) {
        const activity = Object.assign({}, excursion);
        activities.push(activity);
      }
    });

    e.cancel = this.saveChanges(activities);
  };

  private checkIsEqualExcursion = (left: Excursion, right: Excursion) => {
    return (
      left?.[ExcursionKeys.name]?.trim()?.toLocaleLowerCase() ===
        right?.[ExcursionKeys.name]?.trim()?.toLocaleLowerCase() &&
      isSameDay(left?.[ExcursionKeys.date], right?.[ExcursionKeys.date])
    );
  };

  private saveChanges = async (activities: Excursion[]) => {
    const conferenceDbId = this.conference?.[BaseModelKeys.dbId];

    if (!conferenceDbId) {
      Object.assign(this.conference, { [ConferenceKeys.excursions]: activities });
      return false;
    }

    return this.conferenceService.updateFields(conferenceDbId, { [ConferenceKeys.excursions]: activities }).then(() => {
      this.conference[ConferenceKeys.excursions].length = 0;
      this.conference[ConferenceKeys.excursions].push(...activities);

      return false;
    });
  };
}
