<ag-shr-image-with-loader
  class="profile-picture__image"
  [isRound]="isRound"
  [borderRounded]="!isRound"
  [withBorder]="!isRound"
  [wasabiPath]="headshot?.wasabiPath"
  [cropperPayload]="headshot?.imageCropperPayload"
  [mediaSize]="MediaSize.origin"
></ag-shr-image-with-loader>

<dx-drop-down-button
  *ngIf="showDropDownMenu && canEdit; else buttonsTemp"
  class="agent-campaign-picture__button"
  [showArrowIcon]="false"
  [dropDownOptions]="{ width: 230 }"
  icon="edit"
  stylingMode="text"
  [disabled]="isReadOnly"
  [items]="[
    { icon: 'mdi mdi-upload', text: 'Add Picture', onClick: onUploadClicked, visible: !headshot?.wasabiPath && canAdd },
    {
      icon: 'mdi mdi-upload',
      text: 'Change Picture',
      onClick: onUploadClicked,
      visible: headshot?.wasabiPath && canEdit
    },
    {
      icon: 'mdi mdi-pencil',
      text: 'Edit Thumbnail',
      onClick: handleCropImage,
      visible: headshot?.wasabiPath && canEdit
    }
  ]"
>
</dx-drop-down-button>

<ng-template #buttonsTemp>
  <div class="d-flex flex-column align-items-center ms-1">
    <dx-button
      *ngIf="canEdit"
      class="agent-campaign-picture__button"
      icon="edit"
      stylingMode="text"
      [disabled]="isReadOnly"
      type="default"
      [hint]="headshot?.wasabiPath ? 'Change Picture' : 'Add Picture'"
      (onClick)="onUploadClicked()"
    ></dx-button>

    <dx-button
      *ngIf="headshot?.wasabiPath && canEdit"
      class="agent-campaign-picture__button"
      icon="agi-crop"
      stylingMode="text"
      [disabled]="isReadOnly"
      type="default"
      [hint]="'Crop Picture'"
      (onClick)="handleCropImage()"
    ></dx-button>
  </div>
</ng-template>

<ag-shr-media-uploader
  [mediaPathPrefix]="mediaPathPrefix"
  (mediaChanged)="onMediaChanged($event)"
  [mediaItems]="mediaItems"
  [mediaSources]="mediaSources"
></ag-shr-media-uploader>

<ag-shr-image-cropper-modal
  [roundCropper]="isRound"
  [onImageCropped]="onImageCropped"
  [title]="'Edit Thumbnail'"
></ag-shr-image-cropper-modal>
