<div
  class="notification-channel-select__container"
  [class.notification-channel-select__container--read-only]="isReadOnly"
  *var="dataSource$ | async as dataSource"
>
  <ng-container *var="parts$ | async as parts">
    <dx-select-box
      [value]="parts?.[0]"
      [dataSource]="dataSource | notificationChannelDataSource"
      [valueExpr]="BaseModelKeys.dbId"
      [displayExpr]="PushNotificationChannelsKeys.name"
      (onSelectionChanged)="handleSelectPathPart($event)"
      [placeholder]="label"
      [stylingMode]="'underlined'"
      [showClearButton]="true"
      [readOnly]="isReadOnly"
    >
      <dx-validator [validationGroup]="validationGroup">
        <dxi-validation-rule *ngIf="isRequired" type="required" message="Required to Select"></dxi-validation-rule>
      </dx-validator>

      <div *dxTemplate="let data of 'item'">
        <ng-container *ngTemplateOutlet="itemTmp; context: { data }"></ng-container>
      </div>
    </dx-select-box>

    <ng-container *ngFor="let part of parts; let index = index">
      <ng-container *var="dataSource | notificationChannelDataSource : part as items">
        <div *ngIf="items?.length" class="notification-channel-select__part-container">
          <span class="notification-channel-select__divider">/</span>
          <dx-select-box
            [value]="parts?.[index + 1]"
            [dataSource]="items"
            [valueExpr]="BaseModelKeys.dbId"
            [displayExpr]="PushNotificationChannelsKeys.name"
            (onSelectionChanged)="handleSelectPathPart($event, part)"
            [placeholder]="'Root'"
            [stylingMode]="'underlined'"
            [showClearButton]="true"
            [readOnly]="isReadOnly"
          >
            <div *dxTemplate="let data of 'item'">
              <ng-container *ngTemplateOutlet="itemTmp; context: { data }"></ng-container>
            </div>
          </dx-select-box>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #itemTmp let-data="data">
  <div class="notification-channel-select__item-name">
    {{ data?.[PushNotificationChannelsKeys.name] }}
  </div>
  <div
    *ngIf="data?.[PushNotificationChannelsKeys.description] as description"
    class="notification-channel-select__item-description"
  >
    {{ description }}
  </div>
</ng-template>
