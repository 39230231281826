import { Pipe, PipeTransform } from '@angular/core';
import { AgentCampaignChanges, AgentCampaignStepName } from 'ag-common-lib/public-api';

@Pipe({ name: 'campaignsDetailsModalIsStepDone' })
export class CampaignsDetailsModalIsStepDonePipe implements PipeTransform {
  transform(changes: AgentCampaignChanges, stepName: AgentCampaignStepName): boolean {
    switch (stepName) {
      case AgentCampaignStepName.contactInfo:
        return [
          changes[AgentCampaignStepName.contactInfoAddress]?.isStepDone,
          changes[AgentCampaignStepName.contactInfoPhoneNumber]?.isStepDone,
          changes[AgentCampaignStepName.contactInfoEmailAddress]?.isStepDone,
        ].every(Boolean);

      default:
        return changes[stepName]?.isStepDone ?? false;
    }
  }
}
