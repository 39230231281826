<div class="container p-0 m-0">
  <div class="row p-1 m-0 justify-content-between">
    <div class="col-12 p-0 m-0 align-self-center text-start">
      <div class="row p-1 m-0 justify-content-between">
        <div class="col-5 p-1 m-0 align-self-center text-start">
          To: <dx-text-box [(value)]="email.to[0].email_address" [disabled]="isRecipientSet"></dx-text-box>
        </div>
        <div class="col-5 p-1 m-0 align-self-center text-start">
          From: <dx-select-box [items]="from_list" [(value)]="email.from" displayExpr="email_address"></dx-select-box>
        </div>
        <div class="col-2 p-1 m-0 align-self-center text-end">
          <dx-button (onClick)="showCc = !showCc">Cc</dx-button>
          <dx-button (onClick)="showBcc = !showBcc">Bcc</dx-button>
        </div>
      </div>
      <div *ngIf="showCc || showBcc" class="row p-1 m-0 justify-content-between">
        <div *ngIf="showCc" class="col-5 p-1 m-0 align-self-center text-start">
          <!-- Seems its should be tag-box or something equal -->
          Cc: <dx-text-box [value]="showList(email.cc)"></dx-text-box>
        </div>
        <div *ngIf="showBcc" class="col-5 p-1 m-0 align-self-center text-start">
          <!-- Seems its should be tag-box or something equal -->
          Bcc: <dx-text-box [value]="showList(email.bcc)"></dx-text-box>
        </div>
      </div>
      <div class="row p-1 m-0 justify-content-between">
        <dx-select-box
          [dataSource]="emailTemplateList$ | async"
          [displayExpr]="'name'"
          [(value)]="selectedTemplate"
          (onSelectionChanged)="selectTemplate($event)"
        >
        </dx-select-box>
      </div>
      <div class="row p-1 m-0 justify-content-between">
        <div class="col-12 p-0 m-0 align-self-center text-start">
          <div *ngIf="selectedTemplate && !showEmailForm" [innerHtml]="selectedTemplate.body"></div>
          <app-email-template
            *ngIf="selectedTemplate && showEmailForm"
            [emailTemplate]="selectedTemplate"
            [showButtons]="false"
          ></app-email-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex mt-2 justify-content-end" style="margin: 0 auto">
    <div class="col-lg-4 text-end">
      <dx-button
        width="200"
        stylingMode="contained"
        [text]="canSend ? 'Send' : 'Save'"
        type="default"
        (onClick)="save()"
      ></dx-button>
    </div>
    <div class="col-lg-4 text-end">
      <dx-button width="200" stylingMode="contained" text="Close" type="default" (onClick)="close()"></dx-button>
    </div>
  </div>
</div>
