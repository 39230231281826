<ng-container>
  <app-side-nav-outer-toolbar title="{{ appInfo.title }}">
    <router-outlet></router-outlet>
    <app-footer>
      Copyright © 2011-{{ appInfo.currentYear }} {{ appInfo.title }} Inc.
      <br>
      All trademarks or registered trademarks are property of their respective owners.
    </app-footer>
  </app-side-nav-outer-toolbar>
</ng-container>

<ag-shr-idle></ag-shr-idle>
