import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AddressModelKeys,
  Agency,
  AgencyKeys,
  Agent,
  AgentKeys,
  AssociationKeys,
  AT_LEISURE_EXCURSION_SUFFIX,
  ATTENDEE_WIZARD_STATE_LOOKUP,
  AttendeeKeys,
  AttendeeType,
  AttendeeTypeMap,
  Conference,
  CONFERENCE_YEARS_LIST,
  ConferenceKeys,
  ConferenceRegistrationCategoryName,
  ConferenceRegistrationCommonTaskStatusMap,
  ConferenceRegistrationStepName,
  ConferenceStepsConfigurationKeys,
  DietaryConsiderationKeys,
  ExcursionKeys,
  GuestData,
  GuestKeys,
  Headshot,
  HotelReservationKeys,
  InviteeAgeGroupMap,
  InviteeStatus,
  InviteeStatusMap,
  LocalDateTimeString,
  LookupKeys,
  MMDDYYYY_DATE_FORMAT,
  PhoneNumber,
  QualifiedAsMap,
  Registrant,
  RegistrantData,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrationTypeMap,
  ReportSummary,
  RequestOutcome,
  RequestOutcomeKeys,
  RequestOutcomeStateMap,
  Role,
  SelectedExcursions,
  SelectedExcursionsKeys,
  TShirtSizesKeys,
  YEARS_LIST,
} from 'ag-common-lib/public-api';
import {
  AgencyService,
  AgentService,
  ConferenceService,
  localDateTimeFromTimestamp,
  LookupsService,
} from 'ag-common-svc/public-api';
import { Workbook, Worksheet } from 'exceljs';
import saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { at, get, groupBy, has, isEmpty, isNil } from 'lodash';
import { ReportSummaryService } from '../../shared/services/reports.service';
import { compareAsc, differenceInYears, format, getQuarter, isDate, toDate } from 'date-fns';
import { shareReplay } from 'rxjs/operators';
import { getPhoneNumber } from 'ag-common-svc/lib/utils/phone-number-data.utils';
import {
  FlightBookingKeys,
  FlightBookingStatusMap,
  FlightInfoKeys,
  FlightInformationModel,
} from 'ag-common-lib/lib/models/registration/flight-information.model';
import { FlightReservationReportKeys } from './attendee-export.model';
import { WasabiImgPipe } from 'ag-common-svc/shared/pipes/wasabi-img.pipe';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';
import { getConfigurationDescription } from 'ag-common-svc/lib/utils/excursions-data.utils';
import { MilitaryTimeFormatPipe } from 'ag-common-svc/shared/pipes/military-time-format.pipe';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import { LoadOptions } from 'devextreme/data';
import { getAgentFullName } from 'ag-common-svc/lib/services/agent.service/agent-service-utils';
import { TravelMode } from '@ag-common-lib/public-api';

export enum RegistrantsExportType {
  nameBadges = 'nameBadges',
  awardName = 'awardName',
  shirtSize = 'shirtSize',
  dietaryConsideration = 'dietaryConsideration',
  productionByYear = 'productionByYear',
  productionByQuarter = 'productionByQuarter',
  hotelReservation = 'hotelReservation',
  shippingReport = 'shippingReport',
  flightReservationReport = 'flightReservationReport',
  flightInfoReport = 'flightInfoReport',
  headshotExport = 'headshotExport',
  phoneNumbers = 'phoneNumbers',
  hotelCheckIn = 'hotelCheckIn',
  hotelCheckInDateGrouped = 'hotelCheckInDateGrouped',
  drivingReport = 'drivingReport',
}

export class HotelReservationReportRowPayload {
  registrant: Registrant;
  guests: GuestData[] = [];
}

export class HotelCheckInReportRowPayload {
  checkInDate: LocalDateTimeString;
  registrant: Registrant;
  guests: GuestData[] = [];
}

export class AttendeeReportRowPayload {
  registrant: Attendee;
  guests: Attendee[] = [];
}

export class ShippingReportRowPayload {
  registrant: Registrant;
  guests: GuestData[] = [];
}

interface InviteeHeadshot extends Headshot {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AttendeesExportService {
  availableReports: ReportConfig[] = [
    { title: 'Badge Names', reportType: RegistrantsExportType.nameBadges },
    { title: 'Award Names', reportType: RegistrantsExportType.awardName },
    { title: 'Dietary Considerations', reportType: RegistrantsExportType.dietaryConsideration },
    { title: 'Production By Year', reportType: RegistrantsExportType.productionByYear },
    { title: 'Production By Quarter', reportType: RegistrantsExportType.productionByQuarter },
    { title: 'Hotel Reservation', reportType: RegistrantsExportType.hotelReservation },
    { title: 'Shirt Size', reportType: RegistrantsExportType.shirtSize },
    { title: 'Shipping Report', reportType: RegistrantsExportType.shippingReport },
    { title: 'Flight Info Report', reportType: RegistrantsExportType.flightInfoReport },
    { title: 'Flight Reservation Report', reportType: RegistrantsExportType.flightReservationReport },
    { title: 'Driving Info Report', reportType: RegistrantsExportType.drivingReport },
    { title: 'Headshot Export', reportType: RegistrantsExportType.headshotExport },
    { title: 'Phone Numbers Report', reportType: RegistrantsExportType.phoneNumbers },
    { title: 'Hotel Check-In Report', reportType: RegistrantsExportType.hotelCheckIn },
    { title: 'Hotel Check-In Report (Grouped By Date)', reportType: RegistrantsExportType.hotelCheckInDateGrouped },
  ];

  readonly dateFormat = MMDDYYYY_DATE_FORMAT;
  protected readonly attendeeWizardStateLookup = ATTENDEE_WIZARD_STATE_LOOKUP;
  protected readonly agentsLoadOptions: LoadOptions<Agent> = {
    filter: [{ terms: { [AgentKeys.role]: [Role.CONFERENCE_MANAGER] } }],
  };

  private readonly agenciesMap = new Map();

  private _agentSummaries = new Map<string, Map<number, ReportSummary>>();
  private _conference$: Observable<Conference>;

  exportInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(
    private datePipe: DatePipe,
    private conferenceService: ConferenceService,
    private lookupsService: LookupsService,
    private agencyService: AgencyService,
    private toastrService: ToastrService,
    private reportSummaryService: ReportSummaryService,
    private wasabiImgPipe: WasabiImgPipe,
    private cloudFunctionsService: CloudFunctionsService,
    private militaryTimeFormatPipe: MilitaryTimeFormatPipe,
    private agentService: AgentService,
  ) {
    this.fetchAndStoreAgencyInfo();
  }

  runCustomExport(
    conferenceDbId: string,
    exportType: RegistrantsExportType,
    attendees: Attendee[],
    reportYears?: Array<Date | number | string>,
  ): void {
    if (!exportType) {
      this.toastrService.error('Please Select an Export Type');
      return;
    }

    this._conference$ = this.conferenceService.getDocumentData(conferenceDbId).pipe(shareReplay(1));

    switch (exportType) {
      case RegistrantsExportType.nameBadges:
        this.getNameBadgesReport(attendees);
        return;
      case RegistrantsExportType.awardName:
        this.getAwardNameReport(attendees);
        return;
      case RegistrantsExportType.dietaryConsideration:
        this.getDietaryConsiderationReport(attendees);
        return;
      case RegistrantsExportType.hotelReservation:
        this.getHotelReservationReport(attendees);
        return;

      case RegistrantsExportType.hotelCheckInDateGrouped:
        this.getHotelCheckInReport(attendees, true);
        return;
      case RegistrantsExportType.hotelCheckIn:
        this.getHotelCheckInReport(attendees, false);
        return;
      case RegistrantsExportType.flightReservationReport:
        this.getFlightReservationReport(attendees);
        return;
      case RegistrantsExportType.flightInfoReport:
        this.getFlightInfoReport(attendees);
        return;
      case RegistrantsExportType.shirtSize:
        this.getShirtSizeReport(attendees);
        return;
      case RegistrantsExportType.productionByYear:
        this.getProductionByYearReport(attendees);
        return;
      case RegistrantsExportType.productionByQuarter:
        this.getProductionByQuarterReport(attendees, reportYears);
        return;
      case RegistrantsExportType.shippingReport:
        this.getShippingReport(attendees);
        return;
      case RegistrantsExportType.headshotExport:
        this.getHeadshotExport(attendees);
        return;
      case RegistrantsExportType.phoneNumbers:
        this.getPhoneNumbersReport(attendees);
        return;
      case RegistrantsExportType.drivingReport:
        this.getDrivingReport(attendees);
        return;
    }
  }

  async getShirtSizeReport(attendees: Attendee[]) {
    let guestsMax = 0;

    const rowsMap = new Map<string, AttendeeReportRowPayload>();

    attendees.forEach(attendee => {
      const inviteeStatus = attendee[AttendeeKeys.inviteeStatus];

      if (inviteeStatus === InviteeStatus.declined) {
        return;
      }

      if (!rowsMap.has(attendee?.[AttendeeKeys.inviteeEmail])) {
        rowsMap.set(attendee?.[AttendeeKeys.inviteeEmail], new AttendeeReportRowPayload());
      }

      const payload = rowsMap.get(attendee?.[AttendeeKeys.inviteeEmail]);
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee) {
        payload.registrant = attendee;
      }
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Guest) {
        payload.guests.push(attendee);
        guestsMax = Math.max(guestsMax, payload.guests?.length);
      }
    });

    const columns = [];

    const config = await this.getShirtSizesExportConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    filteredConfig.forEach(({ header, dataKey, headerKey, enable }) => {
      if (!enable && !isNil(enable)) {
        return;
      }
      columns.push({ header, key: headerKey ?? dataKey ?? header });
    });

    const rows = [];

    for (const [_, value] of rowsMap) {
      await this.handleAttendeeRows(value, filteredConfig, rows);
    }

    this.getExcelReport({
      rows,
      columns,
      workSheetName: 'Shirt Sizes',
      reportName: `Shirt Sizes ${new Date().toLocaleDateString()}`,
    });
  }

  private fetchAndStoreAgencyInfo(): void {
    this.agencyService.getList().subscribe(agencies => {
      agencies.forEach(agency => this.storeAgencyInfo(agency));
    });
  }

  private storeAgencyInfo(agency: Agency): void {
    const agencyId = agency?.[AgencyKeys.agencyId];
    const agencyName = agency?.[AgencyKeys.name];
    this.agenciesMap.set(agencyId, agencyName);
  }

  private async getProductionSummaryByAgentId(agentId): Promise<Map<number, ReportSummary>> {
    if (!agentId) {
      return null;
    }
    if (this._agentSummaries.has(agentId)) {
      return this._agentSummaries.get(agentId);
    }
    const agentProductionSummary: Map<number, ReportSummary> = new Map<number, ReportSummary>();
    const summaries = await this.reportSummaryService.getAgentSummaryByAgentIdAndYear(agentId, YEARS_LIST, 'year');
    summaries.forEach(summary => {
      if (!summary) {
        return;
      }
      agentProductionSummary.set(summary.year, summary);
    });

    this._agentSummaries.set(agentId, agentProductionSummary);
    return agentProductionSummary;
  }

  private getSummaryByYear(agentId: string, year: number) {
    if (!agentId) {
      return 0;
    }
    const agentSummaries = this._agentSummaries.get(agentId);
    const summaries: ReportSummary = agentSummaries?.get(year);

    const summary =
      summaries?.revenueStatsMonth
        ?.map(revenueStats => Number(revenueStats.premiumTotal))
        ?.reduce(function (acc, subSum) {
          return acc + subSum;
        }, 0) ?? 0;

    return summary;
  }

  private getSummaryByQuarter(agentId, year: number, quarter: number) {
    if (!agentId) {
      return 0;
    }
    const agentSummaries = this._agentSummaries.get(agentId);
    const summaries: ReportSummary = agentSummaries?.get(year);

    const months = new Array(3).fill(null).map((_, index) => {
      const mountsInQuarter = 3;
      return mountsInQuarter * quarter - mountsInQuarter + index;
    });

    const qSummaries = summaries?.revenueStatsMonth?.filter(summary => months?.includes(summary.month));

    const summary = qSummaries
      ?.map(qSummary => Number(qSummary.premiumTotal))
      ?.reduce(function (acc, subSum) {
        return acc + subSum;
      }, 0);

    return summary?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  private async getNameBadgesReport(attendees: Attendee[]): Promise<void> {
    let guestsMax = 0;

    const rowsMap = new Map<string, AttendeeReportRowPayload>();

    attendees.forEach(attendee => {
      const inviteeStatus = attendee[AttendeeKeys.inviteeStatus];

      if (inviteeStatus === InviteeStatus.declined) {
        return;
      }

      if (!rowsMap.has(attendee?.[AttendeeKeys.inviteeEmail])) {
        rowsMap.set(attendee?.[AttendeeKeys.inviteeEmail], new AttendeeReportRowPayload());
      }

      const payload = rowsMap.get(attendee?.[AttendeeKeys.inviteeEmail]);
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee) {
        payload.registrant = attendee;
      }
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Guest) {
        payload.guests.push(attendee);
        guestsMax = Math.max(guestsMax, payload.guests?.length);
      }
    });

    const columns = [];

    const config = await this.getNameBadgesConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    filteredConfig.forEach(({ header, dataKey }) => {
      columns.push({ header, key: dataKey ?? header });
    });

    const rows = [];

    for (const [_, value] of rowsMap) {
      await this.handleAttendeeRows(value, filteredConfig, rows);
    }

    this.getExcelReport({
      rows,
      columns,
      workSheetName: '`Badge Names`',
      reportName: `Badge Names ${new Date().toLocaleDateString()}`,
      format: 'csv',
    });
  }

  private async getDietaryConsiderationReport(attendees: Attendee[]): Promise<void> {
    let guestsMax = 0;

    const rowsMap = new Map<string, AttendeeReportRowPayload>();

    attendees.forEach(attendee => {
      const inviteeStatus = attendee[AttendeeKeys.inviteeStatus];

      if (inviteeStatus === InviteeStatus.declined) {
        return;
      }

      if (!rowsMap.has(attendee?.[AttendeeKeys.inviteeEmail])) {
        rowsMap.set(attendee?.[AttendeeKeys.inviteeEmail], new AttendeeReportRowPayload());
      }

      const payload = rowsMap.get(attendee?.[AttendeeKeys.inviteeEmail]);
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee) {
        payload.registrant = attendee;
      }
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Guest) {
        payload.guests.push(attendee);
        guestsMax = Math.max(guestsMax, payload.guests?.length);
      }
    });

    const columns = [];

    const config = await this.getDietaryConsiderationsConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    filteredConfig.forEach(({ header, dataKey, headerKey }) => {
      columns.push({ header, key: headerKey ?? dataKey ?? header });
    });

    const rows = [];

    for (const [_, value] of rowsMap) {
      await this.handleAttendeeRows(value, filteredConfig, rows);
    }

    this.getExcelReport({
      rows,
      columns,
      workSheetName: '`Dietary Consideration Report`',
      reportName: `Dietary Considerations ${new Date().toLocaleDateString()}`,
    });
  }

  private async handleAttendeeRows(value: any, config: any[], rows: any[]): Promise<void> {
    const attendees = value?.guests ? [value?.registrant, ...value?.guests] : [value?.registrant];
    for (const attendee of attendees) {
      rows.push(await this.constructAttendeeRow(attendee, config));
    }
  }

  private async getAwardNameReport(attendees: Attendee[]): Promise<void> {
    const columns = [];

    const config = await this.getAwardNamesConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    filteredConfig.forEach(({ header, dataKey }) => {
      columns.push({ header, key: dataKey ?? header });
    });

    const rows = [];

    for (const attendee of attendees) {
      const inviteeStatus = attendee[AttendeeKeys.inviteeStatus];

      if (inviteeStatus === InviteeStatus.declined) {
        continue;
      }
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee) {
        rows.push(await this.constructAttendeeRow(attendee, filteredConfig));
      }
    }

    this.getExcelReport({
      rows,
      columns,
      workSheetName: '`Award Names`',
      reportName: `Award Names ${new Date().toLocaleDateString()}`,
    });
  }

  private async getProductionByYearReport(attendees: Attendee[]): Promise<void> {
    const columns = [];

    const config = await this.getProductionByYearConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    filteredConfig.forEach(({ header, dataKey, headerKey, normalizer }) => {
      columns.push({ header, key: headerKey ?? dataKey });
    });
    const promises = attendees
      .filter(
        attendee =>
          attendee?.[AttendeeKeys.inviteeStatus] !== InviteeStatus.declined &&
          attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee,
      )
      .map(attendee =>
        this.getProductionSummaryByAgentId(attendee.registrant[RegistrantModelKeys.data][RegistrantKeys.agentId]),
      );

    await Promise.all(promises);

    const rows = attendees
      .filter(
        attendee =>
          attendee?.[AttendeeKeys.inviteeStatus] !== InviteeStatus.declined &&
          attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee,
      )
      .map(attendee => this.constructProductionRow(attendee, filteredConfig));

    this.getExcelReport({
      rows,
      columns,
      workSheetName: '`Production By Year`',
      reportName: `Production By Year ${new Date().toLocaleDateString()}`,
    });
  }

  private async getProductionByQuarterReport(attendees: Attendee[], reportYears): Promise<void> {
    const columns = [];

    const config = await this.getProductionByQuarterConfig(reportYears);
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    filteredConfig.forEach(({ header, dataKey, headerKey, normalizer }) => {
      columns.push({ header, key: headerKey ?? dataKey });
    });
    const promises = attendees
      .filter(
        attendee =>
          attendee?.[AttendeeKeys.inviteeStatus] !== InviteeStatus.declined &&
          attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee,
      )
      .map(attendee =>
        this.getProductionSummaryByAgentId(attendee.registrant[RegistrantModelKeys.data][RegistrantKeys.agentId]),
      );

    await Promise.all(promises);

    const rows = attendees
      .filter(
        attendee =>
          attendee?.[AttendeeKeys.inviteeStatus] !== InviteeStatus.declined &&
          attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee,
      )
      .map(attendee => this.constructProductionRow(attendee, filteredConfig));

    this.getExcelReport({
      rows,
      columns,
      workSheetName: '`Production By Quarter`',
      reportName: `Production By Quarter ${new Date().toLocaleDateString()}`,
    });
  }

  private async getHotelReservationReport(attendees: Attendee[]): Promise<void> {
    let guestsMax = 0;

    const rowsMap = new Map<string, HotelReservationReportRowPayload>();

    attendees.forEach(attendee => {
      const registrant = attendee?.registrant;
      const registrantData = registrant?.[RegistrantModelKeys.data];
      const inviteeStatus = registrantData[RegistrantKeys.inviteeStatus];
      const inviteeOutcomeStatus = registrantData[RegistrantKeys.inviteeOutcomeStatus];

      if (inviteeStatus === InviteeStatus.declined || inviteeOutcomeStatus === InviteeStatus.cancelled) {
        return;
      }

      if (!rowsMap.has(attendee?.[AttendeeKeys.inviteeEmail])) {
        rowsMap.set(attendee?.[AttendeeKeys.inviteeEmail], new HotelReservationReportRowPayload());
      }

      const payload = rowsMap.get(attendee?.[AttendeeKeys.inviteeEmail]);
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee) {
        payload.registrant = registrant;
      }
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Guest) {
        payload.guests.push(attendee.guest);
        guestsMax = Math.max(guestsMax, payload.guests?.length);
      }
    });

    const config = await this.getHotelReservationConfig(guestsMax);
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    const columns = [];

    filteredConfig.forEach(({ header, dataKey }) => {
      columns.push({ header, key: dataKey ?? header });
    });

    const rows = [];

    for (const value of rowsMap.values()) {
      const registrantData = value?.registrant?.[RegistrantModelKeys.data];
      const guests = value?.guests;
      const row = {
        ['Guests Total']: guests?.length ?? 0,
      };

      for (const { header, dataKey, attendeeType, normalizer, getDynamicValues } of filteredConfig) {
        if (!dataKey) {
          continue;
        }

        const source = attendeeType === AttendeeType.Guest ? guests : registrantData;
        let value = get(source, dataKey);

        if (getDynamicValues) {
          const dynamicValue = await getDynamicValues(value);
          Object.assign(row, dynamicValue);
        }

        if (normalizer) {
          value = normalizer(value);
        }

        Object.assign(row, { [dataKey]: value });
      }

      rows.push(row);
    }

    this.getExcelReport({
      rows,
      columns,
      workSheetName: 'Hotel Check-In',
      reportName: `Hotel Check-In ${new Date().toLocaleDateString()}`,
    });
  }

  private async getHotelCheckInReport(attendees: Attendee[], dateGrouped = false): Promise<void> {
    const rowsMap = new Map<string, HotelCheckInReportRowPayload>();

    attendees.forEach(attendee => {
      const registrant = attendee?.registrant;
      const registrantData = registrant?.[RegistrantModelKeys.data];
      const inviteeStatus = registrantData[RegistrantKeys.inviteeStatus];
      const inviteeOutcomeStatus = registrantData[RegistrantKeys.inviteeOutcomeStatus];

      if (inviteeStatus === InviteeStatus.declined || inviteeOutcomeStatus === InviteeStatus.cancelled) {
        return;
      }

      if (!rowsMap.has(attendee?.[AttendeeKeys.inviteeEmail])) {
        rowsMap.set(attendee?.[AttendeeKeys.inviteeEmail], new HotelCheckInReportRowPayload());
      }

      const payload = rowsMap.get(attendee?.[AttendeeKeys.inviteeEmail]);

      const isInvitee = attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee;
      if (isInvitee) {
        const checkInDate = registrantData?.[RegistrantKeys.hotelReservation]?.[HotelReservationKeys.checkInDate];
        payload.registrant = registrant;
        payload.checkInDate = checkInDate;
      }
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Guest) {
        payload.guests.push(attendee.guest);
      }
    });

    const config = await this.getHotelCheckInConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    const columns = [];

    filteredConfig.forEach(({ header, headerKey, dataKey }) => {
      columns.push({ header, key: headerKey ?? dataKey ?? header });
    });

    const rows = [];

    const getRowData = async (items: HotelCheckInReportRowPayload[]) => {
      const sortedGroupRowsData = items?.sort((left, right) => {
        const leftBadgeName =
          left?.registrant?.[RegistrantModelKeys.data]?.[RegistrantKeys.flightInformation]?.[FlightInfoKeys.lastName];
        const rightBadgeName =
          right?.registrant?.[RegistrantModelKeys.data]?.[RegistrantKeys.flightInformation]?.[FlightInfoKeys.lastName];

        return leftBadgeName?.localeCompare(rightBadgeName, 'en', {
          numeric: true,
          sensitivity: 'base',
          ignorePunctuation: true,
        });
      });
      for (const registrantRowData of sortedGroupRowsData) {
        const registrantData = registrantRowData?.registrant?.[RegistrantModelKeys.data];
        const registrantRow = {
          outlineLevel: dateGrouped ? 1 : 0,
          'Attendee Type': 'Qualifier',
        };

        for (const { dataKey, headerKey, normalizer, getDynamicValues } of filteredConfig) {
          if (!dataKey) {
            continue;
          }

          let value = get(registrantData, dataKey);

          if (getDynamicValues) {
            const dynamicValue = await getDynamicValues(value);
            Object.assign(registrantRow, dynamicValue);
          }

          if (normalizer) {
            value = normalizer(value);
          }

          Object.assign(registrantRow, { [headerKey ?? dataKey]: value });
        }

        rows.push(registrantRow);

        const guests = registrantRowData?.guests;
        const sortedGuests = guests?.sort((left, right) => {
          return left?.[GuestKeys.flightInformation]?.[FlightInfoKeys.lastName]?.localeCompare(
            right?.[GuestKeys.flightInformation]?.[FlightInfoKeys.lastName],
            'en',
            {
              numeric: true,
              sensitivity: 'base',
              ignorePunctuation: true,
            },
          );
        });

        for (const guestRowData of sortedGuests) {
          const guestRow = {
            outlineLevel: dateGrouped ? 2 : 1,
            'Attendee Type': 'Guest',
          };
          for (const { guestDataKey, dataKey, headerKey, normalizer, getDynamicValues } of config) {
            if (!guestDataKey) {
              continue;
            }

            let value = get(guestRowData, guestDataKey);

            if (getDynamicValues) {
              const dynamicValue = await getDynamicValues(value);
              Object.assign(guestRow, dynamicValue);
            }

            if (normalizer) {
              value = normalizer(value);
            }

            Object.assign(guestRow, { [headerKey ?? dataKey]: value });
          }

          rows.push(guestRow);
        }
      }
    };

    if (dateGrouped) {
      const groupedRowsData = groupBy(Array.from(rowsMap.values()), 'checkInDate');
      const sortedGroups = Object.entries(groupedRowsData).sort((left, right) => {
        const leftCheckInData = left[0];
        const rightCheckInDate = right[0];
        return compareAsc(leftCheckInData, rightCheckInDate);
      });

      for (const group of sortedGroups) {
        if (dateGrouped) {
          const groupCheckInDate = group[0];
          const cellsToMerge = { top: rows?.length + 2, bottom: rows?.length + 2, left: 1, right: config?.length };
          const groupRow = {
            [[RegistrantKeys.hotelReservation, HotelReservationKeys.checkInDate].join('.')]:
              this.normalizeDate(groupCheckInDate),
            cellsToMerge: cellsToMerge,
          };

          rows.push(groupRow);
        }

        await getRowData(group[1]);
      }

      this.getExcelReport({
        rows,
        columns,
        workSheetName: 'Hotel reservation',
        reportName: `Hotel Reservation ${new Date().toLocaleDateString()}`,
      });
      return;
    }

    await getRowData(Array.from(rowsMap.values()));

    this.getExcelReport({
      rows,
      columns,
      workSheetName: 'Hotel reservation',
      reportName: `Hotel Reservation ${new Date().toLocaleDateString()}`,
    });
  }

  private async getPhoneNumbersReport(attendees: Attendee[]): Promise<void> {
    const config = await this.getPhoneNumbersReportConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);
    const columns = [];

    filteredConfig.forEach(({ header, dataKey, enable }) => {
      if (!enable && !isNil(enable)) {
        return;
      }
      columns.push({ header, key: dataKey ?? header });
    });

    const rows = [];

    attendees.forEach(attendee => {
      const attendeeType = attendee?.[AttendeeKeys.attendeeType];

      if (attendeeType === AttendeeType.Guest) {
        return;
      }

      const registrant = attendee?.registrant;
      const registrantData = registrant?.[RegistrantModelKeys.data];
      const inviteeStatus = registrantData[RegistrantKeys.inviteeStatus];
      const inviteeOutcomeStatus = registrantData[RegistrantKeys.inviteeOutcomeStatus];

      if (inviteeStatus === InviteeStatus.declined || inviteeOutcomeStatus === InviteeStatus.cancelled) {
        return;
      }

      const row = {};

      for (const { dataKey, normalizer } of filteredConfig) {
        if (!dataKey) {
          continue;
        }

        let value = get(registrantData, dataKey);

        if (normalizer) {
          value = normalizer(value);
        }

        Object.assign(row, { [dataKey]: value });
      }

      rows.push(row);
    });

    this.getExcelReport({
      rows,
      columns,
      workSheetName: 'Phone Numbers',
      reportName: `Phone Numbers ${new Date().toLocaleDateString()}`,
    });
  }

  private async getFlightReservationReport(attendees: Attendee[]): Promise<void> {
    const attendeesReportList = this.getAttendeesFlightInfoData(attendees);
    const config = await this.getFlightReservationConfig(attendeesReportList.legsMax);
    const filteredReservationConfig = config.filter(({ enable }) => enable !== false);

    const columns = filteredReservationConfig.map(({ header, dataKey, headerKey }) => ({
      header,
      key: headerKey ?? dataKey ?? header,
    }));
    const rows = await this.createFlightReportRows(config, attendeesReportList.list);

    this.getExcelReport({
      rows,
      columns,
      workSheetName: 'Flight Reservation',
      reportName: `Flight Reservation ${new Date().toLocaleDateString()}`,
    });
  }

  private async createFlightReportRows(config: ExportConfig[], attendeesReportList) {
    const rows = [];
    for (const attendeeData of attendeesReportList) {
      const row = {};
      for (const { dataKey, headerKey, normalizer, getDynamicValues } of config) {
        let value = dataKey ? get(attendeeData, dataKey) : attendeeData;

        if (getDynamicValues) {
          const dynamicValue = await getDynamicValues(value);
          Object.assign(row, dynamicValue);
          continue;
        }

        if (!dataKey && !headerKey) {
          continue;
        }

        if (normalizer) {
          value = normalizer(value);
        }

        Object.assign(row, { [headerKey ?? dataKey]: value });
      }

      rows.push(row);
    }

    return rows;
  }

  private async getFlightInfoReport(attendees: Attendee[]): Promise<void> {
    const attendeesReportList = this.getAttendeesFlightInfoData(attendees);
    const config = await this.getFlightReservationConfig(null);
    const filteredConfig = config.filter(({ enable }) => enable !== false);
    // Flight Info Report should contain only those attendees who have Travel Mode = Flying AND Book By Own != Yes.
    const filteredAttendeesReportList = attendeesReportList.list.filter(
        item => item?.[RegistrantKeys.flightInformation]?.[FlightInfoKeys.isBookOwnFlights] !== true,
    );

    const columns = filteredConfig.map(({ header, dataKey, headerKey }) => ({
      header,
      key: headerKey ?? dataKey ?? header,
    }));
    const rows = await this.createFlightReportRows(config, filteredAttendeesReportList);

    this.getExcelReport({
      rows,
      columns,
      workSheetName: 'Flight Information',
      reportName: `Flight Information ${new Date().toLocaleDateString()}`,
    });
  }

  private getAttendeesFlightInfoData(attendees: Attendee[]) {
    let legsMax = 0;
    const list = [];
    const filteredAttendees = this.getFilteredAttendeesDateByTravelMode(attendees, TravelMode.flying);

    filteredAttendees.forEach(attendee => {
      const registrantData = attendee?.registrant?.[RegistrantModelKeys.data];
      const registrantTask = attendee?.registrant?.[RegistrantModelKeys.task];
      const hotelReservation = registrantData[RegistrantKeys.hotelReservation];
      const inviteeStatus = registrantData[RegistrantKeys.inviteeStatus];
      const inviteeOutcomeStatus = registrantData[RegistrantKeys.inviteeOutcomeStatus];
      const status = inviteeOutcomeStatus ?? inviteeStatus;
      const taskStatus =
        registrantTask?.[ConferenceRegistrationCategoryName.registrantFlightInformationCategory]?.status ?? null;
      const taskOwner =
        registrantTask?.[ConferenceRegistrationCategoryName.registrantFlightInformationCategory]?.assignedTo ?? null;
      const data = {
        [FlightReservationReportKeys.inviteeEmail]: attendee[AttendeeKeys.inviteeEmail],
        [FlightReservationReportKeys.registrationStatus]: status,
        [FlightReservationReportKeys.taskStatus]: taskStatus,
        [FlightReservationReportKeys.taskOwner]: taskOwner,
        [RegistrantKeys.hotelReservation]: hotelReservation,
      };

      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee) {
        const registrantData = attendee?.registrant?.[RegistrantModelKeys.data];
        const registrantFlightInformation = registrantData?.[RegistrantKeys.flightInformation];
        const registrantBookingInfo = registrantFlightInformation?.[FlightInfoKeys.bookingInfo];
        const fligthInfoUniqueId = `${attendee[AttendeeKeys.dbId]}__${attendee[AttendeeKeys.attendeeType]}`;

        legsMax = Math.max(legsMax, registrantBookingInfo?.length ?? 0);

        Object.assign(data, {
          [FlightReservationReportKeys.dob]: registrantData?.[RegistrantKeys.dob],
          [FlightReservationReportKeys.gender]: registrantData?.[RegistrantKeys.gender],
          [FlightReservationReportKeys.flightInformation]: registrantFlightInformation,
          [FlightReservationReportKeys.flightInformationUniqueId]: fligthInfoUniqueId,
        });
      }

      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Guest) {
        const guestData = attendee?.guest;
        const guestFlightInformation = guestData?.[GuestKeys.flightInformation];
        const guestBookingInfo = guestFlightInformation?.[FlightInfoKeys.bookingInfo];
        const guestFligthInfoUniqueId = `${attendee[AttendeeKeys.dbId]}__${attendee[AttendeeKeys.attendeeType]}`;

        legsMax = Math.max(legsMax, guestBookingInfo?.length ?? 0);

        Object.assign(data, {
          [FlightReservationReportKeys.dob]: guestData?.[AssociationKeys.dob],
          [FlightReservationReportKeys.gender]: guestData?.[AssociationKeys.gender],
          [FlightReservationReportKeys.flightInformation]: guestFlightInformation,
          [FlightReservationReportKeys.flightInformationUniqueId]: guestFligthInfoUniqueId,
        });
      }

      list.push(data);
    });
    return { list, legsMax };
  }

  private async getShippingReport(attendees: Attendee[]): Promise<void> {
    const rowsMap = new Map<string, ShippingReportRowPayload>();

    attendees.forEach(attendee => {
      const registrant = attendee?.registrant;
      const registrantData = registrant?.[RegistrantModelKeys.data];
      const inviteeStatus = registrantData[RegistrantKeys.inviteeStatus];
      const inviteeOutcomeStatus = registrantData[RegistrantKeys.inviteeOutcomeStatus];

      if (inviteeStatus === InviteeStatus.declined || inviteeOutcomeStatus === InviteeStatus.cancelled) {
        return;
      }
      if (!rowsMap.has(attendee?.[AttendeeKeys.inviteeEmail])) {
        rowsMap.set(attendee?.[AttendeeKeys.inviteeEmail], new ShippingReportRowPayload());
      }
      const payload = rowsMap.get(attendee?.[AttendeeKeys.inviteeEmail]);
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee) {
        payload.registrant = registrant;
      }
      if (attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Guest) {
        payload.guests.push(attendee.guest);
      }
    });

    const config = await this.getShippingConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);
    const columns = [];
    const rows = [];

    filteredConfig.forEach(({ header, dataKey }) => {
      columns.push({ header, key: dataKey ?? header });
    });

    for (const value of rowsMap.values()) {
      const registrant = value?.registrant;
      const registrantData = registrant?.[RegistrantModelKeys.data];
      // const recipients = guests ? [registrantData, ...guests] : [registrantData];
      const recipients = [registrantData];

      for (const recipient of recipients) {
        const shippingAddress = recipient?.[RegistrantKeys.shippingAddress];
        const mobilePhone = has(recipient, [RegistrantKeys.mobilePhone])
          ? this.getPrimaryPhoneNumber(recipient?.[RegistrantKeys.mobilePhone]).trim()
          : ''; // format the 10 digits number as follows (xxx) xxx-xxxx
        const rowData = Object.assign(
          {},
          {
            ['orderNum']: '', // blank for now
            ['fullName']: [recipient?.first_name, recipient?.last_name].filter(Boolean).join(' '),
            [RegistrantKeys.firstName]: recipient?.first_name,
            [RegistrantKeys.lastName]: recipient?.last_name,
            [RegistrantKeys.registrationStatus]: value?.[AttendeeKeys.registrationState],
            [RegistrantKeys.inviteeEmail]: recipient?.[RegistrantKeys.inviteeEmail],
            [RegistrantKeys.mobilePhone]: mobilePhone,
            [[RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.address1].join('.')]:
              shippingAddress?.address1,
            [[RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.address2].join('.')]:
              shippingAddress?.address2,
            [[RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.city].join('.')]:
              shippingAddress?.city,
            [[RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.state].join('.')]:
              shippingAddress?.state, // Should be two digit State Code
            [[RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.zip].join('.')]:
              shippingAddress?.zip,
            [[RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.country].join('.')]:
              shippingAddress?.country ?? 'US', // Default All values to US
          },
        );

        rows.push(await this.constructAttendeeRow(rowData, filteredConfig));
      }
    }
    this.getCsvReport({
      rows,
      columns,
      workSheetName: '`Agents`',
      reportName: `Agents ${new Date().toLocaleDateString()}`,
      format: 'csv',
    });
  }

  private getFilteredAttendeesDateByTravelMode(attendees: Attendee[], travelMode: TravelMode) {
    return attendees.filter(attendee => {
      const registrantData = attendee?.registrant?.[RegistrantModelKeys.data];
      const attendeeType = attendee?.[AttendeeKeys.attendeeType];
      const registrantFlightInformation = registrantData?.[RegistrantKeys.flightInformation];
      const equalTravelMode = registrantFlightInformation?.[FlightInfoKeys.equalTravelMode] ?? false;
      const registrantTravelMode = registrantFlightInformation?.[FlightInfoKeys.travelMode];
      if (equalTravelMode && registrantTravelMode === travelMode) {
        return attendee;
      }

      switch (attendeeType) {
        case AttendeeType.Invitee:
          return !equalTravelMode && registrantTravelMode === travelMode ? attendee : null;

        case AttendeeType.Guest:
          const guestTravelMode = attendee?.guest?.[GuestKeys.flightInformation]?.[FlightInfoKeys.travelMode];
          return !equalTravelMode && guestTravelMode === travelMode ? attendee : null;
      }
    });
  }

  private async getDrivingReport(attendees: Attendee[]): Promise<void> {
    const attendeesReportList = this.getAttendeesDrivingInfoData(attendees);
    const config = await this.getDrivingConfig();
    const filteredConfig = config.filter(({ enable }) => enable !== false);

    const columns = filteredConfig.map(({ header, dataKey, headerKey }) => ({
      header,
      key: headerKey ?? dataKey ?? header,
    }));
    const rows = await this.createDrivingReportRows(filteredConfig, attendeesReportList);

    this.getExcelReport({
      rows,
      columns,
      workSheetName: 'Driving Info Report',
      reportName: `Driving Info Report ${new Date().toLocaleDateString()}`,
    });
  }

  private async getDrivingConfig(): Promise<ExportConfig[]> {
    const gendersMap = await this.getGendersMap();
    const suffixesMap = await this.getSuffixesMap();
    const flightFieldEnable = field => !excludedFields.includes([RegistrantKeys.flightInformation, field].join('.'));
    const excludedFields = await this.getCommonExcludedFields();
    return [
      {
        header: 'Registration Status',
        dataKey: FlightReservationReportKeys.registrationStatus,
        normalizer: status => InviteeStatusMap.get(status),
        enable:
          !excludedFields.includes(RegistrantKeys.inviteeOutcomeStatus) ||
          !excludedFields.includes(RegistrantKeys.inviteeStatus),
      },
      {
        header: 'Invitee Email',
        dataKey: FlightReservationReportKeys.inviteeEmail,
        enable: true, // AttendeeKeys.inviteeEmail
      },
      {
        header: 'Full Name',
        headerKey: 'fullName',
        dataKey: FlightReservationReportKeys.flightInformation,
        enable: !excludedFields.includes(RegistrantKeys.firstName) || !excludedFields.includes(RegistrantKeys.lastName),
        getDynamicValues: async (flightInformation: FlightInformationModel) => {
          const firstName = flightInformation?.[FlightInfoKeys.firstName];
          const middleName = flightInformation?.[FlightInfoKeys.middleName];
          const lastName = flightInformation?.[FlightInfoKeys.lastName];
          const suffix = flightInformation?.[FlightInfoKeys.suffix];

          return {
            fullName: [firstName, middleName, lastName, suffixesMap.get(suffix)].filter(Boolean).join(' '),
          };
        },
      },
      {
        header: 'Attendee Type',
        dataKey: FlightReservationReportKeys.attendeeType,
        enable: true, // AttendeeKeys.attendeeType
      },
      {
        header: 'DoB',
        dataKey: FlightReservationReportKeys.dob,
        normalizer: this.normalizeDate,
        enable: !excludedFields.includes(RegistrantKeys.dob),
      },
      {
        header: 'Gender',
        dataKey: FlightReservationReportKeys.gender,
        enable: !excludedFields.includes(RegistrantKeys.gender),
        normalizer: genderId => gendersMap.get(genderId),
      },
      {
        header: 'Arrival Date',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.arrivalDate].join('.'),
        enable: true,
        normalizer: this.normalizeDate,
      },
      {
        header: 'Departure Date',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.departureDate].join('.'),
        enable: true,
        normalizer: this.normalizeDate,
      },
      {
        header: 'Additional Requests',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.additionalRequest].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.additionalRequest),
      },
    ];
  }

  private getAttendeesDrivingInfoData(attendees: Attendee[]) {
    const list = [];
    const filteredAttendees = this.getFilteredAttendeesDateByTravelMode(attendees, TravelMode.driving);
    filteredAttendees.forEach(attendee => {
      const registrant = attendee?.registrant;
      const registrantData = registrant?.[RegistrantModelKeys.data];
      const inviteeStatus = registrantData[RegistrantKeys.inviteeStatus];
      const inviteeOutcomeStatus = registrantData[RegistrantKeys.inviteeOutcomeStatus];
      const status = inviteeOutcomeStatus ?? inviteeStatus;
      const attendeeType = attendee?.[AttendeeKeys.attendeeType];
      const hotelReservation = registrantData?.[RegistrantKeys.hotelReservation];
      const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
      const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
      const registrantFlightInformation = registrantData?.[RegistrantKeys.flightInformation];

      const data = {
        [FlightReservationReportKeys.inviteeEmail]: attendee[AttendeeKeys.inviteeEmail],
        [FlightReservationReportKeys.registrationStatus]: status,
        [FlightReservationReportKeys.attendeeType]: attendeeType,
      };
      switch (attendeeType) {
        case AttendeeType.Invitee:
          Object.assign(registrantFlightInformation, {
            [FlightInfoKeys.arrivalDate]: registrantFlightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate,
            [FlightInfoKeys.departureDate]: registrantFlightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate,
            [FlightInfoKeys.firstName]: registrantData?.[RegistrantKeys.firstName],
            [FlightInfoKeys.middleName]: registrantData?.[RegistrantKeys.middleName],
            [FlightInfoKeys.lastName]: registrantData?.[RegistrantKeys.lastName],
            [FlightInfoKeys.suffix]: registrantData?.[RegistrantKeys.suffix],
          });
          Object.assign(data, {
            [FlightReservationReportKeys.dob]: registrantData?.[RegistrantKeys.dob],
            [FlightReservationReportKeys.gender]: registrantData?.[RegistrantKeys.gender],
            [FlightReservationReportKeys.flightInformation]: registrantFlightInformation,
          });
          list.push(data);
          break;

        case AttendeeType.Guest:
          const guestData = attendee?.guest;
          const guestFlightInformation = guestData?.[GuestKeys.flightInformation];
          Object.assign(guestFlightInformation, {
            [FlightInfoKeys.arrivalDate]: guestFlightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate,
            [FlightInfoKeys.departureDate]: guestFlightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate,
            [FlightInfoKeys.firstName]: guestData?.[AssociationKeys.firstName],
            [FlightInfoKeys.middleName]: guestData?.[AssociationKeys.middleName],
            [FlightInfoKeys.lastName]: guestData?.[AssociationKeys.lastName],
            [FlightInfoKeys.suffix]: guestData?.[AssociationKeys.suffix],
          });
          Object.assign(data, {
            [FlightReservationReportKeys.dob]: guestData?.[AssociationKeys.dob],
            [FlightReservationReportKeys.gender]: guestData?.[AssociationKeys.gender],
            [FlightReservationReportKeys.flightInformation]: guestFlightInformation,
          });
          list.push(data);
          break;
      }
    });
    return list;
  }

  private async createDrivingReportRows(config: ExportConfig[], attendeesReportList) {
    const rows = [];
    for (const attendeeData of attendeesReportList) {
      const row = {};
      for (const { dataKey, headerKey, normalizer, getDynamicValues } of config) {
        let value = dataKey ? get(attendeeData, dataKey) : attendeeData;

        if (getDynamicValues) {
          const dynamicValue = await getDynamicValues(value);
          Object.assign(row, dynamicValue);
          continue;
        }

        if (!dataKey && !headerKey) {
          continue;
        }

        if (normalizer) {
          value = normalizer(value);
        }

        Object.assign(row, { [headerKey ?? dataKey]: value });
      }

      rows.push(row);
    }
    return rows;
  }

  private getHeadshotExport(attendees: Attendee[]): void {
    this.exportInProgress$.next(true);
    const media: InviteeHeadshot[] = [];

    attendees
      // .filter(
      //   attendee =>
      //     attendee?.[AttendeeKeys.inviteeStatus] === InviteeStatus.accepted &&
      //     attendee?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee,
      // )
      .forEach(({ registrant }) => {
        const registrantData = registrant?.[RegistrantModelKeys.data];
        const registrantHeadshot = registrantData?.[RegistrantKeys.headshot];
        const wasabiPath = registrantHeadshot?.wasabiPath;
        if (!!wasabiPath) {
          media.push({
            name: [registrantData?.[RegistrantKeys.firstName], registrantData?.[RegistrantKeys.lastName]]
              .filter(Boolean)
              .join('_'),
            wasabiPath: wasabiPath,
            imageCropperPayload: registrantHeadshot.imageCropperPayload,
          });
        }
      });

    if (!media?.length) {
      this.toastrService.error('No Headshots Currently Exist');
      this.exportInProgress$.next(false);
      return;
    }

    console.log('media', media);

    this.cloudFunctionsService.getArchivedImages(media).finally(() => {
      this.exportInProgress$.next(false);
    });
  }

  private getPrimaryPhoneNumber = phoneNumber => (phoneNumber?.is_primary ? getPhoneNumber(phoneNumber, false) : '');

  private constructProductionRow = (data, config: ExportConfig[]) => {
    const row = {};

    config.forEach(({ header, dataKey, headerKey, normalizer }) => {
      if (!dataKey) {
        return;
      }

      let value = get(data, dataKey);

      if (normalizer) {
        value = normalizer(value);
      }

      Object.assign(row, { [headerKey ?? dataKey]: value });
    });

    return row;
  };

  private constructAttendeeRow = async (data, config: ExportConfig[]) => {
    const row = {};

    for (const { dataKey, attendeeType, headerKey, getDynamicValues, normalizer } of config) {
      if (!dataKey) {
        continue;
      }

      let value = get(data, headerKey || dataKey);

      if (normalizer) {
        value = normalizer(value);
      }

      if (getDynamicValues) {
        const dynamicValue = await getDynamicValues(value);

        Object.assign(row, dynamicValue);
      }

      Object.assign(row, { [headerKey || dataKey]: value });
    }

    return row;
  };

  private async getNameBadgesConfig(): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    return [
      {
        header: 'Invitee Email',
        dataKey: AttendeeKeys.inviteeEmail,
        enable: !excludedCommonFields.includes(RegistrantKeys.inviteeEmail)
      },
      {
        header: 'First Name',
        dataKey: AttendeeKeys.firstName,
        enable: !excludedCommonFields.includes(RegistrantKeys.firstName)
      },
      {
        header: 'Middle Name',
        dataKey: AttendeeKeys.middleName,
        enable: !excludedCommonFields.includes(RegistrantKeys.middleName)
      },
      {
        header: 'Last Name',
        dataKey: AttendeeKeys.lastName,
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName)
      },
      {
        header: 'Suffix',
        dataKey: AttendeeKeys.suffix,
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.suffix)
      },
      {
        header: 'Badge Name',
        dataKey: AttendeeKeys.badgeName,
        enable: !excludedCommonFields.includes(RegistrantKeys.badgeName)
      },
      {
        header: 'MGA / Company',
        dataKey: AttendeeKeys.mgaId,
        normalizer: mgaId => {
          return this.agenciesMap.get(mgaId);
        },
      },
      {
        header: 'Qualified As',
        dataKey: AttendeeKeys.qualifiedAs,
        normalizer: qualifiedAs => {
          return QualifiedAsMap.get(qualifiedAs);
        },
      },
      {
        header: 'Invitee or Guest',
        dataKey: AttendeeKeys.attendeeType,
        normalizer: attendeType => {
          return AttendeeTypeMap.get(attendeType);
        },
      },
    ];
  }

  private async getAwardNamesConfig(): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    return [
      {
        header: 'Invitee Email',
        dataKey: AttendeeKeys.inviteeEmail,
        enable: !excludedCommonFields.includes(RegistrantKeys.inviteeEmail)
      },
      {
        header: 'First Name',
        dataKey: AttendeeKeys.firstName,
        enable: !excludedCommonFields.includes(RegistrantKeys.firstName)
      },
      {
        header: 'Middle Name',
        dataKey: AttendeeKeys.middleName,
        enable: !excludedCommonFields.includes(RegistrantKeys.middleName)
      },
      {
        header: 'Last Name',
        dataKey: AttendeeKeys.lastName,
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName)
      },
      {
        header: 'Suffix',
        dataKey: AttendeeKeys.suffix,
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.suffix)
      },
      {
        header: 'Award Name',
        dataKey: AttendeeKeys.awardName,
        enable: !excludedCommonFields.includes(RegistrantKeys.awardName)
      },
      {
        header: 'MGA / Company',
        dataKey: AttendeeKeys.mgaId,
        normalizer: mgaId => {
          return this.agenciesMap.get(mgaId);
        },
      },
    ];
  }

  private async getProductionByYearConfig(): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    return [
      {
        header: 'Agent ID',
        dataKey: ['registrant', RegistrantModelKeys.data, RegistrantKeys.agentId].join('.'),
      },
      {
        header: 'Invitee Email',
        dataKey: AttendeeKeys.inviteeEmail,
        enable: !excludedCommonFields.includes(RegistrantKeys.inviteeEmail)
      },
      {
        header: 'First Name',
        dataKey: AttendeeKeys.firstName,
        enable: !excludedCommonFields.includes(RegistrantKeys.firstName)
      },
      {
        header: 'Middle Name',
        dataKey: AttendeeKeys.middleName,
        enable: !excludedCommonFields.includes(RegistrantKeys.middleName)
      },
      {
        header: 'Last Name',
        dataKey: AttendeeKeys.lastName,
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName)
      },
      {
        header: 'Suffix',
        dataKey: AttendeeKeys.suffix,
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.suffix)
      },

      ...CONFERENCE_YEARS_LIST.map(year => {
        return {
          header: `${year} Weighted Premium`,
          dataKey: ['registrant', RegistrantModelKeys.data, RegistrantKeys.agentId].join('.'),
          headerKey: ['weightedPremium', year].join('_'),
          normalizer: agentId => {
            const agentReportSummary = this.getSummaryByYear(agentId, year);

            return (agentReportSummary || 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          },
        };
      }),

      {
        header: 'MGA / Company',
        dataKey: AttendeeKeys.mgaId,
        normalizer: mgaId => {
          return this.agenciesMap.get(mgaId);
        },
      },
    ];
  }

  private async getShippingConfig(): Promise<ExportConfig[]> {
    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    return [
      {
        header: 'Order #',
        dataKey: 'orderNum',
      },
      {
        header: 'Recipient Full Name',
        dataKey: 'fullName',
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName) && !excludedCommonFields.includes(RegistrantKeys.firstName),
      },
      {
        header: 'Recipient First Name',
        dataKey: RegistrantKeys.firstName,
        enable: !excludedCommonFields.includes(RegistrantKeys.firstName),
      },
      {
        header: 'Recipient Last Name',
        dataKey: RegistrantKeys.lastName,
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName),
      },
      {
        header: 'Registration State',
        dataKey: RegistrantKeys.registrationStatus,
        enable: !excludedCommonFields.includes(RegistrantKeys.registrationStatus),
      },
      {
        header: 'Recipient Phone',
        dataKey: RegistrantKeys.mobilePhone,
        enable: !excludedCommonFields.includes(RegistrantKeys.mobilePhone),
      },
      {
        header: 'Recipient Email',
        dataKey: RegistrantKeys.inviteeEmail,
        enable: !excludedCommonFields.includes(RegistrantKeys.inviteeEmail),
      },
      {
        header: 'Address Line 1',
        dataKey: [RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.address1].join('.'),
        enable: !excludedCommonFields.includes(RegistrantKeys.shippingAddress),
      },
      {
        header: 'Address Line 2',
        dataKey: [RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.address2].join('.'),
        enable: !excludedCommonFields.includes(RegistrantKeys.shippingAddress),
      },
      {
        header: 'City',
        dataKey: [RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.city].join('.'),
        enable: !excludedCommonFields.includes(RegistrantKeys.shippingAddress),
      },
      {
        header: 'State',
        dataKey: [RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.state].join('.'),
        enable: !excludedCommonFields.includes(RegistrantKeys.shippingAddress),
      },
      {
        header: 'Postal Code',
        dataKey: [RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.zip].join('.'),
        enable: !excludedCommonFields.includes(RegistrantKeys.shippingAddress),
      },
      {
        header: 'Country Code',
        dataKey: [RegistrantKeys.shippingAddress, AssociationKeys.address, AddressModelKeys.country].join('.'),
        enable: !excludedCommonFields.includes(RegistrantKeys.shippingAddress),
      },
    ];
  }

  private async getProductionByQuarterConfig(years: number[] = CONFERENCE_YEARS_LIST): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const conference = await firstValueFrom(this._conference$);
    const startConferenceDate = toDate(conference?.[ConferenceKeys.startDate]);
    const thisYear = startConferenceDate.getFullYear();
    const thisQuarter = getQuarter(startConferenceDate);
    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    return [
      {
        header: 'Agent ID',
        dataKey: ['registrant', RegistrantModelKeys.data, RegistrantKeys.agentId].join('.'),
      },
      {
        header: 'Invitee Email',
        dataKey: AttendeeKeys.inviteeEmail,
        enable: !excludedCommonFields.includes(RegistrantKeys.inviteeEmail)
      },
      {
        header: 'First Name',
        dataKey: AttendeeKeys.firstName,
        enable: !excludedCommonFields.includes(RegistrantKeys.firstName)
      },
      {
        header: 'Middle Name',
        dataKey: AttendeeKeys.middleName,
        enable: !excludedCommonFields.includes(RegistrantKeys.middleName)
      },
      {
        header: 'Last Name',
        dataKey: AttendeeKeys.lastName,
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName)
      },
      {
        header: 'Suffix',
        dataKey: AttendeeKeys.suffix,
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.suffix)
      },

      ...years.map(year => {
        const quarters = new Array(4)
          .fill(null)
          .map((_, index) => index + 1)
          .reverse();

        return quarters
          .filter(quarter => !(year === thisYear && quarter > thisQuarter)) // ignore not future quarters of the current year
          .map(quarter => {
            return {
              header: `${year}  Q${quarter} Weighted Premium`,
              dataKey: ['registrant', RegistrantModelKeys.data, RegistrantKeys.agentId].join('.'),
              headerKey: ['weightedPremium', year, `Q${quarter}`].join('_'),
              normalizer: agentId => {
                const agentReportSummary = this.getSummaryByQuarter(agentId, year, quarter);

                return (agentReportSummary || 0).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
              },
            };
          });
      }),

      {
        header: 'MGA / Company',
        dataKey: AttendeeKeys.mgaId,
        normalizer: mgaId => {
          return this.agenciesMap.get(mgaId);
        },
      },
    ].flat();
  }

  private async getDietaryConsiderationsConfig(): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const dietaryConsiderationsTypeMap = await this.getDietaryConsiderationsTypeMap();
    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    return [
      {
        header: 'Invitee Email',
        dataKey: AttendeeKeys.inviteeEmail,
        enable: !excludedCommonFields.includes(RegistrantKeys.inviteeEmail)
      },
      {
        header: 'First Name',
        dataKey: AttendeeKeys.firstName,
        enable: !excludedCommonFields.includes(RegistrantKeys.firstName)
      },
      {
        header: 'Middle Name',
        dataKey: AttendeeKeys.middleName,
        enable: !excludedCommonFields.includes(RegistrantKeys.middleName)
      },
      {
        header: 'Last Name',
        dataKey: AttendeeKeys.lastName,
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName)
      },
      {
        header: 'Suffix',
        dataKey: AttendeeKeys.suffix,
        enable: !excludedCommonFields.includes(RegistrantKeys.suffix),
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
      },
      {
        header: 'Dietary Consideration',
        dataKey: [AttendeeKeys.dietaryConsideration, DietaryConsiderationKeys.hasDietaryOrPersonalConsideration].join(
          '.',
        ),
      },
      {
        header: 'Consideration Type',
        dataKey: [AttendeeKeys.dietaryConsideration, DietaryConsiderationKeys.dietaryConsiderationType].join('.'),
        normalizer: suffixId => {
          return dietaryConsiderationsTypeMap.get(suffixId);
        },
      },
      {
        header: 'Consideration(s) Description',
        dataKey: [AttendeeKeys.dietaryConsideration, DietaryConsiderationKeys.dietaryConsideration].join('.'),
      },
      {
        header: 'DoB',
        headerKey: 'dob',
        dataKey: AttendeeKeys.dob,
        normalizer: this.normalizeDate,
        enable: !excludedCommonFields.includes(RegistrantKeys.dob),
      },
      {
        header: 'Age',
        headerKey: 'age',
        dataKey: AttendeeKeys.dob,
        normalizer: this.normalizeDate,
        enable: !excludedCommonFields.includes(RegistrantKeys.dob),
        getDynamicValues: async (date: LocalDateTimeString) => {
          const age = await this.getAge(date);
          return age;
        },
      },
      {
        header: 'MGA / Company',
        dataKey: AttendeeKeys.mgaId,
        normalizer: mgaId => {
          return this.agenciesMap.get(mgaId);
        },
      },
    ];
  }

  private async getHotelReservationConfig(guestsMax: number): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    return [
      {
        header: 'ROOM TYPE',
      },
      {
        header: 'Qualified As',
        dataKey: RegistrantKeys.qualifiedAs,
        normalizer: qualifiedAs => {
          return QualifiedAsMap.get(qualifiedAs);
        },
      },
      {
        header: 'MGA Id',
        dataKey: RegistrantKeys.mgaId,
      },
      {
        header: 'Agent Id',
        dataKey: RegistrantKeys.agentId,
      },
      {
        header: 'Registration Type',
        dataKey: RegistrantKeys.registrationType,
        normalizer: registrationType => {
          return RegistrationTypeMap.get(registrationType);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.registrationType)
      },
      {
        header: 'First Name',
        dataKey: RegistrantKeys.firstName,
        enable: !excludedCommonFields.includes(RegistrantKeys.firstName)
      },
      {
        header: 'Middle Name',
        dataKey: RegistrantKeys.middleName,
        enable: !excludedCommonFields.includes(RegistrantKeys.middleName)
      },
      {
        header: 'Last Name',
        dataKey: RegistrantKeys.lastName,
        enable: !excludedCommonFields.includes(RegistrantKeys.lastName)
      },
      {
        header: 'Suffix',
        dataKey: RegistrantKeys.suffix,
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.suffix)
      },
      {
        header: 'DoB',
        dataKey: RegistrantKeys.dob,
        normalizer: this.normalizeDate,
        getDynamicValues: async (date: LocalDateTimeString) => {
          const age = await this.getAge(date);
          return {
            ['Age']: age,
          };
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.dob)
      },
      {
        header: 'Age',
        enable: !excludedCommonFields.includes(RegistrantKeys.dob)
      },
      {
        header: 'Guests Total',
      },
      new Array(guestsMax).fill(null).map((_, index) => {
        const guestNumber = index + 1;

        return [
          {
            header: `Guest ${guestNumber} First Name`,
            attendeeType: AttendeeType.Guest,
            dataKey: [index, AssociationKeys.firstName].join('.'),
            enable: !excludedCommonFields.includes(RegistrantKeys.firstName)
          },
          {
            header: `Guest ${guestNumber} Middle Name`,
            attendeeType: AttendeeType.Guest,
            dataKey: [index, AssociationKeys.middleName].join('.'),
            enable: !excludedCommonFields.includes(RegistrantKeys.middleName)
          },
          {
            header: `Guest ${guestNumber} Last Name`,
            attendeeType: AttendeeType.Guest,
            dataKey: [index, AssociationKeys.lastName].join('.'),
            enable: !excludedCommonFields.includes(RegistrantKeys.lastName)
          },
          {
            header: `Guest ${guestNumber} Suffix`,
            attendeeType: AttendeeType.Guest,
            dataKey: [index, AssociationKeys.suffix].join('.'),
            normalizer: suffixId => {
              return suffixesMap.get(suffixId);
            },
            enable: !excludedCommonFields.includes(RegistrantKeys.suffix)
          },
          {
            header: `Guest ${guestNumber} DoB`,
            attendeeType: AttendeeType.Guest,
            dataKey: [index, AssociationKeys.dob].join('.'),
            normalizer: this.normalizeDate,
            getDynamicValues: async (date: LocalDateTimeString) => {
              const age = await this.getAge(date);
              return {
                [`Guest ${guestNumber} Age`]: age,
              };
            },
            enable: !excludedCommonFields.includes(RegistrantKeys.dob)
          },
          {
            header: `Guest ${guestNumber} Age`,
            attendeeType: AttendeeType.Guest,
            enable: !excludedCommonFields.includes(RegistrantKeys.dob)
          },
        ];
      }),
      {
        header: 'Check-In Date',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.checkInDate].join('.'),
        normalizer: this.normalizeDate,
      },
      {
        header: 'Check-Out Date',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.checkOutDate].join('.'),
        normalizer: this.normalizeDate,
        enable: !excludedCommonFields.includes(HotelReservationKeys.checkOutDate),
      },
      {
        header: 'Requested Check-In Date',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.requestedCheckInDate].join('.'),
        normalizer: this.normalizeDate,
        enable: !excludedCommonFields.includes(HotelReservationKeys.requestedCheckInDate),
      },
      {
        header: 'Requested Check-Out Date',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.requestedCheckOutDate].join('.'),
        normalizer: this.normalizeDate,
        enable: !excludedCommonFields.includes(HotelReservationKeys.requestedCheckOutDate),
      },
      {
        header: 'Differ Booking Dates Request Outcome',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.requestDifferBookingDatesOutcome].join('.'),
        normalizer: (requestOutcome: RequestOutcome) => {
          const requestOutcomeState = requestOutcome?.[RequestOutcomeKeys.state];
          return RequestOutcomeStateMap.get(requestOutcomeState);
        },
        enable: !excludedCommonFields.includes(HotelReservationKeys.requestDifferBookingDatesOutcome),
      },
      {
        header: 'Bed Preferences',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.bedPreference].join('.'),
        enable: !excludedCommonFields.includes(HotelReservationKeys.bedPreference),
      },
      {
        header: 'ADA',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.aidsRequired].join('.'),
        enable: !excludedCommonFields.includes(HotelReservationKeys.aidsRequired),
      },
      {
        header: 'Additional Room Requested',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.additionalRoomRequested].join('.'),
        normalizer: this.normalizeBoolean,
        enable: !excludedCommonFields.includes(HotelReservationKeys.additionalRoomRequested),
      },
      {
        header: 'Additional Room Request Outcome',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.additionalRoomRequestOutcome].join('.'),
        normalizer: (requestOutcome: RequestOutcome) => {
          const requestOutcomeState = requestOutcome?.[RequestOutcomeKeys.state];
          return RequestOutcomeStateMap.get(requestOutcomeState);
        },
        enable: !excludedCommonFields.includes(HotelReservationKeys.additionalRoomRequestOutcome),
      },
      {
        header: 'Special Requests',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.additionalInfo].join('.'),
        enable: !excludedCommonFields.includes(HotelReservationKeys.additionalInfo),
      },
    ].flat(2);
  }

  private async getHotelCheckInConfig(): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const conference = await firstValueFrom(this._conference$);

    const excursionsGroups = groupBy(conference?.[ConferenceKeys.excursions], ExcursionKeys.date);
    const excursionsColumns = [];
    Object.entries(excursionsGroups)?.map(([date, excursions]) => {
      const title = format(date, 'EEEE');
      const atLeisureExcursionId = format(date, 'yyyy-MM-dd') + AT_LEISURE_EXCURSION_SUFFIX;
      const dayExcursionsMap = new Map([[atLeisureExcursionId, { name: 'At Leisure' }]]);
      excursions.forEach(excursion => {
        dayExcursionsMap.set(excursion?.[ExcursionKeys.id], excursion);
      });

      const excursionDayColumns = [
        {
          header: `${title} Excursion`,
          headerKey: `${title}excursion`,
          dataKey: RegistrantKeys.selectedExcursions,
          guestDataKey: GuestKeys.selectedExcursions,
          normalizer: selectedExcursions => {
            if (!selectedExcursions) {
              return '';
            }

            const dayExcursionId = Object.keys(selectedExcursions ?? {})?.find(id => {
              return dayExcursionsMap.has(id);
            });

            if (!dayExcursionId) {
              return '';
            }

            const excursionData = dayExcursionsMap.get(dayExcursionId);

            return excursionData?.name;
          },
        },
        {
          header: `${title} Excursion Details`,
          headerKey: `${title}excursionDetails`,
          dataKey: RegistrantKeys.selectedExcursions,
          guestDataKey: GuestKeys.selectedExcursions,
          normalizer: (selectedExcursions: SelectedExcursions) => {
            if (!selectedExcursions) {
              return '';
            }
            const dayExcursionId = Object.keys(selectedExcursions ?? {})?.find(id => {
              return dayExcursionsMap.has(id);
            });

            if (!dayExcursionId) {
              return '';
            }

            const configurations = selectedExcursions?.[dayExcursionId]?.[SelectedExcursionsKeys.configurations];

            if (!configurations || isEmpty(configurations)) {
              return '';
            }

            return getConfigurationDescription(configurations);
          },
        },
      ];

      excursionsColumns.push(excursionDayColumns);
    });

    const excludedCommonFields: string[] = await this.getCommonExcludedFields();
    const flightFieldEnable = field => !excludedCommonFields.includes([RegistrantKeys.flightInformation, field].join('.'));
    return [
      {
        header: 'Check-In Date',
        dataKey: [RegistrantKeys.hotelReservation, HotelReservationKeys.checkInDate].join('.'),
        normalizer: this.normalizeDate,
        enable: !excludedCommonFields.includes(HotelReservationKeys.checkInDate),
      },
      {
        header: 'Qualified As',
        dataKey: RegistrantKeys.qualifiedAs,
        normalizer: qualifiedAs => {
          return QualifiedAsMap.get(qualifiedAs);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.qualifiedAs)
      },
      {
        header: 'MGA Id',
        dataKey: RegistrantKeys.mgaId,
      },
      {
        header: 'Agent Id',
        dataKey: RegistrantKeys.agentId,
      },
      {
        header: 'Company Reference',
        dataKey: RegistrantKeys.companyReference,
        enable: !excludedCommonFields.includes(RegistrantKeys.companyReference)
      },
      {
        header: 'Registration Type',
        dataKey: RegistrantKeys.registrationType,
        normalizer: registrationType => {
          return RegistrationTypeMap.get(registrationType);
        },
        enable: !excludedCommonFields.includes(RegistrantKeys.registrationType)
      },
      {
        header: 'Attendee Type',
      },
      {
        header: 'First Name',
        dataKey: [RegistrantKeys.flightInformation, FlightInfoKeys.firstName].join('.'),
        guestDataKey: [GuestKeys.flightInformation, FlightInfoKeys.firstName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.firstName)
      },
      {
        header: 'Middle Name',
        dataKey: [RegistrantKeys.flightInformation, FlightInfoKeys.middleName].join('.'),
        guestDataKey: [GuestKeys.flightInformation, FlightInfoKeys.middleName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.middleName),
      },
      {
        header: 'Last Name',
        dataKey: [RegistrantKeys.flightInformation, FlightInfoKeys.lastName].join('.'),
        guestDataKey: [GuestKeys.flightInformation, FlightInfoKeys.lastName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.lastName),
      },
      {
        header: 'Suffix',
        dataKey: [RegistrantKeys.flightInformation, FlightInfoKeys.suffix].join('.'),
        guestDataKey: [GuestKeys.flightInformation, FlightInfoKeys.suffix].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.suffix),
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
      },
      {
        header: 'Badge Name',
        dataKey: RegistrantKeys.badgeName,
        guestDataKey: GuestKeys.badgeName,
        enable: !excludedCommonFields.includes(RegistrantKeys.badgeName),
      },
      excursionsColumns,
    ].flat(3);
  }

  private async getPhoneNumbersReportConfig(): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const excludedFields = await this.getCommonExcludedFields();
    // Need invitee email, preferred name, phone number, and reg type
    return [
      {
        header: 'Invitee Email',
        dataKey: RegistrantKeys.inviteeEmail,
        enable: !excludedFields.includes(RegistrantKeys.inviteeEmail),
      },
      {
        header: 'Registration Type',
        dataKey: RegistrantKeys.registrationType,
        enable: !excludedFields.includes(RegistrantKeys.registrationType),
        normalizer: registrationType => {
          return RegistrationTypeMap.get(registrationType);
        },
      },
      {
        header: 'First Name',
        dataKey: RegistrantKeys.firstName,
        enable: !excludedFields.includes(RegistrantKeys.firstName),
      },
      {
        header: 'Middle Name',
        dataKey: RegistrantKeys.middleName,
        enable: !excludedFields.includes(RegistrantKeys.middleName),
      },
      {
        header: 'Last Name',
        dataKey: RegistrantKeys.lastName,
        enable: !excludedFields.includes(RegistrantKeys.lastName),
      },
      {
        header: 'Suffix',
        dataKey: RegistrantKeys.suffix,
        enable: !excludedFields.includes(RegistrantKeys.suffix),
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
      },
      {
        header: 'Phone Number',
        dataKey: RegistrantKeys.mobilePhone,
        enable: !excludedFields.includes(RegistrantKeys.mobilePhone),
        normalizer: (phoneNumber: PhoneNumber) => getPhoneNumber(phoneNumber, true),
      },
    ];
  }

  private async getFlightReservationConfig(legsMax: number): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const gendersMap = await this.getGendersMap();
    const excludedFields = await this.getCommonExcludedFields();
    const flightFieldEnable = field => !excludedFields.includes([RegistrantKeys.flightInformation, field].join('.'));
    const showFlightInfoColumns = !legsMax;

    return [
      {
        header: 'Registration Status',
        dataKey: FlightReservationReportKeys.registrationStatus,
        normalizer: status => {
          return InviteeStatusMap.get(status);
        },
        enable:
          !excludedFields.includes(RegistrantKeys.inviteeOutcomeStatus) ||
          !excludedFields.includes(RegistrantKeys.inviteeStatus),
      },
      {
        header: 'Invitee Email',
        dataKey: FlightReservationReportKeys.inviteeEmail,
        enable: true, // AttendeeKeys.inviteeEmail
      },
      {
        header: 'Unique ID',
        dataKey: FlightReservationReportKeys.flightInformationUniqueId,
        enable: true,
      },
      {
        header: 'Full Name',
        headerKey: 'fullName',
        dataKey: FlightReservationReportKeys.flightInformation,
        enable: flightFieldEnable(FlightInfoKeys.firstName) || flightFieldEnable(FlightInfoKeys.lastName),
        getDynamicValues: async (flightInformation: FlightInformationModel) => {
          const firstName = flightInformation?.[FlightInfoKeys.firstName];
          const middleName = flightInformation?.[FlightInfoKeys.middleName];
          const lastName = flightInformation?.[FlightInfoKeys.lastName];
          const suffix = flightInformation?.[FlightInfoKeys.suffix];

          return {
            fullName: [firstName, middleName, lastName, suffixesMap.get(suffix)].filter(Boolean).join(' '),
          };
        },
      },
      {
        header: 'Passport First Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.firstName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.firstName),
      },
      {
        header: 'Passport Middle Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.middleName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.middleName),
      },
      {
        header: 'Passport Last Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.lastName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.lastName),
      },
      {
        header: 'Passport Suffix',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.suffix].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.suffix),
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
      },
      {
        header: 'Driver License First Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.driverLicenseFirstName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.driverLicenseFirstName),
      },
      {
        header: 'Driver License Middle Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.driverLicenseMiddleName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.driverLicenseMiddleName),
      },
      {
        header: 'Driver License Last Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.driverLicenseLastName].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.driverLicenseLastName),
      },
      {
        header: 'Driver License Suffix',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.driverLicenseSuffix].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.driverLicenseSuffix),
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
      },
      {
        header: 'DoB',
        dataKey: FlightReservationReportKeys.dob,
        normalizer: this.normalizeDate,
        enable: !excludedFields.includes(RegistrantKeys.dob),
      },
      {
        header: 'Gender',
        dataKey: FlightReservationReportKeys.gender,
        enable: !excludedFields.includes(RegistrantKeys.gender),
        normalizer: genderId => {
          return gendersMap.get(genderId);
        },
      },
      {
        header: 'Booked By Own',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.isBookOwnFlights].join('.'),
        normalizer: this.normalizeBoolean,
        enable: flightFieldEnable(FlightInfoKeys.isBookOwnFlights),
      },
      {
        header: 'Confirmation Status',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.status].join('.'),
        enable: flightFieldEnable(FlightInfoKeys.status),
        normalizer: status => {
          return FlightBookingStatusMap.get(status);
        },
      },
      {
        header: 'Task Status',
        dataKey: FlightReservationReportKeys.taskStatus,
        normalizer: status => {
          return ConferenceRegistrationCommonTaskStatusMap.get(status);
        },
        enable: true,
      },
      {
        header: 'Task Owner',
        dataKey: FlightReservationReportKeys.taskOwner,
        getDynamicValues: async agentId => {
          const agent = await this.getAgent(agentId);
          const agentFullName = getAgentFullName(agent) ?? '';
          return {
            [FlightReservationReportKeys.taskOwner]: agentFullName,
          };
        },
        enable: true,
      },
      {
        header: 'Preferred Airline 1',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.preferredAirline].join('.'),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.preferredAirline),
      },
      {
        header: 'Loyalty Number 1',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.preferredAirlineLoyaltyNumber].join(
          '.',
        ),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.preferredAirlineLoyaltyNumber),
      },
      {
        header: 'Preferred Airline 2',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.secondChoiceAirline].join('.'),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.secondChoiceAirline),
      },
      {
        header: 'Loyalty Number 2',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.secondChoiceAirlineLoyaltyNumber].join(
          '.',
        ),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.secondChoiceAirlineLoyaltyNumber),
      },
      {
        header: 'Known Traveler Number',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.knownTravelerNumber].join('.'),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.knownTravelerNumber),
      },
      {
        header: 'Seat Preference',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.seatPreference].join('.'),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.seatPreference),
      },
      {
        header: 'Departure Airport',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.preferredDepartureAirport].join('.'),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.preferredDepartureAirport),
      },
      {
        header: 'Arrival Date',
        headerKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.arrivalDate].join('.'),
        enable: showFlightInfoColumns,
        normalizer: attendee => {
          const hotelReservation = attendee?.[RegistrantKeys.hotelReservation];
          const flightInformation = attendee?.[FlightReservationReportKeys.flightInformation];
          const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
          const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
          const guestArrivalDate = flightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate;
          const guestDepartureDate = flightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate;
          return this.normalizeDate(guestArrivalDate);
        },
      },
      {
        header: 'Departure Date',
        headerKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.departureDate].join('.'),
        enable: showFlightInfoColumns,
        normalizer: attendee => {
          const hotelReservation = attendee?.[RegistrantKeys.hotelReservation];
          const flightInformation = attendee?.[FlightReservationReportKeys.flightInformation];
          const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
          const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
          const guestDepartureDate = flightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate;
          return this.normalizeDate(guestDepartureDate);
        },
      },
      {
        header: 'Additional Requests',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.additionalRequest].join('.'),
        enable: showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.additionalRequest),
      },
      {
        header: 'Vendor First Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.vendorFirstName].join('.'),
        enable: !showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.vendorFirstName),
      },
      {
        header: 'Vendor Last Name',
        dataKey: [FlightReservationReportKeys.flightInformation, FlightInfoKeys.vendorLastName].join('.'),
        enable: !showFlightInfoColumns && flightFieldEnable(FlightInfoKeys.vendorLastName),
      },

      new Array(legsMax).fill(null).map((_, index) => {
        if (isNil(legsMax)) {
          return [];
        }
        const legNumber = index + 1;

        return [
          {
            header: `Itinerary Leg ${legNumber} Confirmation #`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.confirmationNumber,
            ].join('.'),
          },
          {
            header: `Itinerary Leg ${legNumber} Confirmation #`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.confirmationNumber,
            ].join('.'),
          },
          {
            header: `Itinerary Leg ${legNumber} Airline`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.airline,
            ].join('.'),
          },
          {
            header: `Itinerary Leg ${legNumber} Flight #`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.flightNumber,
            ].join('.'),
          },
          {
            header: `Itinerary Leg ${legNumber} Departure Airport`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.departureAirport,
            ].join('.'),
          },
          {
            header: `Itinerary Leg ${legNumber} Departure Date`,
            headerKey: `itineraryLeg${legNumber}departureDate`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.flightDepartureDate,
            ].join('.'),
            normalizer: this.normalizeDate,
          },
          {
            header: `Itinerary Leg ${legNumber} Departure Time`,
            headerKey: `itineraryLeg${legNumber}departureTime`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.flightDepartureTime,
            ].join('.'),
            normalizer: this.militaryTimeFormatPipe.transform,
          },
          {
            header: `Itinerary Leg ${legNumber} Arrival Airport`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.arrivalAirport,
            ].join('.'),
          },
          {
            header: `Itinerary Leg ${legNumber} Arrival Date`,
            headerKey: `itineraryLeg${legNumber}arrivalDate`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.flightArrivalDate,
            ].join('.'),
            normalizer: this.normalizeDate,
          },
          {
            header: `Itinerary Leg ${legNumber} Arrival Time`,
            headerKey: `itineraryLeg${legNumber}arrivalTime`,
            dataKey: [
              FlightReservationReportKeys.flightInformation,
              FlightInfoKeys.bookingInfo,
              index,
              FlightBookingKeys.flightArrivalTime,
            ].join('.'),
            normalizer: this.militaryTimeFormatPipe.transform,
          },
        ];
      }),
    ].flat(2);
  }

  private async getShirtSizesExportConfig(): Promise<ExportConfig[]> {
    const suffixesMap = await this.getSuffixesMap();
    const tShirtSizeMap = await this.getTShirtSizeTypeMap();
    const excludedFields = await this.getCommonExcludedFields();
    const shirtSizesFieldEnable = field => !excludedFields.includes([RegistrantKeys.tShirtSizes, field].join('.'));

    return [
      {
        header: 'Invitee Email',
        dataKey: AttendeeKeys.inviteeEmail,
        enable: !excludedFields.includes(RegistrantKeys.inviteeEmail),
      },
      {
        header: 'Prefix',
        dataKey: AttendeeKeys.prefix,
        enable: !excludedFields.includes(RegistrantKeys.prefix),
      },
      {
        header: 'First Name',
        dataKey: AttendeeKeys.firstName,
        enable: !excludedFields.includes(RegistrantKeys.firstName),
      },
      {
        header: 'Middle Name',
        dataKey: AttendeeKeys.middleName,
        enable: !excludedFields.includes(RegistrantKeys.middleName),
      },
      {
        header: 'Last Name',
        dataKey: AttendeeKeys.lastName,
        enable: !excludedFields.includes(RegistrantKeys.lastName),
      },
      {
        header: 'Suffix',
        dataKey: AttendeeKeys.suffix,
        normalizer: suffixId => {
          return suffixesMap.get(suffixId);
        },
        enable: !excludedFields.includes(RegistrantKeys.suffix),
      },
      {
        header: 'T-Shirt Size',
        dataKey: [AttendeeKeys.tShirtSizes, TShirtSizesKeys.tShirtSize].join('.'),
        normalizer: tShirtSizeId => {
          return tShirtSizeMap.get(tShirtSizeId);
        },
        enable: shirtSizesFieldEnable(TShirtSizesKeys.tShirtSize),
      },
      {
        header: 'T-Shirt Size Other',
        dataKey: [AttendeeKeys.tShirtSizes, TShirtSizesKeys.tShirtSizeOther].join('.'),
        enable: shirtSizesFieldEnable(TShirtSizesKeys.tShirtSize),
      },
      {
        header: 'Unisex T-Shirt Size',
        dataKey: [AttendeeKeys.tShirtSizes, TShirtSizesKeys.unisexTShirtSize].join('.'),
        normalizer: tShirtSizeId => {
          return tShirtSizeMap.get(tShirtSizeId);
        },
        enable: shirtSizesFieldEnable(TShirtSizesKeys.unisexTShirtSize),
      },
      {
        header: 'Unisex T-Shirt Size Other',
        dataKey: [AttendeeKeys.tShirtSizes, TShirtSizesKeys.unisexTShirtSizeOther].join('.'),
        enable: shirtSizesFieldEnable(TShirtSizesKeys.unisexTShirtSize),
      },
      {
        header: 'Shirt Size Range',
        dataKey: AttendeeKeys.childOrAdult,
        normalizer: mgaId => {
          return InviteeAgeGroupMap.get(mgaId);
        },
        enable: !excludedFields.includes(AttendeeKeys.childOrAdult),
      },
    ];
  }

  private normalizeDate(date: LocalDateTimeString): string {
    if (!date) {
      return null;
    }

    return format(localDateTimeFromTimestamp(date), MMDDYYYY_DATE_FORMAT);
  }

  private getSuffixesMap = async (): Promise<Map<string, string>> => {
    const suffixes = await firstValueFrom(this.lookupsService.suffixesLookup$);
    const map = new Map<string, string>();

    suffixes.forEach(suffix => {
      map.set(suffix.dbId, suffix?.[LookupKeys.description]);
    });

    return map;
  };

  private getGendersMap = async (): Promise<Map<string, string>> => {
    const lookups = await firstValueFrom(this.lookupsService.gendersLookup$);
    const map = new Map<string, string>();

    lookups.forEach(lookup => {
      map.set(lookup.dbId, lookup?.[LookupKeys.description]);
    });

    return map;
  };

  private getDietaryConsiderationsTypeMap = async (): Promise<Map<string, string>> => {
    const suffixes = await firstValueFrom(this.lookupsService.dietaryConsiderationTypesLookup$);
    const map = new Map<string, string>();

    suffixes.forEach(suffix => {
      map.set(suffix.dbId, suffix?.[LookupKeys.description]);
    });

    return map;
  };

  private getTShirtSizeTypeMap = async (): Promise<Map<string, string>> => {
    const suffixes = await firstValueFrom(this.lookupsService.tShortSizesLookup$);
    const map = new Map<string, string>();

    suffixes.forEach(suffix => {
      map.set(suffix.dbId, suffix?.[LookupKeys.description]);
    });

    return map;
  };

  private async getAge(date: LocalDateTimeString): Promise<number> {
    const conference = await firstValueFrom(this._conference$);
    const startConferenceDate = conference?.[ConferenceKeys.startDate];
    if (!isDate(toDate(date)) || !isDate(toDate(startConferenceDate))) {
      return null;
    }

    const age = differenceInYears(startConferenceDate, date);
    if (Number.isNaN(age)) {
      return null;
    }

    return age;
  }

  private normalizeBoolean(date: boolean): string {
    return date ? 'Yes' : 'No';
  }

  private getExcelReport({ workSheetName, reportName, rows, columns, format = 'xlsx' }) {
    const workbook = new Workbook();
    const worksheet: Worksheet = workbook.addWorksheet(workSheetName);

    worksheet.columns = columns;

    worksheet.eachRow(row => {
      row.eachCell(cell => {
        cell.alignment = { horizontal: 'center' };
        cell.style = { font: { bold: true } };
      });
    });

    worksheet.autoFilter = { from: { row: 1, column: 1 }, to: { row: rows?.length, column: columns?.length } };

    rows.forEach((rowData, index) => {
      const { outlineLevel, cellsToMerge, ...data } = rowData;
      const row = worksheet.addRow(data);

      if (cellsToMerge) {
        const top = cellsToMerge?.top;
        const right = cellsToMerge?.right;
        const bottom = cellsToMerge?.bottom;
        const left = cellsToMerge?.left;

        worksheet.mergeCells(top ?? index + 1, left, bottom ?? index + 1, right);
      }

      row.outlineLevel = outlineLevel ?? 0;
    });

    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const contentLength = cell.value ? cell.value.toString().length : 0;
        if (contentLength > maxLength) {
          maxLength = contentLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength; // Set a minimum width
    });

    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${reportName}.${format}`);
    });
  }

  private getCsvReport({ workSheetName, reportName, rows, columns, format = 'csv' }): void {
    const workbook = new Workbook();
    const worksheet: Worksheet = workbook.addWorksheet(workSheetName);

    worksheet.columns = columns;

    rows.forEach(rowData => {
      const { outlineLevel, ...data } = rowData;
      const row = worksheet.addRow(data);

      row.outlineLevel = outlineLevel ?? 0;
    });

    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const contentLength = cell.value ? cell.value.toString().length : 0;
        if (contentLength > maxLength) {
          maxLength = contentLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength; // Set a minimum width
    });

    workbook.csv.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${reportName}.${format}`);
    });
  }

  private getExcludedFields = async (stepName: ConferenceRegistrationStepName) => {
    const conference = await firstValueFrom(this._conference$);
    return (
      conference?.[ConferenceKeys.stepsConfiguration].find(
        config => config?.[ConferenceStepsConfigurationKeys.stepName] === stepName,
      )?.[ConferenceStepsConfigurationKeys.excludedFields] ?? []
    );
  };

  private async getAgent(agentId: string) {
    return await this.agentService.getById(agentId);
  }

  private isGuestSectionEnable = async (): Promise<boolean> => {
    const conference = await firstValueFrom(this._conference$);
    return conference?.[ConferenceKeys.guestsEnabled] ?? true;
  };

  private getCommonExcludedFields = async (): Promise<string[]> => {
    let excludedFields = [];
    const isGuestEnable = await this.isGuestSectionEnable();
    const excludedRSVPStepFields = await this.getExcludedFields(ConferenceRegistrationStepName.rsvpStep);
    excludedFields.push(...excludedRSVPStepFields);
    const excludedPersonalInfoStepFields = await this.getExcludedFields(
        ConferenceRegistrationStepName.registrantPersonalInformationStep,
    );
    const excludedYourFlightInfoStepFields = await this.getExcludedFields(
        ConferenceRegistrationStepName.registrantFlightInformationStep,
    );
    const excludedHotelReservationStepFields = await this.getExcludedFields(
        ConferenceRegistrationStepName.registrantHotelReservationStep,
    );

    if (isGuestEnable) {
      // Get common fields for Guests and Invitee to exclude
      const excludedGuestInfoStepFields: string[] = await this.getExcludedFields(
          ConferenceRegistrationStepName.registrantGuestConfigurationStep,
      );
      const excludedGuestsFlightInfoStepFields: string[] = await this.getExcludedFields(
          ConferenceRegistrationStepName.registrationGuestFlightInformationStep,
      );
      // Personal Info
      excludedPersonalInfoStepFields.map(field => {
        switch (field) {
          case RegistrantKeys.badgeName:
            if (excludedGuestInfoStepFields.includes(GuestKeys.badgeName)) {
              excludedFields.push(field);
            }
            break;
            // Guests don't have this keys in excluded fields:
          case RegistrantKeys.middleName:
          case RegistrantKeys.awardName:
          case RegistrantKeys.shippingAddress:
            excludedFields.push(field);
            break;

          default:
            const item = [RegistrantKeys.isGuestsAttended, field].join('.') as RegistrantKeys;
            if (excludedGuestInfoStepFields.includes(item)) {
              excludedFields.push(field);
            }
            break;
        }
      });
      // Flight Info
      excludedYourFlightInfoStepFields.map(field => {
        if (excludedGuestsFlightInfoStepFields.includes(field)) {
          excludedFields.push(field);
        }
      });

     return [...excludedFields, ...excludedHotelReservationStepFields];
    }

    return [...excludedFields, ...excludedPersonalInfoStepFields, ...excludedYourFlightInfoStepFields, ...excludedHotelReservationStepFields];
  };
}

export class ExportConfig {
  header: string;
  dataKey?: string;
  guestDataKey?: string;
  headerKey?: string;
  attendeeType?: AttendeeType;
  enable?: boolean;
  normalizer?: (payload: any) => any;
  getDynamicValues?: (payload: any, source?: RegistrantData | GuestData) => Promise<any>;
}

export class ReportConfig {
  title: string;
  reportType: RegistrantsExportType;

  constructor(title: string, reportType: RegistrantsExportType, enabled = true) {
    this.title = title;
    this.reportType = reportType;
  }
}
