<ag-shr-modal-window
  #noteModalComponentRef
  [title]="'Log Note'"
  [width]="800"
  [height]="500"
  [inProgress]="inProgress$ | async"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="handleSaveNote($event)"
>
  <dx-form #noteFormRef [formData]="noteFormData" labelMode="floating">
    <dxi-item
      [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationSupportLogKey.title].join('.')"
      [label]="{ text: 'Title' }"
    >
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"> </dxi-validation-rule>
    </dxi-item>
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationSupportLogKey.owner].join('.')"
        [template]="'noteOwnerTemplate'"
      >
      </dxi-item>

      <dxi-item
        [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationSupportLogKey.date].join('.')"
        [label]="{ text: 'Available Date' }"
        editorType="dxDateBox"
        [editorOptions]="dateEditorOptions"
      >
        <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"> </dxi-validation-rule>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        [dataField]="ConferenceRegistrationLogKeys.type"
        [label]="{ text: 'Note Type' }"
        editorType="dxSelectBox"
        [editorOptions]="{
          items: REGISTRATION_LOG_TYPE_LOOKUP,
          valueExpr: LookupKeys.value,
          displayExpr: LookupKeys.description
        }"
      >
        <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"> </dxi-validation-rule>
      </dxi-item>
      <dxi-item
        *ngIf="isSpecialRequest$ | async"
        [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationRequestLogKey.status].join('.')"
        [label]="{ text: 'Special Request Status' }"
        editorType="dxSelectBox"
        [editorOptions]="{
          items: REGISTRATION_LOG_STATUS_LOOKUP,
          valueExpr: LookupKeys.value,
          displayExpr: LookupKeys.description
        }"
      >
      </dxi-item>
    </dxi-item>

    <dxi-item
      [dataField]="[ConferenceRegistrationLogKeys.data, ConferenceRegistrationSupportLogKey.details].join('.')"
      editorType="dxTextArea"
      [editorOptions]="{ height: 150 }"
    >
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"> </dxi-validation-rule>
    </dxi-item>

    <div *dxTemplate="let data of 'noteOwnerTemplate'">
      <dx-select-box
        label="Owner"
        [value]="noteFormData?.[ConferenceRegistrationLogKeys.data]?.[ConferenceRegistrationSupportLogKey.owner]"
        (valueChange)="
          noteFormData[ConferenceRegistrationLogKeys.data][ConferenceRegistrationSupportLogKey.owner] = $event
        "
        [dataSource]="agentsDataSource"
        [valueExpr]="'dbId'"
        [displayExpr]="AgentKeys.p_agent_name"
        [searchEnabled]="true"
        [searchExpr]="[AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name]"
      >
        <div *dxTemplate="let data of 'item'">
          {{ data.p_agent_name }}
          <ng-container *ngIf="data?.[AgentKeys.email_addresses] | AgentPrimaryEmailAddress  as email">
            (<i>{{ email | empty }}</i
            >)
          </ng-container>
        </div>
      </dx-select-box>
    </div>
  </dx-form>
</ag-shr-modal-window>
