import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ModalWindowModule } from 'ag-common-svc/lib/components';
import { ConferenceTransactionsModalComponent } from './conference-transactions-modal.component';
import { ConferencesPipesModule } from '../../pipes/conferences-pipes.module';
import {
    ConferenceRegistrationPipesModule
} from 'ag-common-svc/shared/pipes/conference-registration/conference-registration-pipes.module';

@NgModule({
    imports: [ CommonModule, SharedModule, ModalWindowModule, ConferencesPipesModule, ConferenceRegistrationPipesModule ],
  declarations: [ConferenceTransactionsModalComponent],
  exports: [ConferenceTransactionsModalComponent],
})
export class ConferenceTransactionsModalModule {}
