export enum FlightReservationReportKeys {
  registrationStatus = 'registrationStatus',
  dob = 'dob',
  gender = 'gender',
  inviteeEmail = 'inviteeEmail',
  flightInformation = 'flightInformation',
  flightInformationUniqueId = 'flightInformationUniqueId',
  taskOwner = 'taskOwner',
  taskStatus = 'taskStatus',
  attendeeType = 'attendeeType',
}
