import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeChangeLogDetailModule } from '../attendee-change-log-detail/attendee-change-log-detail.module';
import { AttendeePersonalInfoLogDetailsComponent } from './attendee-personal-info-log-details.component';
import { ChangeLogPipesModule } from '../pipes/change-log-pipes.module';
import {
    AttendeeChangeLogDetailByTypeModule
} from '../attendee-change-log-detail-by-type/attendee-change-log-detail-by-type.module';

@NgModule({
  declarations: [AttendeePersonalInfoLogDetailsComponent],
    imports: [ CommonModule, SharedModule, AttendeeChangeLogDetailModule, ChangeLogPipesModule, AttendeeChangeLogDetailByTypeModule ],
  exports: [AttendeePersonalInfoLogDetailsComponent],
})
export class AttendeePersonalInfoLogDetailsModule {}
