import { Pipe, PipeTransform } from '@angular/core';
import * as jsondiffpatch from 'jsondiffpatch';
// @ts-ignore
import * as htmlFormatter from 'jsondiffpatch/formatters/html';
import { getJsonFromWasabiFile } from 'ag-common-svc/lib/utils/json-from-wasabifile.util';

@Pipe({
  name: 'logsManagerDiff',
})
export class LogsManagerDiffPipe implements PipeTransform {
  async transform(path: string): Promise<string> {
    const json = await getJsonFromWasabiFile(path);
    const delta = jsondiffpatch.diff(json?.oldData, json?.newData);
    htmlFormatter.hideUnchanged();
    return htmlFormatter.format(delta, json?.oldData);
  }
}
