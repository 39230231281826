<div class="row w-100 m-0 p-0 justify-content-center" style="width: 100vw; position: fixed; height: 100vh">
  <div class="col-3 m-0 p-0 d-flex justify-content-start" style="height: 85%; overflow-y: scroll"></div>
  <div class="col-6 m-0 p-0 d-flex justify-content-center" style="height: 85%; overflow-y: scroll">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h4 class="mt-3">Registration Reports</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-6 pt-3">
          <dx-select-box
            [dataSource]="conferences | async"
            label="Select Conference"
            fieldTemplate="field"
            [(ngModel)]="event"
          >
            <div *dxTemplate="let data of 'field'">
              <div class="custom-item">
                <dx-text-box [value]="data && data.event_name" [readOnly]="true"> </dx-text-box>
              </div>
            </div>
            <div *dxTemplate="let data of 'item'">
              <div class="custom-item">
                <div class="product-name">
                  {{ data.event_name }} ({{ data.event_id }}) {{ data.start_date }} - {{ data.end_date }}
                </div>
              </div>
            </div>
          </dx-select-box>
        </div>
        <div class="col-6 pt-3">
          <dx-select-box
            #report_type
            label="Select Report Type"
            [(ngModel)]="reportType"
            [items]="['Registration Report', 'Registration Report with Financial Data']"
          >
          </dx-select-box>
        </div>
      </div>
      <div class="row" *ngIf="report_type.value == 'Registration Report with Financial Data'">
        <div class="col-6 pt-3">
          <dx-select-box [(ngModel)]="fromYear" label="From Year" [items]="reportYears"> </dx-select-box>
        </div>
        <div class="col-6 pt-3">
          <dx-select-box [(ngModel)]="toYear" label="To Year" [items]="reportYears"> </dx-select-box>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3 text-end">
          <dx-button (onClick)="generateReport()">Generate Report</dx-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3 text-end">
          <dx-data-grid
            class="dx-card wide-card"
            #registrantGrid
            [dataSource]="registrants | async"
            [showBorders]="false"
            [columns]="columns"
            [columnHidingEnabled]="true"
            [scrollLeft]="true"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true"
            [height]="460"
          >
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-export [enabled]="true"></dxo-export>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 m-0 p-0 d-flex justify-content-end" style="height: 85%; overflow-y: scroll"></div>
</div>
