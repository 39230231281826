import { Component, HostBinding, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import {
  AgentCampaignChange,
  AgentCampaignChanges,
  AgentCampaignStepName,
  BaseModelKeys,
  CampaignsManagementIssuesStatus,
  CampaignsManagementTaskIssues,
  CampaignsManagementTaskIssuesKeys,
  CampaignsManagementTasks,
  CampaignsManagementTasksKeys,
  CampaignsManagementTasksStatus,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  LookupKeys,
  MediaSize,
  Tab,
} from 'ag-common-lib/public-api';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { CampaignsAddIssuesModalService } from '../campaigns-add-issues-modal/campaigns-add-issues-modal.service';
import { confirm } from 'devextreme/ui/dialog';
import { CampaignsManagementTaskBoardService } from '../campaigns-management-task-board.service';
import { CampaignsAddIssuesModalComponent } from '../campaigns-add-issues-modal/campaigns-add-issues-modal.component';
import { ToastrService } from 'ngx-toastr';
import { CampaignsIssuesGridComponent } from '../campaigns-issues-grid/campaigns-issues-grid.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AgentCampaignsManagementTasksService } from 'ag-common-svc/public-api';
import { CampaignsDetailsModalGetAllImagesIdsPipe } from './campaigns-details-modal-get-all-images-ids.pipe';
import { WasabiArchivedImagesPipe } from 'ag-common-svc/shared/pipes/wasabi-archived-images.pipe';
import { saveAs } from 'file-saver';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';

export enum DetailsTab {
  campaignsInfo = 'Campaigns Info',
  issues = 'Issues',
}

@UntilDestroy()
@Component({
  selector: 'ag-crm-campaign-details-modal',
  templateUrl: './campaigns-details-modal.component.html',
  styleUrls: ['./campaigns-details-modal.component.scss'],
  providers: [
    CampaignsAddIssuesModalService,
    CampaignsManagementTaskBoardService,
    CampaignsDetailsModalGetAllImagesIdsPipe,
    WasabiArchivedImagesPipe,
    HasPermissionPipe,
  ],
})
export class CampaignsDetailsModalComponent {
  @HostBinding('class') className = 'campaign-details-modal';
  @ViewChild('campaignsInfoModalRef', { static: true }) campaignsInfoModalComponent: ModalWindowComponent;
  @ViewChild('campaignIssuesModalRef', { static: true }) campaignIssuesModalComponent: CampaignsAddIssuesModalComponent;
  @ViewChild('campaignIssuesGridRef', { static: true }) campaignIssueGridComponent: CampaignsIssuesGridComponent;

  details: AgentCampaignChanges;
  selectedTask: CampaignsManagementTasks;
  taskIssues$ = new BehaviorSubject<CampaignsManagementTaskIssues[]>([]);
  detailsTabs: Tab[] = [
    {
      id: 0,
      text: DetailsTab.campaignsInfo,
      template: DetailsTab.campaignsInfo,
      isPermitted: this.hasPermissionPipe.transform(Entity.campaignsInfo),
    },
    {
      id: 1,
      text: DetailsTab.issues,
      template: DetailsTab.issues,
      isPermitted: this.hasPermissionPipe.transform(Entity.campaignsTaskIssues),
    },
  ];
  selectedDetailsTab: DetailsTab = DetailsTab.campaignsInfo;
  selectedIndex: number = 0;
  inProgress$ = new BehaviorSubject<boolean>(false);
  isActionsVisible$ = new BehaviorSubject<boolean>(true);
  protected readonly downloadOptions = [
    { text: 'Optimized Images', actionFn: this.onOptimizedDownloadClicked.bind(this) },
    { text: 'Origin Images', actionFn: this.onDownloadClicked.bind(this) },
  ];

  protected readonly CampaignsManagementTasksStatus = CampaignsManagementTasksStatus;
  protected readonly LookupKeys = LookupKeys;
  protected readonly AgentCampaignStepName = AgentCampaignStepName;
  protected readonly DetailsTab = DetailsTab;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private agentCampaignsManagementTasksService: AgentCampaignsManagementTasksService,
    private campaignsIssuesModalService: CampaignsAddIssuesModalService,
    private toastrService: ToastrService,
    private campaignsDetailsModalGetAllImagesIdsPipe: CampaignsDetailsModalGetAllImagesIdsPipe,
    private wasabiArchivedImagesPipe: WasabiArchivedImagesPipe,
    private readonly hasPermissionPipe: HasPermissionPipe,
  ) {}

  setDetailsTab(tab: DetailsTab): void {
    this.selectedDetailsTab = tab;
    this.selectedIndex = this.detailsTabs.find(tab => tab.template === this.selectedDetailsTab).id;
  }

  showModal = (data?: CampaignsManagementTasks, isEditable: boolean = true): void => {
    this.selectedTask = data ?? null;
    this.details = data?.details ?? null;
    this.setDetailsTab(DetailsTab.campaignsInfo);
    const isDetailsEditable = isEditable && this.selectedTask?.taskStatus !== CampaignsManagementTasksStatus.done;
    this.isActionsVisible$.next(isDetailsEditable);
    if (data) {
      this.refreshIssues();
    }
    this.campaignsInfoModalComponent?.showModal();
  };

  hideModal = (): void => {
    this.campaignsInfoModalComponent?.hideModal();
  };

  showAddIssuePopupByField = (field?: AgentCampaignStepName): void => {
    const issue = { [CampaignsManagementTaskIssuesKeys.issueField]: field };
    this.campaignIssuesModalComponent?.showModal(this.selectedTask, issue);
  };

  showEditIssuePopup = (issue: CampaignsManagementTaskIssues): void => {
    this.campaignIssuesModalComponent?.showModal(this.selectedTask, issue);
  };

  completeTaskConfirmation = (): void => {
    const excludedStatuses = [CampaignsManagementIssuesStatus.closed, CampaignsManagementIssuesStatus.resolved];
    const issuesInProgress = this.taskIssues$.value.filter(
      issue => !excludedStatuses.includes(issue[CampaignsManagementTaskIssuesKeys.issueStatus]),
    ).length;
    const allIssuesDone = Object.values(this.details)
      .filter(item => !!item.incomingStepData)
      .every(val => val.isStepDone);

    if (!!issuesInProgress) {
      this.toastrService.error('Please Close or Resolve All Issues before complete!');
      this.setDetailsTab(DetailsTab.issues);
      return;
    }

    if (allIssuesDone) {
      const result = confirm('<i>Are you sure you want to <b>COMPLETE</b> this task?</i>', 'Confirm');
      result.then(dialogResult => {
        if (dialogResult) {
          this.inProgress$.next(true);
          const agentId = this.selectedTask?.[CampaignsManagementTasksKeys.agentDbId];
          const taskId = this.selectedTask?.[BaseModelKeys.dbId];

          this.agentCampaignsManagementTasksService
            .update(agentId, taskId, {
              [CampaignsManagementTasksKeys.taskStatus]: CampaignsManagementTasksStatus.done,
            })
            .then(() => {
              this.hideModal();
            })
            .finally(() => {
              this.inProgress$.next(false);
            });
        }
      });
    } else {
      this.toastrService.error('All Issues should be Done before complete!');
      this.setDetailsTab(DetailsTab.campaignsInfo);
    }
  };

  refreshIssues(): void {
    const taskId = this.selectedTask[BaseModelKeys.dbId];
    this.getIssue$(taskId)
      .pipe(
        map(issues => {
          this.taskIssues$.next(issues ?? []);
          this.campaignIssueGridComponent?.refreshGrid();
        }),
      )
      .subscribe();
  }

  isDetailsVisible = (details: AgentCampaignChange<any>): boolean =>
    !!details?.currentStepData || !!details?.incomingStepData;

  onStepDoneChanged(stepName: AgentCampaignStepName, value): void {
    switch (stepName) {
      case AgentCampaignStepName.contactInfo:
        this.details[AgentCampaignStepName.contactInfoAddress].isStepDone = value;
        this.details[AgentCampaignStepName.contactInfoPhoneNumber].isStepDone = value;
        this.details[AgentCampaignStepName.contactInfoEmailAddress].isStepDone = value;
        break;

      default:
        this.details[stepName].isStepDone = value;
        break;
    }

    const agentId = this.selectedTask?.[CampaignsManagementTasksKeys.agentDbId];
    const taskId = this.selectedTask?.[BaseModelKeys.dbId];

    this.agentCampaignsManagementTasksService.update(agentId, taskId, {
      [CampaignsManagementTasksKeys.details]: this.details,
    });
  }

  async getArchiveImagesBlob(optimized?: boolean) {
    const paths = this.campaignsDetailsModalGetAllImagesIdsPipe.transform(this.details);
    const archiveUrl = this.wasabiArchivedImagesPipe.transform(paths, MediaSize.origin, optimized);
    return fetch(archiveUrl).then(res => res.blob());
  }

  async onDownloadClicked() {
    const blob = await this.getArchiveImagesBlob();
    saveAs(blob, 'images.zip');
  }

  async onOptimizedDownloadClicked() {
    const blob = await this.getArchiveImagesBlob(true);
    saveAs(blob, 'images.zip');
  }

  logAction(e) {
    e.itemData.actionFn();
  }

  private getIssue$(taskId: string): Observable<CampaignsManagementTaskIssues[]> {
    return this.campaignsIssuesModalService.getIssuesById$(taskId).pipe(
      map(items => items.map(({ data }) => data)),
      take(1),
    );
  }

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
