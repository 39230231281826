import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentSalesGoalsModalComponent } from './agent-sales-goals-modal.component';
import { AgTabsModule } from '../ag-tabs/ag-tabs.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { DxDataGridModule, DxSelectBoxModule } from 'devextreme-angular';
import { AgentSalesGoalsGridSourcePipe } from './agent-sales-goals-grid-source.pipe';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [AgentSalesGoalsModalComponent, AgentSalesGoalsGridSourcePipe],
  imports: [CommonModule, AgTabsModule, ModalWindowModule, DxDataGridModule, DxSelectBoxModule, SharedModule],
  exports: [AgentSalesGoalsModalComponent],
})
export class AgentSalesGoalsModalModule {}
