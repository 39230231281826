import { Pipe, PipeTransform } from '@angular/core';
import { Agent, AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { SalesGoalsTab } from './agent-sales-goals.models';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

@Pipe({
  name: 'agentSalesGoalsGridSource',
})
export class AgentSalesGoalsGridSourcePipe implements PipeTransform {
  transform(selectedAgent: Agent, salesGoalsTab: SalesGoalsTab): DataSource {
    switch (salesGoalsTab) {
      case SalesGoalsTab.PERSONAL:
        return new DataSource({
          store: new ArrayStore({
            key: 'year',
            data: Array.isArray(selectedAgent?.[AgentKeys.personal_goals])
              ? selectedAgent?.[AgentKeys.personal_goals]
              : [],
          }),
        });
      case SalesGoalsTab.CONFERENCE:
        return new DataSource({
          store: new ArrayStore({
            key: 'year',
            data: Array.isArray(selectedAgent?.[AgentKeys.conference_goals])
              ? selectedAgent?.[AgentKeys.conference_goals]
              : [],
          }),
        });
      case SalesGoalsTab.MANAGER:
        return new DataSource({
          store: new ArrayStore({
            key: 'year',
            data: Array.isArray(selectedAgent?.[AgentKeys.manager_goals])
              ? selectedAgent?.[AgentKeys.manager_goals]
              : [],
          }),
        });
    }
  }
}
