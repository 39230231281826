import { Pipe, PipeTransform } from '@angular/core';
import { AgentCampaignChanges, AgentCampaignStepName } from 'ag-common-lib/public-api';

@Pipe({ name: 'campaignsDetailsModalStepVisible' })
export class CampaignsDetailsModalStepVisiblePipe implements PipeTransform {
  transform(changes: AgentCampaignChanges, stepNames: AgentCampaignStepName[]): boolean {
    return stepNames?.some(stepName => {
      return changes[stepName]?.incomingStepData;
    });
  }
}
