<ag-shr-modal-window
  #conferenceExcursionStatisticModalRef
  [title]="eventNameTitle"
  [width]="'100vw'"
  [height]="'100vh'"
  [showSaveButton]="false"
  [showFullScreenButton]="false"
  [useScrollView]="false"
>
  <dx-tabs
    class="conference-excursion-statistic-modal__tab-panel"
    [dataSource]="statisticTabs"
    [selectedIndex]="selectedIndex"
    [repaintChangesOnly]="true"
    (onItemClick)="selectTab($event)"
  ></dx-tabs>

  <ng-container [ngSwitch]="selectedTab">
      <ag-crm-conference-excursions-statistic-grid
        *ngSwitchCase="StatisticTab.SeatsRemaining"
      ></ag-crm-conference-excursions-statistic-grid>

      <ag-crm-conference-not-selected-grid
        *ngSwitchCase="StatisticTab.PersonsWithoutExcursions"
      ></ag-crm-conference-not-selected-grid>
  </ng-container>
</ag-shr-modal-window>
