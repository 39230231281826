<ag-shr-modal-window
  #attendeeItineraryModalRef
  *var="Entity.conferenceAttendeeDetails | hasPermission: activity | async as canWrite"
  [title]="'Attendee Itinerary'"
  [width]="'70vw'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleSave()"
  [onCloseModal]="handleClosePopup"
  [showSaveButton]="canWrite"
>
  <div *ngIf="attendeeItineraryModalRef?.popupComponent?.visible ?? false" class="attendee-itinerary-modal__container">
    <div class="attendee-itinerary-modal__info-container">
      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.firstName | async">
        <span class="attendee-itinerary-modal__info-title">Passport First Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.firstName] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.middleName | async">
        <span class="attendee-itinerary-modal__info-title">Passport Middle Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.middleName] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.lastName | async">
        <span class="attendee-itinerary-modal__info-title">Passport Last Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.lastName] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.suffix | async">
        <span class="attendee-itinerary-modal__info-title">Passport Suffix Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.suffix] | suffix | async | empty }}</span>
      </div>
    </div>

    <div class="attendee-itinerary-modal__info-container">
      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.driverLicenseFirstName | async">
        <span class="attendee-itinerary-modal__info-title">Driving License First Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.driverLicenseFirstName] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.driverLicenseMiddleName | async">
        <span class="attendee-itinerary-modal__info-title">Driving License Middle Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.driverLicenseMiddleName] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.driverLicenseLastName | async">
        <span class="attendee-itinerary-modal__info-title">Driving License Last Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.driverLicenseLastName] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.driverLicenseSuffix | async">
        <span class="attendee-itinerary-modal__info-title">Driving License Suffix Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.driverLicenseSuffix] | suffix | async | empty }}</span>
      </div>
    </div>

    <div class="attendee-itinerary-modal__info-container">
      <div *ngIf="attendeeType | isFlightFieldsExcluded: 'dob' | async">
        <span class="attendee-itinerary-modal__info-title">Date of Birth:</span>
        <span> {{ dob | date: dateFormat | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: 'gender' | async">
        <span class="attendee-itinerary-modal__info-title">Gender:</span>
        <span> {{ gender | gender | async | empty }}</span>
      </div>
    </div>

    <div class="attendee-itinerary-modal__info-container">
      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.preferredAirline | async">
        <span class="attendee-itinerary-modal__info-title">Pref. Airline1:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.preferredAirline] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.preferredAirlineLoyaltyNumber | async">
        <span class="attendee-itinerary-modal__info-title">Loyalty #:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.preferredAirlineLoyaltyNumber] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.secondChoiceAirline | async">
        <span class="attendee-itinerary-modal__info-title">Pref. Airline 2:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.secondChoiceAirline] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.secondChoiceAirlineLoyaltyNumber | async">
        <span class="attendee-itinerary-modal__info-title">Loyalty #:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.secondChoiceAirlineLoyaltyNumber] | empty }}</span>
      </div>
    </div>

    <div class="attendee-itinerary-modal__info-container">
      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.knownTravelerNumber | async">
        <span class="attendee-itinerary-modal__info-title">Known Travel #:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.knownTravelerNumber] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.seatPreference | async">
        <span class="attendee-itinerary-modal__info-title">Seat Pref. :</span>
        <span> {{ flightInfo?.[FlightInfoKeys.seatPreference] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.preferredDepartureAirport | async">
        <span class="attendee-itinerary-modal__info-title">Pref. Departure Airport :</span>
        <span> {{ flightInfo?.[FlightInfoKeys.preferredDepartureAirport] | empty }}</span>
      </div>
    </div>

    <div class="attendee-itinerary-modal__info-container">
      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.vendorFirstName | async">
        <span class="attendee-itinerary-modal__info-title">Vendor First Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.vendorFirstName] | empty }}</span>
      </div>

      <div *ngIf="attendeeType | isFlightFieldsExcluded: FlightInfoKeys.vendorLastName | async">
        <span class="attendee-itinerary-modal__info-title">Vendor Last Name:</span>
        <span> {{ flightInfo?.[FlightInfoKeys.vendorLastName] | empty }}</span>
      </div>
    </div>

    <dx-form
      #formRef
      [formData]="formData"
      labelMode="floating"
      [validationGroup]="validationGroup"
      (onInitialized)="onInit($event)"
      [screenByWidth]="formWidth$ | async | formScreenByWidth: { md: 860 }"
      [readOnly]="!canWrite"
    >
      <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.confirmationNumber"
          [label]="{ text: 'Conf #' }"
        ></dxi-item>
        <dxi-item [colSpan]="4" [dataField]="FlightBookingKeys.airline" [label]="{ text: 'Airline' }"></dxi-item>
        <dxi-item [colSpan]="4" [dataField]="FlightBookingKeys.flightNumber" [label]="{ text: 'Flight #' }"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.departureAirport"
          [label]="{ text: 'Departure Airport' }"
        ></dxi-item>

        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.flightDepartureDate"
          [label]="{ text: 'Departure Date' }"
          editorType="dxDateBox"
          [editorOptions]="dateTimeEditorOptions"
        ></dxi-item>

        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.flightDepartureTime"
          [label]="{ text: 'Departure Time' }"
          editorType="dxDateBox"
          [editorOptions]="timeEditorOptions"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.arrivalAirport"
          [label]="{ text: 'Arrival Airport' }"
        ></dxi-item>

        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.flightArrivalDate"
          [label]="{ text: 'Arrival Date' }"
          editorType="dxDateBox"
          [editorOptions]="dateTimeEditorOptions"
        ></dxi-item>

        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.flightArrivalTime"
          [label]="{ text: 'Arrival Time' }"
          editorType="dxDateBox"
          [editorOptions]="timeEditorOptions"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.frequentFlyerNumber"
          [label]="{ text: 'Frequent Flyer #' }"
        ></dxi-item>
        <dxi-item
          [colSpan]="4"
          [dataField]="FlightBookingKeys.knownTravelerNumber"
          [label]="{ text: 'Known Travel #' }"
        ></dxi-item>
      </dxi-item>
    </dx-form>
  </div>
</ag-shr-modal-window>
