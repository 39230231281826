<div>
  <div class="pt-2" style="height: 78vh">
    <dx-data-grid
      class="dx-card wide-card pt-1"
      [dataSource]="dataSource"
      [showBorders]="false"
      [focusedRowEnabled]="true"
      [focusedRowIndex]="0"
      [columnAutoWidth]="true"
      [scrollLeft]="true"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [height]="'100%'"
    >
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-editing mode="popup" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true">
        <dxo-popup title="Add Date" [showTitle]="false" [width]="600" [height]="400"></dxo-popup>
      </dxo-editing>

      <dxi-column
        dataField="date"
        [caption]="'Date'"
        [dataType]="'date'"
        [format]="{ style: 'shortdate' }"
      ></dxi-column>
    </dx-data-grid>
  </div>
</div>
