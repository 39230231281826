<div class="container">
  <div class="row justify-content-between" style="padding: 20px 40px">
    <div class="col-12 align-self-center text-start">
      <h4>Contests</h4>
    </div>
  </div>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      #contestGrid
      [dataSource]="dataSource"
      [showBorders]="false"
      [focusedRowEnabled]="true"
      [focusedRowIndex]="0"
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [scrollLeft]="true"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      [height]="550"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onRowDblClick)="viewContestDetails($event)"
    >
      <dxo-paging [pageSize]="50"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxi-column dataField="name"></dxi-column>
      <dxi-item [colSpan]="6" editorType="dxDateBox" [editorOptions]="startDateEditorOptions">
        <dxo-label text="Start Date"></dxo-label>
      </dxi-item>
      <dxi-item [colSpan]="6" editorType="dxDateBox" [editorOptions]="endDateEditorOptions">
        <dxo-label text="End Date"></dxo-label>
      </dxi-item>
      <dxi-column dataField="location"></dxi-column>
      <dxi-column type="buttons" [width]="125" [fixed]="true">
        <dxi-button
          [visible]="Entity.registrationsContests | hasPermission : EntityPermissionActivityKeys.write | async"
          name="edit"
          [onClick]="showContestInfo.bind(this)"
        ></dxi-button>
        <dxi-button
          [visible]="Entity.registrationsContests | hasPermission : EntityPermissionActivityKeys.delete | async"
          name="delete"
        ></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>
</div>

<ng-container *ngIf="contestDetailsVivible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="Contest Details"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="contestDetailsVivible"
  >
    <div class="row justify-content-between" style="padding: 20px 40px">
      <dx-form [colCount]="12" [formData]="selectedContest">
        <dxi-item itemType="group" caption="Contest Information" [colCount]="12" [colSpan]="12">
          <dxi-item dataField="name" [colSpan]="12"></dxi-item>
          <dxi-column dataField="start_date" [calculateCellValue]="getStartDate"></dxi-column>
          <dxi-column dataField="end_date" [calculateCellValue]="getEndDate"></dxi-column>
          <dxi-item dataField="location" [colSpan]="6"></dxi-item>
          <dxi-column type="buttons" [width]="125" [fixed]="true">
            <dxi-button
              [visible]="Entity.registrationsContests | hasPermission : EntityPermissionActivityKeys.write | async"
              name="edit"
              [onClick]="viewContestDetails.bind(this)"
            ></dxi-button>
            <dxi-button
              [visible]="Entity.registrationsContests | hasPermission : EntityPermissionActivityKeys.delete | async"
              name="delete"
            ></dxi-button>
          </dxi-column>
        </dxi-item>
      </dx-form>
    </div>
    <div class="row d-flex mt-2 justify-content-end" style="margin: 0 auto">
      <div class="col-lg-4 text-end">
        <dx-button
          width="200"
          stylingMode="contained"
          text="Save"
          type="default"
          (onClick)="saveContestDetails()"
        ></dx-button>
      </div>
      <div class="col-lg-4 text-end">
        <dx-button
          width="200"
          stylingMode="contained"
          text="Close"
          type="default"
          (onClick)="contestDetailsVivible = false"
        ></dx-button>
      </div>
    </div>
  </dx-popup>
</ng-container>

<ng-container *ngIf="contestRegistrationListVisible">
  <dx-popup
    [width]="950"
    [height]="850"
    [showTitle]="true"
    title="Contest Registrants"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="contestRegistrationListVisible"
  >
    <div class="row justify-content-between" style="padding: 20px 40px">
      <div class="col-12 align-self-center text-start">
        <h4>Contest Registrants</h4>
      </div>
    </div>
    <div></div>
  </dx-popup>
</ng-container>
