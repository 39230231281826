<div class="ag-grid">
  <dx-data-grid
    class="ag-grid__component"
    #rejectedRegistratsGrid
    [dataSource]="declinedRegistrants$ | async"
    [showBorders]="true"
    [columnAutoWidth]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [noDataText]="emptyMessage"
    height="100%"
    (onRowDblClick)="showAttendeeModal($event.data)"
  >
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-paging [pageSize]="30"></dxo-paging>
    <dxo-pager
      [visible]="showPager()"
      [displayMode]="'full'"
      [showPageSizeSelector]="false"
      [showInfo]="false"
      [showNavigationButtons]="true"
    >
    </dxo-pager>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>

    <dxo-editing
      [mode]="'cell'"
      [useIcons]="true"
      [allowDeleting]="false"
      [allowAdding]="false"
      [allowUpdating]="false"
    ></dxo-editing>

    <dxo-toolbar>
      <dxi-item location="before" name="groupPanel"></dxi-item>

      <dxi-item location="after" name="columnChooserButton" cssClass="ag-grid__toolbar-control"></dxi-item>
    </dxo-toolbar>

    <dxi-column
      dataField="data.first_name"
      [caption]="titleList.first_name"
      [allowEditing]="false"
      showWhenGrouped="true"
      cellTemplate="agentCellTmp"
    ></dxi-column>

    <dxi-column
      [dataField]="[RegistrantModelKeys.data, RegistrantKeys.inviteeStatus] | path"
      [caption]="'Invitee Status'"
    >
      <dxo-lookup
        [dataSource]="inviteeStatusLookup"
        [allowClearing]="false"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="[RegistrantModelKeys.data, RegistrantKeys.inviteeOutcomeStatus] | path"
      [caption]="'Invitee Outcome Status'"
    >
      <dxo-lookup
        [dataSource]="inviteeOutcomeStatusLookup"
        [allowClearing]="false"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="data.agency_id"
      [caption]="titleList.agency_id"
      [allowEditing]="false"
      [visible]="false"
      showWhenGrouped="true"
    >
      <dxo-lookup
        [dataSource]="agencies$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="data.mga_id" [caption]="titleList.mga_id" [allowEditing]="false" showWhenGrouped="true">
      <dxo-lookup
        [dataSource]="mgaList$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="data.shippingAddress"
      [caption]="titleList.shippingAddress"
      [allowEditing]="false"
      showWhenGrouped="true"
      cellTemplate="shippingAddressCellTmp"
    ></dxi-column>

    <dxi-column
      dataField="data.award_name"
      [caption]="titleList.awardName"
      [allowEditing]="false"
      showWhenGrouped="true"
    ></dxi-column>

    <dxi-column
      dataField="data.isSendPlaque"
      [caption]="titleList.isSendPlaque"
      [alignment]="'center'"
      [allowEditing]="true"
      showWhenGrouped="true"
    >
      <dxo-lookup
        [dataSource]="yesNoLookup"
        [allowClearing]="false"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>

    <!--    Templates-->
    <div *dxTemplate="let cell of 'shippingAddressCellTmp'">
      <ng-container *ngIf="cell?.value?.is_primary_shipping; else emptyContent">
        <p class="mb-0">{{ cell.value | fullAddress }}</p>
      </ng-container>
      <ng-template #emptyContent><p class="mb-0 ag-empty-text">n/a</p></ng-template>
    </div>

    <div *dxTemplate="let cell of 'agentCellTmp'">
      <ng-container *var="cell.data.data as registrant">
        <div class="ag-text-clickable" (click)="viewAgentDetails(cell.data.dbId)">
          {{ [registrant?.first_name, registrant?.last_name] | fullName }}
          <ng-container *ngIf="registrant?.primary_email_address?.address as email"> ({{ email }}) </ng-container>
        </div>
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'groupOwnerCellTemplate'">
      Send Plaque Owner:
      {{ cell.displayValue | agent : "p_agent_name" | async | empty }}
    </div>
  </dx-data-grid>
</div>
