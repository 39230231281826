import { Pipe, PipeTransform } from '@angular/core';
import { ConferencePossibleStayPeriod, ConferencePossibleStayPeriodKey } from '@ag-common-lib/public-api';
import { isSameMonth, isValid, toDate } from 'date-fns';

@Pipe({
  name: 'isMultiView',
})
export class IsMultiViewPipe implements PipeTransform {
  transform(conferencePossibleStayPeriod: ConferencePossibleStayPeriod): boolean {
    if (
      !isValid(toDate(conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.shoulderArrivalDate])) ||
      !isValid(toDate(conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.shoulderDepartureDate]))
    ) {
      return true;
    }

    return !isSameMonth(
      conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.shoulderArrivalDate],
      conferencePossibleStayPeriod?.[ConferencePossibleStayPeriodKey.shoulderDepartureDate],
    );
  }
}
