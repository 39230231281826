import { Pipe, PipeTransform } from '@angular/core';
import { Agent } from 'ag-common-lib/lib/models/domain/agent.model';
import { BaseModelKeys } from '@ag-common-lib/public-api';
import { AgentService, QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';
import { firstValueFrom } from 'rxjs';

@Pipe({
  name: 'recipientsDataSource',
})
export class RecipientsDataSourcePipe implements PipeTransform {
  constructor(private agentService: AgentService) {}

  transform(agentsDbId: string[]): Promise<Agent[]> {
    if (!agentsDbId?.length) {
      return Promise.resolve([]);
    }

    console.log('agentsDbId', agentsDbId);

    const promises: Promise<Agent[]>[] = [];

    const batchSize = 30;
    const batchCount = Math.ceil(agentsDbId?.length / batchSize);

    for (let i = 0; i < batchCount; i++) {
      const startIndex = i * batchSize;
      const endIndex = (i + 1) * batchSize;
      const batchValues = agentsDbId.slice(startIndex, endIndex);

      const qp = new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.in, batchValues);

      const promise = firstValueFrom(this.agentService.getList([qp]));

      promises.push(promise);
    }

    return Promise.all(promises).then(items => items.flat(1));
  }
}
