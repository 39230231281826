import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { StripeTransaction } from 'ag-common-lib/lib/models/stripe/stripe-transaction.model';
import { PaymentMethodCreateParams } from '@stripe/stripe-js';
import { AttendeeTransactionsService } from './attendee-transactions.service';
import { AttendeesListService } from '../../../../attendees-list.service';
import {
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from 'ag-common-lib/lib/models/utils/entity-permission.model';
import { Entity } from 'ag-common-lib/lib/lists/entity-permission.enum';

@Component({
  selector: 'ag-crm-attendee-transactions',
  templateUrl: './attendee-transactions.component.html',
  styleUrls: ['./attendee-transactions.component.scss'],
})
export class AttendeeTransactionsComponent {
  protected billingDetails$: Observable<PaymentMethodCreateParams.BillingDetails> =
    this.attendeeTransactionsService.billingDetails$;
  protected registrantTransactions$: Observable<StripeTransaction[]> =
    this.attendeeTransactionsService.registrantTransactions$;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private attendeeTransactionsService: AttendeeTransactionsService,
    private attendeesListService: AttendeesListService,
  ) {}

  protected handleSaveTransaction = this.attendeeTransactionsService.handleSaveTransaction;

  protected handleTransactionRemoving = this.attendeeTransactionsService.handleTransactionRemoving;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
