import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ARMAuthService } from '../shared/services/util/arm-auth.service';
import {
  campaignMenuEntityList,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  RoleEntityPermissionMap,
} from 'ag-common-lib/public-api';
import { hasPermissionGuard } from 'ag-common-svc/shared/utils/has-permission-guard';

@Injectable({ providedIn: 'root' })
export class AgCampaignsGuard implements CanActivate {
  constructor(private authService: ARMAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.armPermissions$.pipe(
      map((roleEntityPermissionMap: RoleEntityPermissionMap) => {
        return hasPermissionGuard(campaignMenuEntityList, roleEntityPermissionMap);
      }),
      tap(hasPermission => {
        if (!hasPermission) {
          this.router.navigate(['/access-denied']);
        }
      }),
    );
  }
}
