<h5>Excursions</h5>

<div class="md-4">
  <p *ngIf="!changeLogs">Data was initialized</p>

  <ng-container *ngFor="let ind of excursionKeysIndexes">
    <ng-container *ngIf="changeLogs?.[ind] as log">
      <ng-container *ngIf="log?.length === 2; else removedExcursionsData">
        <p>{{ ind | changeLogExcursionNameById : excursionsNameLookup }} was changed for {{ attendeeName }}.</p>
        <ng-container
          *ngTemplateOutlet="
            detailsContainer;
            context: {
              log: log,
              type: ChangeLogsType.changed
            }
          "
        ></ng-container>
      </ng-container>

      <ng-template #removedExcursionsData>
        <ng-container *ngIf="log?.length === 3; else addedExcursionsData">
          <p>
            <u>{{ ind | changeLogExcursionNameById : excursionsNameLookup }}</u> was removed for {{ attendeeName }}.
          </p>
        </ng-container>

        <ng-template #addedExcursionsData>
          <p>
            <u>{{ ind | changeLogExcursionNameById : excursionsNameLookup }}</u> was added for {{ attendeeName }}.
          </p>
          <ng-container
            *ngTemplateOutlet="
              detailsContainer;
              context: {
                log: log[0] ?? log,
                type: ChangeLogsType.added
              }
            "
          ></ng-container>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #detailsContainer let-log="log" let-type="type">
  <ng-container *ngFor="let item of selectedExcursionsKeysConfig">
    <ng-container *var="item.value | changeLogChildKey as key">
      <ng-container [ngSwitch]="key">
        <ng-container *ngSwitchCase="SelectedExcursionsKeys.preferences">
          <ag-crm-change-log-excursion-preferences [log]="log?.[key]"></ag-crm-change-log-excursion-preferences>
        </ng-container>

        <ng-container *ngSwitchCase="SelectedExcursionsKeys.configurations">
          <ag-crm-change-log-excursion-configurations [log]="log?.[key]"></ag-crm-change-log-excursion-configurations>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ag-crm-change-log-detail
            *ngIf="logHasKey(key, log)"
            [item]="item.description"
            [type]="type"
            [changeLog]="log?.[key]"
            [dataType]="item?.dataType ?? null"
            [activeLookupName]="item?.activeLookupName ?? null"
            [valueLookup]="item?.valueLookup ?? null"
          ></ag-crm-change-log-detail>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
