import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'registrantFieldFormatter',
})
export class RegistrantFieldFormatterPipe implements PipeTransform {
  transform(registrantKey: string, subKey: string | string[]): string {
    return Array.isArray(subKey) ? [...subKey, registrantKey].join('.') : [subKey, registrantKey].join('.');
  }
}
