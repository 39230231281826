<div class="container p-0 m-0">
  <div class="row p-0 m-0">
    <div class="col-12 p-0 m-0">
      <dx-toolbar class="header-toolbar">
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="backButtonOptions"
          [height]="'10px'"
        ></dxi-item>
        <dxi-item
          location="after"
          widget="dxDropDownButton"
          locateInMenu="auto"
          [options]="dropDownOptions"
          [height]="'10px'"
        ></dxi-item>
      </dx-toolbar>
    </div>
  </div>
</div>
<div class="row p-4 m-0 justify-content-start" *ngIf="selectedDistributionList">
  <div class="col-12 p-2 m-0 align-self-center text-start">
    <dx-form [formData]="selectedDistributionList" [colCount]="5">
      <dxi-item dataField="name" [colSpan]="5"></dxi-item>
      <dxi-item
        dataField="description"
        [colSpan]="5"
        [editorType]="'dxTextArea'"
        [editorOptions]="{ height: 200 }"
      ></dxi-item>
      <dxi-item dataField="owner" [colSpan]="5" [disabled]="true"></dxi-item>
      <dxi-item dataField="created_date" [colSpan]="5" [disabled]="true" [editorType]="'dxDateBox'"></dxi-item>
    </dx-form>
  </div>
</div>
<div class="row d-flex mt-5 justify-content-around" style="margin: 0 auto" *ngIf="selectedDistributionList">
  <div class="col-lg-6 text-center"></div>
  <div class="col-lg-6 text-center">
    <dx-button
      width="100"
      stylingMode="contained"
      text="Save"
      type="default"
      (onClick)="saveDistributionList()"
    ></dx-button>
  </div>
</div>
