import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog, normalizeLog } from '../../utils/normalize-log';
import { LookupKeys, RequestOutcomeKeys, RequestOutcomeState, RequestOutcomeStateMap } from 'ag-common-lib/public-api';
import { AttendeeChangeLogService } from '../../attendee-change-log.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, shareReplay } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ag-crm-change-log-request-outcome-detail',
  templateUrl: './change-log-request-outcome.component.html',
  styleUrls: ['./change-log-request-outcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogRequestOutcomeComponent {
  @Input() log: Delta;

  protected readonly hasLog = hasLog;
  protected readonly normalizeLog = normalizeLog;
  protected readonly requestOutcomeKeys = Object.values(RequestOutcomeKeys);
  protected readonly requestOutcomeLookup = [
    {
      [LookupKeys.value]: RequestOutcomeKeys.state,
      [LookupKeys.description]: 'State',
    },
    {
      [LookupKeys.value]: RequestOutcomeKeys.processedBy,
      [LookupKeys.description]: 'Processed By',
    },
  ];
  protected requestOutcomeKeyWithLookup = {
    [RequestOutcomeKeys.state]: [
      {
        [LookupKeys.value]: RequestOutcomeState.approved,
        [LookupKeys.description]: RequestOutcomeStateMap.get(RequestOutcomeState.approved),
      },
      {
        [LookupKeys.value]: RequestOutcomeState.rejected,
        [LookupKeys.description]: RequestOutcomeStateMap.get(RequestOutcomeState.rejected),
      },
    ],
  };

  constructor(attendeeChangeLogService: AttendeeChangeLogService) {
    attendeeChangeLogService.agentsLookupBase$
      .pipe(
        map(lookup => {
          Object.assign(this.requestOutcomeKeyWithLookup, { [RequestOutcomeKeys.processedBy]: lookup ?? [] });
        }),
        shareReplay(1),
      )
      .subscribe();
  }
}
