import { Inject, Injectable } from '@angular/core';
import { EmailTemplates, LogMessage } from '@ag-common-lib/public-api';
import { differenceInSeconds, format, isAfter, isPast, isValid, parse } from 'date-fns';
import { FirebaseApp } from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { CloudFunctionsService } from './cloud-functions.service';
import { LoggerService } from './logger.service';
import { OTP } from '@ag-common-lib/lib';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class OtpService {
  private readonly RESENT_TIMEOUT = 45;

  private readonly fsDao: CommonFireStoreDao<any>;
  private collection = 'otp';

  public isResendAvailable$: Observable<boolean>;
  private readonly _isResendAvailable$ = new BehaviorSubject<boolean>(true);

  public resendTimeout$: Observable<number>;
  private readonly _resendTimeout$ = new BehaviorSubject<number>(this.RESENT_TIMEOUT);

  public isSendOTPInProgress$: Observable<boolean>;
  private readonly _isSendOTPInProgress$ = new BehaviorSubject<boolean>(false);

  public isOTPSended$: Observable<boolean>;
  private readonly _isOTPSended$ = new BehaviorSubject<boolean>(false);
  private _lastCountdownValue: Date;

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private loggerService: LoggerService,
    private toastrService: ToastrService,
    private cloudFunctionsService: CloudFunctionsService,
  ) {
    this.fsDao = new CommonFireStoreDao<any>(fireBaseApp);

    this.isSendOTPInProgress$ = this._isSendOTPInProgress$.asObservable();
    this.isOTPSended$ = this._isOTPSended$.asObservable();
    this.isResendAvailable$ = this._isResendAvailable$.asObservable();
    this.resendTimeout$ = this._resendTimeout$.asObservable();
  }

  getList(qp: QueryParam[] = []) {
    const path = this.collection;
    return this.fsDao.getList(path, qp);
  }

  public validateOTP = async (otp, email): Promise<boolean | void> => {
    if (!email) {
      const logMessage: LogMessage = this.loggerService.generateLogMessage(
        'OTP_VALIDATION_ISSUE',
        'system',
        'Failed to Detect Google User Email',
        null,
      );

      await this.loggerService.create(logMessage);
      return false;
    }

    let getOtpError;
    const otpData: OTP = await this.fsDao.getById(this.collection, otp).catch(err => {
      getOtpError = err;
      return null;
    });

    if (getOtpError) {
      const logMessage: LogMessage = this.loggerService.generateLogMessage(
        'OTP_VALIDATION_ISSUE',
        email,
        'Get OTP data issue detected',
        { otp, email, getOtpError: getOtpError?.message ?? getOtpError },
      );

      await this.loggerService.create(logMessage);
      return false;
    }

    if (!otpData) {
      const logMessage: LogMessage = this.loggerService.generateLogMessage(
        'OTP_VALIDATION_ISSUE',
        email,
        'OTP Not Found',
        { otp, email },
      );

      await this.loggerService.create(logMessage);

      this.toastrService.error(
        'Entered code is out-of-date. Please check your inbox for the latest code.',
        'One Time Code Invalid',
      );
      return false;
    }

    const userEmail = email ? `${email}`?.trim()?.toLocaleLowerCase() : null;
    const otpEmail = otpData?.email ? `${otpData?.email}`?.trim()?.toLocaleLowerCase() : null;

    if (!otpEmail) {
      return false;
    }

    if (userEmail !== otpEmail) {
      const logMessage: LogMessage = this.loggerService.generateLogMessage(
        'OTP_VALIDATION_ISSUE',
        email,
        'OTP email not match user email',
        { otp, userEmail, otpEmail },
      );

      await this.loggerService.create(logMessage);
      return false;
    }

    if (isPast(otpData?.expirationDate)) {
      const logMessage: LogMessage = this.loggerService.generateLogMessage(
        'OTP_VALIDATION_ISSUE',
        email,
        'OTP Is Expired',
        { otp, email, otpData, dateNow: new Date() },
      );

      await this.loggerService.create(logMessage);

      this.toastrService.error(
        'Entered confirmation code is expired. Please note that code is valid during 5 minutes.',
        'One Time Code Is Expired',
      );
      return false;
    }

    return true;
  };

  public sendOtp = async (
    email,
    emailTemplate:
      | EmailTemplates.confirmEmailUpdateTmp
      | EmailTemplates.confirmRegisterEmailTmp
      | EmailTemplates.confirmPasswordUpdateTmp,
    duration: 5 | 120 = 5,
    subject: string = 'Confirm Your Email',
  ) => {
    try {
      this._isOTPSended$.next(false);
      this._isSendOTPInProgress$.next(true);
      this._isResendAvailable$.next(false);
      await this.cloudFunctionsService
        .sendOTP({ email, duration, emailTemplate, subject })
        .then(data => {
          this.startCountdown();
          this._isOTPSended$.next(true);
        })
        .catch(e => {
          this._isResendAvailable$.next(true);
        })
        .finally(() => {
          this._isSendOTPInProgress$.next(false);
        });
    } catch (error) {
      // todo: show error
    }
  };

  private startCountdown = () => {
    const currentDate = new Date();

    if (!this._lastCountdownValue) {
      this._isResendAvailable$.next(false);
      this._resendTimeout$.next(this.RESENT_TIMEOUT);
      this._lastCountdownValue = currentDate;
      setTimeout(this.startCountdown, 100);

      return;
    }

    const diff = differenceInSeconds(currentDate, this._lastCountdownValue);
    const resendTimeout = this._resendTimeout$.value;
    const secondsLeft = resendTimeout - diff;

    if (secondsLeft <= 0) {
      this._resendTimeout$.next(0);
      this._isResendAvailable$.next(true);
      this._lastCountdownValue = null;
      return;
    }

    if (diff > 0) {
      this._resendTimeout$.next(secondsLeft);
      this._lastCountdownValue = currentDate;
    }

    setTimeout(this.startCountdown, 100);
  };
}
