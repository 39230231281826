import { Pipe, PipeTransform } from '@angular/core';
import { AttendeeFlightData, AttendeeFlightGroup } from '../attendee-flight.service';
import { AttendeeKeys } from 'ag-common-lib/lib/models/registration/attendees.model';

@Pipe({
  name: 'attendeeFlightCopyTargets',
})
export class AttendeeFlightCopyTargetsPipe implements PipeTransform {
  transform(
    flightGroupsMap: Map<string, AttendeeFlightGroup>,
    currentFlightData: AttendeeFlightData,
  ): AttendeeFlightGroup[] {
    const targetFlightGroupsMap = new Map(flightGroupsMap);
    targetFlightGroupsMap.delete(currentFlightData[AttendeeKeys.dbId]);
    return Array.from(targetFlightGroupsMap.values());
  }
}
