<dx-data-grid
  [dataSource]="notSelectedExcursionsDataSource$ | async"
  [showRowLines]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [height]="'100%'"
  [noDataText]="emptyMessage"
  [columnHidingEnabled]="true"
  (onExporting)="onExporting($event)"
>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>

  <dxo-toolbar>
    <dxi-item location="before">
      <span *dxTemplate class="dx-form-group-caption">Persons who haven't selected their excursions yet</span>
    </dxi-item>

    <dxi-item location="before" name="groupPanel"></dxi-item>

    <dxi-item name="exportButton" location="after"></dxi-item>

    <dxi-item name="columnChooserButton" location="after"></dxi-item>
  </dxo-toolbar>

  <dxi-column [dataField]="ExcursionInfoTableDataKeys.fullName" [caption]="'Full Name'" width="auto"></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.attendeeDob"
    [caption]="'Age'"
    [calculateCellValue]="calculateAge"
    [filterOperations]="['<', '<=', '>', '>=', 'between']"
    [selectedFilterOperation]="'<'"
    [width]="150"
  ></dxi-column>

  <dxi-column [dataField]="ExcursionInfoTableDataKeys.attendeeType" [width]="150" [caption]="'Attendee Type'">
    <dxo-lookup [dataSource]="attendeeTypeLookup" displayExpr="description" valueExpr="value"></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.inviteeEmail"
    [caption]="'Invitee Email'"
    width="auto"
    [groupIndex]="1"
  ></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.phoneNumber"
    [caption]="'Phone Number'"
    [editorOptions]="{ mask: phoneMask }"
    cellTemplate="phoneNumberCellTemplate"
    width="auto"
  ></dxi-column>

  <dxi-column
    [dataField]="ExcursionInfoTableDataKeys.excursionState"
    [caption]="'Excursion State'"
    [groupIndex]="0"
    cellTemplate="excursionStateCellTemplate"
    groupCellTemplate="groupExcursionStateCellTemplate"
  ></dxi-column>

  <dxo-summary>
    <dxi-group-item [column]="ExcursionInfoTableDataKeys.excursionState" summaryType="count" displayFormat="{0}">
    </dxi-group-item>
  </dxo-summary>

  <!--Templates-->
  <div *dxTemplate="let cell of 'phoneNumberCellTemplate'">
    <div>{{ [cell.value] | primaryPhoneNumber | phoneNumberMask }}</div>
  </div>

  <div *dxTemplate="let cell of 'excursionStateCellTemplate'">
    {{ WizardStepStateMap.get(cell.value) }}
  </div>

  <div *dxTemplate="let cell of 'groupExcursionStateCellTemplate'">
    Excursion State: {{ WizardStepStateMap.get(cell.value) }} ({{ cell?.data?.aggregates[0] }})
  </div>
</dx-data-grid>
