import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeLogChildKey',
})
export class ChangeLogChildKeyPipe implements PipeTransform {
  transform(str: string): string {
    return str?.split('.').pop() || '';
  }
}
