import { Delta } from 'jsondiffpatch/lib/types';
import { isNil } from 'lodash';

export const normalizeLog = (key, value: Delta) => ({ [key]: value[key] });
export const hasLog = (key, value: Delta) => Object.keys(value).some(id => id === key);
export const logHasKey = (key, value) => value.hasOwnProperty(key);

export const getChangedLog = log => {
  if (!Array.isArray(log)) {
    return log;
  }
  return log.length == 2 && isNil(log[0]) ? log[1] : log[0];
};
