<div class="container p-0 m-0">
  <div class="row p-1 m-0 justify-content-between">
    <div class="col-12 p-0 m-0 align-self-center">
      <dx-html-editor height="500px" [(value)]="note.note">
        <dxo-toolbar [multiline]="true">
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"> </dxi-item>
          <dxi-item
            name="font"
            [acceptedValues]="[
              'Arial',
              'Courier New',
              'Georgia',
              'Impact',
              'Lucida Console',
              'Tahoma',
              'Times New Roman',
              'Verdana'
            ]"
          >
          </dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="bold"></dxi-item>
          <dxi-item name="italic"></dxi-item>
          <dxi-item name="strike"></dxi-item>
          <dxi-item name="underline"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="alignLeft"></dxi-item>
          <dxi-item name="alignCenter"></dxi-item>
          <dxi-item name="alignRight"></dxi-item>
          <dxi-item name="alignJustify"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="orderedList"></dxi-item>
          <dxi-item name="bulletList"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"> </dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="color"></dxi-item>
          <dxi-item name="background"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="link"></dxi-item>
          <dxi-item name="image"></dxi-item>
        </dxo-toolbar>
        <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
      </dx-html-editor>
    </div>
  </div>
  <div class="row d-flex mt-2 justify-content-end" style="margin: 0 auto">
    <div class="col-lg-4 text-end">
      <dx-button width="200" stylingMode="contained" text="Save" type="default" (onClick)="save()"></dx-button>
    </div>
    <div class="col-lg-4 text-end">
      <dx-button width="200" stylingMode="contained" text="Close" type="default" (onClick)="close()"></dx-button>
    </div>
  </div>
</div>
