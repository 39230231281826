import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeFlightComponent } from './attendee-flight.component';
import { AttendeeFlightService } from './attendee-flight.service';
import { AgAssignOwnerViewerModule, ButtonWithIndicatorModule } from 'ag-common-svc/lib/components';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { DxDropDownButtonModule, DxPopoverModule } from 'devextreme-angular';
import { AttendeeFlightPipesModule } from './pipes/attendee-flight-pipes.module';
import { AttendeeFlyingGridModule } from './attendee-flying-grid/attendee-flying-grid.module';

@NgModule({
  declarations: [AttendeeFlightComponent],
  imports: [
    CommonModule,
    ButtonWithIndicatorModule,
    SharedModule,
    AttendeeFlyingGridModule,
    DxPopoverModule,
    DxDropDownButtonModule,
    AttendeeFlightPipesModule,
    AgAssignOwnerViewerModule,
  ],
  exports: [AttendeeFlightComponent],
  providers: [AttendeeFlightService],
})
export class AttendeeFlightModule {}
