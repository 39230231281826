import { Component, ViewChild } from '@angular/core';
import { ActiveLookup, Constants, EmailTemplates, Messages } from '@ag-common-lib/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { SetLoginEmailModalService } from './set-login-email-modal.service';
import { SetLoginEmailForm, SetLoginEmailModalSteps } from './set-login-email-modal.model';
import { OtpService } from '../../../../services/otp.service';

@Component({
  selector: 'ag-shr-set-login-email-modal',
  templateUrl: './set-login-email-modal.component.html',
  styleUrls: ['./set-login-email-modal.component.scss'],
  providers: [OtpService, SetLoginEmailModalService],
})
export class SetLoginEmailModalComponent {
  @ViewChild('setPrimaryEmailModalRef', { static: true }) setPrimaryEmailModalComponent: ModalWindowComponent;
  @ViewChild('setPrimaryEmailFormRef', { static: false }) setPrimaryEmailFormComponent: DxFormComponent;

  formData: SetLoginEmailForm;
  hasSameEmailOnOtherAgent = false;
  SetLoginEmailModalSteps = SetLoginEmailModalSteps;
  emailAddressesLookup$: Observable<ActiveLookup[]>;
  inProgress$ = this.setLoginEmailModalService.inProgress$;
  isSendOTPInProgress$ = this.otpService.isSendOTPInProgress$;
  isOTPSended$ = this.otpService.isOTPSended$;
  isEmailValid$ = this.setLoginEmailModalService.isEmailValid$;
  isEmailExistOnOtherRecord$ = this.setLoginEmailModalService.isEmailExistOnOtherRecord$;
  isResendAvailable$ = this.otpService.isResendAvailable$;
  resendTimeout$ = this.otpService.resendTimeout$;
  selectedStep$ = this.setLoginEmailModalService.selectedStep$;

  protected readonly Messages = Messages;
  readonly oneTimeCodeDigits = Constants.ONE_TIME_CODE_DIGITS;

  constructor(private setLoginEmailModalService: SetLoginEmailModalService, private otpService: OtpService) {}

  showModal = async (agentId: string, agentUID: string): Promise<void> => {
    this.setLoginEmailModalService.init(agentId, agentUID);
    this.formData = this.setLoginEmailModalService.formData;
    this.emailAddressesLookup$ = this.setLoginEmailModalService.emailAddressesLookup$;
    this.setPrimaryEmailModalComponent?.showModal();
  };

  emailAddressAsyncValidation = item =>
    this.setLoginEmailModalService.emailAddressAsyncValidation(item?.value as ActiveLookup);

  sendOtp = () =>
    this.otpService.sendOtp(this.formData?.emailAddress?.description, EmailTemplates.confirmEmailUpdateTmp);

  otpAsyncValidation = item => this.setLoginEmailModalService.otpAsyncValidation(item?.value);

  handleNextStepClick = async (e): Promise<void> => {
    this.setLoginEmailModalService.handleNextStepClick(this.setPrimaryEmailFormComponent, e.component);
  };

  backToEmailList = this.setLoginEmailModalService.backToEmailList;
}
