import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { Timestamp } from 'firebase/firestore';
import { ToastrService } from 'ngx-toastr';
import { Contest, Entity, EntityPermissionActivityKeys, EntityPermissionModelKeys } from 'ag-common-lib/public-api';
import { ContestService } from 'src/app/shared/services/contest.service';

@Component({
  selector: 'app-contests',
  templateUrl: './contests.component.html',
  styleUrls: ['./contests.component.scss'],
})
export class ContestsComponent {
  @ViewChild('contestGrid') contestGrid: DxDataGridComponent;

  selectedContest: Contest;

  contestRegistrationListVisible: boolean = false;
  contestDetailsVivible: boolean = false;

  startDateEditorOptions: any = {};
  endDateEditorOptions: any = {};

  dataSource = {};

  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(public router: Router, public contestService: ContestService, public toastrService: ToastrService) {
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: function (loadOptions: any) {
        return contestService.getAll('name');
      },
      insert: function (value: Contest) {
        return contestService.create(value);
      },
      update: function (key: any, value: Contest) {
        return contestService.update(value);
      },
      remove: function (id: any) {
        return contestService.delete(id);
      },
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: this.viewContestDetails.bind(this),
      },
    });
  }

  viewContestDetails(e) {
    if (e.row && e.row.data) {
      this.selectedContest = e.row.data;
    } else {
      this.selectedContest = { ...new Contest() };
    }

    this.startDateEditorOptions = {
      placeholder: this.getStartDate(this.selectedContest),
      onValueChanged: e => {
        var d = new Date(e.value);
        this.selectedContest.start_date = new Timestamp(d.getTime() / 1000, 0);
      },
    };

    this.endDateEditorOptions = {
      placeholder: this.getEndDate(this.selectedContest),
      onValueChanged: e => {
        var d = new Date(e.value);
        this.selectedContest.start_date = new Timestamp(d.getTime() / 1000, 0);
      },
    };
    this.contestDetailsVivible = true;
  }

  saveContestDetails() {
    if (this.selectedContest.dbId) {
      this.contestService.update(this.selectedContest).then(() => {
        this.toastrService.success('Contest Successfully Saved');
        this.contestDetailsVivible = false;
        this.contestGrid.instance.refresh();
      });
    } else {
      this.contestService.create(this.selectedContest).then(() => {
        this.toastrService.success('Contest Successfully Created');
        this.contestDetailsVivible = false;
        this.contestGrid.instance.refresh();
      });
    }
  }

  viewContestRegistrationList(e) {
    this.contestRegistrationListVisible = true;
  }

  showContestInfo(e) {
    this.router.navigate(['Contact-Management']);
  }

  getStartDate(e): string {
    if (e.start_date) {
      return new Date(e.start_date.seconds * 1000).toLocaleDateString();
    } else {
      return new Date().toLocaleDateString();
    }
  }

  getEndDate(e): string {
    if (e.end_date) {
      return new Date(e.end_date.seconds * 1000).toLocaleDateString();
    } else {
      return new Date().toLocaleDateString();
    }
  }

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
